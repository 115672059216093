import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const LinkVehicleForm = forwardRef(({vehicle, job = null, toJob = false, order, onSave = null, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={vehicle}
      type="vehicle"
      fieldsComponent={(props) => <Fields {...props} job={job} toJob={toJob} order={order}/>}
      onSave={useCallback(({vehicle}) => (
        onSave ? onSave(vehicle) : services.vehicle.linkJob(vehicle, job.id)
      ), [services, job, onSave])}
      onSaved={onSaved}
    />
  );
});

export default LinkVehicleForm;
