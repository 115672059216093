import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const SwapProductForm = forwardRef(({product, onSave = null, onSaved}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={product}
      type="product"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={useCallback(({alternative}) => onSave(alternative), [onSave])}
      onSaved={onSaved}
    />
  );
});

export default SwapProductForm;
