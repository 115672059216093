import {DetailColumns, DetailPane, name} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import LocationList from '../../components/LocationList';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import What from './JobDetails';
import NewContactLocation from './NewContactLocation';
import withContact from './withContact';

const Slide = ({contact, onNext, goBack}) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (contact) {
      setLocations(contact.locations.map(location => ({...location, type: location.type?.name ?? 'Contact'})));
    }
  }, [contact]);

  return (
    <>
      <Header>Contact Locations</Header>
      <DetailColumns columns={[

        <DetailPane
          title="Core Details"
          details={[
            {title: 'Name', value: contact ? name(contact) : '-'},
            {title: 'Job Title', value: contact?.position ?? '-'}
          ]}
        />,


        <DetailPane
          title="Contact Details"
          details={[
            {title: 'Email Address', value: contact?.email ?? '-', link: contact?.email ? `mailto:${contact.email}` : null},
            {title: 'Telephone Number', value: contact?.mobile ?? '-'},
            {title: 'Mobile Number', value: contact?.phone ?? '-'}
          ]}
        />,

      ]}/>
      <Box marginTop={2}>
        <LocationList
          locations={locations}
          layout="wide"
          addNew={{props: {}, type: ''}}
          onSelect={(selected) => {
            if (Object.keys(selected) < 1) {
              onNext({type: NewContactLocation.type, id: NewContactLocation.type});
            }
          }}
        />
      </Box>
      <Controls
        onBack={goBack}
        onNext={() => onNext({type: What.type, id: What.type})}
        nextLabel="Continue to Job Details"
      />
    </>
  );
};

const ContactLocations = withContact(Slide);

ContactLocations.type = 'CONTACT_LOCATIONS';

export default ContactLocations;
