import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const PaymentForm = forwardRef(({invoice, subscription, payment, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={payment}
      type="payment"
      fieldsComponent={Fields}
      onSave={useCallback((update) => (
        invoice ? services.invoice.savePayment(invoice, update) : services.subscription.savePayment(subscription, update)
      ), [services, invoice, subscription])}
      onSaved={onSaved}
    />
  );
});

export default PaymentForm;
