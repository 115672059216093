import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import ProductForm from '../../forms/ProductForm';
import Associations from './Associations';
import Wrapper from './Wrapper';
import Tabs from './Tabs';

export default function Product({history, archive = false}) {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    product: 'Sorry the product could not be accessed'
  }), []));

  const {
    entity: product,
    setEntity: setProduct,
    loadEntity: loadProduct,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.product.getProduct,
    archive,
    useCallback((product) => [{title: product.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('product', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setProduct(updated);
    } else if (product?.id) {
      loadProduct(product.id)
    }
  }, [toggleDialog, setProduct, loadProduct, product]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.product.restoreProduct(product.id).then((restored) => {
      setLoading(false);
      setProduct(restored)
      history.push(reverse(`${routes.stock.products.detail}`, {id: product.id}))
    }).catch(() => setLoading(false));
  }, [services, history, product, setProduct, setLoading]);

  return (
    <Wrapper title="Product" loading={loading} product={product} crumbs={crumbs} errors={errors}>
      {product?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Product Details"
              minHeight={400}
              actions={!product.archived ? [
                {title: 'Edit Product', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Product', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {
                  title: 'Restore Product', icon: <RestoreFromTrashIcon/>, onClick: handleRestore
                },
              ]}
              details={[
                {title: 'Name', value: product.name ?? '-'},
                {title: 'Code', value: product.code ?? '-'},
                {title: 'SKU', value: product.sku ?? '-'},
                {title: 'Purchase Price', value: <Currency amount={product.purchase_price}/>},
                {title: 'Sale Price', value: <Currency amount={product.sale_price}/>},
                {
                  title: 'Manufacturer',
                  value: product.manufacturer ? product.manufacturer.name : '-',
                  route: product.manufacturer ? reverse(
                    product.manufacturer.archived ? routes.archive.companies.detail : routes.companies.detail,
                    {id: product.manufacturer.id}
                  ) : null
                },
                {
                  title: 'Review Link',
                  value: product.review_url ? 'Click here' : '-',
                  link: product.review_url ?? null
                },
                {title: 'Scorpion Product?', value: <YesNo value={product.is_scorpion}/>},
                {title: 'Safe Track Product?', value: <YesNo value={product.is_safe_track}/>},
                {title: 'Fleet Product?', value: <YesNo value={product.is_fleet}/>},
                {title: 'Has Driver ID Tags (Scorpion or Safe Track only)?', value: <YesNo value={product.has_tags}/>},
                {
                  title: 'Can Immobilise (Scorpion or Safe Track only)?',
                  value: <YesNo value={product.has_immobiliser}/>
                },
                {title: 'Hidden on Invoices by Default?', value: <YesNo value={product.is_hidden_on_invoices}/>},
                {title: 'Requires Certificate?', value: <YesNo value={product.requires_certificate}/>},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Product"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <ProductForm
                        {...props}
                        product={product}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={product ? `Are you sure you want to delete ${product.name}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.product.deleteProduct(product.id).then(() => {
                          setLoading(false);
                          history.push(reverse(routes.stock.index, {tab: 'products'}))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <Box display="flex" flexDirection="column" width="100%">
              <Box width="100%" display="flex" flex={1} marginBottom={1}>
                <DetailPane
                  title="Available"
                  details={[
                    {title: 'Quantity', value: product.available.quantity},
                    {title: 'Cost', value: <Currency amount={product.available.cost}/>},
                    {title: 'Value', value: <Currency amount={product.available.value}/>},
                  ]}
                />
              </Box>
              <Box width="100%" display="flex" flex={1} marginTop={1}>
                <DetailPane
                  title="Allocated"
                  details={[
                    {title: 'Quantity', value: product.allocated.quantity},
                    {title: 'Cost', value: <Currency amount={product.allocated.cost}/>},
                    {title: 'Value', value: <Currency amount={product.allocated.value}/>},
                  ]}
                />
              </Box>
            </Box>,

            <Box display="flex" flexDirection="column" width="100%">
              <Box width="100%" display="flex" flex={1} marginBottom={1}>
                <Associations product={product} onLoading={setLoading}/>
              </Box>
              <Box width="100%" display="flex" flex={1} marginTop={1}>
                <DetailPane
                  title="Admin Details"
                  details={[
                    {title: 'Created', value: fullDateTime(product.created_at)},
                    {title: 'Last Updated', value: fullDateTime(product.updated_at)},
                    ...(product.archived ? [{title: 'Archived', value: fullDateTime(product.deleted_at)}] : [])
                  ]}
                />
              </Box>
            </Box>,
          ]}/>
          <Tabs
            product={product}
            onReloadProduct={() => loadProduct(product.id, true)}
            onLoading={setLoading}
          />
        </>
      ) : null}
    </Wrapper>
  );
}
