import {FormDialog} from '@management-ui/core';
import {LocalShipping, WorkOutline} from '@mui/icons-material';
import {styled} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import DeliveryForm from '../../forms/DeliveryForm';
import LinkJobForm from '../../forms/LinkJobForm';
import OrderJobForm from '../../forms/OrderJobForm';
import Blocks from './Blocks';
import ContactSection from './ContactSection';
import Items from './Items';
import Section from './Section';
import Vehicles from './Vehicles';

const Columns = styled('div')(({theme}) => ({
  display: 'flex',

  '& > div': {
    flex: '0 0 50%',
    width: '50%',

    '&:first-of-type': {
      paddingRight: theme.spacing(1),
    },

    '&:last-of-type': {
      paddingLeft: theme.spacing(1),
    }
  }
}));

const Management = ({order, setOrder, loadOrder, loading, setLoading, showLinkJob, setShowLinkJob}) => {
  const services = useContext(ServiceContext);

  const [showAddJob, setShowAddJob] = useState(false);
  const [showAddDelivery, setShowAddDelivery] = useState(false);

  const handleLinkJob = useCallback(({job_id}) => {
    setShowLinkJob(false);
    return new Promise(resolve => {
      if (job_id) {
        setLoading(true);
        services.job.saveJob({id: job_id, order_id: order.id}).then(() => {
          setLoading(false);
          loadOrder(order.id);
          resolve();
        }).catch(() => {
          setLoading(false);
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, [services, setLoading, order, loadOrder, setShowLinkJob]);

  return (
    <>
      <ContactSection
        title="Billing Details"
        prefix="billing"
        order={order}
        loading={loading}
        onLoading={setLoading}
        onOrderUpdated={setOrder}
        onLoadOrder={loadOrder}
      />

      <ContactSection
        title="Installation Details"
        prefix="installation"
        order={order}
        toggle={{field: 'installation_at_billing', message: 'Install at Billing Address'}}
        loading={loading}
        onLoading={setLoading}
        onOrderUpdated={setOrder}
        onLoadOrder={loadOrder}
      />

      <ContactSection
        title="Delivery Details"
        prefix="delivery"
        order={order}
        toggle={{field: 'deliver_to_billing', message: 'Deliver to Billing Address'}}
        loading={loading}
        onLoading={setLoading}
        onOrderUpdated={setOrder}
        onLoadOrder={loadOrder}
      />

      {order.status.slug === 'paid' ? (
        <Columns>
          <Section title="Jobs">
            <Blocks
              blocks={order.jobs.map(({id, reference}) => ({
                title: reference,
                icon: <WorkOutline/>,
                link: reverse(routes.jobs.detail, {id})
              }))}
              linkExisting={{title: 'Link an Existing Job', onSelect: () => setShowLinkJob(true)}}
              addNew={{title: 'Add a New Job', onSelect: () => setShowAddJob(true)}}
              loading={loading}
            />
            <FormDialog
              title="Add a New Job"
              open={showAddJob}
              maxWidth="sm"
              onClose={() => setShowAddJob(false)}
              render={(props) => (
                <OrderJobForm
                  {...props}
                  order={order}
                  job={{}}
                  onSaved={() => setShowAddJob(false) || loadOrder(order.id)}
                />
              )}
            />
            <FormDialog
              title="Link an Existing Job"
              open={showLinkJob}
              maxWidth="sm"
              onClose={() => setShowLinkJob(false)}
              render={(props) => (
                <LinkJobForm
                  {...props}
                  job={{}}
                  onSave={handleLinkJob}
                />
              )}
            />
          </Section>
          <Section title="Deliveries">
            <Blocks
              blocks={order['deliveries'].map(({id, reference}) => ({
                title: reference,
                icon: <LocalShipping/>,
                link: reverse(routes.orders.deliveries.detail, {orderID: order.id, id})
              }))}
              addNew={{title: 'Add a New Delivery', onSelect: () => setShowAddDelivery(true)}}
              loading={loading}
            />
            <FormDialog
              title="Add a New Delivery"
              open={showAddDelivery}
              maxWidth="sm"
              onClose={() => setShowAddDelivery(false)}
              render={(props) => (
                <DeliveryForm
                  {...props}
                  order={order}
                  delivery={{}}
                  onSaved={() => setShowAddDelivery(false) || loadOrder(order.id)}
                />
              )}
            />
          </Section>
        </Columns>
      ) : null}


      <Section title="Vehicles">
        <Vehicles order={order} onOrderUpdated={setOrder} loading={loading} onLoading={setLoading}/>
      </Section>

      <Section title="Items">
        <Items order={order} onOrderUpdated={setOrder} loading={loading} onLoading={setLoading}/>
      </Section>
    </>
  );
}

export default Management;
