import {styled} from '@mui/material';

const Button = styled('button')(({theme}) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.success.light,
  border: 0,
  borderRadius: theme.spacing(1),
  color: theme.palette.common.white,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: '700',
  margin: 0,
  padding: theme.spacing(1, 2),
  transition: '0.25s background-color ease-in-out',

  '& > svg': {
    margin: theme.spacing(0, 1, 0, 0),
  },

  '&.action': {
    backgroundColor: theme.palette.grey['300'],
    color: theme.palette.text.primary,
    fontWeight: '400',

    '&:hover': {
      backgroundColor: theme.palette.grey['200'],
    },
  },

  '&.small': {
    fontSize: '0.9em',
    padding: theme.spacing(0.5, 1),

    '& > svg': {
      marginRight: theme.spacing(0.5),
      width: 16,
    }
  },

  '&.padRight': {
    margin: theme.spacing(0, 1, 0, 0),
  }
}));

export default Button;
