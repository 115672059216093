import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import FuelTypeForm from '../../forms/FuelTypeForm';

const FuelTypes = () => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Fuel Types"
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Scorpion Reference', field: 'scorpion_reference'},
        {title: 'UKVD Reference', field: 'ukvd_reference'},
        {title: 'Default?', field: 'is_default', lookup: {true: 'Yes', false: 'No'}},
      ], [])}
      loadData={query => new Promise((resolve, reject) => {
        services.fuelType.getTableTypes(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Type',
        render: (props) => <FuelTypeForm type={{}} {...props}/>
      }}
      editForm={{
        render: (type, props) => <FuelTypeForm type={type} {...props}/>
      }}
    />
  );
};

export default FuelTypes;
