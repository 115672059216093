import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const UserForm = forwardRef(({user, admin = false, engineer = false, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={user}
      type="user"
      fieldsComponent={(props) => <Fields admin={admin} engineer={engineer} {...props}/>}
      onSave={(update) => services.user.saveUser({
        ...update,
        ...(admin ? {is_admin: true} : {}),
        ...(engineer ? {is_engineer: true} : {})
      })}
      onSaved={onSaved}
    />
  );
});

export default UserForm;
