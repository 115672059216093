import numeral from 'numeral';
import React from 'react';
import AttributeUpdate from './AttributeUpdate';

export const ADDRESS_ATTRS = {
  address_number: {title: 'House Number', render: null},
  address1: {title: 'Address Line 1', render: null},
  address2: {title: 'Address Line 2', render: null},
  town: {title: 'Town', render: null},
  region: {title: 'Region', render: null},
  postcode: {title: 'Postcode', render: null},
  country: {title: 'Country', render: (action) => action.country ? action.country.name : ''},
  latitude: {title: 'Latitude', render: (action) => action.latitude ? numeral(action.latitude).format('0,0.00000') : 0},
  longitude: {
    title: 'Longitude',
    render: (action) => action.longitude ? numeral(action.longitude).format('0,0.00000') : 0
  },
};

const ATTRS = {
  name: {
    title: 'Name',
    render: (action) => action.name ? (typeof action.name === 'string' ? action.name : action.name.label) : ''
  },
  type: {title: 'Type', render: (action) => action.type ? action.type.name : ''},
  ...ADDRESS_ATTRS,
  phone: {title: 'Phone', render: null},
  notes: {title: 'Notes', render: null},
};

export default function LocationUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
