import {useDetail, useErrors} from '@management-ui/core';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import JobAllocationForm from '../../../forms/JobAllocationForm';
import Wrapper from '../Wrapper';

export default function New() {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({
    job: 'Sorry the job could not be accessed',
  }), []));

  const {
    entity: job,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.job.getJob,
    false,
    useCallback((job) => [
      {title: job.reference, link: reverse(routes.jobs.detail, {id: job.id})},
      {title: 'New Allocation'}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('job', displayError), [toggleError])
  );

  return (
    <Wrapper title="New Allocation" loading={loading} job={job} crumbs={crumbs} errors={errors}>
      {job?.id ? (
        <Box marginTop={2}>
          <JobAllocationForm
            job={job}
            allocation={{}}
            additionalAvailability={[]}
            onSaved={(saved) => history.push(reverse(routes.jobs.detail, {id: saved.job.id}))}
            loading={loading}
            onLoading={setLoading}
          />
        </Box>
      ) : null}
    </Wrapper>
  );
}
