import {makeStyles} from '@mui/styles';
import React from 'react';
import Day from './Day';

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  return {
    container: {
      borderBottom: border,
      display: 'flex',

      '&:last-child': {
        borderBottom: 'none',
      }
    },

    day: {
      borderLeft: border,
      display: 'flex',
      flex: 1,
      minHeight: 100,

      '&:first-child': {
        borderLeft: 'none',
      }
    },

    header: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      justifyContent: 'center',
      fontSize: '1.4em',
      fontWeight: '700',
      minHeight: 50,
      padding: theme.spacing(2),
    }
  }
});

const Week = ({dayHeaders = false, week, month, onSelectDay, onSelectEvent, onRenderNotes, onRenderEvents}) => {
  const classes = useStyles();
  return (
    <>
      {dayHeaders ? (
        <div className={classes.container}>
          {week.map((day, index) => (
            <div key={index} className={[classes.day, classes.header].join(' ')}>
              {day.date.format('ddd')}
            </div>
          ))}
        </div>
      ) : null}
      <div className={classes.container}>
        {week.map((day, index) => (
          <div key={index} className={classes.day}>
            <Day
              day={day}
              month={month}
              onSelectDay={onSelectDay}
              onSelectEvent={onSelectEvent}
              onRenderNotes={onRenderNotes}
              onRenderEvents={onRenderEvents}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Week;
