import {makeStyles} from '@mui/styles';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  amount: {
    fontFamily: 'Roboto Mono',
  }
}));

const Currency = ({amount, classes: extraClasses = []}) => {
  const classes = useStyles();
  return <span className={[classes.amount, ...extraClasses].join(' ')}>{`£${numeral(amount).format('0,0.00')}`}</span>
};

Currency.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default Currency;
