import {Paper} from '@mui/material';
import React from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import Table from '../Companies/Table';
import CompanyLocations from './CompanyLocations';
import NewCompany from './NewCompany';
import Who from './Who';

const SelectCompany = ({onBack, onNext}) => {
  return (
    <>
      <Header>Select an Existing Company</Header>
      <Paper>
        <Table
          title=""
          onSelect={({id, name}) => onNext(
            {type: CompanyLocations.type, id: CompanyLocations.type},
            {selectedCompany: {id, name}}
          )}
          canCreate={false}
        />
      </Paper>
      <Controls
        onBack={() => onBack({type: Who.type, id: Who.type})}
        onNext={() => onNext({type: NewCompany.type, id: NewCompany.type})}
        nextLabel="Create a New Company"
      />
    </>
  );
};

SelectCompany.type = 'SELECT_COMPANY';

export default SelectCompany;
