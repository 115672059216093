import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import moment from 'moment';
import {Button, styled} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Currency from '../../../../components/Currency';
import {Download} from '@mui/icons-material';
import SummaryModal from './SummaryModal';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  transition: '0.25s opacity ease-in-out',

  '&.loading': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

const Buttons = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2),
}));

const YearSelector = styled('div')(({theme}) => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  const buttonSize = '60px';

  return ({
    borderBottom: border,
    borderTop: border,
    display: 'flex',
    margin: theme.spacing(2, 0, 0),

    '& h2': {
      alignItems: 'center',
      borderLeft: border,
      borderRight: border,
      display: 'flex',
      flex: 1,
      fontSize: '1.4em',
      fontWeight: 700,
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      textAlign: 'center',
    },

    '& button': {
      alignItems: 'center',
      background: 'none',
      border: 0,
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      display: 'flex',
      height: buttonSize,
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      width: buttonSize,

      '&:focus': {
        outline: 0,
      }
    }
  })
});

const Summaries = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: theme.spacing(1),
}));

const Break = styled('div')(() => ({
  flex: '0 0 100%',
  height: 0,
  width: '100%',
}));

const SummaryContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flex: `0 0 ${100 / 3}%`,
  flexDirection: 'column',
  padding: theme.spacing(1),
  textAlign: 'center',
  width: `${100 / 3}%`,

  '& > h3': {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '1.4em',
    margin: 0,
    padding: theme.spacing(0.5),
  }
}));

const SummaryRow = styled('div')(() => ({
  display: 'flex',
}));

const SummaryColumn = styled('div')(({theme}) => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  return {
    borderBottom: border,
    borderLeft: border,
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',

    '&:last-child': {
      borderRight: border,
    },

    '& > h4': {
      backgroundColor: theme.palette.grey['100'],
      cursor: 'pointer',
      flex: '0 0 100%',
      fontSize: '1.1em',
      margin: 0,
      padding: theme.spacing(0.5, 0),
      transition: '0.25s background-color ease-in-out',
      width: '100%',

      '&:hover': {
        backgroundColor: theme.palette.grey['200'],
      }
    },

    '& > div': {
      flex: 1,

      '& > h5': {
        color: theme.palette.grey['500'],
        fontSize: '0.8em',
        margin: 0,
        padding: theme.spacing(0.5, 0),
      },

      '& > h6': {
        fontSize: '0.95em',
        margin: 0,
        padding: theme.spacing(0.5, 0),
      },

      '&:nth-of-type(1) > h6': {
        color: theme.palette.info.main,
      },

      '&:nth-of-type(2) > h6': {
        color: theme.palette.warning.main,
      },

      '&:nth-of-type(3) > h6': {
        color: theme.palette.success.main,
      }
    },
  };
});

const Summary = (
  {
    title,
    period: {
      amount,
      cost,
      profit,
      count,
      recurring: {amount: recurringAmount, cost: recurringCost, profit: recurringProfit, count: recurringCount},
      fixed: {amount: fixedAmount, cost: fixedCost, profit: fixedProfit, count: fixedCount},
    },
    onSelect
  }
) => (
  <SummaryContainer>
    <h3>{title}</h3>
    <SummaryRow>
      <SummaryColumn>
        <h4 onClick={() => onSelect(title, null)}>Total ({count} Payments)</h4>
        <div>
          <h5>Revenue:</h5>
          <h6><Currency amount={amount}/></h6>
        </div>
        <div>
          <h5>Cost:</h5>
          <h6><Currency amount={cost}/></h6>
        </div>
        <div>
          <h5>Profit:</h5>
          <h6><Currency amount={profit}/></h6>
        </div>
      </SummaryColumn>
    </SummaryRow>
    <SummaryRow>
      <SummaryColumn>
        <h4 onClick={() => onSelect(title, 'recurring')}>Recurring ({recurringCount} Payments)</h4>
        <div>
          <h5>Revenue:</h5>
          <h6><Currency amount={recurringAmount}/></h6>
        </div>
        <div>
          <h5>Cost:</h5>
          <h6><Currency amount={recurringCost}/></h6>
        </div>
        <div>
          <h5>Profit:</h5>
          <h6><Currency amount={recurringProfit}/></h6>
        </div>
      </SummaryColumn>
      <SummaryColumn>
        <h4 onClick={() => onSelect(title, 'fixed')}>Fixed ({fixedCount} Payments)</h4>
        <div>
          <h5>Revenue:</h5>
          <h6><Currency amount={fixedAmount}/></h6>
        </div>
        <div>
          <h5>Cost:</h5>
          <h6><Currency amount={fixedCost}/></h6>
        </div>
        <div>
          <h5>Profit:</h5>
          <h6><Currency amount={fixedProfit}/></h6>
        </div>
      </SummaryColumn>
    </SummaryRow>
  </SummaryContainer>
);

const Payments = () => {
  const services = useContext(ServiceContext);
  const [selectedYear, setSelectedYear] = useState(null);
  const years = useRef({});
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [detailTitle, setDetailTitle] = useState('');
  const [canCheck, setCanCheck] = useState(false);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (selectedYear) {
      if (Object.keys(years.current).indexOf(`${selectedYear}`) < 0) {
        setLoading(`Loading ${selectedYear}...`);
        services.reporting.getPaymentYear(selectedYear).then((response) => {
          years.current[`${selectedYear}`] = response;
          setReport(response);
          setLoading('');
        }).catch(() => setLoading(''))
      } else {
        setReport(years.current[`${selectedYear}`]);
      }
    } else {
      setSelectedYear(parseInt(moment().format('YYYY')));
    }
  }, [services, selectedYear]);

  const handleNext = useCallback(() => {
    setSelectedYear(selectedYear + 1);
  }, [selectedYear]);

  const handleBack = useCallback(() => {
    setSelectedYear(selectedYear - 1);
  }, [selectedYear]);

  const handleExport = useCallback(() => {
    services.reporting.downloadExport().then(() => null).catch(() => null);
  }, [services]);

  const handleSelect = useCallback((period, type) => {
    const match = report.months.find(({title}) => title === period);
    let recurring = [];
    let fixed = [];
    if (match) {
      recurring = match.recurring.payments;
      fixed = match.fixed.payments;
    } else {
      for (let month of report.months) {
        recurring = [...recurring, ...month.recurring.payments];
        fixed = [...fixed, ...month.fixed.payments];
      }
    }
    let payments = [];
    if (!type || type === 'recurring') {
      payments = [...payments, ...recurring];
    }
    if (!type || type === 'fixed') {
      payments = [...payments, ...fixed];
    }
    payments.sort((a, b) => moment(a.date).isBefore(moment(b.date)) ? -1 : 1);
    setPayments(payments);
    setShowDetail(true);
    let title = 'All';
    if (type === 'recurring') {
      title = 'Recurring';
    } else if (type === 'fixed') {
      title = 'Fixed';
    }
    title = `${report.title} - ${title} Payments`;
    if (match) {
      title = `${match.title} ${title}`;
    }
    setDetailTitle(title);
    setCanCheck(!type && !!match);
  }, [report]);

  return (
    <Container className={loading === '' ? '' : 'loading'}>
      <Buttons>
        <Button onClick={handleExport} disabled={loading !== ''} variant="contained"><Download/> Export All</Button>
      </Buttons>
      <YearSelector>
        <button onClick={handleBack}><ChevronLeftIcon/></button>
        <h2><span>{loading === '' ? selectedYear : loading}</span></h2>
        <button onClick={handleNext}><ChevronRightIcon/></button>
      </YearSelector>
      {report ? (
        <Summaries>
          <Summary title="Annual Totals" period={report} onSelect={handleSelect}/>
          <Break/>
          {report.months.map((month, index) => (
            <Summary title={month.title} period={month} onSelect={handleSelect} key={index}/>
          ))}
        </Summaries>
      ) : null}
      <SummaryModal
        open={showDetail}
        onClose={() => setShowDetail(false)}
        title={detailTitle}
        showCheck={canCheck}
        payments={payments}
      />
    </Container>
  );
}

export default Payments;