import React, {useEffect, useState} from 'react';
import Table from '../../../../../components/Table';
import Metrics from '../../../forms/JobAllocationForm/Metrics';

const HasAssociatedSerials = ({job}) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (job) {
      setDisplay(job.products.filter(
        ({items}) => items.filter(
          ({associated_serial_number}) => !!associated_serial_number
        ).length > 0
      ).length > 0);
    } else {
      setDisplay(false);
    }
  }, [job]);

  return (
    <Table.Notice
      display={display}
      colour={Metrics.COLOURS.amber}>
      {display ? 'This job has stock with associated components' : ''}
    </Table.Notice>
  );
};

export default HasAssociatedSerials;
