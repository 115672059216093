import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  selected: {
    margin: 0,
    padding: `0 0 ${theme.spacing(2)}`,
    textAlign: 'center',

    '& span:first-child': {
      color: theme.palette.grey['500'],
      fontStyle: 'italic',
      marginRight: theme.spacing(1)
    },

    '& span:last-child': {
      fontWeight: '600',
    },
  }
}));

const Selected = ({title, value}) => {
  const classes = useStyles();
  return (
    <p className={classes.selected}>
      <span>Selected {title}:</span>
      <span>{value}</span>
    </p>
  );
}

export default Selected;
