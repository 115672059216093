import {ConfirmationDialog, DetailPane, FormDialog, fullDate, name} from '@management-ui/core';
import {MoneyOff} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SendIcon from '@mui/icons-material/Send';
import {Alert, Menu, MenuItem, Snackbar} from '@mui/material';
import {useStripe} from '@stripe/react-stripe-js';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import InvoiceForm from '../../forms/InvoiceForm';

const Details = ({invoice, onUpdate, onLoading, openDialogs, toggleDialog, contacts}) => {
  const services = useContext(ServiceContext);
  const stripe = useStripe();
  const history = useHistory();
  const tokenRef = useRef('');
  const {search} = useLocation();
  const [actions, setActions] = useState([]);
  const [showContacts, setShowContacts] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [contact, setContact] = useState(null);
  const [showSent, setShowSent] = useState(false);
  const [contactAnchor, setContactAnchor] = useState(null);

  const [showMethods, setShowMethods] = useState(false);
  const [methodAnchor, setMethodAnchor] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const payerID = params.get('PayerID');
    if (invoice && invoice.status.slug !== 'paid' && token && tokenRef.current !== token && payerID) {
      tokenRef.current = token;
      onLoading(true);
      services.invoice.captureInvoice(invoice, token, 'PayPal').then(updated => {
        onLoading(false);
        onUpdate(updated);
      }).catch(() => onLoading(false));
    }
  }, [invoice, onUpdate, onLoading, services, search]);

  const handleShouldSend = useCallback((event) => {
    setContactAnchor(event.currentTarget);
    setShowContacts(true);
  }, []);

  const handleDownload = useCallback(() => {
    onLoading(true);
    services.invoice.downloadInvoice(invoice)
      .then(() => onLoading(false))
      .catch(() => onLoading(false));
  }, [services, onLoading, invoice]);

  const handlePaymentMethod = useCallback((event) => {
    setMethodAnchor(event.currentTarget);
    setShowMethods(true);
  }, []);

  const handlePay = useCallback((method) => {
    onLoading(true);
    const url = `${window.location.origin}${window.location.pathname}`;
    services.invoice.payInvoice(invoice, url, `${url}?cancelled=1`, method).then((token) => {
      onLoading(false);
      if (method === 'Stripe') {
        stripe.redirectToCheckout({
          sessionId: token
        }).then((result) => {
          return Promise.reject(result.error.message);
        }).catch(() => null);
      } else {
        window.location.href = token;
      }
    }).catch(() => onLoading(false));
  }, [services, stripe, invoice, onLoading]);

  const handleDeposit = useCallback((deposit) => {
    history.push(reverse(routes.invoices.detail, {jobID: deposit.job.id, id: deposit.id}));
  }, [history]);

  const handleNotify = useCallback(confirmed => {
    setShowNotify(false);
    if (confirmed) {
      services.invoice.requestPayment(invoice, contact.contact, contact.type).then(() => {
        setShowSent(true);
      }).catch(() => null);
    }
  }, [services, invoice, contact]);

  useEffect(() => {
    let list = [];
    if (invoice.published_at) {
      if (invoice.payments.length < 1) {
        list.push({title: 'Void Invoice', icon: <MoneyOff/>, onClick: () => toggleDialog('void', true)});
      }
      list.push({title: 'Download PDF', icon: <PictureAsPdfIcon/>, onClick: handleDownload});
      if (invoice.account && invoice.outstanding) {
        list.push({title: 'Pay Invoice', icon: <PaymentIcon/>, onClick: handlePaymentMethod});
        if (contacts.length > 0) {
          list.push({title: 'Send', icon: <SendIcon/>, onClick: handleShouldSend});
        }
      }
    } else {
      list = [
        ...list,
        {title: 'Edit Invoice', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
      ];
      if (invoice.items.length > 0) {
        list.push({title: 'Approve Invoice', icon: <PriceCheckIcon/>, onClick: () => toggleDialog('approve', true)});
        list.push({title: 'Generate Deposit', icon: <PaymentsIcon/>, onClick: () => toggleDialog('deposit', true)});
      }
      if (!invoice.archived) {
        list.push({title: 'Delete Invoice', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)});
      }
    }
    setActions(list);
  }, [invoice, handleDownload, handlePaymentMethod, toggleDialog, contacts, handleShouldSend]);

  return (
    <>
      <DetailPane
        title="Invoice Details"
        actions={actions}
        details={[
          {
            title: 'Job',
            value: invoice.job.reference,
            route: reverse(routes.jobs.tab, {id: invoice.job.id, tab: 'invoicing'})
          },
          ...(invoice.job.company ? [{
            title: 'Company',
            value: invoice.job.company.name,
            route: reverse(routes.companies.tab, {id: invoice.job.company.id, tab: 'invoicing'})
          }] : []),
          ...(invoice.job.contact ? [{
            title: 'Contact',
            value: name(invoice.job.contact),
            route: reverse(routes.contacts.tab, {id: invoice.job.contact.id, tab: 'invoicing'})
          }] : []),
          {title: 'Account', value: invoice.account?.name ?? '-'},
          {title: 'Reference', value: invoice.reference},
          {title: 'Date', value: invoice.published_at ? fullDate(invoice.published_at) : '-'},
          {title: 'Payment Link', value: invoice.link ? 'Click here to view' : '-', link: invoice.link ?? null},
        ]}
        dialogs={[
          (props) => (
            <FormDialog
              {...props}
              open={openDialogs.edit ?? false}
              title="Edit Invoice"
              onClose={() => toggleDialog('edit', false)}
              maxWidth="sm"
              render={(props) => (
                <InvoiceForm
                  {...props}
                  job={invoice.job}
                  invoice={invoice}
                  onSaved={(saved) => toggleDialog('edit', false) || onUpdate(saved)}
                />
              )}
            />
          ),
          (props) => (
            <FormDialog
              {...props}
              open={openDialogs['deposit'] ?? false}
              title="Generate Deposit Invoice"
              onClose={() => toggleDialog('deposit', false)}
              maxWidth="sm"
              render={(props) => (
                <InvoiceForm
                  {...props}
                  job={invoice.job}
                  invoice={{account_id: invoice.account?.id ?? null}}
                  depositFor={invoice}
                  onSaved={(saved) => toggleDialog('deposit', false) || handleDeposit(saved)}
                />
              )}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={openDialogs.delete ?? false}
              title="Delete"
              message={invoice ? 'Are you sure you want to delete this invoice?' : ''}
              onClose={confirmed => {
                toggleDialog('delete', false);
                if (confirmed) {
                  onLoading(true);
                  services.invoice.deleteInvoice(invoice).then(() => {
                    onLoading(false);
                    history.push(reverse(`${routes.jobs.detail}`, {id: invoice.job.id}))
                  }).catch(() => onLoading(false));
                }
              }}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={openDialogs['approve'] ?? false}
              title="Approve"
              message={invoice ? 'Are you sure you want to approve this invoice? You will no longer be able to edit it.' : ''}
              onClose={confirmed => {
                toggleDialog('approve', false);
                if (confirmed) {
                  onLoading(true);
                  services.invoice.publishInvoice(invoice).then((updated) => {
                    onLoading(false);
                    onUpdate(updated);
                  }).catch(() => onLoading(false));
                }
              }}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={openDialogs['void'] ?? false}
              title="Void"
              message={invoice ? 'Are you sure you want to void this invoice?.' : ''}
              onClose={confirmed => {
                toggleDialog('void', false);
                if (confirmed) {
                  onLoading(true);
                  services.invoice.voidInvoice(invoice).then((updated) => {
                    onLoading(false);
                    onUpdate(updated);
                  }).catch(() => onLoading(false));
                }
              }}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={showNotify}
              title="Send to Customer"
              message={contact ? `Are you sure you want to send this invoice to ${contact.type === 'email' ? contact.contact.email : contact.contact.mobile}?` : ''}
              onClose={handleNotify}
            />
          )
        ]}
      />
      <Menu
        anchorEl={contactAnchor}
        open={showContacts}
        onClose={() => setShowContacts(false)}
      >
        {contacts.map((c, index) => (
          <MenuItem key={index} onClick={() => setContact(c) || setShowContacts(false) || setShowNotify(true)}>
            {c.type === 'email' ? `Email: ${name(c.contact)} - ${c.contact.email}` : `SMS: ${name(c.contact)} - ${c.contact.mobile}`}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={methodAnchor}
        open={showMethods}
        onClose={() => setShowMethods(false)}
      >
        <MenuItem onClick={() => setShowMethods(false) || handlePay('Stripe')}>
          Stripe
        </MenuItem>
        <MenuItem onClick={() => setShowMethods(false) || handlePay('PayPal')}>
          PayPal
        </MenuItem>
      </Menu>
      <Snackbar open={showSent} autoHideDuration={4000} onClose={() => setShowSent(false)}>
        <Alert onClose={() => setShowSent(false)} severity="success">
          The invoice has been sent
        </Alert>
      </Snackbar>
    </>
  );
};

export default Details;
