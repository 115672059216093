import {ConfirmationDialog, FormDialog, moment, name} from '@management-ui/core';
import {Add, Delete, Edit, Link, LinkOff, Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import LinkAnyVehicleForm from '../../forms/LinkAnyVehicleForm';
import VehicleForm from '../../forms/VehicleForm';

const Vehicles = ({contact, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [vehicles, setVehicles] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const loaded = useRef(false);

  const loadVehicles = useCallback(() => {
    onLoading(true);
    services.vehicle.getVehicles({filter: {contact: contact.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setVehicles(response.data);
      }).catch(() => onLoading(false));
  }, [contact, onLoading, services]);

  useEffect(() => {
    if (contact) {
      if (!loaded.current) {
        loaded.current = true;
        loadVehicles();
      }
    }
  }, [contact, loadVehicles]);

  const handleLink = useCallback((vehicleID) => {
    onLoading(true);
    return services.vehicle.saveVehicle({id: vehicleID, contact_id: contact.id})
      .then(() => onLoading(false))
      .catch(() => onLoading(false));
  }, [contact, onLoading, services]);

  const handleUnlink = useCallback((confirmed) => {
    setShowUnlink(false);
    if (confirmed) {
      onLoading(true);
      services.vehicle.saveVehicle({id: selected.id, contact_id: null}).then(() => {
        onLoading(false);
        loadVehicles();
      }).catch(() => onLoading(false));
    }
  }, [onLoading, services, selected, loadVehicles]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      onLoading(true);
      services.vehicle.deleteVehicle(selected.id).then(() => {
        onLoading(false);
        loadVehicles();
      }).catch(() => onLoading(false));
    }
  }, [onLoading, services, selected, loadVehicles]);

  const goToDetail = useCallback((contact) => {
    history.push(reverse(routes.vehicles.detail, {id: contact.id}));
  }, [history]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Vehicles</h3>
          <Tooltip title="Refresh">
            <IconButton onClick={() => loadVehicles()}>
              <Refresh/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Link Existing Vehicle">
            <span>
              <IconButton
                onClick={() => setShowLink(true)}
                disabled={contact.archived}>
                <Link/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Add New Vehicle">
            <span>
              <IconButton
                onClick={() => setSelected(null) || setShowEdit(true)}
                disabled={contact.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Registration</Table.Header>
          <Table.Header>Make / Model</Table.Header>
          <Table.Header>VIN</Table.Header>
          <Table.Header>Colour</Table.Header>
          <Table.Header>Year</Table.Header>
          <Table.Header>Scorpion ID</Table.Header>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {vehicles.length > 0 ? (
          vehicles.map((vehicle, index) => (
            <Table.Row key={index}>
              <Table.Column>{vehicle.registration}</Table.Column>
              <Table.Column>{vehicle.make} {vehicle.model}</Table.Column>
              <Table.Column>{vehicle.vin}</Table.Column>
              <Table.Column>{vehicle.colour}</Table.Column>
              <Table.Column>
                {vehicle.manufactured_on ? moment(vehicle.manufactured_on).format('Y') : '-'}
              </Table.Column>
              <Table.Column>{vehicle.scorpion_reference}</Table.Column>
              <Table.Action>
                <Tooltip title="View Vehicle">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(vehicle)}
                      disabled={contact.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Edit Vehicle">
                  <span>
                    <IconButton
                      onClick={() => setSelected(vehicle) || setShowEdit(true)}
                      disabled={contact.archived}>
                      <Edit/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Unlink Vehicle">
                  <span>
                    <IconButton
                      onClick={() => setSelected(vehicle) || setShowUnlink(true)}
                      disabled={contact.archived}>
                      <LinkOff/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Delete Vehicle">
                  <span>
                    <IconButton
                      onClick={() => setSelected(vehicle) || setShowDelete(true)}
                      disabled={contact.archived}>
                      <Delete/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no vehicles associated with this contact</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title={selected ? 'Edit Vehicle' : 'Add New Vehicle'}
        open={showEdit}
        maxWidth="sm"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <VehicleForm
            {...props}
            contact={contact}
            vehicle={selected ?? {
              contact_id: contact.id,
              contact_name: name(contact)
            }}
            onSaved={() => setShowEdit(false) || loadVehicles()}
          />
        )}
      />
      <FormDialog
        title="Link Existing Vehicle"
        open={showLink}
        maxWidth="sm"
        onClose={() => setShowLink(false)}
        render={props => (
          <LinkAnyVehicleForm
            vehicle={{}}
            contact={contact}
            onSave={handleLink}
            onSaved={() => setShowLink(false) || loadVehicles()}
            {...props}
          />
        )}
      />
      <ConfirmationDialog
        title="Unlink Vehicle"
        message="Are you sure you want to unlink this vehicle?"
        open={showUnlink}
        onClose={handleUnlink}
      />
      <ConfirmationDialog
        title="Delete Vehicle"
        message="Are you sure you want to delete this vehicle?"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
};

export default Vehicles;
