import {BaseForm} from '@management-ui/core';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const CalendarNoteForm = forwardRef(({note, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={note}
      type="note"
      fieldsComponent={Fields}
      onSave={(
        {
          engineer_id,
          date,
          start_at: start,
          end_at: end,
          expires_at: expires,
          description
        }
      ) => services.calendarNote.saveNote({
        ...(note ? {id: note.id} : {}),
        engineer_id,
        date: date ? moment(date).format('YYYY-MM-DD') : null,
        start_at: start ? moment(start).format('HH:mm:ss') : null,
        end_at: end ? moment(end).format('HH:mm:ss') : null,
        expires_at: expires ? moment(expires).format('YYYY-MM-DD HH:mm:ss') : null,
        description
      })}
      onSaved={onSaved}
    />
  );
});

export default CalendarNoteForm;
