import {Breadcrumbs, ErrorPopup, Notice, Title} from '@management-ui/core';
import {reverse} from 'named-urls';
import React from 'react';
import routes from '../../../../routes';


const Wrapper = ({title, loading, titleControls, product, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading} controls={titleControls}/>
      <Notice
        display={product && product.archived}
        message={[
          'This product has been archived and is therefore no longer available.',
          'You won\'t be able to modify it unless it is restored.'
        ].join(' ')}
      />
      {children}
      <ErrorPopup errors={['product']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Stock', link: routes.stock.index},
  {title: 'Products', link: reverse(routes.stock.index, {tab: 'products'})}
];
Wrapper.archiveCrumbs = [
  {title: 'Archive', link: reverse(routes.archive.index)},
  {title: 'Products', link: reverse(routes.archive.index, {tab: 'products'})}
];

export default Wrapper;
