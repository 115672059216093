import {Panes, Select, TextField} from '@management-ui/core';
import React, {useMemo} from 'react';
import {InputAdornment} from '@mui/material';

export default function Fields({payment = {}, prefix = '', children}) {
  const sources = useMemo(() => (
    ['Cash', 'Bank Transfer', 'Cheque', 'PayPal', 'Other'].map(s => ({title: s, value: s}))
  ), []);

  return (
    <Panes
      entity={payment}
      prefix={prefix}
      panes={[
        {
          title: 'Details',
          fields: [
            <Select
              name="source"
              prefix={prefix}
              options={sources}
              rules={{required: 'Please select where the payment came from'}}
              label="Source"
            />,
            <TextField
              name="amount"
              prefix={prefix}
              label="Amount"
              rules={{required: 'Please enter an amount'}}
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="transaction"
              prefix={prefix}
              label="Transaction Reference"
            />,
          ]
        },
        {
          title: 'Notes', fields: [
            <TextField
              name="comments"
              prefix={prefix}
              label="Comments"
              fieldProps={{multiline: true, rows: 3}}
            />
          ]
        }
      ]}
      title={null}
    >{children}</Panes>
  );
}
