import {Checkbox, Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {useCountries, useOptions} from '../../../../hooks';
import PostcodeLookup from '../../components/PostcodeLookup';

function Fields({type = null, location = {}, prefix = '', multiplePanes = true, children}) {
  const {/** @var {function(string): string} */getValues} = useFormContext();

  const names = useOptions('locationNames', useCallback((list) => {
    let options = [...list];
    if (type) {
      switch (type) {
        case 'company':
          options = options.sort((n1, n2) => n1.company_index > n2.company_index ? 1 : -1);
          break;
        case 'contact':
          options = options.sort((n1, n2) => n1.contact_index > n2.contact_index ? 1 : -1);
          break;
        default:
          break;
      }
    }
    return options.map(name => ({
      value: name.id,
      title: name.label
    }));
  }, [type]));
  const types = useOptions('locationTypes', useCallback((list) => list.map(type => ({
    value: type.id,
    title: type.name
  })), []));
  const countries = useCountries();

  const handleReset = useCallback((subject) => {
    if (subject.name) {
      subject.name_id = subject.name.id;
    }
    if (subject.type) {
      subject.type_id = subject.type.id;
    }
    if (subject.country) {
      subject.country_id = subject.country.id;
    }
    return subject;
  }, []);

  const requiredIfPopulated = useCallback((currentField, message) => ({
    validate: () => {
      const fieldPrefix = prefixWithSeparator(prefix);
      const populated = [
        'type_id',
        'address1',
        'address2',
        'town',
        'region',
        'postcode',
        'country_id',
        'phone',
        'notes'
      ].filter(field => !!getValues(`${fieldPrefix}${field}`));
      if (populated.length > 0 && !getValues(`${fieldPrefix}${currentField}`)) {
        return message;
      }
      return true;
    }
  }), [prefix, getValues]);

  return (
    <Panes
      entity={location}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Location Details',
          fields: [
            <Select
              name="name_id"
              prefix={prefix}
              options={names}
              label="Name"
              rules={requiredIfPopulated('name_id', 'Please select a name for this location')}
            />,
            <Checkbox
              name="is_billing"
              prefix={prefix}
              label="Is this a billing address?"
            />,
            <Select
              name="type_id"
              prefix={prefix}
              options={types}
              label="Type"
            />,
            <PostcodeLookup
              prefix={prefix}
            />,
            <TextField
              name="address_number"
              prefix={prefix}
              label="House Number"
            />,
            <TextField
              name="address1"
              prefix={prefix}
              label="Address Line 1"
            />,
            <TextField
              name="address2"
              prefix={prefix}
              label="Address Line 2"
            />,
            <TextField
              name="town"
              prefix={prefix}
              label="Town / City"
            />,
            <TextField
              name="region"
              prefix={prefix}
              label="Region"
              rules={requiredIfPopulated('region', 'Please enter a region')}
            />,
            <TextField
              name="postcode"
              prefix={prefix}
              label="Postcode"
            />,
            <Select
              name="country_id"
              prefix={prefix}
              options={countries}
              label="Country"
            />
          ]
        }, {
          title: 'Additional Information',
          fields: [
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone Number"
            />,
            <TextField
              name="notes"
              prefix={prefix}
              label="Notes"
              multiline
              rows={4}
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Location Details'}
    >{children}</Panes>
  );
}

export function CompanyFields(props) {
  return <Fields type="company" {...props} />;
}

export function ContactFields(props) {
  return <Fields type="contact" {...props} />;
}
