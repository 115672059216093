import {DetailColumns, DetailPane, fullDateTime, name} from '@management-ui/core';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useCallback} from 'react';
import DetailDialog from '../../../../components/DetailDialog';
import Pill from '../../../../components/Pill';
import routes from '../../../../routes';
import Status from '../Status';
import Tabs from './Tabs';

const OutboundMessageDialog = ({message, open, onClose}) => {

  const linkedEntity = useCallback((title, label, entity, routeType) => (entity ? [{
    title,
    value: label(entity),
    route: reverse(entity.archived ? routes.archive[routeType].detail : routes[routeType].detail, {id: entity.id})
  }] : []), []);

  return message ? (
    <DetailDialog
      title={message.subject}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      actions={[
        {label: 'Close', colour: 'primary', onClick: () => onClose()}
      ]}
    >
      <Box display="flex" flexDirection="column">
        <DetailColumns columns={[
          <DetailPane
            title="Message Details"
            minHeight={400}
            details={[
              {title: 'To', value: message.recipients.join(', ')},
              {title: 'From', value: message.from},
              {title: 'Subject', value: message.subject},
              ...linkedEntity('Contact', e => name(e), message.contact, 'contacts'),
              ...linkedEntity('Invoice', e => e.reference, message.invoice, 'invoices'),
              ...linkedEntity('Job', e => e.reference, message.job, 'jobs'),
              ...linkedEntity('Order', e => e.reference, message.order, 'orders'),
              ...linkedEntity('Subscription', e => e.reference, message.subscription, 'subscriptions'),
              ...linkedEntity('User', e => name(e), message.user, 'engineers'),
            ]}
          />,
          <DetailPane
            title="Technical Details"
            minHeight={400}
            details={[
              {title: 'Message ID', value: message.uuid},
              {title: 'Notification', value: message.notification},
            ]}
          />,
          <DetailPane
            title="Admin Details"
            details={[
              {title: 'Type', value: <Pill className={`paned ${message.type}`}>{message.type}</Pill>},
              {title: 'Status', value: <Box marginBottom={1}><Status entity={message}/></Box>},
              {title: 'Sent', value: fullDateTime(message.created_at)},
              ...(message.delivered_at ? [
                {title: 'Delivered', value: fullDateTime(message.delivered_at)},
              ] : []),
              ...(message.opened_at ? [
                {title: 'Opened', value: fullDateTime(message.opened_at)},
              ] : []),
              ...(message.clicked_at ? [
                {title: 'Clicked', value: fullDateTime(message.clicked_at)},
              ] : []),
              ...(message.bounced_at ? [
                {title: 'Bounced', value: fullDateTime(message.bounced_at)},
              ] : []),
              ...(message.marked_as_spam_at ? [
                {title: 'Marked as Spam', value: fullDateTime(message.marked_as_spam_at)},
              ] : []),
            ]}
          />,
        ]}/>
        <Tabs message={message}/>
      </Box>
    </DetailDialog>
  ) : null;
}

export default OutboundMessageDialog;
