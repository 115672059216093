import {fullDate, time} from '@management-ui/core';
import * as React from 'react';
import {fullDateTime} from '@management-ui/core';
import Table from '../../../../components/Table';
import EngineerLabel from '../../components/EngineerLabel';

const Dates = ({request, loading}) => (
  <Table className={loading ? 'loading' : ''}>
    <Table.Actions>
      <h3>Dates</h3>
    </Table.Actions>
    <Table.Row>
      <Table.Header>Engineer</Table.Header>
      <Table.Header>Date</Table.Header>
      <Table.Header>Time</Table.Header>
      <Table.Header>Expires</Table.Header>
    </Table.Row>
    {request.notes.length > 0 ? (
      request.notes.map((note, index) => (
        <Table.Row key={index}>
          <Table.Column>
            {note.engineer ? (
              <EngineerLabel engineer={note.engineer}/>
            ) : 'Unallocated'}
          </Table.Column>
          <Table.Column>{fullDate(note.date)}</Table.Column>
          <Table.Column>{time(note.start_at)} - {time(note.end_at)}</Table.Column>
          <Table.Column>{note.expires_at ? fullDateTime(note.expires_at) : '-'}</Table.Column>
        </Table.Row>
      ))
    ) : (
      <Table.NoRecords>There are no dates associated with this request</Table.NoRecords>
    )}
  </Table>
);

export default Dates;
