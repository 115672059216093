import React from 'react';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  product: {title: 'Product', render: (data) => `${data.product.name}`},
  quantity: {title: 'Quantity', render: null}
};

export default function JobProductUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
