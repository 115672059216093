import * as qs from 'qs';
import {clearLocationNames} from '../store/actions/options';
import {API} from './API';

export class LocationNameService {
  async getTableNames(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getNames(query, page, length);
  }

  async getNames(query, page, length) {
    return (await API.getConnection())
      .get('location-names', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(API.handleError);
  }

  async saveName(data) {
    let method = 'post';
    let url = 'location-names';
    if (data.id) {
      method = 'put';
      url = `location-names/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearLocationNames());
        return response.data.data
      })
      .catch(API.handleError);
  }
}
