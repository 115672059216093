import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const ConfirmationForm = forwardRef(({job, confirmation, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={confirmation}
      type="confirmation"
      fieldsComponent={(props) => <Fields job={job} {...props}/>}
      onSave={useCallback((
        {
          contact_id,
          general_required,
          scorpion_required,
          safe_track_required,
          scorpion_fitter_id,
          scorpion_fitter_name,
          end_user_required
        }
      ) => services.job.saveConfirmation(job, {
        ...(confirmation?.id ? {id: confirmation.id} : {}),
        contact_id,
        general_required,
        scorpion_required,
        safe_track_required,
        scorpion_fitter_id,
        scorpion_fitter_name,
        end_user_required
      }), [services, job, confirmation])}
      onSaved={onSaved}
    />
  );
});

export default ConfirmationForm;
