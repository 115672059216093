import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  name,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Delete, Edit, RestoreFromTrash} from '@mui/icons-material';
import moment from 'moment';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import VehicleForm from '../../forms/VehicleForm';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const Vehicle = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({vehicle: 'Sorry the vehicle could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['vehicle', 'delete', 'restore']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState(null);

  const loadVehicle = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.vehicle.getVehicle(id, archive).then(retrieved => {
        setVehicle(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('vehicle', true);
        setLoading(false);
        setTimeout(() => {
          history.push(archive ? reverse(routes.archive.index, {tab: 'vehicles'}) : routes.vehicles.index);
        }, 3000);
      });
    }
  }, [archive, services, history, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadVehicle(match.params?.id);
    }
  }, [history, match, vehicle, loadVehicle]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...(archive ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs)]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [];
    if (vehicle) {
      header = [vehicle.registration, vehicle.make, vehicle.model].filter(p => !!p).join(' - ');
      trail = [
        ...(vehicle.archived ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs),
        {title: header, link: ''}
      ];
    }
    setTitle(header);
    setCrumbs(trail);
  }, [vehicle]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('vehicle', false);
    if (updated) {
      setVehicle(updated);
    } else if (vehicle?.id) {
      loadVehicle(vehicle.id);
    }
  }, [toggleDialog, setVehicle, loadVehicle, vehicle]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.vehicle.deleteVehicle(vehicle).then(() => {
        setLoading(false);
        history.push(routes.vehicles.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, vehicle]);

  const handleRestore = useCallback((confirmed) => {
    toggleDialog('restore', false);
    if (confirmed) {
      setLoading(true);
      services.vehicle.restoreVehicle(vehicle).then((restored) => {
        setLoading(false);
        history.push(reverse(routes.vehicles.tab, {id: vehicle.id, tab: 'locations'}));
        setVehicle(restored);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, vehicle]);

  return (
    <Wrapper title={title} vehicle={vehicle} loading={loading} crumbs={crumbs} errors={errors}>
      {vehicle?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Vehicle Details"
              actions={vehicle.archived ? [
                {title: 'Restore', icon: <RestoreFromTrash/>, onClick: () => toggleDialog('restore', true)},
              ] : [
                {title: 'Edit', icon: <Edit/>, onClick: () => toggleDialog('vehicle', true)},
                {title: 'Delete', icon: <Delete/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Registration / VIN', value: `${vehicle.registration ?? '-'} / ${vehicle.vin ?? '-'}`},
                {title: 'Aliases', value: vehicle.aliases?.length ? vehicle.aliases.join(', ') : '-'},
                {title: 'Make / Model', value: `${vehicle.make ?? '-'} / ${vehicle.model ?? '-'}`},
                {
                  title: 'Colour / Year',
                  value: `${vehicle.colour ?? '-'} / ${vehicle.manufactured_on ? moment(vehicle.manufactured_on).format('Y') : '-'}`
                },
                {
                  title: 'Type / Fuel / Battery',
                  value: `${vehicle.vehicle_type?.name ?? '-'} / ${vehicle.fuel_type?.name ?? '-'} / ${vehicle.battery_type?.name ?? '-'}`
                }
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit"
                    open={openDialogs['vehicle'] ?? false}
                    onClose={() => toggleDialog('vehicle', false)}
                    render={(props) => (
                      <VehicleForm
                        {...props}
                        vehicle={vehicle}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete Vehicle"
                    message="Are you sure you want to delete this vehicle?"
                    onClose={handleDelete}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['restore'] ?? false}
                    title="Restore Vehicle"
                    message="Are you sure you want to restore this vehicle?"
                    onClose={handleRestore}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Ownership"
              details={[
                {
                  title: 'Company',
                  value: vehicle.company?.name ?? '-',
                  route: vehicle.company ? reverse(routes.companies.detail, {id: vehicle.company.id}) : null
                },
                {
                  title: 'Contact',
                  value: vehicle.contact ? name(vehicle.contact) : '-',
                  route: vehicle.contact ? reverse(routes.contacts.tab, {
                    id: vehicle.contact.id,
                    tab: 'vehicles'
                  }) : null
                },
                {title: 'Scorpion ID', value: vehicle.scorpion_reference ?? '-'},
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(vehicle.created_at)},
                {title: 'Last Updated', value: dateTime(vehicle.updated_at)}
              ]}
            />,
          ]}/>

          <Tabs
            vehicle={vehicle}
            onVehicleUpdated={setVehicle}
            loading={loading}
            onLoading={setLoading}
            archive={archive}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export default Vehicle;
