import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const InvoiceForm = forwardRef(({job, invoice, fromStock = false, depositFor = null, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={invoice}
      type="invoice"
      fieldsComponent={(props) => <Fields {...props} depositFor={depositFor}/>}
      onSave={useCallback(({account_id, amount, other}) => {
        if (depositFor) {
          return services.invoice.depositInvoice(depositFor, {
            account_id, amount: amount === 'other' ? other : amount
          });
        } else {
          return services.invoice.saveInvoice({
            ...(invoice.id ? {id: invoice.id} : {}),
            job_id: job.id,
            account_id,
            ...(fromStock ? {from_stock: '1'} : {}),
          });
        }

      }, [services, job, invoice, fromStock, depositFor])}
      onSaved={onSaved}
    />
  );
});

export default InvoiceForm;
