export function itemTitle(item) {
  return `${item.product?.name ?? ''}${item.serial_number ? ` (${item.serial_number})` : ''}`;
}

export function truncate(text, length = 50, suffix = '...') {
  if (text.length > length) {
    return `${text.substring(0, length)}${suffix}`;
  } else {
    return text;
  }
}

export function vehicleTitle(vehicle) {
  return [vehicle.make, vehicle.model, vehicle.registration, vehicle.vin].filter(p => !!p).join(' - ');
}
