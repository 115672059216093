import {Panes, Select} from '@management-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({entity = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [referrers, setReferrers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    services.referrer.getReferrers({}, 1, 50)
      .then(({data}) => {
        setReferrers(data.map(referrer => ({
          title: referrer.name,
          value: `${referrer.id}`
        })))
        setLoaded(true);
      })
      .catch(() => setLoaded(true));
  }, [services]);

  return (
    <Panes
      entity={entity}
      prefix={prefix}
      panes={[
        {
          title: 'Referrer',
          fields: [
            <Select
              name="referrer"
              prefix={prefix}
              options={referrers.length < 1 ? [{title: '', value: '0'}] : referrers}
              label={loaded ? (referrers.length > 0 ? 'Select a Referrer' : 'No Referrers to Link') : 'Loading...'}
              fieldProps={{disabled: referrers.length < 1}}
            />
          ]
        }
      ]}
      title={''}
    />
  );
};

export default Fields;
