import * as qs from 'qs';
import {API} from './API';

export class VehicleService {
  async getTableVehicles(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getVehicles(query, page, length);
  }

  async getVehicles(query, page, length) {
    return (await API.getConnection())
      .get('vehicles', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async getVehicle(id, archive = false) {
    return (await API.getConnection())
      .get(`vehicles/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async lookupRegistration(registration) {
    return (await API.getConnection())
      .get('vehicles/lookup', {
        params: {registration},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveVehicle(vehicle) {
    let data = vehicle;
    let method = 'post';
    let url = 'vehicles';
    if (vehicle.id) {
      method = 'put';
      url = `vehicles/${vehicle.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async linkJob(vehicleID, jobID) {
    return (await API.getConnection())
      .put(`vehicles/${vehicleID}/job`, {job_id: jobID})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteVehicle(id) {
    return (await API.getConnection())
      .delete(`vehicles/${id}`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async restoreVehicle(id) {
    return (await API.getConnection())
      .put(`vehicles/${id}/restore`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }
}
