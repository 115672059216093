import {Panes, RelationAutocomplete} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {truncate} from '../../../../formatters';

const Fields = ({vehicle = {}, company = null, contact = null, prefix = ''}) => {
  const services = useContext(ServiceContext);
  return (
    <Panes
      entity={vehicle}
      prefix={prefix}
      panes={[
        {
          title: 'Vehicle',
          fields: [
            <Box minHeight={300}>
              <RelationAutocomplete
                valueName="vehicle_id"
                titleName="vehicle_summary"
                prefix={prefix}
                label="Vehicle"
                loadOptions={useCallback(query => {
                  const filter = {search: query};
                  if (company) {
                    filter['linkCompany'] = `${company.id}`;
                  }
                  if (contact) {
                    filter['linkContact'] = `${contact.id}`;
                  }
                  return services.vehicle.getVehicles({filter}, 1, 50)
                    .then(response => response.data.map(vehicle => ({
                      title: truncate([vehicle.registration, vehicle.make, vehicle.model, vehicle.vin].filter(d => !!d).join(' - ')),
                      value: vehicle.id
                    })))
                    .catch(() => []);
                }, [services, company, contact])}
              />
            </Box>
          ]
        }
      ]}
      title=""
    />
  );
};

export default Fields;
