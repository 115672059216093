import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {ServiceContext} from '../../../../components/Services';
import {setSettings} from '../../../../store/actions/settings';
import Fields from './Fields';

const SettingsForm = forwardRef(({settings, onSaved, onError}, ref) => {
  const services = useContext(ServiceContext);
  const dispatch = useDispatch();
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={settings}
      type="settings"
      fieldsComponent={Fields}
      onSave={(update) => services.settings.saveSettings(update).then((updated) => {
        dispatch(setSettings(updated));
        return updated;
      }).catch(error => {
        onError(error);
        return Promise.reject(error);
      })}
      onSaved={onSaved}
    />
  );
});

export default SettingsForm;
