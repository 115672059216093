import {Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';

export const useStyles = makeStyles(theme => ({
  blocks: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: `0 ${theme.spacing(2)}`,
  },

  narrow: {
    justifyContent: 'space-between',

    '& $block': {
      cursor: 'pointer',
      flex: '0 0 50%',
      width: '50%',
    },

    '& $blockPaper': {
      backgroundColor: theme.palette.background.default,
    },
  },

  wide: {
    justifyContent: 'center',

    '& $block': {
      flex: '0 0 25%',
      width: '25%',
    },

    '& $blockPaper': {
      backgroundColor: theme.palette.background.paper,

      '&$new': {
        backgroundColor: theme.palette.primary.main,

        '& p': {
          color: theme.palette.background.paper,
        }
      }
    },
  },

  block: {
    display: 'flex',
    padding: theme.spacing(1),
  },

  blockPaper: {
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(1),

    '& p': {
      fontWeight: 600,
      margin: 0,
      padding: 0,

      '&:first-child': {
        paddingBottom: theme.spacing(1),
      },

      '&:last-child': {
        color: theme.palette.primary.main,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(1),
      }
    }
  },

  new: {
    cursor: 'pointer'
  }
}));

const BlockedList = ({blocks, onSelect, layout, newProps = null, onRenderItem}) => {
  const classes = useStyles();

  return (
    <ul className={[classes.blocks, classes[layout]].join(' ')}>
      {blocks.map((block, index) => (
        <li key={index} className={classes.block} onClick={() => onSelect(block)}>
          <Paper className={classes.blockPaper}>
            {onRenderItem(block)}
          </Paper>
        </li>
      ))}
      {newProps ? (
        <li className={classes.block} onClick={(event) => onSelect(newProps, event)}>
          <Paper className={[classes.blockPaper, classes.new].join(' ')}>
            {onRenderItem(null)}
          </Paper>
        </li>
      ) : null}
    </ul>
  )
};

export default BlockedList;
