import {FormDialog, Title} from '@management-ui/core';
import AddCommentIcon from '@mui/icons-material/AddComment';
import {Box} from '@mui/material';
import qs from 'qs';
import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import routes from '../../../routes';
import JobAllocationForm from '../forms/JobAllocationForm';
import {Person} from '@mui/icons-material';
import EngineerAvailabilityNoteForm from '../forms/EngineerAvailabilityNoteForm';

const Calendar = () => {
  const [loading, setLoading] = useState(false);
  const [showNote, setShowNote] = useState(false);
  /** @type {({current: JobAllocationForm})} */
  const formRef = useRef(null);
  const history = useHistory();
  return (
    <>
      <Title
        title="Calendar" loading={loading}
        controls={[
          {icon: <Person/>, tooltip: 'Add Engineer Availability', onClick: () => setShowNote(true)},
          {icon: <AddCommentIcon/>, tooltip: 'Quick Allocations', onClick: () => history.push(routes.bookings.notes)}
        ]}
      />
      <Box marginTop={2}>
        <JobAllocationForm
          ref={formRef}
          allocation={{}}
          job={{}}
          additionalAvailability={[]}
          onSaved={(saved) => (
            history.push({pathname: routes.jobs.new, search: `?${qs.stringify({when: saved})}`})
          )}
          canEdit={false}
          loading={loading}
          onLoading={setLoading}
        />
      </Box>
      <FormDialog
        title="Update Availability Note"
        open={showNote}
        onClose={() => setShowNote(false)}
        render={(props) => (
          <EngineerAvailabilityNoteForm
            {...props}
            note={{}}
            onSaved={() => {
              setShowNote(false);
              if (formRef.current) {
                formRef.current.reload();
              }
            }}/>
        )}
        minHeight={400}
        maxWidth="md"
      />
    </>
  );
}

export default Calendar;
