import {Link, LocalShipping} from '@mui/icons-material';
import {Menu, MenuItem} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import routes from '../../../../routes';
import Button from './Button';

const DeliveryLink = ({order, delivery, job, onLink}) => {
  const history = useHistory();
  const anchorRef = useRef();
  const [showNew, setShowNew] = useState(false);

  return order.deliveries.length > 0 && !job ? (
    <>
      {delivery ? (
        <Button
          onClick={e => e.preventDefault() || history.push(reverse(routes.orders.deliveries.detail, {
            orderID: order.id,
            id: delivery.id
          }))}
          className="small"
        >
          <LocalShipping/>{delivery.reference}
        </Button>
      ) : (
        <Button
          ref={anchorRef}
          onClick={e => e.preventDefault() || setShowNew(true)}
          className="small action"
        >
          <Link/>Link to Delivery
        </Button>
      )}
      <Menu
        anchorEl={() => anchorRef.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        open={showNew}
        onClose={() => setShowNew(false)}
      >
        {order.deliveries.map((delivery, index) => (
          <MenuItem key={index} onClick={() => setShowNew(false) || onLink(delivery)}>{delivery.reference}</MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};

export default DeliveryLink;
