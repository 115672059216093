import * as qs from 'qs';
import {API} from './API';

export class PostcodeService {
  async lookupPostcode(postcode) {
    return (await API.getConnection())
      .get('postcodes', {
        params: {postcode},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data.addresses)
      .catch(error => API.handleError(error));
  }
}
