import {styled, Tooltip} from '@mui/material';
import React from 'react';

const Container = styled('div')(({theme}) => ({
  alignItems: 'center',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.25),
}));

const Label = styled('p')(({theme}) => ({
  fontSize: '0.8em',
  fontWeight: '700',
  margin: 0,
  padding: theme.spacing(0, 0, 0.25),
  textAlign: 'center',
}));

const Blocks = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey['300'],
  display: 'flex',
  height: 20,
  position: 'relative',
  width: 20,
}));

const Block = styled('div')(({theme}) => ({
  alignItems: 'center',
  color: theme.palette.common.white,
  display: 'flex',
  fontSize: '0.8em',
  fontWeight: '700',
  justifyContent: 'center',
  textAlign: 'center',
}));

const Counter = styled('div')(({theme}) => ({
  alignItems: 'center',
  bottom: 0,
  color: theme.palette.common.white,
  display: 'flex',
  fontSize: '0.8em',
  fontWeight: '700',
  justifyContent: 'center',
  left: 0,
  pointerEvents: 'none',
  position: 'absolute',
  right: 0,
  textAlign: 'center',
  top: 0,
}));

const Metric = ({abbreviation, blocks = [], counter = 0}) => (
  <Container>
    <Label>{abbreviation}</Label>
    <Blocks>
      {blocks.map(({width, colour, title}, index) => (
        <Tooltip key={index} title={title}>
          <Block style={{
            ...(colour ? {backgroundColor: colour} : {}),
            flex: `0 0 ${100 * width}%`,
            width: `${100 * width}%`
          }}/>
        </Tooltip>
      ))}
      {counter > 0 ? <Counter>{counter}</Counter> : null}
    </Blocks>
  </Container>
);

export default Metric;
