import {Checkbox, Panes, Select, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({calendar = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={calendar}
      prefix={prefix}
      panes={[
        {
          title: 'Calendar Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <Select
              name="type"
              prefix={prefix}
              options={[
                {title: 'iCal', value: 'iCal'}
              ]}
              label="Type"
            />,
            <TextField
              name="url"
              prefix={prefix}
              label="URL"
              rules={{required: 'Please enter a URL'}}
            />
          ]
        },
        {
          title: 'Settings',
          fields: [
            <Checkbox
              name="is_active"
              prefix={prefix}
              label="Is Active?"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Calendar Details'}
    >{children}</Panes>
  );
}
