import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const InvoiceItemForm = forwardRef(({invoice, item, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const handleSave = useCallback(({id, description, unit_price, discount, excluding_tax, quantity}) => {
    return services.invoice.saveInvoiceItem(
      invoice,
      {
        id,
        description,
        unit_price: parseFloat(unit_price ?? '0') * (1 + (excluding_tax ? invoice.tax_rate : 0)),
        discount: parseFloat(discount ?? '0') * (1 + (excluding_tax ? invoice.tax_rate : 0)),
        excluding_tax,
        quantity
      }
    );
  }, [services, invoice]);

  return (
    <BaseForm
      ref={formRef}
      entity={item ? {
        ...item,
        unit_price: (item.excluding_tax ? item.unit_price / (1 + invoice.tax_rate) : item.unit_price) ?? 0,
        discount: (item.excluding_tax ? item.discount / (1 + invoice.tax_rate) : item.discount) ?? 0
      } : {}}
      type="item"
      fieldsComponent={props => <Fields {...props}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default InvoiceItemForm;
