import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const AssociateProductForm = forwardRef(({product, existing, association, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={association}
      type="association"
      fieldsComponent={(props) => <Fields {...props} product={product} existing={existing}/>}
      onSave={useCallback(({associate}) => services.product.associate(product, associate), [services, product])}
      onSaved={onSaved}
    />
  );
});

export default AssociateProductForm;
