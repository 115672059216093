import {
  ColouredLabel,
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  name,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import UserForm from '../../forms/UserForm';
import Actions from './Actions';
import Wrapper from './Wrapper';
import Tabs from './Tabs';

const Engineer = ({history, archive = false}) => {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    user: 'Sorry the user could not be accessed',
    activity: 'Sorry the user activity could not be loaded'
  }), []));

  const {
    entity: user,
    setEntity: setUser,
    loadEntity: loadUser,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.user.getUser,
    archive,
    useCallback((user) => [{title: name(user)}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('user', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setUser(updated);
    } else if (user?.id) {
      loadUser(user.id)
    }
  }, [toggleDialog, setUser, loadUser, user]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.user.restoreUser(user.id).then((restored) => {
      setLoading(false);
      setUser(restored);
      history.push(reverse(routes.engineers.detail, {id: user.id}))
    }).catch(() => setLoading(false));
  }, [user, services, history, setUser, setLoading]);

  return (
    <Wrapper title="Engineer" loading={loading} user={user} crumbs={crumbs} errors={errors}>
      {user?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="User Details"
              minHeight={400}
              actions={!user.archived ? [
                {title: 'Edit User', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete User', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {title: 'Restore User', icon: <RestoreFromTrashIcon/>, onClick: handleRestore},
              ]}
              details={[
                {
                  title: 'Name',
                  value: <Box marginBottom={0.5}><ColouredLabel label={name(user)}
                                                                colour={user.colour ?? 'white'}/></Box>
                },
                {title: 'Email Address', value: user.email},
                {title: 'Regular Engineer?', value: <YesNo value={user.is_regular}/>},
                {
                  title: 'Location',
                  value: user.location?.name ?? '-',
                  route: user.location ? reverse(routes.stock.locations.detail, {id: user.location.id}) : null
                },
                {title: 'Postcodes', value: user.postcodes?.length > 0 ? user.postcodes.join(', ') : '-'},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit User"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <UserForm
                        {...props}
                        user={user}
                        engineer={true}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={user ? `Are you sure you want to delete ${name(user)}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.user.deleteUser(user.id).then(() => {
                          setLoading(false);
                          history.push(reverse(routes.engineers.index))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: fullDateTime(user.created_at)},
                {title: 'Last Updated', value: fullDateTime(user.updated_at)},
                ...(user.archived ? [{title: 'Archived', value: fullDateTime(user.deleted_at)}] : [])
              ]}
            />,

            <DetailPane
              title="Recent Activity"
              actions={[
                {
                  title: 'View all Activity', icon: <QuestionAnswerIcon/>, onClick: () => {
                    history.push(reverse(user.archived ? routes.archive.engineers.activity : routes.engineers.activity, {id: user.id}))
                  }
                }
              ]}
            ><Actions
              user={user}
              setLoading={setLoading}
              openDialogs={openDialogs}
              toggleDialog={toggleDialog}
              toggleError={toggleError}
            /></DetailPane>
          ]}/>

          <Tabs engineer={user} loading={loading} onLoading={setLoading}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Engineer;
