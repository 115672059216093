import {useDialogs} from '@management-ui/core';
import {Add} from '@mui/icons-material';
import {IconButton, Pagination, Paper, styled, Tooltip} from '@mui/material';
import * as React from 'react';
import {useContext, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import Actions from './Actions';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    padding: 0,
  },
}));

const Paging = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1, 0),
}));

const Activity = ({company, onLoading, toggleError}) => {
  const services = useContext(ServiceContext);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const {openDialogs, toggleDialog} = useDialogs(['note']);

  return company ? (
    <>
      <Container>
        <Table.Actions>
          <h3>Activity</h3>
          <Tooltip title="Add New Note">
            <span>
              <IconButton disabled={company.archived} onClick={() => toggleDialog('note', true)}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Actions
          company={company}
          setLoading={onLoading}
          openDialogs={openDialogs}
          toggleDialog={toggleDialog}
          toggleError={toggleError}
          saveNote={(note) => services.company.saveComment(company, note)}
          page={page}
          length={30}
          updatePageCount={setPages}
        />
        <Paging>
          <Pagination count={pages} page={page} onChange={(e, p) => setPage(p)}/>
        </Paging>
      </Container>
    </>
  ) : null;
}

export default Activity;
