import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const SubscriptionForm = forwardRef(({job, subscription, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={subscription}
      type="subscription"
      fieldsComponent={(props) => <Fields {...props} />}
      onSave={useCallback(({account_id, is_recurring}) => services.subscription.saveSubscription({
          ...(subscription.id ? {id: subscription.id} : {}),
          job_id: job.id,
          account_id,
          is_recurring
        }),
        [services, job, subscription])}
      onSaved={onSaved}
    />
  );
});

export default SubscriptionForm;
