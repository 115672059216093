import {Box, Paper} from '@mui/material';
import React, {useRef} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import JobProductForm from '../../forms/JobProductForm';
import JobProducts from './JobProducts';

const NewJobProduct = ({onBack, onNext, session}) => {
  /** @type {({current: JobProductForm})} */
  const formRef = useRef();
  return (
    <>
      <Header>Add a New Product</Header>
      <Paper component={Box} padding={2}>
        <JobProductForm ref={formRef} product={{}} job={session.selectedJob} onSaved={() => onNext(
          {type: JobProducts.type, id: JobProducts.type}
        )}/>
      </Paper>
      <Controls
        onBack={() => onBack({type: JobProducts.type, id: JobProducts.type})}
        onNext={() => formRef.current.save()}
        nextLabel="Save New Product"
      />
    </>
  );
};

NewJobProduct.type = 'NEW_JOB_PRODUCT';

export default NewJobProduct;
