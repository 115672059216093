
export class GoogleService {

  async autocomplete(query) {
    return new Promise((resolve, reject) => {
      try {
        new window.google.maps.places.AutocompleteService().getPlacePredictions(
          {input: query, componentRestrictions: {country: 'gb'}},
          resolve
        )
      } catch (e) {
        reject(e)
      }
    });
  }

  async getPlace(id, separateNumber = false) {
    return new Promise((resolve, reject) => {
      let mapping = {
        address2: ['sublocality'],
        town: ['postal_town'],
        region: ['administrative_area_level_2'],
        postcode: ['postal_code']
      };
      if (!separateNumber) {
        mapping = {
          address1: ['street_number', 'route'],
          ...mapping,
        };
      } else {
        mapping = {
          address_number: ['street_number'],
          address1: ['route'],
          ...mapping,
        };
      }
      try {
        new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
          {placeId: id, fields: ['address_component']},
          (place) => {
            const details = {};
            Object.keys(mapping).forEach(attr => {
              details[attr] = mapping[attr].map(f => (
                place.address_components.filter(c => c.types.indexOf(f) >= 0).map(c => c.long_name).join(' ')
              )).filter(f => !!f).join(' ');
            });
            resolve(details);
          }
        )
      } catch (e) {
        reject(e)
      }
    });
  }
}
