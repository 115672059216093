import {ConfirmationDialog, FormDialog, fullDateTime, name} from '@management-ui/core';
import {Add, AssignmentTurnedIn, Delete, Launch, MarkEmailRead} from '@mui/icons-material';
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import Text from '../../../../components/Text';
import Status from '../../components/Status';
import ConfirmationForm from '../../forms/ConfirmationForm';
import HasAssociatedSerials from './Stock/HasAssociatedSerials';

const Confirmations = ({job, onJobUpdated}) => {
  const services = useContext(ServiceContext);
  const [showNew, setShowNew] = useState(false);
  const [showSendOptions, setShowSendOptions] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [sendType, setSendType] = useState('email');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendAnchor, setSendAnchor] = useState(null);

  const handleSelectSend = useCallback((confirmation) => {
    setSelected(confirmation);
    const types = [
      {value: confirmation.contact?.email, type: 'email'},
      {value: confirmation.contact?.mobile, type: 'sms'}
    ].filter(({value}) => !!value).map(({type}) => type);
    if (types.length > 0) {
      if (types.length < 2) {
        setSendType(types[0]);
        setShowSend(true);
      } else {
        setShowSendOptions(true);
      }
    }
  }, []);

  const handleSend = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      services.job.sendConfirmation(job, selected, sendType).then((saved) => {
        setLoading(false);
        onJobUpdated(saved);
      }).catch(() => setLoading(false));
    }
    setShowSend(false);
  }, [services, job, selected, sendType, onJobUpdated]);

  const handleConfirm = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      services.job.confirmConfirmation(job, selected).then((saved) => {
        setLoading(false);
        onJobUpdated(saved);
      }).catch(() => setLoading(false));
    }
    setShowConfirm(false);
  }, [services, job, selected, onJobUpdated]);

  const handleDelete = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      services.job.deleteConfirmation(job, selected).then((saved) => {
        setLoading(false);
        onJobUpdated(saved);
      }).catch(() => setLoading(false));
    }
    setShowDelete(false);
  }, [services, job, selected, onJobUpdated]);

  const Types = useCallback(({confirmation}) => {
    const types = [
      confirmation.general_required ? 'General' : '',
      confirmation.end_user_required ? 'End User' : '',
      confirmation.scorpion_required ? 'Scorpion' : '',
      confirmation.safe_track_required ? 'Safe Track' : ''
    ].filter(t => !!t);
    return <Text text={types.length < 1 ? 'General' : types.join('\n')}/>
  }, []);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>
            Information Checks
            <HasAssociatedSerials job={job}/>
          </h3>
          <Tooltip title="Request Information">
            <span>
              <IconButton
                onClick={() => setShowNew(true)}
                disabled={job.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Contact</Table.Header>
          <Table.Header>Type</Table.Header>
          <Table.Header>Fitter</Table.Header>
          <Table.Header>Sent</Table.Header>
          <Table.Header>Confirmed</Table.Header>
          <Table.Header>Status</Table.Header>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {job.confirmations.length > 0 ? (
          job.confirmations.map((confirmation, index) => (
            <Table.Row key={index}>
              <Table.Column>{confirmation.contact ? name(confirmation.contact) : '-'}</Table.Column>
              <Table.Column>
                <Types confirmation={confirmation}/>
              </Table.Column>
              <Table.Column>
                {confirmation.scorpion_fitter ? `${confirmation.scorpion_fitter.name} (${confirmation.scorpion_fitter.id})` : '-'}
              </Table.Column>
              <Table.Column>
                {confirmation.sent_at ? fullDateTime(confirmation.sent_at) : '-'}
              </Table.Column>
              <Table.Column>
                {confirmation.confirmed_at ? fullDateTime(confirmation.confirmed_at) : '-'}
              </Table.Column>
              <Table.Column><Status entity={confirmation}/></Table.Column>
              <Table.Action>
                <Tooltip title="View Online">
                  <span>
                    <IconButton
                      href={confirmation.link}
                      target="_blank"
                      disabled={job.archived}>
                      <Launch/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Send">
                  <span>
                    <IconButton
                      onClick={(event) => setSendAnchor(event.currentTarget) || handleSelectSend(confirmation)}
                      disabled={job.archived || confirmation.confirmed_at}>
                      <MarkEmailRead/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Confirm">
                  <span>
                    <IconButton
                      onClick={() => setSelected(confirmation) || setShowConfirm(true)}
                      disabled={job.archived || confirmation.end_user_required || confirmation.confirmed_at}>
                      <AssignmentTurnedIn/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Delete Request">
                  <span>
                    <IconButton
                      onClick={() => setSelected(confirmation) || setShowDelete(true)}
                      disabled={job.archived || confirmation.confirmed_at || confirmation.sent_at}>
                      <Delete/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          <Table.NoRecords>There are no requests associated with this job</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title="Request Information"
        open={showNew}
        maxWidth="sm"
        onClose={() => setShowNew(false)}
        render={(props) => (
          <ConfirmationForm
            {...props}
            job={job}
            confirmation={{}}
            onSaved={(updated) => setShowNew(false) || onJobUpdated(updated)}
          />
        )}
      />
      <ConfirmationDialog
        open={showSend}
        title="Send Confirmation?"
        message={selected ? `Are you sure you want to send this confirmation to ${sendType === 'email' ? selected.contact?.email : selected.contact?.mobile}?` : null}
        onClose={handleSend}
      />
      <ConfirmationDialog
        open={showConfirm}
        title="Confirm?"
        message="Are you sure you want to confirm the jobs details on the customer's behalf?"
        onClose={handleConfirm}
      />
      <ConfirmationDialog
        title="Delete Request"
        message="Are you sure you want to delete this request?"
        open={showDelete}
        onClose={handleDelete}
      />
      <Menu
        anchorEl={sendAnchor}
        open={showSendOptions}
        onClose={() => setShowSendOptions(false)}
      >
        {selected?.contact?.email ? (
          <MenuItem onClick={() => setSendType('email') || setShowSendOptions(false) || setShowSend(true)}>
            Email: {name(selected.contact)} - {selected.contact.email}
          </MenuItem>
        ) : null}
        {selected?.contact?.mobile ? (
          <MenuItem onClick={() => setSendType('sms') || setShowSendOptions(false) || setShowSend(true)}>
            SMS: {name(selected.contact)} - {selected.contact.mobile}
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default Confirmations;
