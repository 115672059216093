import {Checkbox, Panes, prefixWithSeparator, Select} from '@management-ui/core';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

export default function Fields({subscription = {}, prefix = ''}) {
  const {setValue, /** @type {function} */watch} = useFormContext();

  const accounts = useOptions('gatewayAccounts', useCallback((list) => list.map(({id, name, brand: {slug}}) => ({
    value: id,
    title: name,
    brand: slug
  })), []));

  const selectedAccount = watch(useMemo(() => `${prefixWithSeparator(prefix)}account_id`, [prefix]));

  useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setValue(
        `${prefixWithSeparator(prefix)}account_id`,
        accounts[accounts.findIndex(a => a.brand === 'safe-track') ?? 0].value
      );
    }
  }, [selectedAccount, accounts, setValue, prefix]);

  const handleReset = useCallback((subject) => {
    if (subject.account) {
      subject.account_id = subject.account.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={subscription}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Gateway Account',
          fields: [
            <Select
              name="account_id"
              prefix={prefix}
              options={accounts}
              label="Stripe Account"
            />,
            <Checkbox
              name="is_recurring"
              prefix={prefix}
              label="Is this a recurring (monthly) subscription?"
            />,
          ]
        }
      ]}
      title={''}
    />
  );
}
