import {Box, Button, Paper, styled} from '@mui/material';
import React, {useCallback, useContext, useState} from 'react';
import DetailDialog from '../../../../components/DetailDialog';
import {ServiceContext} from '../../../../components/Services';
import EngineerLabel from '../EngineerLabel';

const Container = styled(Paper)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 2, 2, 2),

  '& .MuiButton-root': {
    alignSelf: 'flex-start',
  }
}));

const Input = styled('input')(({theme}) => ({
  backgroundColor: theme.palette.grey['100'],
  border: 'none',
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),

  '&:disabled': {
    backgroundColor: theme.palette.grey['300'],
  }
}));

const Notice = styled('p')(({theme}) => ({
  fontWeight: '700',
  margin: theme.spacing(2, 0, 1),
  padding: theme.spacing(0, 2)
}));

const Engineers = styled('ul')(({theme}) => ({
  listStyle: 'none',
  margin: theme.spacing(0),
  padding: theme.spacing(0),

  '& li': {
    padding: theme.spacing(1, 2),
  }
}));

const Dialog = ({open, onClose}) => {
  const services = useContext(ServiceContext);
  const [postcode, setPostcode] = useState('');
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [match, setMatch] = useState(false);
  const [engineers, setEngineers] = useState([]);

  const handleSearch = useCallback(() => {
    if (postcode) {
      setLoading(true);
      services.user.lookupPostcode(postcode).then(({match, users}) => {
        setMatch(match);
        setEngineers(users);
        setSearched(true);
        setLoading(false);
      }).catch(() => {
        setMatch(false);
        setEngineers([]);
        setSearched(false);
        setLoading(false);
      })
    }
  }, [services, postcode]);

  return (
    <DetailDialog
      title="Find engineers by postcode"
      maxWidth="sm"
      open={open}
      onClose={onClose}
      actions={[
        {label: 'Close', colour: 'primary', onClick: () => onClose()}
      ]}
    >
      <Box display="flex" flexDirection="column">
        <Container>
          <label htmlFor="postcode">Enter a Postcode:</label>
          <Input type="text" id="postcode" value={postcode} onChange={e => setPostcode(e.target.value)}/>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleSearch}
          >
            {loading ? 'Searching...' : 'Search'}
          </Button>
        </Container>
        {searched ? (
          <>
            {match ? (
              <>
                <Notice>The following engineers can serve the postcode entered:</Notice>
              </>
            ) : engineers.length > 0 ? (
              <>
                <Notice>No engineers can serve in the system can serve the postcode entered, however these have no
                  postcodes assigned:</Notice>
              </>
            ) : (
              <Notice>No engineers could be found who may be able to serve the postcode entered.</Notice>
            )}
            {engineers.length > 0 ? (
              <Engineers>
                {engineers.map((engineer, index) => (
                  <li key={index}><EngineerLabel engineer={engineer}/></li>
                ))}
              </Engineers>
            ) : null}
          </>
        ) : null}
      </Box>
    </DetailDialog>
  );
}

export default Dialog;
