import {DataTable, fullDateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import ProductForm from '../../forms/ProductForm';

export default function Products({title = 'Products', company, canCreate = true, archive = false}) {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((product) => {
    history.push(reverse(`${archive ? routes.archive.products.detail : routes.stock.products.detail}`, {id: product.id}));
  }, [history, archive]);

  return (
    <DataTable
      title={title}
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 320px)'
      }}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Code', field: 'code'},
        {title: 'Purchase Price', field: 'purchase_price', render: data => <Currency amount={data.purchase_price}/>},
        {title: 'Sale Price', field: 'sale_price', render: data => <Currency amount={data.sale_price}/>},
        ...(company ? [] : [{
          title: 'Manufacturer',
          field: 'manufacturer',
          render: data => data.manufacturer ? data.manufacturer.name : '-'
        }]),
        {
          title: 'Items (Available / Allocated)',
          field: 'in_stock',
          render: data => `${data.available.quantity} / ${data.allocated.quantity}`,
          sorting: false
        },
        {title: 'Date Added', field: 'created_at', render: data => fullDateTime(data.created_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => fullDateTime(data.deleted_at)}] : [])
      ], [company, archive])}
      loadData={query => new Promise((resolve, reject) => {
        const filters = archive ? {archive: 'only'} : {};
        if (company) {
          filters.manufacturer = company.id
        }
        services.product.getTableProducts(query, filters)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={archive || !canCreate ? null : {
        title: 'Add New Product',
        render: (props) => <ProductForm {...props} product={company ? {manufacturer_id: company.id} : {}}/>,
        onSaved: goToDetail
      }}
    />
  );
}
