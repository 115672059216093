import {ColouredLabel, DataTable, fullDateTime, name} from '@management-ui/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import UserForm from '../../forms/UserForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((user) => {
    history.push(reverse(`${archive ? routes.archive.engineers.detail : routes.engineers.detail}`, {id: user.id}));
  }, [history, archive]);

  return (
    <DataTable
      title="Engineers"
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 260px)'
      }}
      columns={useMemo(() => [
        {
          title: 'Name',
          field: 'name',
          render: data => <ColouredLabel label={name(data)} colour={data.colour ?? 'white'}/>
        },
        {title: 'Email', field: 'email'},
        {title: 'Stock Location', field: 'location', render: data => data.location?.name ?? '-'},
        {title: 'Active?', field: 'is_active', render: data => <YesNo value={data.is_active}/>},
        {title: 'Regular?', field: 'is_regular', render: data => <YesNo value={data.is_regular}/>},
        {title: 'Date Added', field: 'created_at', render: data => fullDateTime(data.created_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => fullDateTime(data.deleted_at)}] : [])
      ], [archive])}
      loadData={query => new Promise((resolve, reject) => {
        services.user.getTableUsers(query, {engineers: 1, ...(archive ? {archive: 'only'} : {})})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={{
        title: 'Add New Engineer',
        render: (props) => <UserForm user={{}} engineer={true} {...props}/>,
        onSaved: goToDetail
      }}
    />
  );
};

export default Table;
