import {Panes, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

export default function Fields({product = {}, prefix = '', multiplePanes = true, children}) {

  const products = useOptions('products', useCallback((list) => list.map(p => ({
    value: p.id,
    title: p.name
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.product) {
      subject.product_id = subject.product.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={product}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="reference"
              prefix={prefix}
              label="Reference"
              rules={{required: 'Please enter a reference'}}
            />,
            <TextField
              name="xero_account_code"
              prefix={prefix}
              label="Xero Sales Code"
            />,
            <TextField
              name="xero_item_code"
              prefix={prefix}
              label="Xero Item Code (Products & Services)"
            />,
            <TextField
              name="price"
              prefix={prefix}
              label="Default Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="cost"
              prefix={prefix}
              label="Cost"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="length"
              prefix={prefix}
              label="Length (Months)"
            />,
            <Select
              name="product_id"
              prefix={prefix}
              options={products}
              label="Product"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Details'}
    >{children}</Panes>
  );
}
