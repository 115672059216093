import {Panes} from '@management-ui/core';
import {UploadField} from '@management-ui/upload';
import React from 'react';

const Fields = ({holder = {}, prefix = ''}) => {
  return (
    <Panes
      entity={holder}
      prefix={prefix}
      panes={[
        {
          title: 'Data Upload',
          fields: [
            <UploadField
              name="data"
              prefix={prefix}
              label="Drop a data file here or click to select from your computer"
              rules={{required: 'Please select a CSV file to upload'}}
              types=".csv"
            />
          ]
        }
      ]}
      title=""/>
  );
};

export default Fields;
