import {fullDateTime} from '@management-ui/core';
import React from 'react';
import Currency from '../../../../components/Currency';
import Status from '../Status';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  serial_number: {title: 'Serial', render: null},
  associated_serial_number: {title: 'Component Serial', render: null},
  purchase_price: {title: 'Purchase Price', render: data => <Currency amount={data.purchase_price}/>},
  sale_price: {title: 'Sale Price', render: data => <Currency amount={data.sale_price}/>},
  po_number: {title: 'Order', render: null},
  job: {title: 'Job', render: (data) => data.job?.reference ?? '-'},
  product: {title: 'Product', render: (data) => data.product?.name ?? '-'},
  location: {title: 'Location', render: (data) => data.location?.name ?? '-'},
  supplier: {title: 'Supplier', render: (data) => data.supplier?.name ?? '-'},
  status: {title: 'Status', render: (data) => <Status entity={data}/>},
  activated_at: {title: 'Activated', render: (data) => data.activated_at ? fullDateTime(data.activated_at) : '-'},
  recipient: {title: 'Recipient', render: null},
};

export default function StockItemUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
