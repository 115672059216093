import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const LinkJobForm = forwardRef(({job, unattached = true, onSave = null, onSaved = null}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={job}
      type="job"
      fieldsComponent={(props) => <Fields unattached={unattached} {...props}/>}
      onSave={onSave}
      onSaved={onSaved}
    />
  );
});

export default LinkJobForm;
