import {Panes, RadioGroup, Select} from '@management-ui/core';
import React, {useEffect, useState} from 'react'
import FormErrors from '../../components/FormErrors';
import orderOptions from '../OrderJobForm/orderOptions';

const Fields = ({order, delivery = {}, prefix = '', errors}) => {
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const {companies, contacts, locations} = orderOptions(order);
    setCompanies(companies);
    setContacts(contacts);
    setLocations(locations);
  }, [order]);

  return (
    <Panes
      entity={delivery}
      prefix={prefix}
      panes={[
        {
          title: 'Delivery Details',
          fields: [
            ...(errors.length ? [(
              <FormErrors>
                {errors.map((error, index) => <li key={index}>{error}</li>)}
              </FormErrors>
            )] : []),
            <Select
              name="company_id"
              prefix={prefix}
              options={companies}
              label="Company"
            />,
            <Select
              name="contact_id"
              prefix={prefix}
              options={contacts}
              label="Contact"
            />,
            <RadioGroup
              name="location_id"
              prefix={prefix}
              options={locations}
              label="Location"
            />
          ]
        }
      ]}
      title={null}
    />
  );
};

export default Fields;
