import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    width: '100%',
  },

  header: {
    display: 'flex',

    '& h3': {
      color: theme.palette.secondary.main,
      fontWeight: '700',
      margin: 0,
      padding: 0,
    }
  },

  notes: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  anotherMonth: {
    backgroundColor: theme.palette.grey['100'],

    '& > *': {
      opacity: 0.4,
    }
  },

  today: {
    '& h3': {
      color: theme.palette.primary.main,
    }
  },
}));

const Day = ({day, onSelectDay, onRenderNotes = () => null, onRenderEvents = () => null}) => {
  const classes = useStyles();
  return (
    <div
      className={[
        classes.container,
        !day.current ? classes.anotherMonth : '',
        day.today ? classes.today : ''
      ].join(' ')}
      onClick={() => onSelectDay(day)}
    >
      <div className={classes.header}>
        <h3>{day.date.format('D')}</h3>
        <div className={classes.notes}>
          {onRenderNotes(day)}
        </div>
      </div>
      {onRenderEvents(day)}
    </div>
  );
};

export default Day;
