import {Checkbox, Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import validator from 'email-validator';
import React from 'react';
import {useFormContext} from 'react-hook-form';

export default function Fields({contact = {}, validate = true, prefix = '', multiplePanes = true, children}) {
  const {/** @var {function(string): string} */getValues} = useFormContext();
  return (
    <Panes
      entity={contact}
      prefix={prefix}
      panes={[
        {
          title: 'Personal Details',
          fields: [
            <Select
              name="title"
              prefix={prefix}
              options={['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'].map(salutation => ({title: salutation, value: salutation}))}
              label="Title (Salutation)"
            />,
            <TextField
              name="first_name"
              prefix={prefix}
              rules={validate ? {
                validate: () => {
                  const fieldPrefix = prefixWithSeparator(prefix);
                  if (!getValues(`${fieldPrefix}first_name`) && !getValues(`${fieldPrefix}last_name`)) {
                    return 'Please enter at least a first or last name';
                  }
                  return true;
                }
              } : {}}
              label="First Name"
            />,
            <TextField
              name="last_name"
              prefix={prefix}
              label="Last Name"
            />,
            <TextField
              name="position"
              prefix={prefix}
              label="Position"
            />
          ]
        },
        {
          title: 'Contact Details',
          fields: [
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              autoComplete="email"
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone Number"
            />,
            <TextField
              name="mobile"
              prefix={prefix}
              label="Mobile Number"
            />,
            <Checkbox
              name="is_associated_component_supplier"
              prefix={prefix}
              label="Is this contact an associated component supplier?"
            />,
            <Checkbox
              name="is_subscription_supplier"
              prefix={prefix}
              label="Is this contact a subscription supplier?"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Contact Details'}
    >{children}</Panes>
  );
}

