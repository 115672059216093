import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

const useStyles = makeStyles(theme => ({
  cancel: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    }
  },
  save: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    }
  },
}));

/**
 * A dialog to display an input form dialog with standard buttons
 *
 * @module FormDialog
 *
 * @param {string} title The title of the dialog
 * @param {boolean} open A toggle to determine if the dialog is showing
 * @param {function} onClose A function to be executed when the dialog is closed
 * @param {function} render A function which returns the form component
 * @param {boolean} fullScreen Set the dialog to full screen
 * @param {string} maxWidth The maximum width for the dialog
 * @param {number} minHeight The minimum height for the dialog
 * @param {IFormDialogLabels} buttons An object providing custom labels for the save and cancel buttons
 * @param {IFormDialogButton[]} additionalButtons An array of additional buttons to show in with the default actions
 *
 * @example
 * <FormDialog
 *   title="Edit User"
 *   open={true}
 *   onClose={() => console.log('Closed')}
 *   render={(props) => <UserForm {...props} />}
 *   minHeight={400}
 *   buttons={{save: 'Save User', cancel: 'Cancel Edit'}}
 *   additionalButtons={[{label: 'Delete', colour: 'primary', onClick: () => console.log('Delete')}]}
 * />
 *
 */
const FormDialog = (
  {
    title,
    open = false,
    onClose,
    render,
    fullScreen = false,
    maxWidth= 'xl',
    minHeight,
    buttons,
    additionalButtons = []
  }
) => {
  const classes = useStyles();
  const formRef = useRef(null);

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth={true} maxWidth={maxWidth} fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent style={minHeight ? {minHeight} : null}>
        {render({ref: formRef})}
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancel} onClick={() => onClose()}>
          {buttons?.cancel ?? 'Cancel'}
        </Button>
        {additionalButtons.map(({label, onClick, classes = ''}, index) => (
          <Button key={index} className={classes} onClick={() => onClick(formRef)}>
            {label}
          </Button>
        ))}
        <Button className={classes.save} onClick={() => formRef.current.save()}>
          {buttons?.save ?? 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  render: PropTypes.func,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.number,
  buttons: PropTypes.object,
  additionalButtons: PropTypes.array
};

export default FormDialog;
