import {Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';

const Products = ({company, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(50);
  const [total, setTotal] = useState(0);
  const loaded = useRef(false);

  const loadProducts = useCallback((selectedPage = null, rowCount = null) => {
    onLoading(true);
    services.product.getProducts({filter: {manufacturer: company.id}}, selectedPage ?? page, rowCount ?? rows)
      .then(response => {
        setPage(parseInt(response.meta.current_page));
        setRows(parseInt(response.meta.per_page));
        setTotal(parseInt(response.meta.total));
        onLoading(false);
        setProducts(response.data);
      }).catch(() => onLoading(false));
  }, [company, onLoading, services, page, rows]);

  useEffect(() => {
    if (company) {
      if (!loaded.current) {
        loaded.current = true;
        loadProducts();
      }
    }
  }, [company, loadProducts]);

  const goToDetail = useCallback((product) => {
    history.push(reverse(routes.stock.products.detail, {id: product.id}));
  }, [history]);

  return (
    <Table className={loading ? 'loading' : ''}>
      <Table.Actions>
        <h3>Products Manufactured by {company.name}</h3>
        <Tooltip title="Refresh">
          <IconButton onClick={() => loadProducts()}>
            <Refresh/>
          </IconButton>
        </Tooltip>
      </Table.Actions>
      <Table.Row>
        <Table.Header>Name</Table.Header>
        <Table.Header>Code</Table.Header>
        <Table.Header>Purchase Price</Table.Header>
        <Table.Header>Sale Price</Table.Header>
        <Table.Header>Items (Available / Allocated)</Table.Header>
        <Table.Action/>
      </Table.Row>
      {products.length > 0 ? (
        products.map((product, index) => (
          <Table.Row key={index}>
            <Table.Column>{product.name}</Table.Column>
            <Table.Column>{product.code ?? '-'}</Table.Column>
            <Table.Column><Currency amount={product.purchase_price}/></Table.Column>
            <Table.Column><Currency amount={product.sale_price}/></Table.Column>
            <Table.Column>{product.available.quantity} / {product.allocated.quantity}</Table.Column>
            <Table.Action>
              <Tooltip title="View Product">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(product)}
                      disabled={company.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
              </Tooltip>
            </Table.Action>
          </Table.Row>
        ))
      ) : (
        loading ? null : <Table.NoRecords>This company does not manufacture any products</Table.NoRecords>
      )}
      <Table.Pagination
        total={total}
        page={page}
        onPageChange={(updated) => loadProducts(updated, null)}
        rows={rows}
        onRowsChange={(updated) => loadProducts(null, updated)}
      />
    </Table>
  );
};

export default Products;
