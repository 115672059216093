import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ServiceContext} from '../../../components/Services';
import {setSettings} from '../../../store/actions/settings';

const withSettings = (Component) => {
  return (props, onLoading = () => null) => {
    const services = useContext(ServiceContext);
    const {settings} = useSelector(state => state['settings']);
    const dispatch = useDispatch();

    useEffect(() => {
      if (settings === null) {
        onLoading(true);
        services.settings.getSettings().then((retrieved) => {
          onLoading(false);
          dispatch(setSettings(retrieved ?? {}));
        }).catch(() => {
          onLoading(false);
          dispatch(setSettings({}));
        });
      }
    }, [settings, services, dispatch, onLoading]);

    return settings === null ? null : <Component {...props} settings={settings}/>;
  }
}

export default withSettings;
