import {Panes} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import LocationList from '../../components/LocationList';

const Fields = ({location = {}, company = {}, contact = {}, prefix = '', onSelect}) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (company) {
      setLocations(company.locations.map(location => ({...location, type: location.type?.name ?? 'Company'})));
    } else if (contact) {
      setLocations(contact.locations.map(location => ({...location, type: location.type?.name ?? 'Contact'})));
    }
  }, [company, contact]);

  return (
    <Panes
      entity={location}
      prefix={prefix}
      panes={[
        {
          title: 'Select a Location',
          fields: [
            <Box marginTop={2}>
              <LocationList
                locations={locations}
                layout="narrow"
                onSelect={(selected) => onSelect(selected.id)}
              />
            </Box>
          ]
        }
      ]}
      title=""
    />
  );
};

export default Fields;
