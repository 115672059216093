import {Add, Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import qs from 'qs';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import AllocationSummary from '../../components/AllocationSummary';

const Jobs = ({contact, type = 'contact', loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [jobs, setJobs] = useState([]);
  const loaded = useRef(false);

  const loadJobs = useCallback(() => {
    onLoading(true);
    const filter = {};
    filter[type] = contact.id;
    services.job.getJobs({filter}, 1, 500)
      .then(response => {
        onLoading(false);
        setJobs(response.data);
      }).catch(() => onLoading(false));
  }, [contact, type, onLoading, services]);

  useEffect(() => {
    if (contact) {
      if (!loaded.current) {
        loaded.current = true;
        loadJobs();
      }
    }
  }, [contact, loadJobs]);

  const goToNew = useCallback(() => {
    const {id, first_name, last_name} = contact;
    history.push({pathname: routes.jobs.new, search: `?${qs.stringify({contact: {id, first_name, last_name}})}`});
  }, [history, contact]);

  const goToDetail = useCallback((job) => {
    history.push(reverse(routes.jobs.detail, {id: job.id}));
  }, [history]);

  return (
    <Table className={loading ? 'loading' : ''}>
      <Table.Actions>
        <h3>Jobs as {type === 'contact' ? 'Main Contact' : 'End User'}</h3>
        <Tooltip title="Refresh">
          <IconButton onClick={() => loadJobs()}>
            <Refresh/>
          </IconButton>
        </Tooltip>
        {type === 'contact' ? (
          <Tooltip title="Add New Job">
          <span>
            <IconButton disabled={contact.archived} onClick={() => goToNew()}>
              <Add/>
            </IconButton>
          </span>
          </Tooltip>
        ) : null}
      </Table.Actions>
      <Table.Row>
        <Table.Header>Reference</Table.Header>
        <Table.Header>Summary</Table.Header>
        <Table.Header>First Allocation</Table.Header>
        <Table.Action/>
      </Table.Row>
      {jobs.length > 0 ? (
        jobs.map((job, index) => (
          <Table.Row key={index}>
            <Table.Column>{job.reference}</Table.Column>
            <Table.Column>{job.summary} {job.model}</Table.Column>
            <Table.Column><AllocationSummary allocation={job.first_allocation}/></Table.Column>
            <Table.Action>
              <Tooltip title="View Job">
                <IconButton onClick={() => goToDetail(job)}>
                  <Visibility/>
                </IconButton>
              </Tooltip>
            </Table.Action>
          </Table.Row>
        ))
      ) : (loading ? null : <Table.NoRecords>
        There are no jobs associated with this {type === 'contact' ? 'contact' : 'end user'}
      </Table.NoRecords>)}
    </Table>
  );
};

export default Jobs;
