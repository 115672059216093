import {Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback, useMemo} from 'react';

export default function Fields({account = {}, prefix = '', multiplePanes = true, children}) {

  const brands = useMemo(() => [
    {value: 'safe-and-sound', title: 'Safe & Sound'},
    {value: 'safe-track', title: 'Safe Track'}
  ], []);

  const handleReset = useCallback((subject) => {
    if (subject.brand) {
      subject.brand = subject.brand.slug;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={account}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Identifiers',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="identifier"
              prefix={prefix}
              label="Identifier"
              rules={{required: 'Please enter an identifier'}}
            />,
            <TextField
              name="twilio_number"
              prefix={prefix}
              label="Twilio Sender ID"
            />,
            <Select
              name="brand"
              prefix={prefix}
              options={brands}
              label="Brand"
              rules={{required: 'Please select a brand'}}
            />,
            <TextField
              name="xero_sales_account"
              prefix={prefix}
              label="Xero Sales Account Code (e.g. 200)"
            />,
            <TextField
              name="xero_order_account"
              prefix={prefix}
              label="Xero Online Order Account Code (e.g. 200)"
            />,
          ]
        },
        {
          title: 'Stripe',
          fields: [
            <TextField
              name="public"
              prefix={prefix}
              label="Public Key"
            />,
            <TextField
              name="secret"
              prefix={prefix}
              label="Secret Key"
            />,
            <TextField
              name="hook"
              prefix={prefix}
              label="Webhook Key"
            />,
          ]
        },
        {
          title: 'PayPal',
          fields: [
            <TextField
              name="paypal_client"
              prefix={prefix}
              label="Client ID"
            />,
            <TextField
              name="paypal_secret"
              prefix={prefix}
              label="Secret"
            />,
            <TextField
              name="paypal_app"
              prefix={prefix}
              label="App ID"
            />,
          ]
        },
        {
          title: 'URLs',
          fields: [
            <TextField
              name="invoice_url"
              prefix={prefix}
              label="Invoice Payment URL (use {{REFERENCE}} to indicate where the invoice reference should go)"
            />,
            <TextField
              name="short_invoice_url"
              prefix={prefix}
              label="Short Invoice Payment URL (use {{REFERENCE}} to indicate where the invoice reference should go)"
            />,
            <TextField
              name="subscription_url"
              prefix={prefix}
              label="Subscription Payment URL (use {{REFERENCE}} to indicate where the subscription reference should go)"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Gateway Account Details'}
    >{children}</Panes>
  );
}
