import {DataTable, moment, name} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import VehicleForm from '../../forms/VehicleForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const goToDetail = useCallback((vehicle) => {
    if (vehicle.id) {
      history.push(reverse(`${archive ? routes.archive.vehicles.detail : routes.vehicles.detail}`, {id: vehicle.id}));
    }
  }, [history, archive]);

  return (
    <DataTable
      ref={tableRef}
      title="Vehicles"
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 260px)'
      }}
      columns={useMemo(() => [
        {
          title: 'Customer',
          field: 'customer',
          sorting: false,
          render: data => data.company ? data.company.name : data.contact ? name(data.contact) : '-'
        },
        {title: 'Registration', field: 'registration'},
        {title: 'VIN', field: 'vin'},
        {title: 'Make', field: 'make'},
        {title: 'Model', field: 'model'},
        {title: 'Colour', field: 'colour'},
        {
          title: 'Year of Manufacture',
          field: 'manufactured_on',
          render: vehicle => vehicle.manufactured_on ? moment(vehicle.manufactured_on).format('Y') : ''
        },
        {title: 'Scorpion ID', field: 'scorpion_reference'},
      ], [])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.vehicle.getTableVehicles(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, archive])}
      newForm={!archive ? {
        title: 'Add New Vehicle',
        render: (props) => (
          <VehicleForm
            {...props}
            vehicle={{}}
          />
        ),
        onSaved: () => tableRef.current.refresh()
      } : null}
      onRowClick={goToDetail}
    />
  );
};

export default Table;
