import * as qs from 'qs';
import {clearGatewayAccounts} from '../store/actions/options';
import {API} from './API';

export class GatewayAccountService {

  async getTableAccounts(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getAccounts(query, page, length);
  }

  async getAccounts(query, page, length) {
    return (await API.getConnection())
      .get('gateway-accounts', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getAccount(id) {
    return (await API.getConnection())
      .get(`gateway-accounts/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveAccount(data) {
    let method = 'post';
    let url = 'gateway-accounts';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearGatewayAccounts());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async beginAuthorisation(account, url) {
    return (await API.getConnection())
      .post(`gateway-accounts/${account.id}/authorise/begin`, {redirect_url: url})
      .then(response => response.data.url)
      .catch(error => API.handleError(error));
  }

  async completeAuthorisation(code, state) {
    return (await API.getConnection())
      .post('gateway-accounts/authorise/complete', {code, state})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async verify(account) {
    return (await API.getConnection())
      .post(`gateway-accounts/${account.id}/verify`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reset(account) {
    return (await API.getConnection())
      .post(`gateway-accounts/${account.id}/reset`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
