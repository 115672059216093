import {Panes} from '@management-ui/core';
import React from 'react';
import withTemplatedFields from './withTemplatedFields';

const Fields = ({job = {}, prefix = '', multiplePanes = true, fields, children}) => (
  <Panes
    entity={job}
    prefix={prefix}
    panes={[
      {
        title: 'Job Details',
        fields
      },
      {title: '', fields: []}
    ]}
    title={multiplePanes ? null : 'Job Details'}
  >{children}</Panes>
);

export default withTemplatedFields(Fields);
