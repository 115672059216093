import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const JobForm = forwardRef(({job, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={job}
      type="job"
      fieldsComponent={(props, onLoading) => <Fields onLoading={onLoading} {...props}/>}
      onSave={(update) => {
        const {latitude, longitude, ...data} = update;
        return services.job.saveJob(data);
      }}
      onSaved={onSaved}
    />
  );
});

export default JobForm;
