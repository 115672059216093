import {time} from '@management-ui/core';
import {Edit, Note} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {contrastColor} from 'contrast-color';
import React, {Fragment} from 'react';
import Metrics from './Metrics';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },

  event: {
    borderRadius: 4,
    cursor: 'pointer',
    flex: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    position: 'relative',

    '& .icon': {
      height: 12,
      position: 'absolute',
      right: 2,
      top: 2,
      width: 12
    }
  },

  fade: {
    opacity: 0.5,
  }
}));

const Events = ({events, onSelectEvent, currentAllocation}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {events.sort((a, b) => a.when.start.isBefore(b.when.start) ? -1 : 1).map((event, eventIndex) => (
        <div
          key={eventIndex}
          className={[
            classes.event,
            ...(currentAllocation?.id != null && currentAllocation.id !== event.block?.allocation?.id ? [classes.fade] : [])
          ].join(' ')}
          style={{backgroundColor: event.colour, color: contrastColor({bgColor: event.colour})}}
          onClick={(e) => e.stopPropagation() || onSelectEvent(event)}
        >
          {event.block?.type === 'note' ? <Note className="icon"/> : (event.block?.type === 'pending' ?
            <Edit className="icon"/> : null)}
          {[
            {value: event.when.start && time(event.when.start) !== '00:00' ? time(event.when.start) : '', bold: false},
            {value: event.title, bold: true}
          ].filter(t => !!t.value).map((t, titleIndex) => (
            <Fragment key={`${eventIndex}-${titleIndex}`}>
              {titleIndex > 0 ? <>&nbsp;-&nbsp;</> : null}
              {t.bold ? <strong>{t.value}</strong> : <>{t.value}</>}
            </Fragment>
          ))}
          {event.block?.allocation?.id ? <Metrics allocation={event.block.allocation}/> : null}
        </div>
      ))}
    </div>
  );
};

export default Events;
