import {FormDialog, Spinner} from '@management-ui/core';
import {CompareArrows, VideoLabel} from '@mui/icons-material';
import {Box, IconButton, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Table from '../../../../../components/Table';
import JobProductsForm from '../../../forms/JobProductsForm';
import TransferStockForm from '../../../forms/TransferStockForm';
import HasAssociatedSerials from './HasAssociatedSerials';
import Product from './Product';

const useStyles = makeStyles((theme) => ({

  title: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    display: 'flex',

    '& .MuiTypography-root': {
      flex: 1,
    },
  },

  overlay: {
    alignItems: 'flex-start',
    background: theme.palette.background.paper,
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    left: 0,
    opacity: 0,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    transition: '0.25s opacity ease-in-out'
  },

  loading: {
    opacity: 0.75,
    pointerEvents: 'all'
  },

  button: {
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 64px',
    justifyContent: 'center',
    width: 64
  },
}));

const Stock = ({job, onJobUpdated, onReloadJob, loading: jobLoading}) => {
  const classes = useStyles();
  const loadingRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleLoading = useCallback((isLoading) => {
    if (isLoading) {
      loadingRef.current++;
    } else {
      loadingRef.current--;
    }
    if (loadingRef.current < 0) {
      loadingRef.current = 0;
    }
    setLoading(loadingRef.current > 0);
  }, []);

  useEffect(() => handleLoading(jobLoading), [jobLoading, handleLoading]);

  const handleJobUpdated = useCallback((updated) => {
    onJobUpdated(updated);
    setShowAdd(false);
  }, [onJobUpdated]);

  const handleSelect = useCallback((item) => {
    const updated = [...selectedItems];
    const index = updated.indexOf(item);
    if (index >= 0) {
      updated.splice(index, 1);
    } else {
      updated.push(item);
    }
    setSelectedItems(updated);
  }, [selectedItems]);

  const handleTransferred = useCallback(() => {
    setShowTransfer(false);
    setSelectedItems([]);
    onReloadJob();
  }, [onReloadJob]);

  return job ? (
    <>
      <Box position="relative" width="100%" overflow="hidden">
        <div className={[classes.overlay, loading ? classes.loading : ''].join(' ')}><Spinner/></div>
        <Table.Actions>
          <h3>
            Stock
            <HasAssociatedSerials job={job}/>
          </h3>
          <Tooltip title="Add Products">
            <span>
              <IconButton
                onClick={() => setShowAdd(true)}
                disabled={job.archived}>
                <VideoLabel/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Transfer Selected Items">
            <span>
              <IconButton
                onClick={() => setShowTransfer(true)}
                disabled={job.archived || selectedItems.length < 1}>
                <CompareArrows/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        {job.products.map((product, index) => (
          <Product
            key={index}
            job={job}
            product={product}
            onJobUpdated={handleJobUpdated}
            onLoading={handleLoading}
            onReloadJob={onReloadJob}
            selectedItems={selectedItems}
            onSelectItem={handleSelect}
          />
        ))}
      </Box>
      <FormDialog
        title="Add Products"
        open={showAdd}
        maxWidth="sm"
        onClose={() => setShowAdd(false)}
        render={(props) => <JobProductsForm product={{product_ids: [], quantity: 1}} job={job} onSaved={handleJobUpdated} {...props}/>}
      />
      <FormDialog
        open={showTransfer}
        onClose={() => setShowTransfer(false)}
        maxWidth="sm"
        title={`Transfer ${selectedItems.length} stock item${selectedItems.length === 1 ? '' : 's'}`}
        render={(props) => (
          <TransferStockForm {...props} entity={{}} ids={selectedItems} onSaved={handleTransferred}/>
        )}
      />
    </>
  ) : null;
};

export default Stock;
