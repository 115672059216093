import {ActionList, FormDialog, name} from '@management-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import NoteForm from '../../forms/NoteForm';
import Comment from './Comment';
import CompanyContactLinked from './CompanyContactLinked';
import CompanyUpdate from './CompanyUpdate';
import ConfirmationUpdate from './ConfirmationUpdate';
import ContactUpdate from './ContactUpdate';
import InvoiceUpdate from './InvoiceUpdate';
import JobAllocationUpdate from './JobAllocationUpdate';
import JobProductUpdate from './JobProductUpdate';
import JobUpdate from './JobUpdate';
import JobVehicleUpdate from './JobVehicleUpdate';
import LocationUpdate from './LocationUpdate';
import StockItemUpdate from './StockItemUpdate';
import SubscriptionUpdate from './SubscriptionUpdate';

export default function Actions(
  {
    loadActivity,
    saveNote,
    setLoading,
    openDialogs,
    toggleDialog,
    toggleError,
    page = 1,
    length = 5,
    updatePageCount
  }
) {
  const [activity, setActivity] = useState([]);

  const updateActivity = useCallback((response) => {
    setActivity(response.data.map(action => ({
      ...action,
      who: name(action.user),
      when: action.created_at
    })));
    if (updatePageCount) {
      updatePageCount(response.meta.last_page);
    }
  }, [updatePageCount])

  useEffect(() => {
    setLoading(true);
    loadActivity(page, length).then((response) => {
      setLoading(false);
      updateActivity(response);
    }).catch(() => {
      toggleError('activity', true);
      setLoading(false);
    });
  }, [loadActivity, page, length, setLoading, toggleError, updateActivity]);

  return (
    <>
      <ActionList
        actions={activity}
        renderAction={(action) => {
          let body;
          switch (action.type) {
            case 'company-created':
            case 'company-updated':
            case 'company-deleted':
            case 'company-restored':
              body = <CompanyUpdate action={action}/>;
              break;
            case 'contact-created':
            case 'contact-updated':
            case 'contact-deleted':
            case 'contact-restored':
              body = <ContactUpdate action={action}/>;
              break;
            case 'company-location-created':
            case 'company-location-updated':
            case 'contact-location-created':
            case 'contact-location-updated':
              body = <LocationUpdate action={action}/>;
              break;
            case 'company-contact-linked':
            case 'company-contact-unlinked':
              body = <CompanyContactLinked action={action}/>;
              break;
            case 'comment':
              body = <Comment action={action}/>;
              break;
            case 'job-created':
            case 'job-updated':
            case 'job-deleted':
            case 'job-restored':
              body = <JobUpdate action={action}/>;
              break;
            case 'stock-item-created':
            case 'stock-item-updated':
            case 'stock-item-deleted':
            case 'stock-item-restored':
            case 'stock-item-reminded':
            case 'stock-item-association-sent':
              body = <StockItemUpdate action={action}/>;
              break;
            case 'job-vehicle-created':
            case 'job-vehicle-updated':
            case 'job-vehicle-deleted':
              body = <JobVehicleUpdate action={action}/>;
              break;
            case 'job-product-created':
            case 'job-product-updated':
            case 'job-product-deleted':
              body = <JobProductUpdate action={action}/>;
              break;
            case 'job-allocation-created':
            case 'job-allocation-updated':
            case 'job-allocation-deleted':
            case 'job-allocation-restored':
              body = <JobAllocationUpdate action={action}/>;
              break;
            case 'invoice-created':
            case 'invoice-updated':
            case 'invoice-deleted':
            case 'invoice-restored':
            case 'invoice-sent':
              body = <InvoiceUpdate action={action}/>;
              break;
            case 'subscription-created':
            case 'subscription-updated':
            case 'subscription-deleted':
            case 'subscription-restored':
            case 'subscription-sent':
            case 'subscription-reminded':
              body = <SubscriptionUpdate action={action}/>;
              break;
            case 'confirmation-created':
            case 'confirmation-updated':
            case 'confirmation-deleted':
              body = <ConfirmationUpdate action={action}/>;
              break;
            default:
              body = null;
              break;
          }
          return body;
        }}
      />
      <FormDialog
        title="Add New Comment"
        open={openDialogs?.note ?? false}
        onClose={() => toggleDialog('note', false)}
        maxWidth="md"
        render={(props) => (
          <NoteForm
            {...props}
            note={{}}
            onSave={(note) => saveNote(note, page, length)}
            onSaved={(response) => toggleDialog('note', false) || updateActivity(response)}
          />
        )}
      />
    </>
  );
}
