import {styled} from '@mui/material';
import React from 'react';

const Container = styled('div')(({theme}) => ({
  alignItems: 'center',
  borderBottom: `2px solid ${theme.palette.grey['100']}`,
  borderTop: `2px solid ${theme.palette.grey['100']}`,
  display: 'flex',
  flex: 1,
  padding: `0 ${theme.spacing(2)}`,
  width: '100%',

  '& > span': {
    flex: 0,
    paddingRight: theme.spacing(2),
  }
}));

const Statuses = styled('ul')(() => ({
  display: 'flex',
  flex: 1,
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

const Status = styled('li')(({theme}) => ({
  borderLeft: `2px solid ${theme.palette.grey['100']}`,
  color: 'var(--status-colour)',
  cursor: 'pointer',
  fontWeight: '700',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  '&:last-of-type': {
    borderRight: `2px solid ${theme.palette.grey['100']}`,
  },

  '&.selected': {
    background: 'var(--status-colour)',
    color: theme.palette.common.white,
  }
}));

const Filter = ({status, onChange, statuses}) => (
  <Container>
    <span>Displaying:</span>
    <Statuses>
      {statuses.map(({title, slug, colour}, index) => (
        <Status
          key={index}
          className={status === slug ? 'selected' : ''}
          style={{'--status-colour': colour}}
          onClick={() => status === slug ? null : onChange(slug)}
        >
          {title}
        </Status>
      ))}
    </Statuses>
  </Container>
);

export default Filter;
