import {Panes, Select} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

export default function Fields({entity = {}, prefix = ''}) {

  const locations = useOptions('stockLocations', useCallback((list) => list.map(l => ({
    value: l.id,
    title: l.name
  })), []));

  return (
    <Panes
      entity={entity}
      prefix={prefix}
      panes={[
        {
          title: 'Stock Location',
          fields: [
            <Select
              name="location"
              prefix={prefix}
              options={locations}
              label="Destination"
              rules={{required: 'Please select a destination'}}
            />
          ]
        }
      ]}
      title={null}
    />
  );
}
