import {DataTable, fullDateTime, name} from '@management-ui/core';
import {styled} from '@mui/material';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import Pill from '../../../../components/Pill';
import {ServiceContext} from '../../../../components/Services';
import OutboundMessageDialog from '../../components/OutboundMessageDialog';
import Status from '../../components/Status';

const Details = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',

  '& .sent': {
    flex: '0 0 100%',
    margin: theme.spacing(1, 0, 0),
    width: '100%'
  }
}));

const Table = (
  {
    contact,
    invoice,
    job,
    order,
    subscription
  }
) => {
  const services = useContext(ServiceContext);
  const [selected, setSelected] = useState(null);
  const [showDetail, setShowDetail] = useState(false);

  const handleSelect = useCallback((message) => {
    setSelected(message);
    setShowDetail(true);
  }, []);

  const handleData = useCallback(query => new Promise((resolve, reject) => {
    const params = {};
    if (contact) {
      params.contact = contact.id;
    }
    if (invoice) {
      params.invoice = invoice.id;
    }
    if (job) {
      params.job = job.id;
    }
    if (order) {
      params.order = order.id;
    }
    if (subscription) {
      params.subscription = subscription.id;
    }
    services.outboundMessage.getTableMessages(query, params)
      .then(response => {
        resolve({
          data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
        });
      }).catch(() => {
      reject();
    });
  }), [services, contact, invoice, job, order, subscription]);

  return (
    <>
      <DataTable
        title="Emails"
        options={useMemo(() => ({
          search: false,
          sorting: false,
          draggable: false,
          ...((contact || invoice || job || order || subscription) ? {
            headerStyle: {background: 'white', position: 'sticky', top: 0},
            maxBodyHeight: 'calc(100vh - 280px)'
          } : {})
        }), [contact, invoice, job, order, subscription])}
        columns={useMemo(() => [
          {
            title: 'Recipients',
            field: 'recipients',
            render: data => data.recipients ? data.recipients.join(', ') : ''
          },
          {title: 'Subject', field: 'subject'},
          {
            title: 'Details',
            field: 'details',
            render: data => <Details>
              <Status entity={data}/>
              <span className="sent">{fullDateTime(data.created_at)}</span>
              <Pill className={data.type}>{data.type}</Pill>
              {data.contact ? <Pill><strong>C:</strong> {name(data.contact)}</Pill> : null}
              {data.invoice ? <Pill><strong>I:</strong> {data.invoice.reference}</Pill> : null}
              {data.job ? <Pill><strong>J:</strong> {data.job.reference}</Pill> : null}
              {data.order ? <Pill><strong>O:</strong> {data.order.reference}</Pill> : null}
              {data.subscription ? <Pill><strong>S:</strong> {data.subscription.reference}</Pill> : null}
              {data.user ? <Pill><strong>U:</strong> {name(data.user)}</Pill> : null}
            </Details>
          },
        ], [])}
        loadData={handleData}
        onRowClick={handleSelect}
      />
      <OutboundMessageDialog
        message={selected}
        open={showDetail}
        onClose={() => setShowDetail(false)}
      />
    </>
  );
}

export default Table;
