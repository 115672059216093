import {API} from './API';

export class SettingsService {
  async getSettings() {
    return (await API.getConnection())
      .get('settings')
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async saveSettings(settings) {
    return (await API.getConnection())
      .put('settings', {settings})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}
