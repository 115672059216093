import {ColouredLabel, DataTable, fullDateTime, prefixWithSeparator} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useCallback, useContext, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';

export default function Fields({product, quantity, prefix = ''}) {
  const services = useContext(ServiceContext);
  const {setValue} = useFormContext();
  const prefixedIDs = `${prefixWithSeparator(prefix)}ids`

  const handleSelect = useCallback((rows) => {
    setValue(prefixedIDs, rows.map(r => r.id));
  }, [prefixedIDs, setValue]);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <DataTable
        title={useMemo(() => `Quantity Still Required: ${quantity}`, [quantity])}
        options={{selection: true}}
        tableProps={{onSelectionChange: handleSelect}}
        columns={useMemo(() => [
          {
            title: 'Location',
            field: 'location',
            render: item => {
              if (item.location?.name) {
                if (item.location.users.length > 0 && item.location.users[0].colour) {
                  return <ColouredLabel colour={item.location.users[0].colour} label={item.location.name}/>;
                }
                return item.location.name;
              } else {
                return '-';
              }
            }
          },
          {title: 'Serial', field: 'serial_number'},
          {title: 'Purchase Price', field: 'purchase_price', render: data => <Currency amount={data.purchase_price}/>},
          {title: 'Sale Price', field: 'sale_price', render: data => <Currency amount={data.sale_price}/>},
          {title: 'Order', field: 'po_number'},
          {
            title: 'Supplier',
            field: 'supplier',
            render: item => item.supplier ? item.supplier.name : '-'
          },
          {title: 'Last Moved', field: 'moved_at', render: data => data.moved_at ? fullDateTime(data.moved_at) : '-'},
        ], [])}
        loadData={useCallback(query => new Promise((resolve, reject) => {
          services.stockItem.getTableItems(query, {available: '1', product: product.id})
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => reject());
        }), [services, product])}
      />
    </Box>
  );
}
