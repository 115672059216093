import {Address, address, name} from '@management-ui/core'
import {styled} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Paper from '../Paper';
import Section from '../Section';
import Column from './Column';

const Message = styled('p')(({theme}) => ({
  color: theme.palette.grey['600'],
  fontSize: '1em',
  fontWeight: '700',
  margin: 0,
  padding: theme.spacing(1, 1, 0),
}));

const Columns = styled('div')(({theme}) => ({
  display: 'flex',
  flex: '1',
  transition: '0.25s opacity ease-in-out',

  '&.loading': {
    opacity: 0.5,
    pointerEvents: 'none',
  },

  '& > div': {
    flex: '0 0 50%',
    width: '50%',

    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.grey['100']}`
    },

    '&:last-of-type': {
      borderLeft: `1px solid ${theme.palette.grey['100']}`
    },
  },
}));

const ContactSection = ({title, prefix, order, onOrderUpdated, onLoadOrder, toggle = null, loading, onLoading}) => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    let details = [];
    const getPrefixedValue = (field) => order[`${prefix}_${field}`];
    if (prefix && order) {
      if (getPrefixedValue('first_name') || getPrefixedValue('last_name')) {
        const populated = (
          title,
          field,
          hasValue = (title, value) => ({title, value}),
          noValue = (title) => ({title, value: '-'})
        ) => {
          const value = getPrefixedValue(field);
          return value ? hasValue(title, value) : noValue(title);
        };
        details = [
          populated('Company Name', 'company_name'),
          {
            title: 'Name',
            value: name({first_name: getPrefixedValue('first_name'), last_name: getPrefixedValue('last_name')}) ?? '-'
          },
          populated('Email Address', 'email', (title, value) => ({title, value, link: `mailto:${value}`})),
          populated('Phone Number', 'phone'),
          populated('Mobile Number', 'mobile'),
          {
            title: 'Address',
            value: <Address address={address({
              address1: getPrefixedValue('address1'),
              address2: getPrefixedValue('address2'),
              town: getPrefixedValue('town'),
              region: getPrefixedValue('region'),
              postcode: getPrefixedValue('postcode'),
              country: getPrefixedValue('country'),
            })}/>
          }
        ];
      }
    }
    setDetails(details);
  }, [prefix, order]);

  return (
    <>
      <Section title={title}>
        {toggle && order[toggle['field']] ? <Message>{toggle['message']}</Message> : (
          details.length < 1 ? <Message>Not Required</Message> : (
            <Paper details={details}>
              {order.status.slug === 'paid' ? (
                <Columns className={loading ? 'loading' : ''}>
                  <Column
                    type="Company"
                    prefix={prefix}
                    order={order}
                    onOrderUpdated={onOrderUpdated}
                    onLoadOrder={onLoadOrder}
                    onLoading={onLoading}
                  />
                  <Column
                    type="Contact"
                    prefix={prefix}
                    order={order}
                    onOrderUpdated={onOrderUpdated}
                    onLoadOrder={onLoadOrder}
                    onLoading={onLoading}
                  />
                </Columns>
              ) : null}
            </Paper>
          )
        )}
      </Section>
    </>
  );
};

export default ContactSection;
