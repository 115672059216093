import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';

export default function Bookings() {
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Bookings'}]}/>
      <Paper>
        <Table/>
      </Paper>
    </div>
  );
}
