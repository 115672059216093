import * as qs from 'qs';
import {clearLocationTypes} from '../store/actions/options';
import {API} from './API';

export class LocationTypeService {
  async getTableTypes(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTypes(query, page, length);
  }

  async getTypes(query, page, length) {
    return (await API.getConnection())
      .get('location-types', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(API.handleError);
  }

  async saveType(data) {
    let method = 'post';
    let url = 'location-types';
    if (data.id) {
      method = 'put';
      url = `location-types/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearLocationTypes());
        return response.data.data
      })
      .catch(API.handleError);
  }
}
