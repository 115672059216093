import {name, Panes, prefixWithSeparator, RelationAutocomplete, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import moment from 'moment';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';
import {useOptions} from '../../../../hooks';
import RegistrationLookup from '../../components/RegistrationLookup';
import orderOptions from '../OrderJobForm/orderOptions';

const Fields = ({vehicle = {}, order, prefix = '', children}) => {
  const services = useContext(ServiceContext);
  const {/** @var {function(string): string} */getValues} = useFormContext();
  const [years, setYears] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (years.length < 1) {
      const list = [];
      const current = moment().year();
      for (let y = current; y >= current - 30; y--) {
        list.push({value: `${y}-01-01T00:00:00+00:00`, title: `${y}`});
      }
      setYears(list);
    }
  }, [years]);

  useEffect(() => {
    if (order) {
      const {companies, contacts} = orderOptions(order);
      setCompanies(companies);
      setContacts(contacts);
    }
  }, [order]);

  const types = useOptions('vehicleTypes', useCallback((list) => list.map(type => ({
    value: type.id,
    title: type.name
  })), []));

  const fuels = useOptions('fuelTypes', useCallback((list) => list.map(type => ({
    value: type.id,
    title: type.name
  })), []));

  const batteries = useOptions('batteryTypes', useCallback((list) => list.map(type => ({
    value: type.id,
    title: type.name
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.company) {
      subject.company_id = subject.company.id;
      subject.company_name = subject.company.name;
      delete subject.company;
    }
    if (subject.contact) {
      subject.contact_id = subject.contact.id;
      subject.contact_name = name(subject.contact);
      delete subject.contact;
    }
    if (subject.vehicle_type) {
      subject.vehicle_type_id = subject.vehicle_type.id;
    }
    if (subject.fuel_type) {
      subject.fuel_type_id = subject.fuel_type.id;
    }
    if (subject.battery_type) {
      subject.battery_type_id = subject.battery_type.id;
    }
    if (subject.aliases) {
      subject.aliasList = subject.aliases.join('\n');
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={vehicle}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Vehicle Identification',
          fields: [
            ...(order ? [
              <Select
                name="company_id"
                prefix={prefix}
                options={companies}
                label="Company"
                displayEmpty={true}
                rules={{
                  validate: () => {
                    const fieldPrefix = prefixWithSeparator(prefix);
                    if (!getValues(`${fieldPrefix}company_id`) && !getValues(`${fieldPrefix}contact_id`)) {
                      return 'Please select either a company or a contact';
                    }
                    return true;
                  }
                }}
              />,
              <Select
                name="contact_id"
                prefix={prefix}
                options={contacts}
                label="Contact"
                displayEmpty={true}
              />
            ] : [
              <RelationAutocomplete
                valueName="company_id"
                titleName="company_name"
                prefix={prefix}
                label="Company"
                loadOptions={query => (
                  services.company.getCompanies({filter: {search: query}}, 1, 50)
                    .then(response => response.data.map(company => ({title: company.name, value: company.id})))
                    .catch(() => [])
                )}
              />,
              <RelationAutocomplete
                valueName="contact_id"
                titleName="contact_name"
                prefix={prefix}
                label="Contact"
                loadOptions={query => (
                  services.contact.getContacts({filter: {search: query}}, 1, 50)
                    .then(response => response.data.map(contact => ({title: name(contact), value: contact.id})))
                    .catch(() => [])
                )}
              />
            ]),
            <TextField
              name="registration"
              prefix={prefix}
              label="Vehicle Registration"
              fieldProps={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <RegistrationLookup name="registration" prefix={prefix}/>
                    </InputAdornment>
                  )
                }
              }}
            />,
            <TextField
              name="vin"
              prefix={prefix}
              label="VIN Number"
            />,
            <TextField
              name="make"
              prefix={prefix}
              label="Make"
            />,
            <TextField
              name="model"
              prefix={prefix}
              label="Model"
            />,
            <Select
              name="vehicle_type_id"
              prefix={prefix}
              options={types}
              label="Vehicle Type"
            />,
          ]
        }, {
          title: 'Vehicle Details',
          fields: [
            <TextField
              name="colour"
              prefix={prefix}
              label="Colour"
            />,
            <Select
              name="manufactured_on"
              prefix={prefix}
              options={years}
              label="Year of Manufacture"
            />,
            <Select
              name="fuel_type_id"
              prefix={prefix}
              options={fuels}
              label="Fuel Type"
            />,
            <Select
              name="battery_type_id"
              prefix={prefix}
              options={batteries}
              label="Battery Type"
            />,
            <TextField
              name="scorpion_reference"
              prefix={prefix}
              label="Scorpion ID"
            />,
            <TextField
              name="aliasList"
              prefix={prefix}
              label="Registration Aliases (one per line)"
              fieldProps={{multiline: true}}
            />,
          ]
        }
      ]}
      title={null}
    >{children}</Panes>
  );
};

export default Fields;
