import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {Paper, Switch} from '@mui/material';
import {makeStyles} from '@mui/styles';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },

  engineers: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `0 -8px ${theme.spacing(2)}`,
  },

  toggle: {
    alignItems: 'center',
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1em',
    fontWeight: '600',
    justifyContent: 'center',
    padding: theme.spacing(2),
    textTransform: 'uppercase',

    '& svg': {
      marginLeft: theme.spacing(2),
    }
  },

  hidden: {
    display: 'none',
  },

  engineer: {
    display: 'flex',
    flex: '0 0 25%',
    padding: theme.spacing(1),
    width: '25%',
  },

  engineerPaper: {
    alignItems: 'center',
    borderLeft: `${theme.spacing(2)} solid ${theme.palette.background.paper}`,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    opacity: 0.4,
    overflow: 'hidden',
    padding: [1, 1, 1, 2].map(p => `${theme.spacing(p)}`).join(' '),
    transition: '0.25s opacity ease-in-out',
    width: '100%',

    '&:hover': {
      opacity: 0.75,
    }
  },

  selected: {
    opacity: 1,

    '&:hover': {
      opacity: 1,
    }
  }
}));

const KEY = 'selectedEngineers';

const EngineerSelector = ({engineers, selected, onChange}) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const cached = localStorage.getItem(KEY);
    if (cached) {
      onChange(cached.split(',').map(id => parseInt(id)));
    } else {
      onChange(engineers.map(e => e.id));
    }
  }, [engineers, onChange]);

  const handleSelect = useCallback((engineer) => {
    const update = [...selected].filter(id => `${id}`.match(/[0-9]{1,10}/g));
    const index = update.indexOf(engineer.id);
    if (index >= 0) {
      update.splice(index, 1);
    } else {
      update.push(engineer.id);
    }
    const selection = [...update];
    localStorage.setItem(KEY, selection.join(','));
    onChange(selection);
  }, [selected, onChange]);

  const renderList = (engineers) => engineers.map((engineer, index) => {
    const checked = selected.indexOf(engineer.id) >= 0;
    return (
      <div key={index} className={classes.engineer}>
        <Paper
          onClick={() => handleSelect(engineer)}
          className={[classes.engineerPaper, checked ? classes.selected : ''].join(' ')}
          style={{borderLeftColor: engineer.colour}}
        >
          {engineer.name}
          <Switch
            color="secondary"
            checked={checked}
          />
        </Paper>
      </div>
    )
  });

  return (
    <div className={classes.container}>
      <div className={classes.engineers}>
        {renderList(engineers.filter(e => e.is_regular))}
      </div>
      <div className={[classes.engineers, ...(showAll ? [] : [classes.hidden])].join(' ')}>
        {renderList(engineers.filter(e => !e.is_regular))}
      </div>
      <button onClick={() => setShowAll(!showAll)} className={classes.toggle}>
        {showAll ? <>Hide<ArrowUpward/></> : <>Show All<ArrowDownward/></>}
      </button>
    </div>
  );
};

export default EngineerSelector;
