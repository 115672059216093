import {Paper} from '@mui/material';
import {styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import Emails from '../OutboundMessages/Table';
import Activity from './Activity';
import Allocations from './Allocations';
import Companies from './Companies';
import Invoices from './Invoices';
import Jobs from './Jobs';
import Locations from './Locations';
import Subscriptions from './Subscriptions';
import Vehicles from './Vehicles';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({contact, onContactUpdated, onReloadContact, loading, onLoading, toggleError}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Allocations',
      slug: '',
      path: reverse(contact.archived ? routes.archive.contacts.detail : routes.contacts.detail, {
        id: contact?.id ?? 0
      })
    },
    {
      title: 'Jobs',
      slug: 'jobs',
      path: reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {
        id: contact?.id ?? 0,
        tab: 'jobs'
      })
    },
    {
      title: 'Vehicles',
      slug: 'vehicles',
      path: reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {
        id: contact?.id ?? 0,
        tab: 'vehicles'
      })
    },
    {
      title: 'Locations',
      slug: 'locations',
      path: reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {
        id: contact?.id ?? 0,
        tab: 'locations'
      })
    },
    {
      title: 'Companies',
      slug: 'companies',
      path: reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {
        id: contact?.id ?? 0,
        tab: 'companies'
      })
    },
    {
      title: 'Invoicing',
      slug: 'invoicing',
      path: reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {
        id: contact?.id ?? 0,
        tab: 'invoicing'
      })
    },
    {
      title: 'Activity',
      slug: 'activity',
      path: reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {
        id: contact?.id ?? 0,
        tab: 'activity'
      })
    },
    {
      title: 'Emails',
      slug: 'emails',
      path: reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {
        id: contact?.id ?? 0,
        tab: 'emails'
      })
    },
  ], [contact]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Container>
          <Allocations contact={contact} type="contact" loading={loading} onLoading={onLoading}/>
        </Container>
        <Container>
          <Allocations contact={contact} type="end_user" loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="jobs" currentTab={tab}>
        <Container>
          <Jobs contact={contact} type="contact" loading={loading} onLoading={onLoading}/>
        </Container>
        <Container>
          <Jobs contact={contact} type="end_user" loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="vehicles" currentTab={tab}>
        <Container>
          <Vehicles contact={contact} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="locations" currentTab={tab}>
        <Container>
          <Locations contact={contact} onContactUpdated={onContactUpdated} />
        </Container>
      </Switched>
      <Switched tab="companies" currentTab={tab}>
        <Container>
          <Companies contact={contact} onReloadContact={onReloadContact} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="invoicing" currentTab={tab}>
        <Container>
          <Invoices contact={contact} loading={loading} onLoading={onLoading}/>
        </Container>
        <Container>
          <Subscriptions contact={contact} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="activity" currentTab={tab}>
        <Activity contact={contact} onLoading={onLoading} toggleError={toggleError}/>
      </Switched>
      <Switched tab="emails" currentTab={tab}>
        <Container>
          <Emails contact={contact}/>
        </Container>
      </Switched>
    </>
  );
};

export default Tabs;
