import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const LinkAnyVehicleForm = forwardRef(({vehicle, company = null, contact = null, onSave, onSaved = null}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={vehicle}
      type="vehicle"
      fieldsComponent={(props) => <Fields {...props} company={company} contact={contact}/>}
      onSave={({vehicle_id}) => onSave(vehicle_id)}
      onSaved={onSaved}
    />
  );
});

export default LinkAnyVehicleForm;
