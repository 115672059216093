import {fullDateTime} from '@management-ui/core';
import React from 'react';
import Currency from '../../../../components/Currency';
import Status from '../Status';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  job: {title: 'Job', render: (data) => data.job?.reference ?? '-'},
  account: {title: 'Account', render: (data) => data.account?.name ?? '-'},
  reference: {title: 'Reference', render: (data) => data.reference},
  status: {title: 'Status', render: (data) => <Status entity={data}/>},
  sub_total: {title: 'Sub-total', render: (data) => <Currency amount={data.sub_total ?? 0}/>},
  tax: {title: 'Tax', render: (data) => <Currency amount={data.tax ?? 0}/>},
  tax_rate: {title: 'Tax Rate', render: (data) => `${(data.tax_rate ?? 0) * 100}%`},
  total: {title: 'Total', render: (data) => <Currency amount={data.total ?? 0}/>},
  paid: {title: 'Paid', render: (data) => <Currency amount={data.paid ?? 0}/>},
  outstanding: {title: 'Outstanding', render: (data) => <Currency amount={data.outstanding ?? 0}/>},
  xero_uuid: {title: 'Xero ID', render: (data) => data.xero_uuid ?? '-'},
  published_at: {title: 'Published', render: (data) => data.published_at ? fullDateTime(data.published_at) : '-'},
  updated_at: {title: 'Last Updated', render: (data) => data.updated_at ? fullDateTime(data.updated_at) : '-'},
};

export default function InvoiceUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
