import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const ContactForm = forwardRef(({contact, onSaved, company = null, endUser = false}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={contact}
      type="contact"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={(update) => services.contact.saveContact({
        ...update,
        ...(company ? {
          company_id: company.id,
          is_end_user: endUser
        } : {})
      })}
      onSaved={onSaved}
    />
  );
});

export default ContactForm;
