import {styled} from '@mui/material';

const Toggle = styled('strong')(({theme}) => ({
  '&.yes': {
    color: theme.palette.success.main
  },

  '&.no': {
    color: theme.palette.error.main
  }
}));

const YesNo = ({value}) => <Toggle className={value ? 'yes' : 'no'}>{value ? 'Yes' : 'No'}</Toggle>;

export default YesNo;

