import {FormDialog, fullDate, name} from '@management-ui/core';
import {Add, Edit, Refresh} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import Status from '../../components/Status';
import CostForm from '../../forms/CostForm';

const Costs = ({job, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const [costs, setCosts] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const loaded = useRef(false);

  const loadCosts = useCallback(() => {
    setShowNew(false);
    setShowEdit(false);
    onLoading(true);
    services.cost.getCosts({filter: {job: job.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setCosts(response.data);
      }).catch(() => onLoading(false));
  }, [job, onLoading, services]);

  useEffect(() => {
    if (job) {
      if (!loaded.current) {
        loaded.current = true;
        loadCosts();
      }
    }
  }, [job, loadCosts]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Costs</h3>
          <Tooltip title="Refresh">
            <IconButton onClick={() => loadCosts()}>
              <Refresh/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Add New Cost">
            <span>
              <IconButton
                onClick={() => setShowNew(true)}
                disabled={job.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Engineer</Table.Header>
          <Table.Header>Reference</Table.Header>
          <Table.Header>Status</Table.Header>
          <Table.Header>Date Paid</Table.Header>
          <Table.Header>Amount</Table.Header>
          <Table.Action/>
        </Table.Row>
        {costs.length > 0 ? (
          costs.map((cost, index) => (
            <Table.Row key={index}>
              <Table.Column>{cost.engineer ? name(cost.engineer) : '-'}</Table.Column>
              <Table.Column>{cost.reference}</Table.Column>
              <Table.Column><Status entity={cost}/></Table.Column>
              <Table.Column>{cost.paid_at ? fullDate(cost.paid_at) : '-'}</Table.Column>
              <Table.Column><Currency amount={cost.amount}/></Table.Column>
              <Table.Action>
                <Tooltip title="Edit">
                  <span>
                    <IconButton
                      onClick={() => {
                        setSelected(cost)
                        setShowEdit(true)
                      }}
                      disabled={job.archived}>
                      <Edit/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no costs associated with this job</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        open={showNew}
        title="Add New Cost"
        maxWidth="md"
        render={useCallback((props) => (
          <CostForm cost={{}} job={job} onSaved={loadCosts} {...props} />
        ), [job, loadCosts])}
        onClose={() => setShowNew(false)}/>
      <FormDialog
        open={showEdit}
        title="Edit Cost"
        maxWidth="md"
        render={useCallback((props) => (
          <CostForm cost={selected ?? {}} job={job} onSaved={loadCosts} {...props} />
        ), [job, selected, loadCosts])}
        onClose={() => setShowEdit(false)}/>
    </>
  );
};

export default Costs;
