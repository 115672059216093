import {FormDialog, moment} from '@management-ui/core';
import {Add, DriveEta, Link} from '@mui/icons-material';
import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import LinkVehicleForm from '../../forms/LinkVehicleForm';
import VehicleForm from '../../forms/VehicleForm';
import Button from './Button';
import JobLink from './JobLink';

const Container = styled(Paper)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2, 0, 0),
  overflow: 'hidden',
}));

const Vehicles = ({order, onOrderUpdated, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [vehicles, setVehicles] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    setVehicles(order?.vehicles ?? []);
  }, [order]);

  const handleUpdate = useCallback((vehicle, attrs) => {
    setShowAdd(false);
    setShowLink(false);
    onLoading(true);
    services.order.updateVehicle(order, vehicle, attrs).then((updated) => {
      onLoading(false);
      onOrderUpdated(updated);
    }).catch(() => onLoading(false));
  }, [order, onOrderUpdated, onLoading, services]);

  const handleJob = useCallback((vehicle, job) => {
    onLoading(true);
    services.vehicle.linkJob(vehicle.vehicle.id, job.id).then(() => {
      onLoading(false);
      handleUpdate(vehicle, {job_id: job.id});
    }).catch(() => onLoading(false));
  }, [onLoading, services, handleUpdate]);

  return (
    <Container>
      <Table className={loading ? 'loading' : ''}>
        <Table.Row>
          <Table.Header>Registration</Table.Header>
          <Table.Header>Make / Model</Table.Header>
          <Table.Header>VIN</Table.Header>
          <Table.Header>Colour</Table.Header>
          <Table.Header>Year</Table.Header>
          {order.status.slug === 'paid' ? (
            <>
              <Table.Header>Associated Vehicle</Table.Header>
              <Table.Header>Job</Table.Header>
            </>
          ) : null}
        </Table.Row>
        {vehicles.length > 0 ? (
          vehicles.map((vehicle, index) => (
            <Table.Row key={index}>
              <Table.Column>{vehicle.registration}</Table.Column>
              <Table.Column>{vehicle.make} {vehicle.model}</Table.Column>
              <Table.Column>{vehicle.vin}</Table.Column>
              <Table.Column>{vehicle.colour}</Table.Column>
              <Table.Column>
                {vehicle.manufactured_on ? moment(vehicle.manufactured_on).format('Y') : '-'}
              </Table.Column>
              {order.status.slug === 'paid' ? (
                <>
                  <Table.Column>
                    {vehicle.vehicle ? (
                      <Button
                        onClick={e => e.preventDefault() || history.push(reverse(routes.vehicles.detail, {id: vehicle.vehicle.id}))}
                        className="small"
                      ><DriveEta/>{vehicle.vehicle.registration ?? vehicle.vehicle.vin}</Button>
                    ) : (
                      <>
                        <Button
                          onClick={e => e.preventDefault() || setSelected(vehicle) || setShowAdd(true)}
                          className="small padRight action"
                        ><Add/>Add</Button>
                        <Button
                          onClick={e => e.preventDefault() || setSelected(vehicle) || setShowLink(true)}
                          className="small action"
                        ><Link/>Link</Button>
                      </>
                    )}
                  </Table.Column>
                  <Table.Column>
                    {vehicle.vehicle ?
                      <JobLink
                        order={order}
                        job={vehicle.job}
                        onLink={(job) => handleJob(vehicle, job)}
                      /> : null}
                  </Table.Column>
                </>
              ) : null}
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no vehicles associated with this order</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title="Add a New Vehicle"
        open={showAdd}
        maxWidth="md"
        onClose={() => setShowAdd(false)}
        render={(props) => (
          <VehicleForm
            {...props}
            order={order}
            vehicle={selected ? {
              registration: selected.registration,
              vin: selected.vin,
              make: selected.make,
              model: selected.model,
              colour: selected.colour
            } : {}}
            onSaved={({id}) => handleUpdate(selected ?? {}, {vehicle_id: id})}
          />
        )}
      />
      <FormDialog
        title="Link an Existing Vehicle"
        open={showLink}
        maxWidth="md"
        onClose={() => setShowLink(false)}
        render={(props) => (
          <LinkVehicleForm
            {...props}
            order={order}
            toJob={false}
            vehicle={{}}
            onSave={(vehicle) => new Promise(resolve => resolve(vehicle))}
            onSaved={(id) => handleUpdate(selected ?? {}, {vehicle_id: id})}
          />
        )}
      />
    </Container>
  );
};

export default Vehicles;
