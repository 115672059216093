import {address, Address, FormDialog, name} from '@management-ui/core';
import {Backspace, Link, LinkOff, LocationOff, LocationOn, People, RecentActors} from '@mui/icons-material';
import {styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import CompanyForm from '../../../forms/CompanyForm';
import ContactForm from '../../../forms/ContactForm';
import LinkCompanyForm from '../../../forms/LinkCompanyForm';
import LinkContactForm from '../../../forms/LinkContactForm';
import LinkLocationForm from '../../../forms/LinkLocationForm';
import LocationForm from '../../../forms/LocationForm';

const Container = styled('div')(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),

  '& > h4': {
    color: theme.palette.grey['600'],
    margin: theme.spacing(1, 0),
    padding: 0,
    textTransform: 'uppercase',
  },

  '& > span': {
    flex: '1',
  },

  '& > button': {
    alignItems: 'center',
    backgroundColor: theme.palette.grey['300'],
    border: 0,
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.9em',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 2),
    transition: '0.25s background-color ease-in-out',

    '&:hover': {
      backgroundColor: theme.palette.grey['200'],
    },

    '&.selected': {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
    },

    '& > svg': {
      margin: theme.spacing(0, 1, 0, 0),
    }
  }
}));

const Column = ({type, prefix, order, onOrderUpdated, onLoadOrder, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [entity, setEntity] = useState(null);
  const [location, setLocation] = useState(null);
  const [showLink, setShowLink] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [showLinkLocation, setShowLinkLocation] = useState(false);
  const [canConnect, setCanConnect] = useState(false);
  const [canDisconnect, setCanDisconnect] = useState(false);

  const getPrefixedValue = useCallback((field) => order[`${prefix}_${field}`], [prefix, order]);

  useEffect(() => {
    let entity = null;
    let location = null;
    let connect = false;
    let disconnect = false;
    if (prefix && order) {
      const company = getPrefixedValue('company');
      const contact = getPrefixedValue('contact');
      if (type === 'Company') {
        entity = company;
        location = getPrefixedValue('company_location');
      } else if (type === 'Contact') {
        entity = contact;
        location = getPrefixedValue('contact_location');
      }
      if (type === 'Contact' && company && contact) {
        if (company.contacts.filter(({id}) => id === contact.id).length < 1) {
          connect = true;
        } else {
          disconnect = true;
        }
      }
    }
    setEntity(entity);
    setLocation(location);
    setCanConnect(connect);
    setCanDisconnect(disconnect);
  }, [type, prefix, order, getPrefixedValue]);

  const getFields = useCallback((section) => {
    const values = {};
    let fields;
    if (section === 'entity') {
      fields = type === 'Company' ? {
        name: 'company_name',
        phone: 'phone'
      } : {
        first_name: 'first_name',
        last_name: 'last_name',
        email: 'email',
        phone: 'phone',
        mobile: 'mobile'
      };
    } else {
      fields = {
        address1: 'address1',
        address2: 'address2',
        town: 'town',
        region: 'region',
        postcode: 'postcode',
        country: 'country'
      }
    }
    for (let field of Object.keys(fields)) {
      values[field] = getPrefixedValue(fields[field]);
    }
    return values;
  }, [type, getPrefixedValue]);

  const handleSelect = useCallback((e) => {
    e.preventDefault();
    history.push(reverse(routes[type === 'Company' ? 'companies' : 'contacts'].detail, {id: entity.id}));
  }, [type, entity, history]);

  const handleUpdate = useCallback(async (e, updates) => {
    if (e) {
      e.preventDefault();
    }
    setShowLink(false);
    setShowAdd(false);
    setShowAddLocation(false);
    setShowLinkLocation(false);
    return new Promise(resolve => {
      const prefixed = {};
      for (let key of Object.keys(updates)) {
        prefixed[`${prefix}_${key}`] = updates[key];
      }
      onLoading(true);
      services.order.updateOrder(order, prefixed).then((updated) => {
        onOrderUpdated(updated);
        onLoading(false);
        resolve();
      }).catch(() => {
        onLoading(false);
        resolve();
      });
    });
  }, [prefix, order, onOrderUpdated, onLoading, services]);

  const handleReset = useCallback((e, section) => {
    let updates = type === 'Company' ? {company_location_id: null} : {contact_location_id: null};
    if (section === 'entity') {
      updates[type === 'Company' ? 'company_id' : 'contact_id'] = null;
    }
    handleUpdate(e, updates)
      .then(() => null)
      .catch(() => null);
  }, [type, handleUpdate]);

  const handleConnect = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
    }
    onLoading(true);
    services.company.linkContact(getPrefixedValue('company'), getPrefixedValue('contact')).then(() => {
      onLoading(false);
      onLoadOrder(order.id);
    }).catch(() => {
      onLoading(false);
    });
  }, [order, onLoadOrder, onLoading, services, getPrefixedValue]);

  const handleNewLocation = useCallback(({locations}) => {
    const location = locations.sort((a, b) => a.id > b.id ? -1 : 1)[0];
    if (location) {
      handleUpdate(null, type === 'Company' ? {company_location_id: location.id} : {contact_location_id: location.id})
        .then(() => null)
        .catch(() => null);
    }
  }, [type, handleUpdate]);

  const handleLinkedLocation = useCallback((id) => {
    if (id) {
      handleUpdate(null, type === 'Company' ? {company_location_id: id} : {contact_location_id: id})
        .then(() => null)
        .catch(() => null);
    } else {
      setShowLinkLocation(false);
    }
  }, [type, handleUpdate]);

  return (
    <Container>
      <h4>{type}</h4>
      {entity ? (
        <>
          <button onClick={handleSelect} className="selected">
            {type === 'Company' ? (
              <><RecentActors/> {entity.name}</>
            ) : (
              <><People/> {name(entity)}</>
            )}
          </button>
          {canConnect ? (
            <button onClick={handleConnect}>
              <Link/> Connect to {order[`${prefix}_company`]?.name}
            </button>
          ) : canDisconnect ? (
            <button onClick={handleConnect}>
              <LinkOff/> Disconnect from {order[`${prefix}_company`]?.name}
            </button>
          ) : null}
          {location ? (
            <>
              <button onClick={e => e.preventDefault()} className="selected">
                <LocationOn/> <Address address={address(location)}/>
              </button>
              <button onClick={(e) => handleReset(e, 'location')}>
                <LocationOff/> Reset Location
              </button>
            </>
          ) : (
            <>
              {entity.locations.length > 0 ? (
                <button onClick={e => e.preventDefault() || setShowLinkLocation(true)}>
                  <Link/> Link an Existing {type} Location
                </button>
              ) : null}
              <button onClick={e => e.preventDefault() || setShowAddLocation(true)}>
                <LocationOn/> Add a New {type} Location
              </button>
            </>
          )}
          <span/>
          <button onClick={(e) => handleReset(e, 'entity')}>
            <Backspace/> Reset {type}
          </button>
        </>
      ) : (
        <>
          <button onClick={e => e.preventDefault() || setShowLink(true)}>
            <Link/> Link an Existing {type}
          </button>
          <button onClick={e => e.preventDefault() || setShowAdd(true)}>
            {type === 'Company' ? <RecentActors/> : <People/>} Add a New {type}
          </button>
        </>
      )}
      <FormDialog
        title={`Link Existing ${type}`}
        open={showLink}
        maxWidth="sm"
        onClose={() => setShowLink(false)}
        render={(props) => type === 'Company' ? (
          <LinkCompanyForm
            {...props}
            company={{}}
            onSave={({company_id}) => handleUpdate(null, {company_id})}
          />
        ) : (
          <LinkContactForm
            {...props}
            contact={{}}
            onSave={({contact_id}) => handleUpdate(null, {contact_id})}
          />
        )}
      />
      <FormDialog
        title={`Add a New ${type}`}
        open={showAdd}
        maxWidth="md"
        onClose={() => setShowAdd(false)}
        render={(props) => type === 'Company' ? (
          <CompanyForm
            {...props}
            company={getFields('entity')}
            onSaved={({id}) => handleUpdate(null, {company_id: id})}
          />
        ) : (
          <ContactForm
            {...props}
            contact={getFields('entity')}
            onSaved={({id}) => handleUpdate(null, {contact_id: id})}
          />
        )}
      />
      <FormDialog
        title={`Add a New ${type} Location`}
        open={showAddLocation}
        maxWidth="md"
        onClose={() => setShowAddLocation(false)}
        render={(props) => (
          <LocationForm
            {...props}
            type={type.toLowerCase()}
            parent={entity}
            location={getFields('location')}
            onSaved={handleNewLocation}
          />
        )}
      />
      <FormDialog
        title={`Link Existing ${type} Location`}
        open={showLinkLocation}
        maxWidth="md"
        onClose={() => setShowLinkLocation(false)}
        render={(props) => (
          <LinkLocationForm
            {...props}
            location={{}}
            company={type === 'Company' ? entity : null}
            contact={type === 'Contact' ? entity : null}
            onSelected={handleLinkedLocation}
          />
        )}
      />
    </Container>
  );
};

export default Column;
