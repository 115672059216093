import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import MoneyIcon from '@mui/icons-material/Money';
import * as React from 'react';
import routes from '../../../../routes';
import Payments from './Payments';

const TABS = [
  {
    id: 'payments',
    slug: 'payments',
    title: 'Payments',
    icon: <MoneyIcon/>,
    render: (props) => <Payments {...props} />
  },
];

const Reporting = () => {
  const {tab, handleTab} = useTab(TABS, routes.reporting.index);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Reporting'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}

export default Reporting;