import {ColouredLabel, name} from '@management-ui/core';
import React from 'react';

const EngineerLabel = ({engineer}) => (
  <ColouredLabel label={(
    <><span>{name(engineer)}{engineer.archived ? (<><br/><strong>Archived</strong></>) : null}</span></>
  )} colour={engineer.colour ?? '#f1f1f1'} faded={engineer.archived}/>
);

export default EngineerLabel;
