import {name} from '@management-ui/core';
import React from 'react';
import Selected from '../../components/Slider/Selected';

const SelectedCustomer = ({session: {selectedCompany: company, selectedContact: contact}}) => (
  company ? (
    <Selected title="Company" value={company.name}/>
  ) : contact ? (
    <Selected title="Contact" value={name(contact)}/>
  ) : ''
);

export default SelectedCustomer;
