import {DetailColumns, DetailPane, fullDateTime} from '@management-ui/core';
import {Box} from '@mui/material';
import React from 'react';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai'
import DetailDialog from '../../../../components/DetailDialog';
import {getStatusCodeLabel} from '../../../../utils';

const LogDialog = ({log, open, onClose}) => {
  return (
    <DetailDialog
      title={log ? fullDateTime(log.created_at) : ''}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      actions={[
        {label: 'Close', colour: 'primary', onClick: () => onClose()}
      ]}
    >
      <Box display="flex" flexDirection="column">
        {log ? (
          <DetailColumns columns={[
            <Box display="flex" flexDirection="column" width="100%">
              <DetailPane
                title="Request"
                flexPaper={false}
                details={[
                  {title: 'Endpoint', value: log.url},
                  {title: 'Method', value: log.method},
                  {title: 'Date/Time', value: fullDateTime(log.created_at)},
                ]}
              />
              <JSONPretty data={log.request} theme={JSONPrettyMon}/>
            </Box>,
            <Box display="flex" flexDirection="column" width="100%">
              <DetailPane
                title="Response"
                flexPaper={false}
                details={[
                  {title: 'Result', value: log.type.name},
                  {title: 'Response Status', value: getStatusCodeLabel(log.status)},
                ]}
              />
              <JSONPretty data={log.response} theme={JSONPrettyMon}/>
            </Box>,
          ]}/>
        ) : null}
      </Box>
    </DetailDialog>
  );
}

export default LogDialog;
