import {name} from '@management-ui/core';
import {Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import AllocationSummary from '../../components/AllocationSummary';

const Jobs = ({vehicle, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [jobs, setJobs] = useState([]);
  const loaded = useRef(false);

  const loadJobs = useCallback(() => {
    onLoading(true);
    services.job.getJobs({filter: {vehicle: vehicle.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setJobs(response.data);
      }).catch(() => onLoading(false));
  }, [vehicle, onLoading, services]);

  useEffect(() => {
    if (vehicle) {
      if (!loaded.current) {
        loaded.current = true;
        loadJobs();
      }
    }
  }, [vehicle, loadJobs]);

  const goToDetail = useCallback((job) => {
    history.push(reverse(routes.jobs.tab, {id: job.id, tab: 'vehicles'}));
  }, [history]);

  return (
    <Table className={loading ? 'loading' : ''}>
      <Table.Actions>
        <h3>Jobs</h3>
        <Tooltip title="Refresh">
          <IconButton onClick={() => loadJobs()}>
            <Refresh/>
          </IconButton>
        </Tooltip>
      </Table.Actions>
      <Table.Row>
        <Table.Header>Reference</Table.Header>
        <Table.Header>Summary</Table.Header>
        <Table.Header>Customer</Table.Header>
        <Table.Header>First Allocation</Table.Header>
        <Table.Action/>
      </Table.Row>
      {jobs.length > 0 ? (
        jobs.map((job, index) => (
          <Table.Row key={index}>
            <Table.Column>{job.reference}</Table.Column>
            <Table.Column>{job.summary} {job.model}</Table.Column>
            <Table.Column>{job.company ? job.company.name : job.contact ? name(job.contact) : '-'}</Table.Column>
            <Table.Column><AllocationSummary allocation={job.first_allocation}/></Table.Column>
            <Table.Action>
              <Tooltip title="View Job">
                <IconButton onClick={() => goToDetail(job)}>
                  <Visibility/>
                </IconButton>
              </Tooltip>
            </Table.Action>
          </Table.Row>
        ))
      ) : (
        loading ? null : <Table.NoRecords>There are no jobs associated with this vehicle</Table.NoRecords>
      )}
    </Table>
  );
};

export default Jobs;
