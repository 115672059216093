import {Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';

const Items = ({company, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(50);
  const [total, setTotal] = useState(0);
  const loaded = useRef(false);

  const loadItems = useCallback((selectedPage = null, rowCount = null) => {
    onLoading(true);
    services.stockItem.getItems({filter: {supplier: company.id}}, selectedPage ?? page, rowCount ?? rows)
      .then(response => {
        setPage(parseInt(response.meta.current_page));
        setRows(parseInt(response.meta.per_page));
        setTotal(parseInt(response.meta.total));
        onLoading(false);
        setItems(response.data);
      }).catch(() => onLoading(false));
  }, [company, onLoading, services, page, rows]);

  useEffect(() => {
    if (company) {
      if (!loaded.current) {
        loaded.current = true;
        loadItems();
      }
    }
  }, [company, loadItems]);

  const goToDetail = useCallback((item) => {
    history.push(reverse(routes.stock.items.detail, {id: item.id}));
  }, [history]);

  return (
    <Table className={loading ? 'loading' : ''}>
      <Table.Actions>
        <h3>Stock Items Supplied by {company.name}</h3>
        <Tooltip title="Refresh">
          <IconButton onClick={() => loadItems()}>
            <Refresh/>
          </IconButton>
        </Tooltip>
      </Table.Actions>
      <Table.Row>
        <Table.Header>Product</Table.Header>
        <Table.Header>Location</Table.Header>
        <Table.Header>Serial</Table.Header>
        <Table.Header>Purchase Price</Table.Header>
        <Table.Header>Sale Price</Table.Header>
        <Table.Header>Order</Table.Header>
        <Table.Header>Job</Table.Header>
        <Table.Action/>
      </Table.Row>
      {items.length > 0 ? (
        items.map((item, index) => (
          <Table.Row key={index}>
            <Table.Column>{item.product?.name ?? '-'}</Table.Column>
            <Table.Column>{item.location?.name ?? '-'}</Table.Column>
            <Table.Column>{item.serial_number ?? '-'}</Table.Column>
            <Table.Column><Currency amount={item.purchase_price}/></Table.Column>
            <Table.Column><Currency amount={item.sale_price}/></Table.Column>
            <Table.Column>{item.po_number ?? '-'}</Table.Column>
            <Table.Column>{item.job?.reference ?? '-'}</Table.Column>
            <Table.Action>
              <Tooltip title="View Item">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(item)}
                      disabled={company.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
              </Tooltip>
            </Table.Action>
          </Table.Row>
        ))
      ) : (
        loading ? null : <Table.NoRecords>This company has not supplied any stock items</Table.NoRecords>
      )}
      <Table.Pagination
        total={total}
        page={page}
        onPageChange={(updated) => loadItems(updated, null)}
        rows={rows}
        onRowsChange={(updated) => loadItems(null, updated)}
      />
    </Table>
  );
};

export default Items;
