import {Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  boxes: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: `0 ${0 - theme.spacing(1)}`,
    padding: 0,
  },

  selected: {
    '& $boxPaper h3': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  box: {
    cursor: 'pointer',
    display: 'flex',
    flex: `0 0 ${100 / 3}%`,
    padding: theme.spacing(1),
    width: `${100 / 3}%`,

    '&:hover $boxPaper h3': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },

  boxPaper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    textAlign: 'center',

    '& h3': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      margin: 0,
      padding: `${theme.spacing(1)} 0`,
    }
  },

  facts: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  fact: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.success.main,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(0.5),
  },

  alternate: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.warning.main,
  }
}));

const SummaryBoxes = ({boxes}) => {
  const classes = useStyles();
  return boxes.length > 0 ? (
    <ul className={classes.boxes}>
      {boxes.map(({title, selected = false, rows, onClick}, boxIndex) => (
        <li className={[classes.box, selected ? classes.selected : ''].join(' ')} key={boxIndex} onClick={onClick}>
          <Paper className={classes.boxPaper}>
            <h3>{title}</h3>
            {rows && rows.length > 0 ? (
              rows.map((facts, rowIndex) => (
                <div key={`${boxIndex}-${rowIndex}`} className={classes.facts}>
                  {facts.map(({title, value}, factIndex) => (
                    <div className={[classes.fact, rowIndex % 2 === 0 ? '' : classes.alternate].join(' ')}
                         key={`${boxIndex}-${rowIndex}-${factIndex}`}>
                      <strong>{title}</strong>
                      <span>{value}</span>
                    </div>
                  ))}
                </div>
              ))
            ) : null}
          </Paper>
        </li>
      ))}
    </ul>
  ) : null;
};

export default SummaryBoxes;
