import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import PeopleIcon from '@mui/icons-material/People';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import * as React from 'react';
import routes from '../../../routes';
import CompaniesTable from './Companies/Table';
import ContactsTable from './Contacts/Table';
import EngineersTable from './Engineers/Table';
import JobsTable from './Jobs/Table';
import Products from './Stock/Products';
import StockItems from './Stock/StockItems';
import StockLocations from './Stock/StockLocations';
import VirtualProducts from './Stock/VirtualProducts';

const TABS = [
  {
    id: 'jobs',
    slug: 'jobs',
    title: 'Jobs',
    icon: <WorkOutlineIcon/>,
    render: (props) => <JobsTable {...props} archive={true}/>
  },
  {
    id: 'companies',
    slug: 'companies',
    title: 'Companies',
    icon: <RecentActorsIcon/>,
    render: (props) => <CompaniesTable {...props} archive={true}/>
  },
  {
    id: 'contacts',
    slug: 'contacts',
    title: 'Contacts',
    icon: <PeopleIcon/>,
    render: (props) => <ContactsTable {...props} archive={true}/>
  },
  {
    id: 'engineers',
    slug: 'engineers',
    title: 'Engineers',
    icon: <EmojiTransportationIcon/>,
    render: (props) => <EngineersTable {...props} archive={true}/>
  },
  {
    id: 'products',
    slug: 'products',
    title: 'Products',
    icon: <VideoLabelIcon/>,
    render: (props) => <Products {...props} archive={true}/>
  },
  {
    id: 'locations',
    slug: 'locations',
    title: 'Locations',
    icon: <GpsFixedIcon/>,
    render: (props) => <StockLocations {...props} archive={true}/>
  },
  {
    id: 'items',
    slug: 'items',
    title: 'Items',
    icon: <FormatListBulletedIcon/>,
    render: (props) => <StockItems {...props} archive={true}/>
  },
  {
    id: 'virtualProducts',
    slug: 'virtual-products',
    title: 'Virtual Products',
    icon: <SubscriptionsIcon/>,
    render: (props) => <VirtualProducts {...props} archive={true}/>
  },
];

export default function Archive() {
  const {tab, handleTab} = useTab(TABS, routes.archive.index);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Archive'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
