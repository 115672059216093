import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useEffect, useRef} from 'react';

const StripeHolder = ({publicKey, children}) => {
  const stripe = useRef(null)
  const loaded = useRef('');

  useEffect(() => {
    if (publicKey && loaded.current !== publicKey) {
      loaded.current = publicKey;
      stripe.current = loadStripe(publicKey);
    }
  }, [publicKey]);

  return (
    <Elements stripe={stripe.current}>
      {children}
    </Elements>
  );
};

export default StripeHolder;
