import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const JobProductsForm = forwardRef(({job, product, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  const [productIDs, setProductIDs] = useState([]);

  const handleChangeProductIDs = (event) => {
    const {options} = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setProductIDs(value);
  };

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={product}
      type="product"
      fieldsComponent={(props) => (
        <Fields {...props} productIDs={productIDs} onChangeProductIDs={handleChangeProductIDs}/>
      )}
      onSave={useCallback(({quantity}) => services.job.saveProducts(job, productIDs, quantity), [services, job, productIDs])}
      onSaved={onSaved}
    />
  );
});

export default JobProductsForm;
