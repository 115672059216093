import {DataTable, fullDateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import StockLocationForm from '../../forms/StockLocationForm';

export default function StockLocations({title = 'Locations', canCreate = true, archive = false}) {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((location) => {
    history.push(reverse(`${archive ? routes.archive.locations.detail : routes.stock.locations.detail}`, {id: location.id}));
  }, [history, archive]);

  return (
    <DataTable
      title={title}
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 360px)'
      }}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Type', field: 'type'},
        {
          title: 'Items (Available / Allocated)',
          field: 'in_stock',
          render: data => `${data.available.quantity} / ${data.allocated.quantity}`,
          sorting: false
        },
        {title: 'Date Added', field: 'created_at', render: data => fullDateTime(data.created_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => fullDateTime(data.deleted_at)}] : [])
      ], [archive])}
      loadData={query => new Promise((resolve, reject) => {
        const filters = archive ? {archive: 'only'} : {};
        services.stockLocation.getTableLocations(query, filters)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={archive || !canCreate ? null : {
        title: 'Add New Location',
        render: (props) => <StockLocationForm {...props} location={{}}/>,
        onSaved: goToDetail
      }}
    />
  );
}
