import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import Dates from './Dates';
import Offer from './Offer';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({request, onRequestUpdated, loading, onLoading}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Dates',
      slug: '',
      path: reverse(request.archived ? routes.archive.dateRequests.detail : routes.dateRequests.detail, {
        id: request?.id ?? 0
      })
    }
  ], [request]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        {request.offered_at ? (
          <Container>
            <Dates request={request} loading={loading}/>
          </Container>
        ) : (
          <Offer request={request} onRequestUpdated={onRequestUpdated} loading={loading} onLoading={onLoading}/>
        )}
      </Switched>
    </>
  );
};

export default Tabs;
