import {List, ListItem, ListItemText} from '@mui/material';
import {styled} from '@mui/material';
import React from 'react';
import {Link} from 'react-router-dom';

const linkStyles = (theme) => ({
  color: theme.palette.primary.main,

  '&:hover': {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
})

const RoutedLink = styled(Link)(({theme}) => linkStyles(theme));
const ExternalLink = styled('a')(({theme}) => linkStyles(theme));

const Details = ({details}) => (
  <List dense>
    {details ? details.map((detail, index) => (
      <ListItem key={`detail-${index}`}>
        <ListItemText
          primary={detail['link'] ? (
            <ExternalLink href={detail['link']} target="_blank" rel="noreferrer">
              {detail['value']}
            </ExternalLink>
          ) : (detail['route'] ? (
            <RoutedLink to={detail['route']}>{detail['value']}</RoutedLink>
          ) : detail['value'])}
          secondary={detail['title']}
        />
      </ListItem>
    )) : null}
  </List>
);

export default Details;
