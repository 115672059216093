import {Box, Paper} from '@mui/material';
import React, {useRef} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import CompanyForm from '../../forms/CompanyForm';
import CompanyLocations from './CompanyLocations';
import Who from './Who';

const NewCompany = ({onBack, onNext}) => {
  /** @type {({current: CompanyForm})} */
  const formRef = useRef();
  return (
    <>
      <Header>Create a New Company</Header>
      <Paper component={Box} padding={2}>
        <CompanyForm ref={formRef} company={{}} onSaved={(saved) => onNext(
          {type: CompanyLocations.type, id: CompanyLocations.type},
          {selectedCompany: {id: saved.id, name: saved.name}}
        )}/>
      </Paper>
      <Controls
        onBack={() => onBack({type: Who.type, id: Who.type})}
        onNext={() => formRef.current.save()}
        nextLabel="Save New Company"
      />
    </>
  );
};

NewCompany.type = 'NEW_COMPANY';

export default NewCompany;
