import {FormControl, FormHelperText, InputLabel, MenuItem, Select as MatSelect} from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useFormStyles} from './styles';
import {prefixWithSeparator} from './utilities';

/**
 * A dropdown field for use within the `BaseForm` component.
 *
 * @module Select
 *
 * @param {string} name The name of the field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {ISelectOption[]} options The available options to show in the list
 * @param {object} fieldProps Any additional props for the Material UI [Select](https://material-ui.com/api/Select/)
 *
 * @example
 * <Select
 *   name="title"
 *   prefix={prefix}
 *   label="Title"
 *   id="title"
 *   rules={{required: 'Please select a title'}}
 *   options={[{title: 'Mr', value: 'mr'}, {title: 'Mrs', value: 'mrs'}]}
 * />
 *
 */
const Select = (
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    options = [],
    displayEmpty = false,
    multiple = false,
    fieldProps
  }
) => {
  const classes = useFormStyles();
  const {control, formState: {errors}} = useFormContext();
  const prefixedName = useMemo(() => `${prefixWithSeparator(prefix)}${name}`, [name, prefix]);
  return displayEmpty || options.length ? (
    <FormControl
      variant="outlined"
      className={classes.select}
      required={rules && !!rules.required}
      fullWidth>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={prefixedName}
        control={control}
        rules={rules}
        render={({field}) => (
          <MatSelect
            id={id ?? prefixedName}
            {...fieldProps}
            {...field}
            multiple={multiple}
            value={multiple ? (field.value ?? []) : `${field.value ?? ''}`}
            label={label}
          >
            <MenuItem value=""><em>Select</em></MenuItem>
            {options.map((option, index) => option.value ? (
              <MenuItem key={index} value={`${option.value}`}>{option.title}</MenuItem>
            ) : (
              <ListSubheader key={index}>{option.title}</ListSubheader>
            ))}
          </MatSelect>
        )}
      />
      {!!errors[prefixedName] ? <FormHelperText>{errors[prefixedName].message}</FormHelperText> : null}
    </FormControl>
  ) : null;
};

Select.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.array,
  fieldProps: PropTypes.object
};

export default Select;
