import {Box, Typography} from '@mui/material';
import React from 'react';

const Header = ({size = 5, children}) => (
  <Box textAlign="center" padding={2} marginBottom={2}>
    <Typography variant={`h${size}`} component={`h${size - 2}`}>{children}</Typography>
  </Box>
);

export default Header;
