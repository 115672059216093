import * as qs from 'qs';
import {API} from './API';

export class DateRequestService {
  async getTableRequests(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getRequests(query, page, length);
  }

  async getRequests(query, page, length) {
    return (await API.getConnection())
      .get('date-requests', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getRequest(id, archive = false) {
    return (await API.getConnection())
      .get(`date-requests/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async offerDates(id, expires, dates, response) {
    return (await API.getConnection())
      .post(`date-requests/${id}/offer`, {expires_at: expires, dates, response})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteRequest(id) {
    return (await API.getConnection())
      .delete(`date-requests/${id}`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async restoreRequest(id) {
    return (await API.getConnection())
      .put(`date-requests/${id}/restore`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }
}
