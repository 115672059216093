import {Panes, RelationAutocomplete} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({company = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  return (
    <Panes
      entity={company}
      prefix={prefix}
      panes={[
        {
          title: 'Company',
          fields: [
            <Box minHeight={300}>
              <RelationAutocomplete
                valueName="company_id"
                titleName="company_name"
                prefix={prefix}
                label="Company"
                loadOptions={query => (
                  services.company.getCompanies({filter: {search: query}}, 1, 50)
                    .then(response => response.data.map(company => ({title: company.name, value: company.id})))
                    .catch(() => [])
                )}
              />
            </Box>,
          ]
        }
      ]}
      title=""
    />
  );
};

export default Fields;
