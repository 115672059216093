import {fullDate, name, time} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import BlockedList from '../../components/BlockedList';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import JobPanes from './JobPanes';
import JobProducts from './JobProducts';
import NewJobAllocation from './NewJobAllocation';
import withJob from './withJob';

const Slide = ({job, onNext, onBack, updateSession, setLoading, clearLoading, onFinish}) => {
  const services = useContext(ServiceContext);

  const [allocations, setAllocations] = useState([]);

  useEffect(() => {
    if (job) {
      setLoading('Loading Allocations');
      services.job.getAllocations({filter: {job: job.id}}, 1, 100).then(response => {
        setAllocations(response.data);
        clearLoading();
      }).catch(() => {
        setAllocations([]);
        clearLoading();
      });
    }
  }, [services, job, setLoading, clearLoading]);

  return (
    <>
      <Header>Job Allocations</Header>

      <JobPanes job={job}/>

      <Box marginTop={2}>
        {job ? <BlockedList
          blocks={allocations}
          onSelect={(selected) => {
            if (Object.keys(selected).length < 1) {
              onNext({type: NewJobAllocation.type, id: NewJobAllocation.type});
            }
          }}
          layout="wide"
          newProps={{}}
          onRenderItem={(allocation) => (
            allocation ? (
              <>
                <p>{name(allocation.engineer)}</p>
                <p>{fullDate(allocation.date)} | {time(allocation.start_at)} - {time(allocation.end_at)}</p>
              </>
            ) : <><p/><p>Add a New Allocation</p></>
          )}
        /> : null}
      </Box>

      <Controls
        onBack={() => onBack({type: JobProducts.type, id: JobProducts.type})}
        onFinish={() => onFinish(updateSession, job)}
      />
    </>
  );
};

const JobAllocations = withJob(Slide);

JobAllocations.type = 'JOB_ALLOCATIONS';

export default JobAllocations;
