import {DetailColumns, DetailPane} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import LocationList from '../../components/LocationList';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import What from './JobDetails';
import NewCompanyLocation from './NewCompanyLocation';
import withCompany from './withCompany';

const Slide = ({company, onNext, goBack}) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (company) {
      setLocations(company.locations.map(location => ({...location, type: location.type?.name ?? 'Company'})));
    }
  }, [company]);

  return (
    <>
      <Header>Company Locations</Header>
      <DetailColumns columns={[
        <DetailPane
          title="Company Details"
          details={[
            {title: 'Name', value: company?.name ?? '-'},
            {title: 'Is Manufacturer?', value: company ? (company.is_manufacturer ? 'Yes' : 'No') : '-'},
            {title: 'Is Supplier?', value: company ? (company.is_supplier ? 'Yes' : 'No') : '-'},
          ]}
        />,
        <DetailPane
          title="Contact Details"
          details={[
            {title: 'Telephone Number', value: company?.phone ?? '-'},
            {title: 'Website Address', value: company?.website ?? '-', link: company?.website}
          ]}
        />
      ]}/>
      <Box marginTop={2}>
        <LocationList
          locations={locations}
          layout="wide"
          addNew={{props: {}, type: ''}}
          onSelect={(selected) => {
            if (Object.keys(selected) < 1) {
              onNext({type: NewCompanyLocation.type, id: NewCompanyLocation.type});
            }
          }}
        />
      </Box>
      <Controls
        onBack={goBack}
        onNext={() => onNext({type: What.type, id: What.type})}
        nextLabel="Continue to Job Details"
      />
    </>
  );
};

const CompanyLocations = withCompany(Slide);

CompanyLocations.type = 'COMPANY_LOCATIONS';

export default CompanyLocations;
