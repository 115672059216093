import {styled} from '@mui/material';
import React, {useState} from 'react'
import {createPortal} from 'react-dom'

const Frame = styled('iframe')(() => ({
  border: 'none',
  height: 500,
  width: '100%',
}));

const IFrame = ({children, ...props}) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;
  return (
    <Frame {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </Frame>
  );
}

export default IFrame;
