import {TextField} from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import moment from '../moment';
import {prefixWithSeparator} from './utilities';

/**
 * A date selector field for use within the `BaseForm` component.
 *
 * @module DateField
 *
 * @param {string} name The name for field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {object} fieldProps Any additional props for the Material UI [KeyboardDatePicker](https://mui.com/components/date-picker)
 *
 * @example
 * <DateField
 *   name="date"
 *   prefix={prefix}
 *   label="Date"
 *   id="date"
 *   rules={{required: 'Please select a date'}}
 * />
 *
 */
const DateField = (
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    fieldProps
  }
) => {
  const {control, formState: {errors}, watch, setValue} = useFormContext();
  const prefixedName = `${prefixWithSeparator(prefix)}${name}`
  // noinspection JSCheckFunctionSignatures
  const value = watch(prefixedName, null);
  const valueRef = useRef(null);

  const [date, setDate] = useState(null);

  useEffect(() => {
    if (value !== valueRef.current) {
      valueRef.current = value;
      setDate(value ? moment(value) : null);
    }
  }, [value]);

  const handleSelect = useCallback((selected) => {
    setDate(selected);
    setValue(prefixedName, selected ? moment(selected).toISOString() : null);
  }, [setValue, prefixedName]);

  return (
    <>
      <Controller
        name={prefixedName}
        control={control}
        rules={rules}
        render={() => (
          <DesktopDatePicker
            {...fieldProps}
            renderInput={(props) => (
              <TextField
                {...props}
                margin="normal"
                fullWidth={true}
                error={!!errors[prefixedName]}
                helperText={!!errors[prefixedName] ? errors[prefixedName].message : ''}
              />
            )}
            autoOk
            inputFormat="DD/MM/YYYY"
            inputVariant="outlined"
            variant="inline"
            id={id ?? prefixedName}
            label={label}
            value={date}
            onChange={handleSelect}
          />
        )}
      />
    </>
  );
};

DateField.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  fieldProps: PropTypes.object
};

export default DateField;
