import moment from 'moment';
import {API} from '../../services/API';
import {ServiceProvider} from '../../services/ServiceProvider';
import {SET_TOKEN, SET_USER} from '../reducers/auth';

export function login(email, password) {
  return async dispatch => {
    return ServiceProvider.getServices()
      .auth.login(email, password)
      .then(response => {
        const {access_token: access, refresh_token: refresh, expires_in: expires} = response;
        dispatch(setToken(access, refresh, expires));
        dispatch(getUser());
      })
      .catch(() => Promise.reject());
  };
}

export function getUser() {
  return async dispatch => {
    if (localStorage.getItem('token')) {
      return ServiceProvider.getServices()
        .auth.me()
        .then(user => dispatch(setUser(user)))
        .catch(() => dispatch(setUser(null)));
    } else {
      dispatch(setUser(null));
    }
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function setToken(token, refresh, expires) {
  localStorage.setItem('token', token ?? '');
  localStorage.setItem('refresh', refresh ?? '');
  localStorage.setItem(
    'expires',
    moment()
      .add(expires, 'seconds')
      .toISOString(),
  );
  API.setToken(token);
  return {
    type: SET_TOKEN,
    token,
    refresh,
    expires,
  };
}

export function logout() {
  return dispatch => {
    ServiceProvider.getServices()
      .auth.logout()
      .then(() => {
        dispatch(reset());
      })
      .catch(() => {
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(setToken(null, null, 0));
    dispatch(setUser(null));
  };
}

export function resetPassword(email, password, token) {
  return dispatch => {
    return ServiceProvider.getServices()
      .auth.resetPassword(email, password, token)
      .then(response => {
        const {access_token, refresh, expires_in} = response;
        dispatch(setToken(access_token, refresh, expires_in));
        dispatch(getUser());
      })
      .catch(() => dispatch(reset()));
  };
}
