import * as qs from 'qs';
import {API} from './API';

export class ReferrerService {
  async getTableReferrers(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getReferrers(query, page, length);
  }

  async getReferrers(query, page, length) {
    return (await API.getConnection())
      .get('referrers', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getReferrer(id, archive = false) {
    return (await API.getConnection())
      .get(`referrers/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveReferrer(data) {
    let method = 'post';
    let url = 'referrers';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteReferrer(referrer) {
    return (await API.getConnection())
      .delete(`referrers/${referrer.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }

  async restoreReferrer(id) {
    return (await API.getConnection())
      .put(`referrers/${id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
