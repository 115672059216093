import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  Link,
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
  Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import FormDialog from '../components/FormDialog';
import Spinner from '../components/Spinner';
import {useDialogs} from '../hooks/dialogs';
import {prefixWithSeparator} from './utilities';

const useStyles = makeStyles(theme => ({
  noResults: {
    padding: theme.spacing(2),
  },

  adornment: {
    cursor: 'pointer',
  },
}));


/**
 * A live-search field to associate with other entities for use within the `BaseForm` component.
 *
 *
 * **Methods:**
 *
 * `clear() : void` - Clear the selected entity
 *
 *
 * @module RelationAutocomplete
 *
 * @param {string} valueName The name of the value field
 * @param {string} titleName The name of the title field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {function} loadOptions A callback which returns a `Promise` to resolve search results
 * @param {IRelationAutocompleteAddForm} addForm An object to configure an 'Add New' form
 * @param {object} fieldProps Any additional props for the Material UI [Select](https://material-ui.com/api/Select/)
 *
 * @example
 * <RelationAutocomplete
 *   name="parent_id"
 *   prefix={prefix}
 *   label="Parent"
 *   rules={{required: 'Please select a parent'}}
 *   loadOptions={(query) => new Promise(resolve => resolve(
 *     [{title: 'Father', value: 'father'}, {title: 'Mother', value: 'mother'}]
 *   ))}
 *   addForm={{
 *     title: 'Add New Parent',
 *     render: (props) => <Form {...props}/>,
 *     onSaved: (saved) => console.log(saved)
 *   }}
 * />
 *
 */
const RelationAutocomplete = forwardRef(
  ({
     valueName,
     titleName,
     prefix = '',
     label: initialLabel,
     id = null,
     rules = {},
     loadOptions,
     addForm,
     fieldProps
   }, ref) => {

    const {control, formState: {errors}, watch, setValue} = useFormContext();

    const classes = useStyles();

    const subject$ = useRef(new BehaviorSubject(''));

    const [label, setLabel] = useState('');
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const anchorRef = useRef();
    /** @type {({current: Object})} */
    const selectedRef = useRef();

    const prefixedValueName = `${prefixWithSeparator(prefix)}${valueName}`;
    const prefixedTitleName = `${prefixWithSeparator(prefix)}${titleName}`
    // noinspection JSCheckFunctionSignatures
    const value = watch(prefixedValueName, null);
    // noinspection JSCheckFunctionSignatures
    const title = watch(prefixedTitleName, null);
    const valueRef = useRef(null);

    const dialogName = `${prefixedValueName}_new`;
    const {openDialogs, toggleDialog} = useDialogs([dialogName]);

    const [selected, setSelected] = useState(false);

    useEffect(() => {
      const serialised = JSON.stringify(value);
      if (serialised !== valueRef.current) {
        valueRef.current = serialised;
        setSelected(value);
      }
    }, [value]);

    const handleSelect = useCallback((option) => {
      setSelected(option);
      setValue(prefixedValueName, option ? option.value : null);
      setValue(prefixedTitleName, option ? option.title : null);
    }, [setValue, prefixedValueName, prefixedTitleName]);

    useEffect(() => {
      const subscription = subject$.current.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
        if (value.trim().length >= 3) {
          setLoading(true);
          loadOptions(value).then(loaded => {
            setLoading(false);
            setOptions(loaded);
          }).catch(() => {
            setLoading(false);
            setOptions([]);
          })
        }
      });

      return () => {
        subscription.unsubscribe();
      }
    }, [loadOptions]);

    useEffect(() => {
      if (!label) {
        setLabel(initialLabel);
      }
    }, [initialLabel, label]);

    useEffect(() => {
      if (title == null) {
        selectedRef.current = null;
        setLabel(initialLabel);
        setSelected(false);
      } else if (selectedRef.current == null || title.value !== selectedRef.current.value) {
        selectedRef.current = title;
        setLabel(title);
        setQuery('');
        setOptions([]);
        setSelected(true);
      }
    }, [title, initialLabel, options]);

    useEffect(() => {
      setOpen(options.length > 0);
    }, [options]);

    useEffect(() => {
      subject$.current.next(query ? query : '');
    }, [query]);

    useImperativeHandle(ref, () => ({
      clear() {
        selectedRef.current = '';
        setLabel(initialLabel);
        setQuery('');
        setOpen(false);
        setOptions([]);
      }
    }));

    let results = null;
    if (options && options.length > 0) {
      results = <List>
        {options.map((option, index) => (
          <ListItem
            key={index}
            button
            component={Link}
            onClick={() => handleSelect(option)}
          >
            {option.title}
          </ListItem>
        ))}
      </List>;
    }

    return (
      <>
        <Controller
          name={prefixedValueName}
          control={control}
          rules={rules}
          render={() => (
            <>
              <TextField
                id={id}
                ref={anchorRef}
                fullWidth
                value={query}
                onChange={e => setQuery(e.target.value)}
                required={!!rules?.required}
                error={!!errors[prefixedValueName]}
                helperText={!!errors[prefixedValueName] ? errors[prefixedValueName].message : ''}
                variant="outlined"
                margin="normal"
                label={label}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading ? <Spinner/> : <SearchIcon/>}
                      {selected ? (
                        <div className={classes.adornment} onClick={() => handleSelect(null)}><CloseIcon/></div>
                      ) : (
                        addForm ?
                          <div className={classes.adornment} onClick={() => toggleDialog(dialogName, true)}><AddIcon/>
                          </div> : null
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Popper
                open={open}
                style={{zIndex: 2000}}
                anchorEl={anchorRef.current}
                disablePortal
                placement="bottom-start"
              >
                <Paper component={Box} style={{maxHeight: 220, overflowY: 'auto'}}>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    {results ? results :
                      <Typography className={classes.noResults}>Sorry no results were found for your query</Typography>}
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </>
          )}/>
        {addForm ? (
          <FormDialog
            title={addForm.title}
            open={openDialogs[dialogName] ?? false}
            onClose={() => toggleDialog(dialogName, false)}
            render={(props) => addForm.render({
              onSaved: (entity) => {
                toggleDialog(dialogName, false);
                handleSelect(addForm.onSaved(entity));
              },
              ...props
            })}
          />
        ) : null}
      </>
    )
  }
);

RelationAutocomplete.propTypes = {
  valueName: PropTypes.string,
  titleName: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.array,
  loadOptions: PropTypes.func,
  addForm: PropTypes.object,
  fieldProps: PropTypes.object
};

export default RelationAutocomplete;
