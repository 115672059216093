import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import Emails from '../OutboundMessages/Table';
import Activity from './Activity';
import Allocations from './Allocations';
import Confirmations from './Confirmations';
import Images from './Images';
import Invoices from './Invoices';
import Stock from './Stock';
import Subscriptions from './Subscriptions';
import Updates from './Updates';
import Vehicles from './Vehicles';
import Costs from './Costs';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({job, onJobUpdated, onReloadJob, loading, onLoading, toggleError}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Allocations',
      slug: '',
      path: reverse(job.archived ? routes.archive.jobs.detail : routes.jobs.detail, {
        id: job?.id ?? 0
      })
    },
    {
      title: 'Vehicles',
      slug: 'vehicles',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'vehicles'
      })
    },
    {
      title: `Updates (${job?.updates?.length ?? '0'})`,
      slug: 'updates',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'updates'
      })
    },
    {
      title: `Images (${job?.images?.length ?? '0'})`,
      slug: 'images',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'images'
      })
    },
    {
      title: `Information Checks (${job?.confirmations?.length ?? '0'})`,
      slug: 'checks',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'checks'
      })
    },
    {
      title: 'Stock',
      slug: 'stock',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'stock'
      })
    },
    {
      title: 'Invoicing',
      slug: 'invoicing',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'invoicing'
      })
    },
    {
      title: 'Costs',
      slug: 'costs',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'costs'
      })
    },
    {
      title: 'Activity',
      slug: 'activity',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'activity'
      })
    },
    {
      title: 'Emails',
      slug: 'emails',
      path: reverse(job.archived ? routes.archive.jobs.tab : routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'emails'
      })
    }
  ], [job]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Container>
          <Allocations job={job} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="vehicles" currentTab={tab}>
        <Container>
          <Vehicles job={job} onReloadJob={onReloadJob} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="updates" currentTab={tab}>
        <Container>
          <Updates job={job} onJobUpdated={onJobUpdated}/>
        </Container>
      </Switched>
      <Switched tab="images" currentTab={tab}>
        <Container>
          <Images job={job} onJobUpdated={onJobUpdated}/>
        </Container>
      </Switched>
      <Switched tab="checks" currentTab={tab}>
        <Container>
          <Confirmations job={job} onJobUpdated={onJobUpdated}/>
        </Container>
      </Switched>
      <Switched tab="stock" currentTab={tab}>
        <Container>
          <Stock job={job} onJobUpdated={onJobUpdated} onReloadJob={onReloadJob} loading={loading}/>
        </Container>
      </Switched>
      <Switched tab="invoicing" currentTab={tab}>
        <Container>
          <Invoices job={job} onJobUpdated={onJobUpdated} loading={loading} onLoading={onLoading}/>
        </Container>
        <Container>
          <Subscriptions job={job} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="costs" currentTab={tab}>
        <Container>
          <Costs job={job} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="activity" currentTab={tab}>
        <Activity job={job} onLoading={onLoading} toggleError={toggleError}/>
      </Switched>
      <Switched tab="emails" currentTab={tab}>
        <Container>
          <Emails job={job}/>
        </Container>
      </Switched>
    </>
  );
};

export default Tabs;
