import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import Costs from './Costs';
import Calendars from './Calendars';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({engineer, loading, onLoading}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Costs',
      slug: '',
      path: reverse(engineer.archived ? routes.archive.engineers.detail : routes.engineers.detail, {
        id: engineer?.id ?? 0
      })
    },
    {
      title: 'Calendars',
      slug: 'calendars',
      path: reverse(engineer.archived ? routes.archive.engineers.tab : routes.engineers.tab, {
        id: engineer?.id ?? 0,
        tab: 'calendars'
      })
    },
  ], [engineer]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Container>
          <Costs engineer={engineer} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="calendars" currentTab={tab}>
        <Calendars user={engineer}/>
      </Switched>
    </>
  );
};

export default Tabs;
