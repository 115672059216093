import {date, time, useDetail, useErrors} from '@management-ui/core';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import JobAllocationForm from '../../../forms/JobAllocationForm';
import Wrapper from '../Wrapper';

const Edit = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({
    allocation: 'Sorry the allocation could not be accessed'
  }), []));

  const {
    entity: allocation,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.job.getAllocation,
    archive,
    useCallback((allocation) => [
      {
        title: allocation.job.reference,
        link: reverse(routes.jobs.detail, {id: allocation.job.id})
      },
      {
        title: `${date(allocation.date)} | ${time(allocation.start_at)} - ${time(allocation.end_at)}`,
        link: reverse(routes.jobs.allocations.detail, {jobID: allocation.job.id, id: allocation.id})
      },
      {title: 'Edit Allocation'}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('allocation', displayError), [toggleError])
  );

  return allocation ? (
    <Wrapper title="Edit Allocation" loading={loading} job={allocation.job} crumbs={crumbs} errors={errors}>
      <Box marginTop={2}>
        <JobAllocationForm
          job={allocation.job}
          allocation={allocation}
          additionalAvailability={[]}
          onSaved={(saved) => history.push(reverse(routes.jobs.detail, {id: saved.job.id}))}
          loading={loading}
          onLoading={setLoading}
        />
      </Box>
    </Wrapper>
  ) : null;
}

export default Edit;
