import {Paper} from '@mui/material';
import {styled} from '@mui/styles';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import MonthSelector from './MonthSelector';
import Table from '../Orders/Table';

const Holder = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    marginTop: theme.spacing(2)
  }
}));

const Orders = ({referrer}) => {
  const [total, setTotal] = useState(0);
  const [range, setRange] = useState(null)

  const handleMonth = useCallback(month => setRange(
    {from: month.clone(), to: month.clone().endOf('month')}
  ), []);

  useEffect(() => {
    if (!range) {
      handleMonth(moment().startOf('month'));
    }
  }, [range, handleMonth]);

  const handleDataUpdate = useCallback((data) => {
    setTotal(data.reduce((total, order) => total + order['total'], 0));
  }, []);

  return range ? (
    <Holder>
      <MonthSelector month={range.from} onSelectMonth={handleMonth} total={total}/>
      <Table
        referrer={referrer}
        tableProps={{
          canRefresh: false,
          tableProps: {
            options: {search: false, paging: false, draggable: false, pageSize: 500},
            components: {
              Container: props => <Paper elevation={0} {...props} />,
              Toolbar: () => null
            }
          }
        }}
        range={range}
        showHeader={false}
        filterStatus={false}
        onDataUpdated={handleDataUpdate}
      />
    </Holder>
  ) : null
};

export default Orders;