import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  fullDate,
  fullDateTime,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box, Link} from '@mui/material';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Status from '../../components/Status';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const DateRequest = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({
    request: 'Sorry the date request could not be accessed'
  }), []));

  const {
    entity: request,
    setEntity: onRequestUpdated,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.dateRequest.getRequest,
    archive,
    useCallback((request) => [{title: request.description}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('request', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['delete']);

  return (
    <Wrapper title="Date Request" loading={loading} request={request} crumbs={crumbs} errors={errors}>
      {request?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Request Details"
              minHeight={400}
              actions={!request.archived ? [
                {title: 'Delete Request', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : request.deleted_at ? [
                {
                  title: 'Restore Item', icon: <RestoreFromTrashIcon/>, onClick: () => {
                    setLoading(true);
                    services.dateRequest.restoreRequest(request.id).then(() => {
                      setLoading(false);
                      history.push(reverse(`${routes.dateRequests.detail}`, {id: request.id}))
                    }).catch(() => setLoading(false));
                  }
                },
              ] : []}
              details={[
                {title: 'Name', value: request.name ?? '-'},
                {title: 'Email', value: request.email ?? '-'},
                {title: 'Phone', value: request.phone ?? '-'},
                {title: 'Postcode', value: request.postcode ?? '-'},
                {title: 'Registration', value: request.registration ?? '-'},
                {title: 'Make', value: request.make ?? '-'},
                {title: 'Model', value: request.model ?? '-'},
                {
                  title: 'Year of Manufacture',
                  value: request.manufactured_on ? moment(request.manufactured_on).format('YYYY') : '-'
                },
                {title: 'Comments', value: request.preferences ?? '-'},
                {
                  title: 'Preferred Dates',
                  value: request.preferred_dates ? request.preferred_dates.sort((a, b) => moment(a).isAfter(b) ? 1 : -1).map(date => fullDate(date)).join(', ') : '-'
                },
                {title: 'Description', value: request.description ?? '-'},
              ]}
              dialogs={[
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message="Are you sure you want to delete this date request?"
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.dateRequest.deleteRequest(request.id).then(() => {
                          setLoading(false);
                          history.push(routes.dateRequests.index)
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <Box marginBottom={1}><Status entity={request}/></Box>},
                {title: 'Response', value: request.response ?? '-'},
                {title: 'Created', value: fullDateTime(request.created_at)},
                {title: 'Last Updated', value: fullDateTime(request.updated_at)},
                ...(request.offered_at ? [{
                  title: 'Offered',
                  value: fullDateTime(request.offered_at)
                }] : []),
                ...(request.expires_at ? [{
                  title: 'Expires',
                  value: fullDateTime(request.expires_at)
                }] : []),
                ...(request.accepted_at ? [{
                  title: 'Accepted',
                  value: fullDateTime(request.accepted_at)
                }] : []),
                ...(request.declined_at ? [{
                  title: 'Declined',
                  value: fullDateTime(request.declined_at)
                }] : []),
                ...(request.previous_request ? [{
                  title: 'Previous Request',
                  value: (
                    <Link component={RouterLink}
                          to={reverse(routes.dateRequests.detail, {id: request.previous_request.id})}>
                      Requested at: {fullDateTime(request.previous_request.created_at)}
                    </Link>
                  )
                }] : []),
                ...(request.next_request ? [{
                  title: 'Next Request',
                  value: (
                    <Link component={RouterLink}
                          to={reverse(routes.dateRequests.detail, {id: request.next_request.id})}>
                      Requested at: {fullDateTime(request.next_request.created_at)}
                    </Link>
                  )
                }] : []),
                ...(request.orders.length > 0 ? [{
                  title: 'Order(s)',
                  value: request.orders.map(({id, reference}, index) => (
                    <Link component={RouterLink} to={reverse(routes.orders.detail, {id})} key={index}>{reference}</Link>
                  ))
                }] : []),
                ...(request.archived ? [{
                  title: 'Archived',
                  value: request.deleted_at ? fullDateTime(request.deleted_at) : 'Relation Deleted'
                }] : [])
              ]}
            />
          ]}/>

          <Tabs
            request={request}
            onRequestUpdated={onRequestUpdated}
            loading={loading}
            onLoading={setLoading}
          />
        </>
      ) : null}
    </Wrapper>
  );
}

export default DateRequest;
