import {Box, Paper} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import StockItemsTable from '../Stock/StockItems';
import Locations from './Locations';

const Tabs = ({product, onReloadProduct, onLoading}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');
  /** @type {({current: StockItemsTable})} */
  const itemsRef = useRef(null);

  const tabs = useMemo(() => [
    {
      title: 'Stock Items',
      slug: '',
      path: reverse(product.archived ? routes.archive.products.detail : routes.stock.products.detail, {
        id: product?.id ?? 0
      })
    },
    ...(product.requires_certificate ? [{
      title: 'Certificate',
      slug: 'certificate',
      path: reverse(product.archived ? routes.archive.products.tab : routes.stock.products.tab, {
        id: product?.id ?? 0,
        tab: 'certificate'
      })
    }] : []),
  ], [product]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Locations
          product={product}
          setLoading={onLoading}
          onSelect={(selected) => itemsRef.current.filter(selected ? {location: selected.id} : {})}
        />
        <Paper marginTop={2} width="100%" component={Box}>
          <StockItemsTable
            ref={itemsRef}
            product={product}
            canCreate={!product.archived}
            onCreated={() => onReloadProduct() || itemsRef.current.refresh()}
            archive={product.archived}
          />
        </Paper>
      </Switched>
      <Switched tab="certificate" currentTab={tab}>
        <Box height={200}/>
      </Switched>
    </>
  );
};

export default Tabs;
