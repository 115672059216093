import {DataTable, fullDate, name} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {DownloadForOffline} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Filter from '../../components/Filter';
import Status from '../../components/Status';
import AllocationSummary from '../../components/AllocationSummary';

const STATUSES = [
  {
    title: 'All',
    slug: '',
    colour: '#000'
  },
  {
    title: 'Created',
    slug: 'created',
    colour: '#efcf3d'
  },
  {
    title: 'Outstanding',
    slug: 'outstanding',
    colour: '#43ded9'
  },
  {
    title: 'Paid',
    slug: 'paid',
    colour: '#30b046'
  }
];

const Table = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const {search, pathname} = useLocation();

  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const firstLoad = useRef(true);
  const [initialised, setInitialised] = useState(false);
  const [status, setStatus] = useState(null);

  const lastQuery = useRef(null);
  const lastFilters = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const status = params.get('status') ?? '';
    const current = STATUSES.find(({slug}) => slug === (status ?? ''));
    if (current) {
      setStatus(current);
      setInitialised(true);
      if (!firstLoad.current) {
        tableRef.current.refresh();
      }
      firstLoad.current = false;
    } else {
      history.push(reverse(routes.invoices.index));
    }
  }, [history, search]);

  const handleExport = useCallback(() => {
    services.invoice.getTableInvoices(lastQuery.current, lastFilters.current, `${status?.title} Invoices`)
      .then(() => null)
      .catch(() => null);
  }, [status, services]);

  const changeStatus = useCallback((selected) => {
    history.push({
      pathname,
      search: selected ? `?status=${selected}` : ''
    });
  }, [history, pathname]);

  const goToDetail = useCallback((invoice) => {
    history.push(reverse(routes.invoices.detail, {jobID: invoice.job.id, id: invoice.id}));
  }, [history]);

  const loadData = useCallback(query => new Promise((resolve, reject) => {
    const filters = {};
    if (status?.slug) {
      filters.status = status.slug;
    }
    lastQuery.current = query;
    lastFilters.current = filters;
    services.invoice.getTableInvoices(query, filters)
      .then(response => {
        resolve({
          data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
        });
      }).catch(() => {
      reject();
    });
  }), [services, status]);

  const tableProps = useMemo(() => ({
    title: (
      <Box display="flex">
        <Typography marginRight={2} variant="h6">Invoices</Typography>
        <Button
          variant="contained"
          color="info"
          startIcon={<DownloadForOffline/>}
          onClick={handleExport}
          size="small"
        >
          Export
        </Button>
      </Box>
    ),
    columns: [
      {title: 'Reference', field: 'reference'},
      {
        title: 'Customer',
        field: 'customer',
        sorting: false,
        render: data => data.job.company ? data.job.company.name : data.job?.contact ? name(data.job.contact) : '-'
      },
      {title: 'Job', field: 'job', render: data => data.job.reference},
      {title: 'Account', field: 'account', render: data => data.account?.name ?? '-'},
      {title: 'Status', field: 'status', render: data => <Status entity={data}/>},
      {title: 'Date', field: 'date', render: data => fullDate(data.created_at)},
      {
        title: 'First Allocation',
        field: 'date',
        render: data => data.job.first_allocation ? <AllocationSummary allocation={data.job.first_allocation}/> : '-',
        sorting: false
      },
      {title: 'Total', field: 'total', render: data => <Currency amount={data.total}/>},
      {title: 'Paid', field: 'paid', render: data => <Currency amount={data.paid}/>},
      {title: 'Outstanding', field: 'outstanding', render: data => <Currency amount={data.outstanding}/>},
    ],
    components: {
      Toolbar: props => (
        <div>
          <MTableToolbar {...props} />
          <Filter status={status.slug} onChange={changeStatus} statuses={STATUSES}/>
        </div>
      )
    },
    loadData
  }), [status, changeStatus, handleExport, loadData]);

  return initialised ? (
    <DataTable
      ref={tableRef}
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 320px)'
      }}
      {...tableProps}
      onRowClick={goToDetail}
    />
  ) : null;
};

export default Table;
