import {Paper, styled} from '@mui/material';
import React, {useMemo, useState} from 'react';
import IFrame from '../../../../components/IFrame';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  }
}));

const Pre = styled('pre')(({theme}) => ({
  border: 'none',
  height: 500,
  margin: 0,
  overflowY: 'scroll',
  padding: theme.spacing(2),
  whiteSpace: 'pre-wrap',
  width: '100%',
}));

const Tabs = ({message}) => {
  const [tab, setTab] = useState('html');

  const tabs = useMemo(() => [
    {
      title: 'HTML',
      slug: 'html',
    },
    {
      title: 'Text',
      slug: 'text'
    }
  ], []);

  return (
    <>
      <Switcher tabs={tabs} selectedTab={tab} onSelectTab={setTab}/>
      <Switched tab="html" currentTab={tab}>
        <Container>
          <IFrame>
            <div dangerouslySetInnerHTML={{__html: message.html}}/>
          </IFrame>
        </Container>
      </Switched>
      <Switched tab="text" currentTab={tab}>
        <Container>
          <Pre>{message.text}</Pre>
        </Container>
      </Switched>
    </>
  );
};

export default Tabs;
