import {dateTime, name} from '@management-ui/core';
import {Button, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useRef, useState} from 'react';
import LoadableImage from '../../../../components/LoadableImage';
import {ServiceContext} from '../../../../components/Services';
import JobImageForm from '../../forms/JobImageForm';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },

  column: {
    flex: 1,

    '& h3': {
      color: theme.palette.secondary.main,
      flex: 1,
      fontSize: '1.4em',
      margin: `${theme.spacing(1.6)} ${theme.spacing(1)} 0`,
      padding: 0,
    },

    '&:last-of-type': {
      borderLeft: `2px solid ${theme.palette.grey['200']}`
    }
  },

  form: {
    '& .MuiButton-root': {
      margin: `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },

  noImages: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },

  image: {
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',

    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey['100'],
    },
  },

  holder: {
    flex: '0 0 200px',
    width: 200
  },

  details: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',

    '& .MuiTypography-root': {
      margin: 0,
      padding: theme.spacing(1),
    },

    '& .MuiTypography-subtitle1': {
      flex: 1,
      fontSize: '1em',
      paddingBottom: 0,
    },

    '& .MuiTypography-subtitle2': {
      color: theme.palette.secondary.main,
      fontSize: '0.9em',
      fontWeight: 600,
    }
  }
}));

const Images = ({job, onJobUpdated}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  /** @type {({current: JobImageForm})} */
  const formRef = useRef();
  const [image, setImage] = useState({});

  const handleSaved = useCallback((saved) => {
    setImage({});
    onJobUpdated(saved);
  }, [onJobUpdated]);

  return (
    <div className={classes.container}>
      <div className={classes.column}>

        <div className={classes.form}>
          <h3>Images</h3>
          {job.archived ? (
            <Typography className={classes.noImages}>Images can not be added to this job as it is archived.</Typography>
          ) : (
            <>
              <JobImageForm
                ref={formRef}
                title="Image"
                job={job}
                note={image}
                onSaved={handleSaved}
              />
              <Button variant="contained" onClick={() => formRef.current.save()}>Upload</Button>
            </>
          )}
        </div>
      </div>
      <div className={classes.column}>
        {job.images.length < 1 ? (
          <Typography className={classes.noImages}>There are no images associated with this job</Typography>
        ) : (
          job.images.map((image, index) => (
            <div
              key={index}
              className={classes.image}
              onClick={() => services.job.downloadImage(job.id, image.id).then(() => null).catch(() => null)}
            >
              <div className={classes.holder}>
                <LoadableImage
                  id={`${job.id}-${image.id}`}
                  width={image.width}
                  height={image.height}
                  onLoad={() => services.job.previewImage(job.id, image.id)}
                />
              </div>
              <div className={classes.details}>
                <Typography variant="subtitle1">{image.title} ({image.name})</Typography>
                <Typography variant="subtitle2">{name(image.user)}</Typography>
                <Typography variant="subtitle2">{dateTime(image.created_at)}</Typography>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Images;
