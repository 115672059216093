import moment from 'moment';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {
  DataTable,
  fullDate,
  fullDateTime,
  time
} from '@management-ui/core';
import {ServiceContext} from '../../../../components/Services';
import EngineerLabel from '../../components/EngineerLabel';
import CalendarNoteForm from '../../forms/CalendarNoteForm';

const Table = () => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Quick Allocations"
      columns={useMemo(() => [
        {title: 'Description', field: 'description'},
        {
          title: 'Engineer', field: 'engineer', render: data => data.engineer ? (
            <EngineerLabel engineer={data.engineer}/>
          ) : 'Unallocated'
        },
        {title: 'Date', field: 'date', render: data => fullDate(data.date)},
        {title: 'Time', field: 'time', render: data => `${time(data.start_at)} - ${time(data.end_at)}`},
        {title: 'Expires', field: 'expires_at', render: data => data.expires_at ? fullDateTime(data.expires_at) : '-'}
      ], [])}
      options={{
        rowStyle: (ticket) => ticket.expires_at && moment(ticket.expires_at).isBefore(moment()) ? {
          opacity: 0.4
        } : {}
      }}
      loadData={query => new Promise((resolve, reject) => {
        services.calendarNote.getTableNotes(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Quick Allocation',
        render: (props) => <CalendarNoteForm note={{}} {...props}/>
      }}
      editForm={{
        render: (note, props) => <CalendarNoteForm note={note} {...props}/>
      }}
    />
  );
};

export default Table;
