import * as qs from 'qs';
import {API} from './API';

export class OrderService {
  async getTableOrders(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getOrders(query, page, length);
  }

  async getOrders(query, page, length) {
    return (await API.getConnection())
      .get('orders', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getOrder(id, archive = false) {
    return (await API.getConnection())
      .get(`orders/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async updateOrder(order, data) {
    return (await API.getConnection())
      .put(`orders/${order.id}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadOrder(order) {
    return (await API.getConnection())
      .get(`orders/${order.id}/download`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${order.reference}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async deleteOrder(order) {
    return (await API.getConnection())
      .delete(`orders/${order.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }

  async updateVehicle(order, vehicle, data) {
    return (await API.getConnection())
      .put(`orders/${order.id}/vehicles/${vehicle.id}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async updateItem(order, item, data) {
    return (await API.getConnection())
      .put(`orders/${order.id}/items/${item.id}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
