import moment from 'moment';
import * as qs from 'qs';
import {clearCompanies} from '../store/actions/options';
import {API} from './API';

export class JobService {
  async getTableJobs(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getJobs(query, page, length);
  }

  async getJobs(query, page, length) {
    return (await API.getConnection())
      .get('jobs', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getJob(id, archive = false) {
    return (await API.getConnection())
      .get(`jobs/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveJob(job) {
    let data = job;
    let method = 'post';
    let url = 'jobs';
    if (job.id) {
      method = 'put';
      url = `jobs/${job.id}`;
    } else if (!data.job) {
      data = {job};
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearCompanies());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async togglePaid(job) {
    return (await API.getConnection())
      .put(`jobs/${job.id}/paid`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async notifyInstaller(job, contactID) {
    return (await API.getConnection())
      .post(`jobs/${job.id}/notify/installer`, {contact: contactID})
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async deleteJob(id) {
    return (await API.getConnection())
      .delete(`jobs/${id}`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async restoreJob(id) {
    return (await API.getConnection())
      .put(`jobs/${id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getJobActivity(id, query, page, length) {
    return (await API.getConnection())
      .get(`jobs/${id}/activity`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async saveComment(id, comment, page = 1, length = 5) {
    return (await API.getConnection())
      .post(`jobs/${id}/comment`, {comment}, {
        params: {page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getProducts(id) {
    return (await API.getConnection())
      .get(`jobs/${id}/products`)
      .then(response => response.data.data)
      .catch(API.handleError);
  }

  async getFitters(brand) {
    return (await API.getConnection())
      .get('jobs/fitters', {
        params: {brand},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data['fitters'])
      .catch(error => API.handleError(error));
  }

  async saveConfirmation(job, data) {
    let method = 'post';
    let url = `jobs/${job.id}/confirmations`;
    if (data.id) {
      method = 'put';
      url = `jobs/${job.id}/confirmations/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async sendConfirmation(job, confirmation, type) {
    return (await API.getConnection())
      .post(`jobs/${job.id}/confirmations/${confirmation.id}/send`, {type})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async confirmConfirmation(job, confirmation) {
    return (await API.getConnection())
      .post(`jobs/${job.id}/confirmations/${confirmation.id}/confirm`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteConfirmation(job, confirmation) {
    return (await API.getConnection())
      .delete(`jobs/${job.id}/confirmations/${confirmation.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveProduct(job, data) {
    let method = 'post';
    let url = `jobs/${job.id}/products`;
    if (data.id) {
      method = 'put';
      url = `jobs/${job.id}/products/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveProducts(job, productIDs, quantity) {
    return (await API.getConnection())
      .post(`jobs/${job.id}/products`, {product_ids: productIDs, quantity})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteProduct(job, product) {
    return (await API.getConnection())
      .delete(`jobs/${job.id}/products/${product.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getTableAllocations(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getAllocations(query, page, length);
  }

  async getAllocations(query, page, length) {
    return (await API.getConnection())
      .get(`job-allocations`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getAllocation(id, archive = false) {
    return (await API.getConnection())
      .get(`job-allocations/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveAllocation(data) {
    let method = 'post';
    let url = `job-allocations`;
    if (data.id) {
      method = 'put';
      url = `job-allocations/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async notifyEngineer(allocation) {
    return (await API.getConnection())
      .post(`job-allocations/${allocation.id}/notify/engineer`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async notifyCustomer(allocation, contact, type) {
    return (await API.getConnection())
      .post(`job-allocations/${allocation.id}/notify/customer`, {contact: contact.id, type})
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async acceptAllocation(allocation) {
    return (await API.getConnection())
      .post(`job-allocations/${allocation.id}/accept`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async cancelAllocation(allocation) {
    return (await API.getConnection())
      .post(`job-allocations/${allocation.id}/cancel`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteAllocation(allocation) {
    return (await API.getConnection())
      .delete(`job-allocations/${allocation.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }

  async getAvailability(from, to) {
    return (await API.getConnection())
      .get(`job-allocations/availability`, {
        params: {
          from: moment(from).format('YYYY-MM-DD'),
          to: moment(to).format('YYYY-MM-DD')
        },
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async saveUpdate(job, data) {
    return (await API.getConnection())
      .post(`jobs/${job.id}/updates`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveImage(job, title, file) {
    const body = new FormData();
    body.append('title', title);
    if (file) {
      body.append('image', file);
    }
    return (await API.getConnection())
      .post(`jobs/${job.id}/images`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async previewImage(jobID, id) {
    return (await API.getConnection())
      .get(`jobs/${jobID}/images/${id}?type=preview`, {responseType: 'arraybuffer'})
      .then(response => `data:image/png;base64,${Buffer.from(
        response.data,
        'binary',
      ).toString('base64')}`)
      .catch(error => API.handleError(error));
  }

  async downloadImage(jobID, id) {
    return (await API.getConnection())
      .get(`jobs/${jobID}/images/${id}?type=original`, {responseType: 'blob'})
      .then(response => API.triggerDownload(response))
      .catch(error => API.handleError(error));
  }

  async deleteImage(job, image) {
    return (await API.getConnection())
      .delete(`jobs/${job.id}/images/${image.id}`, {})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
