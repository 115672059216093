import {ConfirmationDialog, FormDialog, moment} from '@management-ui/core';
import {Add, Delete, Edit, Link, LinkOff, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import Metrics from '../../forms/JobAllocationForm/Metrics';
import LinkVehicleForm from '../../forms/LinkVehicleForm';
import VehicleForm from '../../forms/VehicleForm';

const Vehicles = ({job, onReloadJob, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [vehicles, setVehicles] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [selected, setSelected] = useState(null);
  const [contactMatch, setContactMatch] = useState(true);

  useEffect(() => {
    if (job) {
      if (job.vehicles.length > 0 && job.contact) {
        setContactMatch(
          job.vehicles
            .filter(({contact}) => contact?.id === job.contact.id).length === job.vehicles.length
        );
      } else {
        setContactMatch(true);
      }
      setVehicles(job.vehicles);
    } else {
      setVehicles([]);
    }
  }, [job]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      onLoading(true);
      services.vehicle.deleteVehicle(selected.id).then(() => {
        onLoading(false);
        onReloadJob();
      }).catch(() => onLoading(false));
    }
  }, [onReloadJob, onLoading, services, selected]);

  const handleUnlink = useCallback((confirmed) => {
    setShowUnlink(false);
    if (confirmed) {
      onLoading(true);
      services.vehicle.linkJob(selected.id, job.id).then(() => {
        onLoading(false);
        onReloadJob();
      }).catch(() => onLoading(false));
    }
  }, [job, onReloadJob, onLoading, services, selected]);

  const goToDetail = useCallback((job) => {
    history.push(reverse(routes.vehicles.detail, {id: job.id}));
  }, [history]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>
            Vehicles
            <Table.Notice
              display={!contactMatch}
              colour={Metrics.COLOURS.amber}>
              Not all the vehicles on this job are assigned to the same contact as the job
            </Table.Notice>
          </h3>
          <Tooltip title="Link Existing Vehicle">
            <span>
              <IconButton
                onClick={() => setShowLink(true)}
                disabled={job.archived}>
                <Link/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Add New Vehicle">
            <span>
              <IconButton
                onClick={() => setSelected(null) || setShowEdit(true)}
                disabled={job.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Registration</Table.Header>
          <Table.Header>Make / Model</Table.Header>
          <Table.Header>VIN</Table.Header>
          <Table.Header>Colour</Table.Header>
          <Table.Header>Year</Table.Header>
          <Table.Header>Scorpion ID</Table.Header>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {vehicles.length > 0 ? (
          vehicles.map((vehicle, index) => (
            <Table.Row key={index}>
              <Table.Column>{vehicle.registration}</Table.Column>
              <Table.Column>{vehicle.make} {vehicle.model}</Table.Column>
              <Table.Column>{vehicle.vin}</Table.Column>
              <Table.Column>{vehicle.colour}</Table.Column>
              <Table.Column>
                {vehicle.manufactured_on ? moment(vehicle.manufactured_on).format('Y') : '-'}
              </Table.Column>
              <Table.Column>{vehicle.scorpion_reference}</Table.Column>
              <Table.Action>
                <Tooltip title="View Vehicle">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(vehicle)}
                      disabled={job.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Edit Vehicle">
                  <span>
                    <IconButton
                      onClick={() => setSelected(vehicle) || setShowEdit(true)}
                      disabled={job.archived}>
                      <Edit/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                {vehicle.contact || vehicle.company ? (
                  <Tooltip title="Unlink Vehicle">
                  <span>
                    <IconButton
                      onClick={() => setSelected(vehicle) || setShowUnlink(true)}
                      disabled={job.archived}>
                      <LinkOff/>
                    </IconButton>
                  </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Delete Vehicle">
                  <span>
                    <IconButton
                      onClick={() => setSelected(vehicle) || setShowDelete(true)}
                      disabled={job.archived}>
                      <Delete/>
                    </IconButton>
                  </span>
                  </Tooltip>
                )}
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no vehicles associated with this job</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title={selected ? 'Edit Vehicle' : 'Add New Vehicle'}
        open={showEdit}
        maxWidth="sm"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <VehicleForm
            {...props}
            job={job}
            vehicle={selected ?? {}}
            onSaved={() => setShowEdit(false) || onReloadJob()}
          />
        )}
      />
      <FormDialog
        title="Link Existing Vehicle"
        open={showLink}
        maxWidth="sm"
        onClose={() => setShowLink(false)}
        render={props => (
          <LinkVehicleForm
            vehicle={{}}
            toJob={true}
            job={job}
            onSaved={() => setShowLink(false) || onReloadJob()}
            {...props}
          />
        )}
      />
      <ConfirmationDialog
        title="Unlink Vehicle"
        message="Are you sure you want to unlink this vehicle?"
        open={showUnlink}
        onClose={handleUnlink}
      />
      <ConfirmationDialog
        title="Delete Vehicle"
        message="Are you sure you want to delete this vehicle?"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
};

export default Vehicles;
