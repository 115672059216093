import {Box} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import SummaryBoxes from '../../components/SummaryBoxes';

const Locations = ({product, setLoading, onSelect}) => {
  const services = useContext(ServiceContext);
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (product) {
      setLoading(true);
      services.stockLocation.getSummaries(product).then(products => {
        setLoading(false);
        setLocations(products);
      }).catch(() => {
        setLoading(false);
        setLocations([]);
      });
    } else {
      setLocations([]);
    }
  }, [services, product, setLoading]);

  return locations.length > 0 ? (
    <Box marginTop={2} width="100%">
      <SummaryBoxes boxes={locations.map((location) => ({
        title: location.name,
        rows: [[
          {title: 'Available', value: location.available.quantity},
          {title: 'Cost', value: <Currency amount={location.available.cost}/>},
          {title: 'Value', value: <Currency amount={location.available.value}/>}
        ], [
          {title: 'Allocated', value: location.allocated.quantity},
          {title: 'Cost', value: <Currency amount={location.allocated.cost}/>},
          {title: 'Value', value: <Currency amount={location.allocated.value}/>}
        ]],
        selected: selected && selected.id === location.id,
        onClick: () => {
          const updated = selected && selected.id === location.id ? null : location;
          setSelected(updated);
          onSelect(updated);
        }
      }))}/>
    </Box>
  ) : null;
};

export default Locations;
