import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {truncate} from '../../../../formatters';
import JobTemplateForm from '../../forms/JobTemplateForm';

const JobTemplates = () => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Job Templates"
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Description', field: 'description', render: data => truncate(data.description ?? '')}
      ], [])}
      loadData={query => new Promise((resolve, reject) => {
        services.jobTemplate.getTableTemplates(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Template',
        render: (props) => <JobTemplateForm template={{}} {...props}/>
      }}
      editForm={{
        render: (template, props) => <JobTemplateForm template={template} {...props}/>
      }}
    />
  );
};

export default JobTemplates;
