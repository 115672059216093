import {
  address,
  Address,
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  name,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {ArrowUpward, MoveToInbox} from '@mui/icons-material';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import Status from '../../../components/Status';
import DispatchForm from '../../../forms/DispatchForm';
import Section from '../Section';
import Wrapper from '../Wrapper';
import Items from './Items';

const Delivery = ({archive = false}) => {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    delivery: 'Sorry the delivery could not be accessed'
  }), []));

  const {
    entity: delivery,
    setEntity: setDelivery,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.delivery.getDelivery,
    archive,
    useCallback((delivery) => [
      {
        title: delivery.order.reference,
        link: reverse(routes.orders.detail, {id: delivery.order.id})
      },
      {title: delivery.reference}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('delivery', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['processing', 'dispatched']);

  const handleDispatch = useCallback((number) => new Promise(resolve => {
    setLoading(true);
    services.delivery.dispatched(delivery, number).then(updated => {
      setLoading(false);
      setDelivery(updated);
      resolve();
    }).catch(() => {
      setLoading(false);
      resolve();
    });
  }), [services, delivery, setDelivery, setLoading]);

  return delivery ? (
    <Wrapper title="Delivery" loading={loading} order={delivery.order} crumbs={crumbs} errors={errors}>
      {delivery?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Delivery Details"
              minHeight={400}
              actions={[
                ...(delivery['processing_at'] ? [
                  ...(delivery['dispatched_at'] ? [] : [
                    {title: 'Mark as Dispatched', icon: <ArrowUpward/>, onClick: () => toggleDialog('dispatched', true)}
                  ])
                ] : [
                  {title: 'Mark as Processing', icon: <MoveToInbox/>, onClick: () => toggleDialog('processing', true)}
                ])
              ]}
              details={[
                {title: 'Reference', value: delivery.reference},
                ...(delivery.order ? [{
                  title: 'Order',
                  value: delivery.order.reference,
                  route: reverse(routes.orders.detail, {id: delivery.order.id})
                }] : []),
                {
                  title: 'Company',
                  value: delivery.company ? delivery.company.name : '-',
                  route: delivery.company ? reverse(routes.companies.detail, {id: delivery.company.id}) : null
                },
                {
                  title: 'Contact',
                  value: delivery.contact ? name(delivery.contact) : '-',
                  route: delivery.contact ? reverse(routes.contacts.detail, {id: delivery.contact.id}) : null
                },
                {title: 'Address', value: <Address address={address(delivery)}/>}
              ]}
              dialogs={[
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.processing ?? false}
                    title="Mark as Processing"
                    message="Are you sure you want to mark this delivery as processing? (the customer will be notified)"
                    onClose={confirmed => {
                      toggleDialog('processing', false);
                      if (confirmed) {
                        setLoading(true);
                        services.delivery.processing(delivery).then((updated) => {
                          setLoading(false);
                          setDelivery(updated);
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                ),
                (props) => (
                  <FormDialog
                    {...props}
                    title="Mark as Dispatched"
                    open={openDialogs.dispatched}
                    onClose={() => toggleDialog('dispatched', false)}
                    render={(props) => (
                      <DispatchForm
                        {...props}
                        delivery={{}}
                        onSave={handleDispatch}
                        onSaved={() => toggleDialog('dispatched', false)}
                      />
                    )}
                    minHeight={400}
                    maxWidth="sm"
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <Box marginBottom={1}><Status entity={delivery}/></Box>},
                {title: 'Processing', value: delivery['processing_at'] ? fullDateTime(delivery['processing_at']) : '-'},
                {title: 'Dispatched', value: delivery['dispatched_at'] ? fullDateTime(delivery['dispatched_at']) : '-'},
                {title: 'Tracking Number', value: delivery['tracking_number'] ? delivery['tracking_number'] : '-'},
                {title: 'Created', value: fullDateTime(delivery.created_at)},
                {title: 'Last Updated', value: fullDateTime(delivery.updated_at)}
              ]}
            />,
          ]}/>

          <Section title="Items">
            <Items delivery={delivery}/>
          </Section>
        </>
      ) : null}
    </Wrapper>
  ) : null;
}

export default Delivery;
