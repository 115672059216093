import {fullDateTime} from '@management-ui/core';
import React from 'react';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  title: {title: 'Title', render: null},
  first_name: {title: 'First Name', render: null},
  last_name: {title: 'Last Name', render: null},
  position: {title: 'Position', render: null},
  email: {title: 'Email', render: null},
  phone: {title: 'Phone', render: null},
  mobile: {title: 'Mobile', render: null},
  date_of_birth: {
    title: 'Date of Birth',
    render: (data) => data.date_of_birth ? fullDateTime(data.date_of_birth) : '-'
  },
  mothers_maiden_name: {title: 'Mother\'s Maiden Name', render: null},
  secondary_name: {title: 'Secondary - Name', render: null},
  secondary_phone: {title: 'Secondary - Phone', render: null},
  secondary_date_of_birth: {
    title: 'Secondary - Date of Birth',
    render: (data) => data.secondary_date_of_birth ? fullDateTime(data.secondary_date_of_birth) : '-'
  },
  secondary_mothers_maiden_name: {title: 'Secondary - Mother\'s Maiden Name', render: null},
  scorpion_customer: {title: 'Scorpion Customer ID', render: null},
  scorpion_user: {title: 'Scorpion User ID', render: null},
  scorpion_brand: {title: 'Scorpion Brand', render: null},
  associations: {title: 'Company Associations', render: null},
};

export default function ContactUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
