import {ColouredLabel, DataTable, FormDialog, fullDateTime} from '@management-ui/core';
import {DownloadForOffline} from '@mui/icons-material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {Box, Button, Typography} from '@mui/material';
import {reverse} from 'named-urls';
import React, {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import {vehicleTitle} from '../../../../formatters';
import routes from '../../../../routes';
import StockItemForm from '../../forms/StockItemForm';
import TransferStockForm from '../../forms/TransferStockForm';

const StockItems = forwardRef((
  {
    title = 'Stock Items',
    company,
    product,
    location,
    vehicle,
    canCreate = true,
    onCreated = null,
    archive = false
  }, ref
) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  const tableRef = useRef(null);
  const filters = useRef({});
  const lastQuery = useRef(null);
  const lastFilters = useRef(null);

  const [showTransfer, setShowTransfer] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);

  const handleSelection = useCallback((rows) => (
    setSelectedIDs(rows.map(row => row.id))
  ), []);

  useImperativeHandle(ref, () => ({
    refresh() {
      tableRef.current.refresh();
    },

    filter(params) {
      filters.current = params;
      tableRef.current.refresh();
    }
  }));

  const handleExport = useCallback(() => {
    let name = '';
    if (company) {
      name = company.name;
    } else if (product) {
      name = product.name;
    } else if (location) {
      name = location.name;
    } else if (vehicle) {
      name = vehicleTitle(vehicle);
    }
    if (name) {
      name = name.replaceAll('[^a-zA-Z0-9]', '');
    }
    services.stockItem.getTableItems(lastQuery.current, lastFilters.current, name ?? 'Stock Report')
      .then(() => null)
      .catch(() => null);
  }, [company, product, location, vehicle, services]);

  const handleSaved = useCallback(() => {
    setShowTransfer(false);
    tableRef.current.refresh();
    if (onCreated) {
      onCreated();
    }
  }, [onCreated]);

  const goToDetail = useCallback((item) => {
    history.push(reverse(`${archive ? routes.archive.items.detail : routes.stock.items.detail}`, {id: item.id}));
  }, [history, archive]);

  return (
    <>
      <DataTable
        ref={tableRef}
        title={
          <Box display="flex">
            <Typography marginRight={2} variant="h6">{title}</Typography>
            <Button
              variant="contained"
              color="info"
              startIcon={<DownloadForOffline/>}
              onClick={handleExport}
              size="small"
            >
              Export
            </Button>
          </Box>
        }
        options={{
          selection: !archive,
          headerStyle: {background: 'white', position: 'sticky', top: 0},
          maxBodyHeight: 'calc(100vh - 360px)'
        }}
        actions={archive ? {} : {
          after: [
            {
              tooltip: 'Transfer Selected Items',
              icon: CompareArrowsIcon,
              onClick: (event, data) => handleSelection(data) || setShowTransfer(true)
            }
          ]
        }}
        columns={useMemo(() => [
          ...(product ? [] : [{title: 'Product', field: 'product', render: item => item.product?.name ?? '-'}]),
          ...(location ? [] : [{
            title: 'Location',
            field: 'location',
            render: item => {
              if (item.location?.name) {
                if (item.location.users.length > 0 && item.location.users[0].colour) {
                  return <ColouredLabel colour={item.location.users[0].colour} label={item.location.name}/>;
                }
                return item.location.name;
              } else {
                return '-';
              }
            }
          }]),
          {
            title: 'Serial',
            field: 'serial_number',
            render: data => data.serial_number ? `${data.serial_number}${data.associated_serial_number ? ` (${data.associated_serial_number})` : ''}` : '-'
          },
          ...(location ? [] : [
            {
              title: 'Purchase Price',
              field: 'purchase_price',
              render: data => <Currency amount={data.purchase_price}/>
            },
            {title: 'Sale Price', field: 'sale_price', render: data => <Currency amount={data.sale_price}/>},
          ]),
          {title: 'Order', field: 'po_number'},
          ...(company ? [] : [{
            title: 'Supplier',
            field: 'supplier',
            render: item => item.supplier ? item.supplier.name : '-'
          }]),
          ...(vehicle ? [] : [{
            title: 'Vehicle',
            field: 'vehicle',
            render: item => item.vehicle ? vehicleTitle(item.vehicle) : '-'
          }]),
          {
            title: 'Job',
            field: 'job',
            render: item => item.job ? item.job.reference : '-'
          },
          {title: 'Last Moved', field: 'moved_at', render: data => data.moved_at ? fullDateTime(data.moved_at) : '-'},
          ...(archive ? [{
            title: 'Archived',
            field: 'deleted_at',
            render: data => data.deleted_at ? fullDateTime(data.deleted_at) : 'Relation Deleted'
          }] : [])
        ], [product, location, company, vehicle, archive])}
        loadData={query => new Promise((resolve, reject) => {
          const allFilters = {...filters.current, ...(archive ? {archive: 'only'} : {})};
          if (product) {
            allFilters.product = product.id;
          }
          if (company) {
            allFilters.supplier = company.id;
          }
          if (location) {
            allFilters.location = location.id;
          }
          if (vehicle) {
            allFilters.vehicle = vehicle.id;
          }
          lastQuery.current = query;
          lastFilters.current = allFilters;
          services.stockItem.getTableItems(query, allFilters)
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => {
            reject();
          });
        })}
        onRowClick={goToDetail}
        newForm={archive || !canCreate ? null : {
          title: 'Add New Item',
          render: (props) => {
            const initial = {};
            if (product) {
              initial.product_id = product.id;
              initial.purchase_price = product.purchase_price;
              initial.sale_price = product.sale_price;
              if (product.manufacturer?.is_supplier) {
                initial.supplier_id = product.manufacturer.id;
              }
            }
            if (company) {
              initial.supplier_id = company.id;
            }
            if (location) {
              initial.location_id = location.id;
            }
            if (vehicle) {
              initial.vehicle_id = vehicle.id;
            }
            return <StockItemForm {...props} item={initial}/>;
          },
          onSaved: handleSaved ?? goToDetail
        }}
      />
      <FormDialog
        open={showTransfer}
        onClose={() => setShowTransfer(false)}
        maxWidth="sm"
        title={`Transfer ${selectedIDs.length} stock item${selectedIDs.length === 1 ? '' : 's'}`}
        render={(props) => (
          <TransferStockForm {...props} entity={{}} ids={selectedIDs} onSaved={handleSaved}/>
        )}
      />
    </>
  );
});

export default StockItems;
