import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import CountryForm from '../../forms/CountryForm';

export default function Countries() {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Countries"
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Code', field: 'code'},
        {title: 'Default?', field: 'is_default', lookup: {true: 'Yes', false: 'No'}},
      ], [])}
      loadData={query => new Promise((resolve, reject) => {
        services.country.getTableCountries(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Country',
        render: (props) => <CountryForm country={{}} {...props}/>
      }}
      editForm={{
        render: (country, props) => <CountryForm country={country} {...props}/>
      }}
    />
  );
}
