import {Panes, Select, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({location = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={location}
      prefix={prefix}
      panes={[
        {
          title: 'Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <Select
              name="type"
              prefix={prefix}
              options={['Static', 'Mobile', 'External'].map(type => ({title: type, value: type}))}
              rules={{required: 'Please select a type'}}
              label="Type"
            />,
            <TextField
              name="notes"
              prefix={prefix}
              label="Notes"
              fieldProps={{
                multiline: true,
                rows: 5
              }}
            />
          ]
        },
        {title: '', fields: []}
      ]}
      title={multiplePanes ? null : 'Details'}
    >{children}</Panes>
  );
}
