import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {useCountries} from '../../../../hooks';
import PostcodeLookup from '../../components/PostcodeLookup';
import Locations from './Locations';

const Fields = ({job = {}, showLinked = false, prefix = '', multiplePanes = true, children}) => {
  const {/** @var {function(string): string} */watch} = useFormContext();
  const countries = useCountries();

  const lineNumber = watch(`${prefixWithSeparator(prefix)}address_number`);
  const line1 = watch(`${prefixWithSeparator(prefix)}address1`);
  const line2 = watch(`${prefixWithSeparator(prefix)}address2`);
  const town = watch(`${prefixWithSeparator(prefix)}town`);
  const postcode = watch(`${prefixWithSeparator(prefix)}postcode`);

  const handleReset = useCallback((subject) => {
    if (subject.country) {
      subject.country_id = subject.country.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={job}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Address',
          fields: [
            <PostcodeLookup
              prefix={prefix}
            />,
            <TextField
              name="address_number"
              prefix={prefix}
              label="House Number"
            />,
            <TextField
              name="address1"
              prefix={prefix}
              label="Address Line 1"
            />,
            <TextField
              name="address2"
              prefix={prefix}
              label="Address Line 2"
            />,
            <TextField
              name="town"
              prefix={prefix}
              label="Town / City"
            />,
            <TextField
              name="region"
              prefix={prefix}
              label="Region"
              rules={{
                validate: useCallback((value) => {
                  if ((lineNumber || line1 || line2 || town || postcode) && !value) {
                    return 'Please enter a region / county';
                  }
                  return true;
                }, [lineNumber, line1, line2, town, postcode])
              }}
            />,
            <TextField
              name="postcode"
              prefix={prefix}
              label="Postcode"
            />,
            <Select
              name="country_id"
              prefix={prefix}
              options={countries}
              label="Country"
            />
          ]
        },
        showLinked ? {
          title: 'Existing Locations',
          fields: [
            <Locations
              prefix={prefix}
            />,
          ]
        } : {
          title: 'Copy Location',
          fields: [
            <Locations
              prefix={prefix}
              copyIn={true}
              {...(job.company ? {companyID: job.company.id} : {})}
              {...(job.contact ? {contactID: job.contact.id} : {})}
            />
          ]
        },
      ]}
      title={multiplePanes ? null : 'Job Location'}
    >{children}</Panes>
  );
};

export default Fields;
