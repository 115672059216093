import {makeStyles} from '@mui/styles';
import {contrastColor} from 'contrast-color';
import React, {useMemo} from 'react';
import moment from 'moment';
import {Box, Tooltip} from '@mui/material';
import {name} from '@management-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(0.5),
    justifyContent: 'flex-end',
  },

  note: {
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));

const Notes = ({notes, day, engineerIDs, onSelectNote}) => {
  const classes = useStyles();
  const filtered = useMemo(() => notes.filter((note) => (
    moment(day.date).isBetween(moment(note.from), moment(note.to), undefined, '[]')
    &&
    engineerIDs.includes(note.engineer.id)
  )), [notes, day, engineerIDs]);

  return filtered.length > 0 ? (
    <div className={classes.container}>
      {filtered.map((note, index) => (
        <Tooltip title={(
          <Box display="flex" flexDirection="column">
            <strong>{name(note.engineer)}</strong>
            <span>{note.comments}</span>
          </Box>
        )} key={index}>
          <div
            key={index}
            className={classes.note}
            style={{backgroundColor: note.engineer.colour, color: contrastColor({bgColor: note.engineer.colour})}}
            onClick={(e) => e.stopPropagation() || onSelectNote(note)}
          />
        </Tooltip>
      ))}
    </div>
  ) : null;
};

export default Notes;
