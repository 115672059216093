import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const LinkCompanyForm = forwardRef(({company, contact = null, onSave = null, onSaved = null}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={company}
      type="company"
      fieldsComponent={(props) => <Fields {...props} contact={contact}/>}
      onSave={onSave ? onSave : ({company_id}) => services.company.linkContact({id: company_id}, contact)}
      onSaved={onSaved}
    />
  );
});

export default LinkCompanyForm;
