import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  name,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box, Link, Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import StockLocationForm from '../../forms/StockLocationForm';
import StockItemsTable from '../Stock/StockItems';
import Products from './Products';
import Wrapper from './Wrapper';

export default function StockLocation({history, archive = false}) {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    location: 'Sorry the location could not be accessed'
  }), []));

  const {
    entity: location,
    setEntity: setLocation,
    loadEntity: loadLocation,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.stockLocation.getLocation,
    archive,
    useCallback((location) => [{title: location.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('location', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setLocation(updated);
    } else if (location?.id) {
      loadLocation(location.id)
    }
  }, [toggleDialog, setLocation, loadLocation, location]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.stockLocation.restoreLocation(location.id).then((restored) => {
      setLoading(false);
      setLocation(restored);
      history.push(reverse(`${routes.stock.locations.detail}`, {id: location.id}))
    }).catch(() => setLoading(false));
  }, [services, history, location, setLocation, setLoading]);

  /** @type {({current: StockItemsTable})} */
  const itemsRef = useRef(null);

  return (
    <Wrapper title="Location" loading={loading} location={location} crumbs={crumbs} errors={errors}>
      {location?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Location Details"
              minHeight={400}
              actions={!location.archived ? [
                {title: 'Edit Location', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Location', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {title: 'Restore Location', icon: <RestoreFromTrashIcon/>, onClick: handleRestore},
              ]}
              details={[
                {title: 'Name', value: location.name ?? '-'},
                {title: 'Type', value: location.type ?? '-'},
                {
                  title: 'Engineer(s)',
                  value: location.users.map((user, index) => (
                    <span key={index}>
                      {index !== 0 ? <br/> : null}
                      <Link component={RouterLink}
                            to={reverse(routes.engineers.detail, {id: user.id})}>{name(user)}</Link>
                    </span>
                  ))
                },
                {title: 'Notes', value: location.notes ?? '-'}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Location"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <StockLocationForm
                        {...props}
                        location={location}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={location ? `Are you sure you want to delete ${location.name}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.stockLocation.deleteLocation(location.id).then(() => {
                          setLoading(false);
                          history.push(reverse(routes.stock.index, {tab: 'locations'}))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <Box display="flex" flexDirection="column" width="100%">
              <Box width="100%" marginBottom={1}>
                <DetailPane
                  title="Available"
                  details={[
                    {title: 'Quantity', value: location.available.quantity},
                    {title: 'Cost', value: <Currency amount={location.available.cost}/>},
                    {title: 'Value', value: <Currency amount={location.available.value}/>},
                  ]}
                />
              </Box>
              <Box width="100%" marginTop={1}>
                <DetailPane
                  title="Allocated"
                  details={[
                    {title: 'Quantity', value: location.allocated.quantity},
                    {title: 'Cost', value: <Currency amount={location.allocated.cost}/>},
                    {title: 'Value', value: <Currency amount={location.allocated.value}/>},
                  ]}
                />
              </Box>
            </Box>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: fullDateTime(location.created_at)},
                {title: 'Last Updated', value: fullDateTime(location.updated_at)},
                ...(location.archived ? [{title: 'Archived', value: fullDateTime(location.deleted_at)}] : [])
              ]}
            />
          ]}/>

          <Products
            location={location}
            setLoading={setLoading}
            onSelect={(selected) => itemsRef.current.filter(selected ? {product: selected.id} : {})}
          />

          <Paper marginTop={2} width="100%" component={Box}>
            <StockItemsTable
              ref={itemsRef}
              location={location}
              canCreate={!location.archived}
              onCreated={() => loadLocation(location.id)}
              archive={archive}
            />
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
}
