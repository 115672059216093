import {DataTable, fullDate, fullDateTime, name, time} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import qs from 'qs';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import EngineerLabel from '../../components/EngineerLabel';
import Filter from '../../components/Filter';
import Status from '../../components/Status';

const STATUSES = [
  {
    title: 'All',
    slug: '',
    colour: '#000'
  },
  {
    title: 'Created',
    slug: 'created',
    colour: '#efcf3d'
  },
  {
    title: 'Sent',
    slug: 'sent',
    colour: '#43ded9'
  },
  {
    title: 'Booked',
    slug: 'booked',
    colour: '#30b046'
  },
  {
    title: 'Rejected',
    slug: 'rejected',
    colour: '#eaa732'
  },
  {
    title: 'Cancelled',
    slug: 'cancelled',
    colour: '#bd0f0f'
  },
];

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const {search, pathname} = useLocation();

  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const firstLoad = useRef(true);
  const [initialised, setInitialised] = useState(false);
  const [status, setStatus] = useState(null);

  const updateStatus = useCallback((status) => {
    setStatus(status);
    setInitialised(true);
    if (!firstLoad.current) {
      tableRef.current.refresh();
    }
    firstLoad.current = false;
  }, []);

  useEffect(() => {
    if (!archive) {
      const params = new URLSearchParams(search);
      const status = params.get('status');
      const current = STATUSES.find(({slug}) => slug === (status ?? ''));
      if (current) {
        updateStatus(current);
      } else {
        history.push(reverse(routes.bookings.index));
      }
    }
  }, [history, search, archive, updateStatus]);

  useEffect(() => {
    if (archive) {
      if (status === null) {
        updateStatus(STATUSES[0]);
      }
    }
  }, [archive, status, updateStatus]);

  const changeStatus = useCallback((selected) => {
    if (!archive) {
      history.push({
        pathname,
        search: selected ? `?status=${selected}` : ''
      });
    } else {
      updateStatus(STATUSES.find(({slug}) => slug === (selected ?? '')))
    }
  }, [archive, history, pathname, updateStatus]);

  const goToNew = useCallback(() => {
    let search = null;
    history.push({pathname: routes.jobs.new, search: search ? `?${qs.stringify(search)}` : '?when=select'});
  }, [history]);

  const goToDetail = useCallback((allocation) => {
    if (allocation.job?.id) {
      history.push(reverse(`${archive ? routes.archive.jobs.detail : routes.jobs.detail}`, {id: allocation.job.id}));
    }
  }, [history, archive]);

  const loadData = useCallback(query => new Promise((resolve, reject) => {
    const filters = archive ? {archive: 'only'} : {};
    if (status) {
      filters.status = status.slug;
    }
    services.job.getTableAllocations(query, filters)
      .then(response => {
        resolve({
          data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
        });
      }).catch(() => {
      reject();
    });
  }), [services, archive, status]);

  const tableProps = useMemo(() => ({
    components: {
      Container: props => <Paper elevation={0} {...props} />,
      Toolbar: props => (
        <div>
          <MTableToolbar {...props} />
          <Filter status={status.slug} onChange={changeStatus} statuses={STATUSES}/>
        </div>
      )
    },
    columns: [
      {title: 'Reference', field: 'reference', render: data => data.job?.reference},
      {
        title: 'Customer',
        field: 'customer',
        sorting: false,
        render: data => data.job?.company ? data.job.company.name : data.job?.contact ? name(data.job.contact) : '-'
      },
      {
        title: 'Engineer', field: 'engineer', render: data => data.engineer ? (
          <EngineerLabel engineer={data.engineer}/>
        ) : 'Unallocated'
      },
      {title: 'Date', field: 'date', render: data => fullDate(data.date)},
      {title: 'Time', field: 'time', render: data => `${time(data.start_at)} - ${time(data.end_at)}`},
      {title: 'Status', field: 'status', render: data => <Status entity={data}/>},
      ...(archive ? [{
        title: 'Archived',
        field: 'deleted_at',
        render: data => data.deleted_at ? fullDateTime(data.deleted_at) : 'Job Deleted'
      }] : [])
    ],
    actions: {
      after: archive ? [] : [{
        icon: 'add',
        tooltip: 'Add New Job',
        isFreeAction: true,
        onClick: goToNew
      }]
    },
    loadData,
    onRowClick: goToDetail
  }), [archive, status, changeStatus, goToNew, goToDetail, loadData]);

  return initialised ? (
    <DataTable
      title="Bookings"
      ref={tableRef}
      {...tableProps}
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 320px)'
      }}
    />
  ) : null;
};

export default Table;
