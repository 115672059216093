import {Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

const Fields = ({product = {}, prefix = ''}) => {

  const products = useOptions('products', useCallback((list) => list.map(p => ({
    value: p.id,
    title: p.name
  })), []));

  return (
    <Panes
      entity={product}
      prefix={prefix}
      panes={[
        {
          title: 'Required Product',
          fields: [
            <Select
              name="product_id"
              prefix={prefix}
              options={products}
              label="Product"
              rules={{required: 'Please select a product'}}
            />,
            <TextField
              name="quantity"
              prefix={prefix}
              label="Quantity"
              rules={{required: 'Please enter a quantity'}}
            />
          ]
        }
      ]}
      title={null}
    />
  );
};

export default Fields;
