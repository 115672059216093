import moment from 'moment';
import * as qs from 'qs';
import {API} from './API';

export class SubscriptionService {
  async getTableSubscriptions(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getSubscriptions(query, page, length);
  }

  async getSubscriptions(query, page, length) {
    return (await API.getConnection())
      .get('subscriptions', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getSubscription(id, archive = false) {
    return (await API.getConnection())
      .get(`subscriptions/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveSubscription(data) {
    let method = 'post';
    let url = 'subscriptions';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async publishSubscription(subscription) {
    return (await API.getConnection())
      .put(`subscriptions/${subscription.id}/publish`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async amendTotal(subscription) {
    return (await API.getConnection())
      .put(`subscriptions/${subscription.id}/amend`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async requestActivation(subscription, contact) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/request-activation`, {contact: contact.id})
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async toggleReminders(subscription) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/reminders`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async startSubscription(subscription, success, cancelled) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/start`, {success, cancelled})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async cancelSubscription(subscription) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/cancel`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async removeSubscription(subscription) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/remove`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async requestRemoval(subscription, contact) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/request-removal`, {contact: contact.id})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteSubscription(subscription) {
    return (await API.getConnection())
      .delete(`subscriptions/${subscription.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }

  async saveSubscriptionItem(subscription, data) {
    let method = 'post';
    let url = `subscriptions/${subscription.id}/items`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteSubscriptionItem(subscription, item) {
    return (await API.getConnection())
      .delete(`subscriptions/${subscription.id}/items/${item.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async savePayment(subscription, data) {
    let method = 'post';
    let url = `subscriptions/${subscription.id}/payments`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async syncPayment(subscription, payment) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/payments/${payment.id}/sync`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadReceipt(subscription, payment) {
    return (await API.getConnection())
      .get(`subscriptions/${subscription.id}/payments/${payment.id}/download`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${subscription.reference}-${moment(payment.completed_at).format('YYYY-MM-DD')}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async sendReceipt(subscription, payment, contact) {
    return (await API.getConnection())
      .post(`subscriptions/${subscription.id}/payments/${payment.id}/send`, {contact: contact.id})
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async deletePayment(subscription, payment) {
    return (await API.getConnection())
      .delete(`subscriptions/${subscription.id}/payments/${payment.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
