import {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

/**
 * A hook to manage a detail view of a data entity
 *
 * @function useDetail
 *
 * @param {function} getDetail A function which returns a `Promise` to retrieve the entity by route parameter
 * @param {boolean} archive Whether the current detail view is archived
 * @param {function} renderDetailCrumbs A function to render crumbs based on the loaded entity
 * @param {IBreadcrumb[]} standardCrumbs A list of standard breadcrumbs (not archived)
 * @param {IBreadcrumb[]} archiveCrumbs A list of archive breadcrumbs
 * @param {function} onToggleError A function to react when an error is triggered, will pass the name of the error.
 *
 * @returns {{
 *   entity: object,
 *   setEntity: function,
 *   loadEntity: function,
 *   crumbs: IBreadcrumb[],
 *   loading: boolean,
 *   setLoading: function
 * }} `{
 *   entity: object,
 *   setEntity: function,
 *   loadEntity: function,
 *   crumbs: IBreadcrumb[],
 *   loading: boolean,
 *   setLoading: function
 * }`
 *
 * `entity` - The entity retrieved to be displayed
 *
 * `setEntity` - A function which gives access to change the displayed entity
 *
 * `loadEntity` - A function to load the entity from the provided `Promise`
 *
 * `crumbs` - The collated list of breadcrumbs
 *
 * `loading` - A boolean to mark whether the screen is loading
 *
 * `setLoading` - A function to trigger loading state
 *
 * @example
 * const {
 *   entity: person,
 *   setEntity: setPerson,
 *   loadEntity: loadPerson,
 *   crumbs,
 *   loading,
 *   setLoading
 * } = useDetail(
 *   services.people.getPerson,
 *   false,
 *   useCallback((person) => [{title: person.name}], []),
 *   useMemo(() => [{title: 'People', link: '/people'}], []),
 *   useMemo(() => [{title: 'Archive', link: '/archive'}, {title: 'People', link: '/archive/people'}], []),
 *   useCallback((displayError) => console.log(displayError), [])
 * );
 *
 */
export function useDetail(
  getDetail,
  archive,
  renderDetailCrumbs,
  standardCrumbs,
  archiveCrumbs,
  onToggleError
) {
  const [entity, setEntity] = useState(null);
  const [crumbs, setCrumbs] = useState([]);
  const params = useParams();
  const idRef = useRef('');

  const [isLoading, setIsLoading] = useState(false);
  const loadingCount = useRef(0);

  const handleLoading = useCallback((loading) => {
    if (loading) {
      loadingCount.current += 1;
    } else {
      loadingCount.current -= 1;
    }
    setIsLoading(loadingCount.current > 0);
  }, []);

  const loadEntity = useCallback((id, fromArchive = false, forceReload = true) => {
    if (forceReload || idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      handleLoading(true);
      getDetail(id, fromArchive).then(retrieved => {
        setEntity(retrieved);
        handleLoading(false);
      }).catch(() => {
        onToggleError(true);
        handleLoading(false);
      });
    }
  }, [getDetail, onToggleError, handleLoading]);

  useEffect(() => {
    let trail = [...(archive ? archiveCrumbs : standardCrumbs)];
    if (entity) {
      trail = [...trail, ...renderDetailCrumbs(entity)];
    }
    setCrumbs(trail);
  }, [entity, archive, renderDetailCrumbs, standardCrumbs, archiveCrumbs]);

  useEffect(() => {
    setCrumbs(archive ? [...archiveCrumbs] : [...standardCrumbs]);
    loadEntity(params.id, archive, false);
  }, [params, setCrumbs, archive, loadEntity, standardCrumbs, archiveCrumbs]);

  return {entity, setEntity, loadEntity, crumbs, loading: isLoading, setLoading: handleLoading};
}
