import {ColouredLabel, fullDate, time} from '@management-ui/core';
import {Box} from '@mui/material';
import React from 'react';
import EngineerLabel from './EngineerLabel';

const AllocationSummary = ({allocation}) => (
  <Box display="flex" flexDirection="column">
    {allocation ? (
      <>
        {allocation.engineer ? (
          <EngineerLabel engineer={allocation.engineer}/>
        ) : (
          <ColouredLabel label="Unallocated" colour="#bbb"/>
        )}
        <strong>{fullDate(allocation.date)}<br/><i>{time(allocation.start_at)} - {time(allocation.end_at)}</i></strong>
      </>
    ) : (
      <span>-</span>
    )}
  </Box>
);

export default AllocationSummary;
