import {moment} from '@management-ui/core';
import React from 'react';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  registration: {title: 'Registration', render: null},
  vin: {title: 'VIN Number', render: null},
  make: {title: 'Make', render: null},
  model: {title: 'Model', render: null},
  colour: {title: 'Colour', render: null},
  manufactured_on: {
    title: 'Year of Manufacture',
    render: (vehicle) => vehicle.manufactured_on ? moment(vehicle.manufactured_on).format('Y') : ''
  },
};

export default function JobVehicleUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
