import {fullDateTime, name} from '@management-ui/core';
import React from 'react';
import Status from '../Status';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  contact: {title: 'Contact', render: (data) => data.contact ? name(data.contact) : ''},
  sent_at: {title: 'Sent', render: (data) => data.sent_at ? fullDateTime(data.sent_at) : '-'},
  confirmed_at: {title: 'Confirmed', render: (data) => data.confirmed_at ? fullDateTime(data.confirmed_at) : '-'},
  status: {title: 'Status', render: (data) => <Status entity={data}/>},
};

export default function ConfirmationUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
