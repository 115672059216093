import {Box, Paper} from '@mui/material';
import React from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import JobAllocationForm from '../../forms/JobAllocationForm';
import JobAllocations from './JobAllocations';

const NewJobAllocation = ({onBack, onNext, session, setLoading, clearLoading}) => (
  <>
    <Header>Add a New Allocation</Header>
    <Paper component={Box} padding={2}>
      <JobAllocationForm
        allocation={{}}
        job={session.selectedJob}
        additionalAvailability={[]}
        onLoading={loading => loading ? setLoading('Loading...') : clearLoading()}
        onSaved={() => onNext(
          {type: JobAllocations.type, id: JobAllocations.type}
        )}/>
    </Paper>
    <Controls
      onBack={() => onBack({type: JobAllocations.type, id: JobAllocations.type})}
    />
  </>
);

NewJobAllocation.type = 'NEW_JOB_ALLOCATION';

export default NewJobAllocation;
