import {BaseForm} from '@management-ui/core';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import Fields from './Fields';

const DetailForm = forwardRef(({job, allocation, slotOffer = false, onSave, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={allocation}
      type="allocation"
      fieldsComponent={(props) => <Fields {...props} slotOffer={slotOffer}/>}
      onSave={(update) => {
        if (onSave) {
          update = onSave(update);
        }
        const {type, date, start_at, end_at, expires_at, ...attrs} = update;
        if (slotOffer) {
          return new Promise(resolve => resolve({
            ...attrs,
            date: moment(date).toISOString(),
            start_at: moment(`${moment(date).format('YYYY-MM-DD')} ${moment(start_at).format('HH:mm:ss')}`),
            end_at: moment(`${moment(date).format('YYYY-MM-DD')} ${moment(end_at).format('HH:mm:ss')}`),
          }));
        } else if (job.id) {
          return services.job.saveAllocation({
            ...attrs,
            job_id: job.id,
            date: moment(date).format('YYYY-MM-DD'),
            start_at: moment(start_at).format('HH:mm:ss'),
            end_at: moment(end_at).format('HH:mm:ss')
          });
        } else if (type === 'quick') {
          return services.calendarNote.saveNote({
            ...attrs,
            date: moment(date).format('YYYY-MM-DD'),
            start_at: moment(start_at).format('HH:mm:ss'),
            end_at: moment(end_at).format('HH:mm:ss'),
            expires_at: moment(expires_at).format('YYYY-MM-DD HH:mm:ss')
          }).then(() => null);
        } else {
          return new Promise(resolve => {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            resolve({
              d: formattedDate,
              s: `${formattedDate} ${moment(start_at).format('HH:mm')}`,
              e: `${formattedDate} ${moment(end_at).format('HH:mm')}`,
              id: update.engineer_id
            });
          });
        }
      }}
      onSaved={onSaved}
    />
  );
});

export default DetailForm;
