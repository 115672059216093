import {BaseForm} from '@management-ui/core';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const EngineerAvailabilityForm = forwardRef(({note, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={note}
      type="note"
      fieldsComponent={Fields}
      onSave={(
        {
          engineer_id,
          from,
          to,
          comments
        }
      ) => services.engineerAvailabilityNote.saveNote({
        ...(note ? {id: note.id} : {}),
        engineer_id,
        from: from ? moment(from).format('YYYY-MM-DD') : null,
        to: to ? moment(to).format('YYYY-MM-DD') : null,
        comments
      })}
      onSaved={onSaved}
    />
  );
});

export default EngineerAvailabilityForm;
