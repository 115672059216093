import {ConfirmationDialog, DetailPane, FormDialog, fullDate, name} from '@management-ui/core';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import EngineerAvailabilityNoteForm from '../EngineerAvailabilityNoteForm';

const useStyles = makeStyles((theme) => ({
  title: {
    alignItems: 'center',
    display: 'flex',
  },

  engineer: {
    fontSize: '1.2em',
    margin: 0,
    padding: 0,
  },

  colour: {
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
    flex: `0 0 ${theme.spacing(2)}`,
    marginRight: theme.spacing(2),
    width: theme.spacing(2),
  }
}));

const NoteDialog = ({open, onClose, onLoading, note, onNoteSaved}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const theme = useTheme();
  const [editNote, setEditNote] = useState(false);
  const [deleteNote, setDeleteNote] = useState(false);

  const handleDeleteNote = useCallback((confirmed) => {
    setDeleteNote(false);
    if (confirmed) {
      onLoading(true);
      services.engineerAvailabilityNote.deleteNote(note).then(() => {
        onLoading(false);
        onNoteSaved(null);
      }).catch(() => onLoading(false));
    }
  }, [onLoading, note, onNoteSaved, services]);

  return note ? (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{style: {backgroundColor: theme.palette.background.default}}}
      >
        <DialogTitle classes={{root: classes.title}}>
          <span className={classes.colour} style={{backgroundColor: note.engineer.colour}}/>
          <span className={classes.engineer}>{name(note.engineer)}</span>
        </DialogTitle>
        <DialogContent>
          <DetailPane
            title="Availability"
            details={[
              {title: 'Comments', value: note.comments},
              {title: 'From', value: fullDate(note.from)},
              {title: 'To', value: fullDate(note.to)},
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditNote(true)} color="secondary" variant="contained">Edit</Button>
          <Button onClick={() => setDeleteNote(true)} color="error" variant="contained">Delete</Button>
          <Button onClick={() => onClose()} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <FormDialog
        title="Update Availability Note"
        open={editNote}
        onClose={() => setEditNote(false)}
        render={(props) => (
          <EngineerAvailabilityNoteForm
            {...props}
            note={note}
            onSaved={() => {
              setEditNote(false);
              onNoteSaved();
            }}/>
        )}
        minHeight={400}
        maxWidth="md"
      />
      <ConfirmationDialog
        open={deleteNote}
        title="Delete Availability Note"
        message="Are you sure you want to delete this availability note?"
        onClose={handleDeleteNote}
      />
    </>
  ) : null;
}

export default NoteDialog;
