import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

export default function Fields({invoice = {}, prefix = '', depositFor = null}) {
  const {setValue, /** @type {function} */watch} = useFormContext();

  const accounts = useOptions('gatewayAccounts', useCallback((list) => list.map(({id, name, brand: {slug}}) => ({
    value: id,
    title: name,
    brand: slug
  })), []));

  const selectedAccount = watch(useMemo(() => `${prefixWithSeparator(prefix)}account_id`, [prefix]));
  const amount = watch(useMemo(() => `${prefixWithSeparator(prefix)}amount`, [prefix]));

  useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setValue(
        `${prefixWithSeparator(prefix)}account_id`,
        accounts[accounts.findIndex(a => a.brand === 'safe-and-sound') ?? 0].value
      );
    }
  }, [selectedAccount, accounts, setValue, prefix]);

  const handleReset = useCallback((subject) => {
    if (subject.account) {
      subject.account_id = subject.account.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={invoice}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Gateway Account',
          fields: [
            <Select
              name="account_id"
              prefix={prefix}
              options={accounts}
              label="Stripe Account"
            />,
            ...(depositFor ? [
              <Select
                name="amount"
                prefix={prefix}
                label="Deposit Amount"
                options={[
                  {value: '100', title: '£100'},
                  {value: '200', title: '£200'},
                  {value: '300', title: '£300'},
                  {value: 'other', title: 'Other'}
                ]}
              />,
              ...(amount === 'other' ? [
                <TextField
                  name="other"
                  prefix={prefix}
                  label="Other"
                  rules={{required: 'Please enter a deposit amount'}}
                  fieldProps={{
                    InputProps: {
                      startAdornment: <InputAdornment position="start">£</InputAdornment>
                    }
                  }}
                />
              ] : [])
            ] : [])
          ]
        }
      ]}
      title={''}
    />
  );
}
