import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import Activity from './Activity';
import Allocations from './Allocations';
import Contacts from './Contacts';
import Installs from './Installs';
import Invoices from './Invoices';
import Items from './Items';
import Jobs from './Jobs';
import Locations from './Locations';
import Products from './Products';
import Subscriptions from './Subscriptions';
import Vehicles from './Vehicles';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({company, onCompanyUpdated, onReloadCompany, loading, onLoading, toggleError}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Allocations',
      slug: '',
      path: reverse(company.archived ? routes.archive.companies.detail : routes.companies.detail, {
        id: company?.id ?? 0
      })
    },
    {
      title: 'Jobs',
      slug: 'jobs',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'jobs'
      })
    },
    ...(company.is_installer ? [{
      title: 'Installs',
      slug: 'installs',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'installs'
      })
    }] : []),
    {
      title: 'Vehicles',
      slug: 'vehicles',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'vehicles'
      })
    },
    {
      title: 'Locations',
      slug: 'locations',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'locations'
      })
    },
    {
      title: 'Contacts',
      slug: 'contacts',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'contacts'
      })
    },
    {
      title: 'End Users',
      slug: 'end-users',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'end-users'
      })
    },
    {
      title: 'Invoicing',
      slug: 'invoicing',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'invoicing'
      })
    },
    ...(company.is_manufacturer || company.is_supplier ? [{
      title: 'Stock',
      slug: 'stock',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'stock'
      })
    }] : []),
    {
      title: 'Activity',
      slug: 'activity',
      path: reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
        id: company?.id ?? 0,
        tab: 'activity'
      })
    }
  ], [company]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Container>
          <Allocations company={company} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="jobs" currentTab={tab}>
        <Container>
          <Jobs company={company} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="installs" currentTab={tab}>
        <Container>
          <Installs company={company} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="vehicles" currentTab={tab}>
        <Container>
          <Vehicles company={company} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="locations" currentTab={tab}>
        <Container>
          <Locations company={company} onCompanyUpdated={onCompanyUpdated}/>
        </Container>
      </Switched>
      <Switched tab="contacts" currentTab={tab}>
        <Container>
          <Contacts company={company} onReloadCompany={onReloadCompany} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="end-users" currentTab={tab}>
        <Container>
          <Contacts company={company} onReloadCompany={onReloadCompany} loading={loading} onLoading={onLoading} endUsers={true}/>
        </Container>
      </Switched>
      <Switched tab="invoicing" currentTab={tab}>
        <Container>
          <Invoices company={company} loading={loading} onLoading={onLoading}/>
        </Container>
        <Container>
          <Subscriptions company={company} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="stock" currentTab={tab}>
        <Container>
          <Products company={company} loading={loading} onLoading={onLoading}/>
        </Container>
        <Container>
          <Items company={company} loading={loading} onLoading={onLoading}/>
        </Container>
      </Switched>
      <Switched tab="activity" currentTab={tab}>
        <Activity company={company} onLoading={onLoading} toggleError={toggleError}/>
      </Switched>
    </>
  );
};

export default Tabs;
