import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Who from './Who';

const withCompany = (Slide) => {
  return (props) => {
    const {onBack, session, setLoading, clearLoading} = props;

    const services = useContext(ServiceContext);
    const [company, setCompany] = useState(null);
    const idRef = useRef('');

    const handleBack = useCallback(() => onBack({type: Who.type, id: Who.type}, {selectedCompany: null}), [onBack])

    useEffect(() => {
      if (session) {
        const id = session.selectedCompany?.id ? `${session.selectedCompany.id}` : '';
        if (id && id !== idRef.current) {
          idRef.current = id;
          setLoading('Loading Company Details');
          services.company.getCompany(id).then((retrieved) => {
            setCompany(retrieved);
            clearLoading();
          }).catch(() => {
            clearLoading();
            handleBack();
          });
        }
      }
    }, [services, session, setLoading, clearLoading, handleBack]);

    return <Slide company={company} goBack={handleBack} {...props}/>;
  }
};

export default withCompany;
