import * as qs from 'qs';
import {API} from './API';

export class OutboundMessageService {
  async getTableMessages(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getMessages(query, page, length);
  }

  async getMessages(query, page, length) {
    return (await API.getConnection())
      .get('outbound-messages', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}
