import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {vehicleTitle} from '../../../../formatters';
import {useOptions} from '../../../../hooks';

export default function Fields({subscription, item = {}, prefix = '', children}) {
  const {setValue, /** @type {function} */watch} = useFormContext();

  const products = useOptions('virtualProducts', useCallback((list) => list.map(l => ({
    value: l.id,
    title: l.name,
    price: l.price
  })), []));
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (subscription?.job?.vehicles) {
      setVehicles(subscription.job.vehicles.map(v => ({
        value: v.id,
        title: vehicleTitle(v)
      })));
    } else {
      setVehicles([]);
    }
  }, [subscription]);

  const selectedProduct = watch(useMemo(() => `${prefixWithSeparator(prefix)}product_id`, [prefix]));

  useEffect(() => {
    if (selectedProduct) {
      const product = products.find(p => `${p.value}` === selectedProduct);
      if (product) {
        setValue(`${prefixWithSeparator(prefix)}price`, product.price);
      }
    }
  }, [selectedProduct, products, setValue, prefix]);

  const handleReset = useCallback((subject) => {
    if (subject.product) {
      subject.product_id = subject.product.id;
    }
    if (subject.vehicle) {
      subject.vehicle_id = subject.vehicle.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={item}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Vehicle',
          fields: [
            <Select
              name="vehicle_id"
              prefix={prefix}
              options={vehicles}
              rules={{required: 'Please select a vehicle'}}
              label="Vehicle"
            />,
          ]
        },
        {
          title: 'Product', fields: [
            <Select
              name="product_id"
              prefix={prefix}
              options={products}
              rules={{required: 'Please select a product'}}
              label="Product"
            />,
            <TextField
              name="price"
              prefix={prefix}
              label="Price"
              rules={{required: 'Please enter a price'}}
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="discount"
              prefix={prefix}
              label="Discount"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
          ]
        }
      ]}
      title={''}
    >{children}</Panes>
  );
}
