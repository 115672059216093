import {useDetail, useDialogs, useErrors} from '@management-ui/core';
import {Pagination, Paper} from '@mui/material';
import AddCommentIcon from '@mui/icons-material/AddComment';
import {makeStyles} from '@mui/styles';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {itemTitle} from '../../../../formatters';
import routes from '../../../../routes';
import Actions from './Actions';
import Wrapper from './Wrapper';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

export default function Activity({archive = false}) {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const {errors, toggleError} = useErrors(useMemo(() => ({item: 'Sorry the item could not be accessed'}), []));

  const {
    entity: item,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.stockItem.getItem,
    archive,
    useCallback((item) => [
      {
        title: itemTitle(item),
        link: reverse(archive ? routes.archive.items.detail : routes.stock.items.detail, {id: item.id})
      },
      {title: 'Activity'}
    ], [archive]),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('item', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['note']);

  return (
    <Wrapper
      title="Stock Item Activity"
      loading={loading}
      titleControls={archive ? [] : [{icon: <AddCommentIcon/>, onClick: () => toggleDialog('note', true)}]}
      item={item}
      crumbs={crumbs}
      errors={errors}
    >
      {item ? (
        <>
          <Paper className={classes.paper}>
            <Pagination count={pages} page={page} onChange={(e, p) => setPage(p)}/>
          </Paper>
          <Paper className={classes.paper}>
            <Actions
              item={item}
              setLoading={setLoading}
              openDialogs={openDialogs}
              toggleDialog={toggleDialog}
              toggleError={toggleError}
              page={page}
              length={30}
              updatePageCount={setPages}
            />
          </Paper>
          <Paper className={classes.paper}>
            <Pagination count={pages} page={page} onChange={(e, p) => setPage(p)}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
}
