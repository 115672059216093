import {
  Address,
  address,
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  name,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Map} from '@management-ui/maps';
import {Send} from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import numeral from 'numeral';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import {useLoading} from '../../../../hooks';
import routes from '../../../../routes';
import JobForm from '../../forms/JobForm';
import JobLocationForm from '../../forms/JobLocationForm';
import SendToInstallerForm from '../../forms/SendToInstallerForm';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const Job = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'register', 'installer', 'delete', 'location']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    job: 'Sorry the job could not be accessed',
    activity: 'Sorry the job activity could not be loaded'
  }), []));

  const idRef = useRef('');
  const {loading, setLoading} = useLoading();
  const [job, setJob] = useState(null);
  const [hasStock, setHasStock] = useState(false);

  const loadJob = useCallback((id, force = false) => {
    if (force || idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.job.getJob(id, archive).then(retrieved => {
        setJob(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('job', true);
        setLoading(false);
        setTimeout(() => {
          history.push(archive ? reverse(routes.archive.index, {tab: 'jobs'}) : routes.jobs.index);
        }, 3000);
      });
    }
  }, [archive, services, history, toggleError, setLoading]);

  useEffect(() => {
    if (match.params?.id) {
      loadJob(match.params?.id);
    }
  }, [history, match, job, loadJob]);

  useEffect(() => {
    if (job) {
      setHasStock(job.products.filter(({items}) => items.filter(({id}) => !!id).length > 0).length > 0);
    } else {
      setHasStock(false);
    }
  }, [job]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...(archive ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs)]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [];
    if (job) {
      header = job.reference;
      trail = [
        ...(job.archived ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs),
        {title: header, link: ''}
      ];
    }
    setTitle(header);
    setCrumbs(trail);
  }, [job]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setJob(updated);
    } else if (job?.id) {
      loadJob(job.id)
    }
  }, [toggleDialog, setJob, loadJob, job]);

  const handleLocation = useCallback((updated) => {
    toggleDialog('location', false);
    handleSaved(updated);
  }, [toggleDialog, handleSaved]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.job.restoreJob(job.id).then((restored) => {
      setLoading(false);
      setJob(restored);
      if (match.params?.tab) {
        history.push(reverse(routes.jobs.tab, {id: job.id, tab: match.params.tab}))
      } else {
        history.push(reverse(routes.jobs.detail, {id: job.id}))
      }
    }).catch(() => setLoading(false));
  }, [services, history, match, job, setLoading]);

  return (
    <Wrapper title={title} loading={loading} job={job} crumbs={crumbs} errors={errors}>
      {job?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Job Details"
              minHeight={400}
              actions={!job.archived ? [
                {title: 'Edit Job', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                ...((job.end_user && job.end_user.scorpion_customer && !job.end_user.scorpion_user) || (!job.end_user && job.contact && job.contact.scorpion_customer && !job.contact.scorpion_user) ? [
                  {
                    title: 'Register Scorpion User',
                    icon: <AppRegistrationIcon/>,
                    onClick: () => toggleDialog('register', true)
                  }
                ] : []),
                ...(job.installer ? [
                  {
                    title: 'Send to Installer',
                    icon: <Send/>,
                    onClick: () => toggleDialog('installer', true)
                  }
                ] : []),
                {
                  title: hasStock ? 'This job can not be deleted as it has stock allocated to it' : 'Delete Job',
                  icon: <DeleteIcon/>,
                  disabled: hasStock,
                  onClick: () => toggleDialog('delete', true)
                }
              ] : [
                {title: 'Restore Job', icon: <RestoreFromTrashIcon/>, onClick: handleRestore},
              ]}
              details={[
                {title: 'Reference', value: job.reference},
                {
                  title: 'Company',
                  value: job.company ? job.company.name : '-',
                  route: job.company ? reverse(routes.companies.detail, {id: job.company.id}) : null
                },
                {
                  title: 'Contact',
                  value: job.contact ? name(job.contact) : '-',
                  route: job.contact ? reverse(routes.contacts.detail, {id: job.contact.id}) : null
                },
                {
                  title: 'End User',
                  value: job.end_user ? name(job.end_user) : '-',
                  route: job.end_user ? reverse(routes.contacts.detail, {id: job.end_user.id}) : null
                },
                {
                  title: 'Installer',
                  value: job.installer ? job.installer.name : '-',
                  route: job.installer ? reverse(routes.companies.tab, {id: job.installer.id, tab: 'installs'}) : null
                },
                ...(job.end_user ? (job.end_user.scorpion_customer ? [
                  {title: 'Scorpion Customer ID (End User)', value: job.end_user.scorpion_customer ?? '-'},
                  {title: 'Scorpion User ID (End User)', value: job.end_user.scorpion_user ?? '-'},
                ] : []) : !job.end_user && job.contact?.scorpion_customer ? [
                  {title: 'Scorpion Customer ID', value: job.contact.scorpion_customer ?? '-'},
                  {title: 'Scorpion User ID', value: job.contact.scorpion_user ?? '-'},
                ] : []),
                {title: 'Summary', value: job.summary ?? '-'},
                {title: 'Description', value: job.description},
                {title: 'Notes', value: job.notes ?? '-'},
                {title: 'Admin Notes', value: job.admin_notes ?? '-'},
                ...(job.order ? [{
                  title: 'Order',
                  value: job.order.reference,
                  route: reverse(routes.orders.detail, {id: job.order.id})
                }] : []),
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Job"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <JobForm
                        {...props}
                        job={job}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <FormDialog
                    {...props}
                    title="Send to Installer"
                    open={openDialogs.installer ?? false}
                    onClose={() => toggleDialog('installer', false)}
                    maxWidth="md"
                    buttons={{save: 'Send', cancel: 'Cancel'}}
                    render={(props) => (
                      <SendToInstallerForm
                        {...props}
                        contact={{}}
                        job={job}
                        onSent={() => toggleDialog('installer', false)}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={job ? `Are you sure you want to delete ${job.reference}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.job.deleteJob(job.id).then(() => {
                          setLoading(false);
                          history.push(reverse(`${routes.jobs.index}`))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                ),
                ...((job.end_user && job.end_user.scorpion_customer) || (!job.end_user && job.contact && job.contact.scorpion_customer) ? [(props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.register ?? false}
                    title="Register Scorpion User"
                    message={job.contact || job.end_user ? `Are you sure you want to register ${name(job.end_user ?? job.contact)} as a scorpion user? If they are a Safe Track customer this will send them their username and password` : ''}
                    onClose={confirmed => {
                      toggleDialog('register', false);
                      if (confirmed) {
                        setLoading(true);
                        services.contact.registerScorpionUser(job.end_user ? job.end_user.id : job.contact.id).then(() => {
                          setLoading(false);
                          loadJob(job.id);
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )] : [])
              ]}
            />,

            <DetailPane
              title="Job Location"
              actions={!job.archived ? [
                {title: 'Edit Location', icon: <EditIcon/>, onClick: () => toggleDialog('location', true)}
              ] : []}
              details={[
                {title: 'Address', value: <Address address={address(job)}/>},
                {title: 'Latitude', value: job.latitude ? numeral(job.latitude).format('0,0.00000') : '-'},
                {title: 'Longitude', value: job.longitude ? numeral(job.longitude).format('0,0.00000') : '-'},
              ]}
              wrapChildren={false}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Job Location"
                    open={openDialogs['location'] ?? false}
                    onClose={() => toggleDialog('location', false)}
                    render={(props) => (
                      <JobLocationForm
                        {...props}
                        job={job}
                        onSaved={handleLocation}
                      />
                    )}
                  />
                )
              ]}
            >
              {job.latitude && job.longitude ? (
                <Box flex={1}>
                  <Map
                    mapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    latitude={job.latitude}
                    longitude={job.longitude}
                    title={job.reference}
                  />
                </Box>
              ) : null}
            </DetailPane>,


            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: fullDateTime(job.created_at)},
                {title: 'Last Updated', value: fullDateTime(job.updated_at)},
                ...(job.archived ? [{title: 'Archived', value: fullDateTime(job.deleted_at)}] : [])
              ]}
            />,

          ]}/>

          <Tabs
            job={job}
            onJobUpdated={handleSaved}
            loading={loading}
            onReloadJob={() => loadJob(job.id, true)}
            onLoading={setLoading}
            toggleError={toggleError}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export default Job;
