import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material';

const Container = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 2, 2, 2)
}));

const Input = styled('input')(({theme}) => ({
  backgroundColor: theme.palette.grey['100'],
  border: 'none',
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),

  '&:disabled': {
    backgroundColor: theme.palette.grey['300'],
  }
}));

const Generator = ({token}) => {
  const [url, setURL] = useState('https://www.sasmobile.co.uk');
  const [generated, setGenerated] = useState('');

  useEffect(() => {
    try {
      const parsed = new URL(url);
      const params = new URLSearchParams(parsed.search);
      params.set('from', token);
      setGenerated([parsed.origin, parsed.pathname, '?', params.toString(), parsed.hash].join(''));
    } catch (e) {
    }
  }, [url, token]);

  return (
    <Container>
      <label htmlFor="url">Enter a URL:</label>
      <Input type="text" id="url" value={url} onChange={e => setURL(e.target.value)}/>
      <label htmlFor="url">URL including Unique Token:</label>
      <Input type="text" readOnly disabled value={generated}/>
    </Container>
  )
};

export default Generator;