import {ConfirmationDialog, FormDialog, fullDateTime, name} from '@management-ui/core';
import {Add, Delete, PictureAsPdf, Send} from '@mui/icons-material';
import {Alert, IconButton, Menu, MenuItem, Snackbar, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import PaymentForm from '../../forms/PaymentForm';

const Payments = ({invoice, onInvoiceUpdated, contacts}) => {
  const services = useContext(ServiceContext);
  const [showNew, setShowNew] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [contact, setContact] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleDownload = useCallback((payment) => {
    setLoading(true);
    services.invoice.downloadReceipt(invoice, payment).then(() => {
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [services, invoice]);

  const handleNotify = useCallback(confirmed => {
    setShowNotify(false);
    if (confirmed) {
      setLoading(true);
      services.invoice.sendReceipt(invoice, selected, contact).then(() => {
        setShowSent(true);
        setLoading(false);
      }).catch(() => setLoading(false));
    }
  }, [invoice, services, selected, contact]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.invoice.deletePayment(invoice, selected).then(updated => {
        setLoading(false);
        onInvoiceUpdated(updated);
      }).catch(() => setLoading(false));
    }
  }, [services, invoice, selected, onInvoiceUpdated]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Payments</h3>
          <Tooltip title="Add New Payment">
            <span>
              <IconButton
                onClick={() => setSelected(null) || setShowNew(true)}
                disabled={!!invoice.archived || !invoice.published_at || !invoice.outstanding}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Source</Table.Header>
          <Table.Header>Transaction</Table.Header>
          <Table.Header>Amount</Table.Header>
          <Table.Header>Paid</Table.Header>
          <Table.Header>Comments</Table.Header>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {invoice.payments.length > 0 ? (
          invoice.payments.map((payment, index) => (
            <Table.Row key={index}>
              <Table.Column>{payment.source ?? '-'}</Table.Column>
              <Table.Column>{payment.transaction ?? '-'}</Table.Column>
              <Table.Column><Currency amount={payment.amount}/></Table.Column>
              <Table.Column>{fullDateTime(payment.completed_at)}</Table.Column>
              <Table.Column>{payment.comments ?? '-'}</Table.Column>
              <Table.Action>
                <Tooltip title="Download Receipt">
                  <IconButton onClick={() => handleDownload(payment)}>
                    <PictureAsPdf/>
                  </IconButton>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Send Receipt">
                  <IconButton
                    onClick={e => setMenuAnchor(e.currentTarget) || setSelected(payment) || setShowContacts(true)}>
                    <Send/>
                  </IconButton>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Delete Payment">
                  <span>
                    <IconButton
                      onClick={() => setSelected(payment) || setShowDelete(true)}
                      disabled={!payment.manual}>
                      <Delete/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          <Table.NoRecords>There are no payments associated with this invoice</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title="Add New Payment"
        open={showNew}
        maxWidth="md"
        onClose={() => setShowNew(false)}
        render={(props) => (
          <PaymentForm
            {...props}
            invoice={invoice}
            payment={{amount: invoice.outstanding}}
            onSaved={(updated) => setShowNew(false) || onInvoiceUpdated(updated)}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete Payment"
        message="Are you sure you want to delete this payment?"
        open={showDelete}
        onClose={handleDelete}
      />
      <ConfirmationDialog
        title="Send to Customer"
        message={contact ? `Are you sure you want to send this receipt to ${contact.email}?` : ''}
        open={showNotify}
        onClose={handleNotify}
      />
      <Menu
        anchorEl={menuAnchor}
        open={showContacts}
        onClose={() => setShowContacts(false)}
      >
        {contacts.filter(({type}) => type === 'email').map((c, index) => (
          <MenuItem key={index} onClick={() => setContact(c.contact) || setShowContacts(false) || setShowNotify(true)}>
            {name(c.contact)} ({c.contact.email})
          </MenuItem>
        ))}
      </Menu>
      <Snackbar open={showSent} autoHideDuration={4000} onClose={() => setShowSent(false)}>
        <Alert onClose={() => setShowSent(false)} severity="success">
          The receipt has been sent
        </Alert>
      </Snackbar>
    </>
  );
};

export default Payments;
