import {Checkbox, Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({keyPair = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={keyPair}
      prefix={prefix}
      panes={[
        {
          title: 'Access Key Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title'}}
            />
          ]
        },
        {
          title: 'Settings',
          fields: [
            <Checkbox
              name="is_active"
              prefix={prefix}
              label="Is Active?"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Access Key Details'}
    >{children}</Panes>
  );
}
