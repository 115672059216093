import {name, Panes, Select} from '@management-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({contact = {}, job, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [contacts, setContacts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    services.contact.getContacts({
      filter: {
        company: job.installer?.id ?? 0
      }
    }, 1, 50)
      .then(({data}) => {
        setContacts(data.map(contact => ({
          title: name(contact),
          value: `${contact.id}`
        })))
        setLoaded(true);
      })
      .catch(() => setLoaded(true));
  }, [services, job]);

  return (
    <Panes
      entity={contact}
      prefix={prefix}
      panes={[
        {
          title: 'Contact to Notify',
          fields: [
            <Select
              name="contact"
              prefix={prefix}
              options={contacts.length < 1 ? [{title: '', value: '0'}] : contacts}
              label={loaded ? (contacts.length > 0 ? 'Select a Contact' : 'No Contacts to Notify') : 'Loading...'}
              rules={{required: 'Please select a contact'}}
              fieldProps={{disabled: contacts.length < 1}}
            />
          ]
        }
      ]}
      title={''}
    />
  );
};

export default Fields;
