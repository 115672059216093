import {FormDialog} from '@management-ui/core';
import {Add, CheckCircle, Refresh, Unpublished, Visibility} from '@mui/icons-material';
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {fullDate} from '@management-ui/core';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import Status from '../../components/Status';
import InvoiceForm from '../../forms/InvoiceForm';
import Metrics from '../../forms/JobAllocationForm/Metrics';

const Invoices = ({job, onJobUpdated, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [fromStock, setFromStock] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);
  const anchorRef = useRef();
  const loaded = useRef(false);

  const loadInvoices = useCallback(() => {
    onLoading(true);
    services.invoice.getInvoices({filter: {job: job.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setInvoices(response.data);
      }).catch(() => onLoading(false));
  }, [job, onLoading, services]);

  useEffect(() => {
    if (job) {
      if (!loaded.current) {
        loaded.current = true;
        loadInvoices();
      }
    }
  }, [job, loadInvoices]);

  const togglePaid = useCallback(() => {
    onLoading(true);
    services.job.togglePaid(job).then((updated) => {
      onJobUpdated(updated);
      onLoading(false);
    }).catch(() => onLoading(false));
  }, [job, onJobUpdated, onLoading, services]);

  const goToDetail = useCallback((invoice) => {
    history.push(reverse(routes.invoices.detail, {jobID: invoice.job.id, id: invoice.id}));
  }, [history]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>
            Invoices
            <Table.Notice display={!!job.paid_at} colour={Metrics.COLOURS.green}>
              {job.paid_at ? <>Manually Flagged as Paid: <strong>{fullDate(job.paid_at)}</strong></> : null}
            </Table.Notice>
          </h3>
          {job.paid_at ? (
            <Tooltip title="Clear Paid Flag">
              <span>
                <IconButton onClick={() => togglePaid()} disabled={job.archived}>
                  <Unpublished/>
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Manually Flag as Paid">
              <span>
                <IconButton onClick={() => togglePaid()} disabled={job.archived}>
                  <CheckCircle/>
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Refresh">
            <IconButton onClick={() => loadInvoices()}>
              <Refresh/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Add New Invoice">
            <span ref={anchorRef}>
              <IconButton
                onClick={() => setShowNew(true)}
                disabled={job.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Reference</Table.Header>
          <Table.Header>Account</Table.Header>
          <Table.Header>Status</Table.Header>
          <Table.Header>Date</Table.Header>
          <Table.Header>Total</Table.Header>
          <Table.Header>Paid</Table.Header>
          <Table.Header>Outstanding</Table.Header>
          <Table.Action/>
        </Table.Row>
        {invoices.length > 0 ? (
          invoices.map((invoice, index) => (
            <Table.Row key={index}>
              <Table.Column>{invoice.reference}</Table.Column>
              <Table.Column>{invoice.account?.name ?? '-'}</Table.Column>
              <Table.Column><Status entity={invoice}/></Table.Column>
              <Table.Column>{fullDate(invoice.created_at)}</Table.Column>
              <Table.Column><Currency amount={invoice.total}/></Table.Column>
              <Table.Column><Currency amount={invoice.paid}/></Table.Column>
              <Table.Column><Currency amount={invoice.outstanding}/></Table.Column>
              <Table.Action>
                <Tooltip title="View Invoice">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(invoice)}
                      disabled={job.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no invoices associated with this job</Table.NoRecords>
        )}
      </Table>
      <Menu
        anchorEl={() => anchorRef.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={showNew}
        onClose={() => setShowNew(false)}
      >
        {['From Stock', 'From Scratch'].map((option, index) => (
          <MenuItem key={index} onClick={() => {
            setFromStock(option === 'From Stock');
            setShowNewForm(true);
            setShowNew(false);
          }}>{option}</MenuItem>
        ))}
      </Menu>
      <FormDialog
        open={showNewForm}
        title={`Add New Invoice${fromStock ? ' (based on stock)' : ''}`}
        maxWidth="sm"
        render={useCallback((props) => (
          <InvoiceForm invoice={{}} fromStock={fromStock} job={job} onSaved={goToDetail} {...props} />
        ), [goToDetail, fromStock, job])}
        onClose={() => setShowNewForm(false)}/>
    </>
  );
};

export default Invoices;
