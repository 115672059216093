import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import GatewayAccountForm from '../../forms/GatewayAccountForm';
import {useHistory} from 'react-router-dom';
import {reverse} from 'named-urls';
import routes from '../../../../routes';

const GatewayAccounts = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((selected) => {
    history.push(reverse(routes.developer.gatewayAccounts.detail, {id: selected.id}));
  }, [history]);

  return (
    <>
      <DataTable
        title="Gateway Accounts"
        columns={useMemo(() => [
          {title: 'Name', field: 'name'},
          {title: 'Identifier', field: 'identifier'},
        ], [])}
        loadData={query => new Promise((resolve, reject) => {
          services.gatewayAccount.getTableAccounts(query)
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => {
            reject();
          });
        })}
        newForm={{
          title: 'Add New Account',
          render: (props) => <GatewayAccountForm account={{}} {...props}/>,
          onSaved: (saved, refresh) => refresh()
        }}
        onRowClick={goToDetail}
      />
    </>
  );
};

export default GatewayAccounts;
