import {Map} from '@mui/icons-material';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';
import React, {useState} from 'react';
import Dialog from './Dialog';

const EngineersByPostcode = ({linkClasses}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem className={linkClasses} onClick={() => setOpen(true)} button>
        <ListItemIcon>
          <Map/>
        </ListItemIcon>
        <ListItemText>Lookup Postcode</ListItemText>
      </ListItem>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default EngineersByPostcode;
