import {Panes, prefixWithSeparator, Select} from '@management-ui/core';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';

export default function Fields({product, prefix = ''}) {
  const {setValue} = useFormContext();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(product.alternatives.map(p => ({title: p.name, value: p.id})));
  }, [product]);

  useEffect(() => {
    if (product.product?.id && products.length && products.findIndex(v => v.value === `${product.product.id}`) >= 0) {
      setValue(`${prefixWithSeparator(prefix)}product`, `${product.product.id}`);
    }
  }, [product, prefix, setValue, products]);

  return (
    <Panes
      entity={product}
      prefix={prefix}
      panes={[
        {
          title: 'Product',
          fields: [
            <Select
              name="alternative"
              prefix={prefix}
              options={products.length < 1 ? [{title: '', value: '0'}] : products}
              label={products.length > 0 ? 'Select a Product' : 'No Alternatives Available'}
              fieldProps={{disabled: products.length < 1}}
            />
          ]
        }
      ]}
      title={''}
    />
  );
}
