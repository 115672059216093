import {address, Address, name} from '@management-ui/core';
import React from 'react';
import BlockedList from './BlockedList';

const LocationList = ({locations, onSelect, addNew = null, layout = 'narrow'}) => {
  return (
    <BlockedList
      blocks={locations}
      onSelect={onSelect}
      layout={layout}
      newProps={addNew?.props}
      onRenderItem={(location) => (
        location ? (
          <>
            <p>{location.name?.label} - {location.type}</p>
            <Address address={address(location)}/>
            {location.contact || location.company ? (
              <p>{location.contact ? name(location.contact) : location.company.name}</p>
            ) : null}
          </>
        ) : (
          <>
            <p>Add a New Location</p>
            <p>{addNew.type}</p>
          </>
        )
      )}
    />
  );
};

export default LocationList;
