import {Paper, styled, Typography} from '@mui/material';
import React, {useCallback} from 'react';
import {Link, useLocation} from 'react-router-dom';

const Container = styled('div')(({theme}) => ({
  alignSelf: 'flex-end',
  padding: `${theme.spacing(2)} 0 ${theme.spacing(1)}`,
}));

const Inner = styled(Paper)(() => ({
  '&.MuiPaper-root': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }
}));

const Tabs = styled('ul')(({theme}) => ({
  display: 'flex',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  width: '100%',

  '& li': {
    display: 'flex',
    flexBasis: '100%',

    '& > a': {
      alignItems: 'center',
      border: `0 solid ${theme.palette.grey['200']}`,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },

    '&:first-of-type > a': {
      borderLeftColor: 'transparent',
    },

    '&:last-of-type > a': {
      borderRightColor: 'transparent',
    },
  },

  '& a': {
    flex: 1,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    textAlign: 'center',
    textDecoration: 'none',

    '& .MuiTypography-root': {
      color: theme.palette.secondary.main,
      fontSize: '1em',
      fontWeight: 600,
    },

    '&.active': {
      backgroundColor: theme.palette.primary.main,

      '& .MuiTypography-root': {
        color: theme.palette.primary.contrastText,
      },
    }
  }
}));

const Switcher = ({tabs, selectedTab = null, onSelectTab = null}) => {
  const location = useLocation();

  const handleClick = useCallback((e, slug) => {
    if (onSelectTab != null) {
      e.preventDefault();
      onSelectTab(slug);
    }
  }, [onSelectTab])

  return (
    <Container>
      <Inner>
        <Tabs>
          {tabs.map(({title, slug, path}, index) => (
            <li key={index}>
              <Link
                className={(selectedTab === null ? location.pathname === path : selectedTab === slug) ? 'active' : ''}
                to={path ?? '#'}
                onClick={e => handleClick(e, slug)}
              >
                <Typography>{title}</Typography>
              </Link>
            </li>
          ))}
        </Tabs>
      </Inner>
    </Container>
  );
}

export default Switcher;
