import {name, Panes, RelationAutocomplete} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({contact = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  return (
    <Panes
      entity={contact}
      prefix={prefix}
      panes={[
        {
          title: 'Contact',
          fields: [
            <Box minHeight={300}>
              <RelationAutocomplete
                valueName="contact_id"
                titleName="contact_name"
                prefix={prefix}
                label="Contact"
                loadOptions={query => (
                  services.contact.getContacts({filter: {search: query}}, 1, 50)
                    .then(response => response.data.map(contact => ({title: name(contact), value: contact.id})))
                    .catch(() => [])
                )}
              />
            </Box>
          ]
        }
      ]}
      title=""
    />
  );
};

export default Fields;
