import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ActionHolder from '../../components/Actions';

export default function Actions(
  {
    job,
    setLoading,
    openDialogs,
    toggleDialog,
    toggleError,
    page = 1,
    length = 5,
    updatePageCount
  }) {
  const services = useContext(ServiceContext);

  const loadActivity = useCallback((currentPage, selectedLength) => {
    return services.job.getJobActivity(
      job.id,
      job.archived ? {filter: {archive: 'only'}} : {},
      currentPage,
      selectedLength
    );
  }, [services, job]);

  return (
    <ActionHolder
      loadActivity={loadActivity}
      saveNote={(note, currentPage, selectedLength) => services.job.saveComment(job.id, note, currentPage, selectedLength)}
      setLoading={setLoading}
      openDialogs={openDialogs}
      toggleDialog={toggleDialog}
      toggleError={toggleError}
      page={page}
      length={length}
      updatePageCount={updatePageCount}
    />
  );
}
