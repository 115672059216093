import {DataTable, fullDateTime} from '@management-ui/core';
import {Box, useTheme} from '@mui/material';
import React, {useCallback, useContext, useState} from 'react';
import DetailDialog from '../../../../components/DetailDialog';
import {ServiceContext} from '../../../../components/Services';
import {getStatusCodeLabel} from '../../../../utils';
import LogDialog from './LogDialog';

const ListDialog = ({open, onClose}) => {
  const theme = useTheme();
  const services = useContext(ServiceContext);
  const [selected, setSelected] = useState(null);
  const [showDetail, setShowDetail] = useState(false);

  return (
    <>
      <DetailDialog
        title="Scorpion API Logs"
        maxWidth="lg"
        open={open}
        onClose={onClose}
        actions={[
          {label: 'Close', colour: 'primary', onClick: () => onClose()}
        ]}
        backgroundColour={theme.palette.background.paper}
      >
        <Box display="flex" flexDirection="column">
          <DataTable
            title={null}
            columns={[
              {title: 'Endpoint', field: 'url'},
              {title: 'Method', field: 'method'},
              {title: 'Result', field: 'type', render: data => data.type.name},
              {title: 'Response Status', field: 'status', render: data => getStatusCodeLabel(data.status)},
              {title: 'Date/Time', field: 'created_at', render: data => fullDateTime(data.created_at)},
            ]}
            loadData={useCallback(query => new Promise((resolve, reject) => {
              services.scorpionLog.getTableLogs(query)
                .then(response => {
                  resolve({
                    data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                  });
                }).catch(() => reject());
            }), [services])}
            onRowClick={(log) => {
              setSelected(log);
              setShowDetail(true);
            }}
          />
        </Box>
      </DetailDialog>
      <LogDialog log={selected} open={showDetail} onClose={() => setShowDetail(false)}/>
    </>
  );
}

export default ListDialog;
