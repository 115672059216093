import {Link, WorkOutline} from '@mui/icons-material';
import {Menu, MenuItem} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import routes from '../../../../routes';
import Button from './Button';

const JobLink = ({order, job, delivery, onLink}) => {
  const history = useHistory();
  const anchorRef = useRef();
  const [showNew, setShowNew] = useState(false);

  return order.jobs.length > 0 && !delivery ? (
    <>
      {job ? (
        <Button
          onClick={e => e.preventDefault() || history.push(reverse(routes.jobs.detail, {id: job.id}))}
          className="small padRight"
        >
          <WorkOutline/>{job.reference}
        </Button>
      ) : (
        <Button
          ref={anchorRef}
          onClick={e => e.preventDefault() || setShowNew(true)}
          className="small padRight action"
        >
          <Link/>Link to Job
        </Button>
      )}
      <Menu
        anchorEl={() => anchorRef.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        open={showNew}
        onClose={() => setShowNew(false)}
      >
        {order.jobs.map((job, index) => (
          <MenuItem key={index} onClick={() => setShowNew(false) || onLink(job)}>{job.reference}</MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};

export default JobLink;
