import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const OfferDateForm = forwardRef(({request, onOffer}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={request}
      type="dateRequest"
      fieldsComponent={(props) => <Fields {...props} request={request}/>}
      onSave={useCallback(({expires, response}) => (
        onOffer(expires, response)
      ), [onOffer])}
    />
  );
});

export default OfferDateForm;
