import {DateField, Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useEngineers} from '../../../../hooks';

const Fields = ({note = {}, prefix = '', multiplePanes = true, children}) => {
  const engineers = useEngineers();

  const handleReset = useCallback((subject) => {
    if (subject.engineer) {
      subject.engineer_id = subject.engineer.id;
      delete subject.engineer;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={note}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'When',
          fields: [
            <DateField
              name="from"
              prefix={prefix}
              label="From"
              rules={{required: 'Please select a start date'}}
            />,
            <DateField
              name="to"
              prefix={prefix}
              label="To"
              rules={{required: 'Please select an end date'}}
            />,
          ]
        },
        {
          title: 'Who',
          fields: [
            <Select
              name="engineer_id"
              prefix={prefix}
              options={engineers}
              label="Engineer"
              rules={{required: 'Please select an engineer'}}
            />,
            <TextField
              name="comments"
              prefix={prefix}
              label="Comments"
              fieldProps={{
                multiline: true,
                rows: 5
              }}
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Availability Details'}
    >{children}</Panes>
  );
};

export default Fields;
