import * as qs from 'qs';
import {API} from './API';

export class ReviewProviderService {
  async getTableProviders(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getProviders(query, page, length);
  }

  async getProviders(query, page, length) {
    return (await API.getConnection())
      .get('review-providers', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getProvider(id, archive = false) {
    return (await API.getConnection())
      .get(`review-providers/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveProvider(data) {
    let method = 'post';
    let url = 'review-providers';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteProvider(provider) {
    return (await API.getConnection())
      .delete(`review-providers/${provider.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }

  async restoreProvider(id) {
    return (await API.getConnection())
      .put(`review-providers/${id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
