import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const VehicleForm = forwardRef((
  {
    company = null,
    contact = null,
    job = null,
    order,
    vehicle,
    onSaved
  }, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={vehicle}
      type="vehicle"
      fieldsComponent={props => <Fields company={company} contact={contact} order={order} {...props}/>}
      onSave={useCallback((update) => {
        const attrs = {...update};
        if (company) {
          attrs['company_id'] = company.id;
        }
        if (contact) {
          attrs['contact_id'] = contact.id;
        }
        if (job) {
          attrs['job_id'] = job.id;
        }
        if (attrs['aliasList']) {
          attrs['aliases'] = attrs['aliasList'].split('\n').map(alias => alias.trim());
        } else {
          attrs['aliases'] = [];
        }
        if (attrs['aliases'].length < 1) {
          attrs['aliases'] = null;
        }
        return services.vehicle.saveVehicle(attrs);
      }, [services, company, contact, job])}
      onSaved={onSaved}
    />
  );
});

export default VehicleForm;
