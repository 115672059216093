import {Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {fullDate} from '@management-ui/core';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import Status from '../../components/Status';

const Invoices = ({company, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const loaded = useRef(false);

  const loadInvoices = useCallback(() => {
    onLoading(true);
    services.invoice.getInvoices({filter: {company: company.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setInvoices(response.data);
      }).catch(() => onLoading(false));
  }, [company, onLoading, services]);

  useEffect(() => {
    if (company) {
      if (!loaded.current) {
        loaded.current = true;
        loadInvoices();
      }
    }
  }, [company, loadInvoices]);

  const goToDetail = useCallback((invoice) => {
    history.push(reverse(routes.invoices.detail, {companyID: invoice.job.id, id: invoice.id}));
  }, [history]);

  return (
    <Table className={loading ? 'loading' : ''}>
      <Table.Actions>
        <h3>Invoices</h3>
        <Tooltip title="Refresh">
          <IconButton onClick={() => loadInvoices()}>
            <Refresh/>
          </IconButton>
        </Tooltip>
      </Table.Actions>
      <Table.Row>
        <Table.Header>Reference</Table.Header>
        <Table.Header>Account</Table.Header>
        <Table.Header>Status</Table.Header>
        <Table.Header>Date</Table.Header>
        <Table.Header>Total</Table.Header>
        <Table.Header>Paid</Table.Header>
        <Table.Header>Outstanding</Table.Header>
        <Table.Action/>
      </Table.Row>
      {invoices.length > 0 ? (
        invoices.map((invoice, index) => (
          <Table.Row key={index}>
            <Table.Column>{invoice.reference}</Table.Column>
            <Table.Column>{invoice.account?.name ?? '-'}</Table.Column>
            <Table.Column><Status entity={invoice}/></Table.Column>
            <Table.Column>{fullDate(invoice.created_at)}</Table.Column>
            <Table.Column><Currency amount={invoice.total}/></Table.Column>
            <Table.Column><Currency amount={invoice.paid}/></Table.Column>
            <Table.Column><Currency amount={invoice.outstanding}/></Table.Column>
            <Table.Action>
              <Tooltip title="View Invoice">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(invoice)}
                      disabled={company.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
              </Tooltip>
            </Table.Action>
          </Table.Row>
        ))
      ) : (
        loading ? null : <Table.NoRecords>There are no invoices associated with this company</Table.NoRecords>
      )}
    </Table>
  );
};

export default Invoices;
