import {DataTable, fullDateTime, name} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import NewContactForm from '../../forms/NewContactForm';

export default function Table(
  {
    title = 'Contacts',
    archive = false,
    canCreate = true,
    onSelect = null,
    options = {}
  }
) {
  // noinspection DuplicatedCode
  const services = useContext(ServiceContext);
  const history = useHistory();

  const handleSelect = useCallback((contact) => {
    if (onSelect) {
      onSelect(contact)
    } else {
      history.push(reverse(`${archive ? routes.archive.contacts.detail : routes.contacts.detail}`, {id: contact.id}));
    }
  }, [history, archive, onSelect]);

  return (
    <DataTable
      title={title}
      options={{
        ...options,
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 280px)'
      }}
      columns={useMemo(() => [
        {title: 'Name', field: 'name', render: data => name(data)},
        {title: 'Email Address', field: 'email'},
        {title: 'Telephone', field: 'phone'},
        {
          title: 'Companies',
          field: 'associations',
          render: data => data.associations ? data.associations.join(', ') : ''
        },
        {title: 'Date Added', field: 'created_at', render: data => fullDateTime(data.created_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => fullDateTime(data.deleted_at)}] : [])
      ], [archive])}
      loadData={query => new Promise((resolve, reject) => {
        services.contact.getTableContacts(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={handleSelect}
      newForm={canCreate && archive ? null : {
        title: 'Add New Contact',
        render: (props) => <NewContactForm {...props}/>,
        onSaved: handleSelect
      }}
    />
  );
}
