import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import StockItems from '../Stock/StockItems';
import Jobs from './Jobs';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({vehicle, archive, loading, onLoading}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Jobs',
      slug: '',
      path: reverse(vehicle.archived ? routes.archive.vehicle.detail : routes.vehicles.detail, {
        id: vehicle?.id ?? 0,
        tab: ''
      })
    },
    {
      title: 'Stock Items',
      slug: 'stock-items',
      path: reverse(vehicle.archived ? routes.archive.vehicles.tab : routes.vehicles.tab, {
        id: vehicle?.id ?? 0,
        tab: 'stock-items'
      })
    }
  ], [vehicle]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Container>
        <Switched tab="" currentTab={tab}>
          <Jobs vehicle={vehicle} loading={loading} onLoading={onLoading}/>
        </Switched>
        <Switched tab="stock-items" currentTab={tab}>
          <StockItems
            vehicle={vehicle}
            canCreate={false}
            archive={archive}
          />
        </Switched>
      </Container>
    </>
  );
};

export default Tabs;
