import {Typography} from '@mui/material';
import {Add, Search} from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {makeStyles} from '@mui/styles';
import React from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import NewCompany from './NewCompany';
import NewContact from './NewContact';
import SelectCompany from './SelectCompany';
import SelectContact from './SelectContact';
import When from './When';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },

  half: {
    display: 'flex',
    flex: 1,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

    '& > div': {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      maxWidth: 400,
      textAlign: 'center'
    },

    '& h4': {
      alignItems: 'center',
      display: 'flex',
      fontSize: '1.4em',
      justifyContent: 'center',
      marginTop: 0,
      paddingTop: 0,

      '& svg': {
        marginRight: theme.spacing(2),
      }
    },

    '&:first-child': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      justifyContent: 'flex-end',
    },

    '&:last-child': {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      justifyContent: 'flex-start',
    }
  },

  button: {
    alignItems: 'center',
    border: 'none',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.background.paper,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing(1)} 0`,
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(5)}`,
    position: 'relative',
    textTransform: 'none',
    width: '100%',

    '& p': {
      fontWeight: 700,
    },

    '& svg': {
      bottom: 0,
      left: theme.spacing(1),
      margin: 'auto 0',
      position: 'absolute',
      top: 0,
    }
  },

  existing: {
    backgroundColor: theme.palette.info.main,
  },

  new: {
    backgroundColor: theme.palette.success.main,
  }
}))

const Who = ({onBack, onNext}) => {
  const classes = useStyles();

  return (
    <>
      <Header>Who is the Job For?</Header>
      <div className={classes.container}>
        <div className={classes.half}>
          <div>
            <h4><RecentActorsIcon/> Company</h4>
            <button
              className={[classes.button, classes.existing].join(' ')}
              onClick={() => onNext({type: SelectCompany.type, id: SelectCompany.type})}
            ><Search/>&nbsp;<Typography variant="body1">Select An Existing Company</Typography>
            </button>
            <button
              className={[classes.button, classes.new].join(' ')}
              onClick={() => onNext({type: NewCompany.type, id: NewCompany.type})}
            ><Add/>&nbsp;<Typography variant="body1">Create a New Company</Typography>
            </button>
          </div>
        </div>
        <div className={classes.half}>
          <div>
            <h4><PeopleIcon/> Contact</h4>
            <button
              className={[classes.button, classes.existing].join(' ')}
              onClick={() => onNext({type: SelectContact.type, id: SelectContact.type})}
            ><Search/>&nbsp;<Typography variant="body1">Select An Existing Contact</Typography>
            </button>
            <button
              className={[classes.button, classes.new].join(' ')}
              onClick={() => onNext({type: NewContact.type, id: NewContact.type})}
            ><Add/>&nbsp;<Typography variant="body1">Create a New Contact</Typography>
            </button>
          </div>
        </div>
      </div>
      <Controls onBack={() => onBack(
        {type: When.type, id: When.type},
        {selectedAllocation: null}
      )} />
    </>
  );
};

Who.type = 'WHO';

export default Who;
