import {name, Panes, prefixWithSeparator, RelationAutocomplete, Select} from '@management-ui/core';
import React, {useCallback, useContext} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';
import {useOptions} from '../../../../hooks';
import withTemplatedFields from '../JobDetailsForm/withTemplatedFields';
import NewCompanyForm from '../NewCompanyForm';
import NewContactForm from '../NewContactForm';

const Fields = ({job = {}, prefix = '', fields, multiplePanes = true, children}) => {
  const services = useContext(ServiceContext);
  const {/** @var {function(string): string} */getValues} = useFormContext();

  const installers = useOptions('installers', useCallback((list) => list.map(({id, name}) => ({
    value: id,
    title: name
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.company) {
      subject.company_id = subject.company.id;
      subject.company_name = subject.company.name;
      delete subject.company;
    }
    if (subject.contact) {
      subject.contact_id = subject.contact.id;
      subject.contact_name = name(subject.contact);
      delete subject.contact;
    }
    if (subject.end_user) {
      subject.end_user_id = subject.end_user.id;
      subject.end_user_name = name(subject.end_user);
      delete subject.end_user;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={job}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Customer',
          fields: [
            <RelationAutocomplete
              valueName="company_id"
              titleName="company_name"
              prefix={prefix}
              label="Company"
              rules={{
                validate: () => {
                  const fieldPrefix = prefixWithSeparator(prefix);
                  if (!getValues(`${fieldPrefix}company_id`) && !getValues(`${fieldPrefix}contact_id`)) {
                    return 'Please select either a company or a contact';
                  }
                  return true;
                }
              }}
              loadOptions={query => (
                services.company.getCompanies({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(company => ({title: company.name, value: company.id})))
                  .catch(() => [])
              )}
              addForm={{
                title: 'Add New Company',
                onSaved: (company) => ({title: company.name, value: company.id}),
                render: (props) => (
                  <NewCompanyForm
                    {...props}
                    type="jobCompany"
                    company={{}}
                  />
                )
              }}
            />,
            <RelationAutocomplete
              valueName="contact_id"
              titleName="contact_name"
              prefix={prefix}
              label="Contact"
              loadOptions={query => (
                services.contact.getContacts({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(contact => ({title: name(contact), value: contact.id})))
                  .catch(() => [])
              )}
              addForm={{
                title: 'Add New Contact',
                onSaved: (contact) => ({title: name(contact), value: contact.id}),
                render: (props) => (
                  <NewContactForm
                    {...props}
                    type="jobContact"
                    contact={{}}
                  />
                )
              }}
            />,
            <RelationAutocomplete
              valueName="end_user_id"
              titleName="end_user_name"
              prefix={prefix}
              label="End User"
              loadOptions={query => (
                services.contact.getContacts({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(contact => ({title: name(contact), value: contact.id})))
                  .catch(() => [])
              )}
              addForm={{
                title: 'Add New Contact',
                onSaved: (contact) => ({title: name(contact), value: contact.id}),
                render: (props) => (
                  <NewContactForm
                    {...props}
                    type="jobEndUser"
                    contact={{}}
                  />
                )
              }}
            />,
            <Select
              name="installer_id"
              prefix={prefix}
              options={installers}
              label="Installer"
            />,
          ]
        },
        {
          title: 'Job Details', fields
        }
      ]}
      title={multiplePanes ? null : 'Job Details'}
    >{children}</Panes>
  );
}

export default withTemplatedFields(Fields);
