import moment from 'moment';
import * as qs from 'qs';
import {API} from './API';

export class InvoiceService {
  async getTableInvoices(params, additional = {}, exportName = null) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getInvoices(query, page, length, exportName);
  }

  async getInvoices(query, page, length, exportName = null) {
    return (await API.getConnection())
      .get(`invoices${exportName ? '/export' : ''}`, {
        params: {...query, ...{page, length}, ...(exportName ? {name: exportName} : {})},
        paramsSerializer: params => qs.stringify(params),
        ...(exportName ? {responseType: 'blob'} : {})
      })
      .then(response => {
        if (exportName) {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: 'octet/stream'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        } else {
          return response.data
        }
      })
      .catch(error => API.handleError(error));
  }

  async getInvoice(id, archive = false) {
    return (await API.getConnection())
      .get(`invoices/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveInvoice(data) {
    let method = 'post';
    let url = 'invoices';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async depositInvoice(invoice, data) {
    return (await API.getConnection())
      .post(`invoices/${invoice.id}/deposit`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async publishInvoice(invoice) {
    return (await API.getConnection())
      .put(`invoices/${invoice.id}/publish`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async voidInvoice(invoice) {
    return (await API.getConnection())
      .put(`invoices/${invoice.id}/void`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadInvoice(invoice) {
    return (await API.getConnection())
      .get(`invoices/${invoice.id}/download`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoice.reference}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async requestPayment(invoice, contact, type) {
    return (await API.getConnection())
      .post(`invoices/${invoice.id}/request-payment`, {contact: contact.id, type})
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async payInvoice(invoice, success, cancelled, method) {
    return (await API.getConnection())
      .post(`invoices/${invoice.id}/pay`, {success, cancelled, method})
      .then(response => response.data.token)
      .catch(error => API.handleError(error));
  }

  async captureInvoice(invoice, transaction, method) {
    return (await API.getConnection())
      .post(`invoices/${invoice.id}/capture`, {transaction, method})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteInvoice(invoice) {
    return (await API.getConnection())
      .delete(`invoices/${invoice.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }

  async saveInvoiceItem(invoice, data) {
    let method = 'post';
    let url = `invoices/${invoice.id}/items`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteInvoiceItem(invoice, item) {
    return (await API.getConnection())
      .delete(`invoices/${invoice.id}/items/${item.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async savePayment(invoice, data) {
    let method = 'post';
    let url = `invoices/${invoice.id}/payments`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadReceipt(invoice, payment) {
    return (await API.getConnection())
      .get(`invoices/${invoice.id}/payments/${payment.id}/download`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoice.reference}-${moment(payment.completed_at).format('YYYY-MM-DD')}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async sendReceipt(invoice, payment, contact) {
    return (await API.getConnection())
      .post(`invoices/${invoice.id}/payments/${payment.id}/send`, {contact: contact.id})
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async deletePayment(invoice, payment) {
    return (await API.getConnection())
      .delete(`invoices/${invoice.id}/payments/${payment.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getInvoiceActivity(id, query, page, length) {
    return (await API.getConnection())
      .get(`invoices/${id}/activity`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async saveComment(id, comment, page = 1, length = 5) {
    return (await API.getConnection())
      .post(`invoices/${id}/comment`, {comment}, {
        params: {page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}
