import {Panes, TextField, useFormStyles} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';
import {FormControl, InputLabel, Select} from '@mui/material';

const Fields = ({product = {}, prefix = '', productIDs, onChangeProductIDs}) => {
  const classes = useFormStyles();

  const products = useOptions('products', useCallback((list) => list.map(p => ({
    value: `${p.id}`,
    title: p.name
  })), []));

  return (
    <Panes
      entity={product}
      prefix={prefix}
      panes={[
        {
          title: 'Required Product(s)',
          fields: [
            <FormControl
              variant="outlined"
              className={classes.select}
              required
              fullWidth>
              <InputLabel shrink>Product(s)</InputLabel>
              <Select
                multiple
                native
                inputProps={{size: 10}}
                value={productIDs}
                label="Product(s)"
                onChange={onChangeProductIDs}
              >
                {products.map((option, index) => option.value ? (
                  <option key={index} value={`${option.value}`}>{option.title}</option>
                ) : (
                  <optgroup key={index}>{option.title}</optgroup>
                ))}
              </Select>
            </FormControl>,
            <TextField
              name="quantity"
              prefix={prefix}
              label="Quantity"
              rules={{required: 'Please enter a quantity'}}
            />
          ]
        }
      ]}
      title={null}
    />
  );
};

export default Fields;
