import {Paper, styled} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import Currency from '../../../../../components/Currency';
import Table from '../../../../../components/Table';

const Container = styled(Paper)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2, 0, 0),
  overflow: 'hidden',
}));

const Items = ({delivery}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(delivery?.items ?? []);
  }, [delivery]);

  return (
    <Container>
      <Table>
        <Table.Row>
          <Table.Header>Description</Table.Header>
          <Table.Header>Add-ons</Table.Header>
          <Table.Header>Unit Price</Table.Header>
          <Table.Header>Quantity</Table.Header>
          <Table.Header>Total</Table.Header>
        </Table.Row>
        {items.length > 0 ? (
          items.map((item, index) => (
            <Table.Row key={index}>
              <Table.Column>{item['description']}</Table.Column>
              <Table.Column>{item['add_ons']}</Table.Column>
              <Table.Column><Currency amount={item['unit_price']}/></Table.Column>
              <Table.Column>{item['quantity']}</Table.Column>
              <Table.Column><Currency amount={item['total']}/></Table.Column>
            </Table.Row>
          ))
        ) : (
          <Table.NoRecords>There are no items associated with this delivery</Table.NoRecords>
        )}
      </Table>
    </Container>
  );
};

export default Items;
