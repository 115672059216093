import {DynamicForm} from '@mui/icons-material';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';
import React, {useState} from 'react';
import ListDialog from './ListDialog';

const ScorpionLogs = ({linkClasses}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem className={linkClasses} onClick={() => setOpen(true)} button>
        <ListItemIcon>
          <DynamicForm/>
        </ListItemIcon>
        <ListItemText>Scorpion Logs</ListItemText>
      </ListItem>
      <ListDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default ScorpionLogs;
