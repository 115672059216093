import {fullDate} from '@management-ui/core';
import {Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import Status from '../../components/Status';
import {useHistory} from 'react-router-dom';
import {reverse} from 'named-urls';
import routes from '../../../../routes';

const Costs = ({engineer, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [costs, setCosts] = useState([]);
  const loaded = useRef(false);

  const loadCosts = useCallback(() => {
    onLoading(true);
    services.cost.getCosts({filter: {engineer: engineer.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setCosts(response.data);
      }).catch(() => onLoading(false));
  }, [engineer, onLoading, services]);

  useEffect(() => {
    if (engineer) {
      if (!loaded.current) {
        loaded.current = true;
        loadCosts();
      }
    }
  }, [engineer, loadCosts]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Costs</h3>
          <Tooltip title="Refresh">
            <IconButton onClick={() => loadCosts()}>
              <Refresh/>
            </IconButton>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Job</Table.Header>
          <Table.Header>Reference</Table.Header>
          <Table.Header>Status</Table.Header>
          <Table.Header>Date Paid</Table.Header>
          <Table.Header>Amount</Table.Header>
          <Table.Action/>
        </Table.Row>
        {costs.length > 0 ? (
          costs.map((cost, index) => (
            <Table.Row key={index}>
              <Table.Column>{cost.job ? cost.job.reference : '-'}</Table.Column>
              <Table.Column>{cost.reference}</Table.Column>
              <Table.Column><Status entity={cost}/></Table.Column>
              <Table.Column>{cost.paid_at ? fullDate(cost.paid_at) : '-'}</Table.Column>
              <Table.Column><Currency amount={cost.amount}/></Table.Column>
              <Table.Action>
                <Tooltip title="View Job">
                  <span>
                    <IconButton
                      onClick={() => history.push(reverse(routes.jobs.tab, {id: cost.job.id, tab: 'costs'}))}
                      disabled={engineer.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no costs associated with this engineer</Table.NoRecords>
        )}
      </Table>
    </>
  );
};

export default Costs;
