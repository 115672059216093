import {DateField, DateTimeField, Panes, RadioGroup, Select, TextField, TimeRangeSelector} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {prefixWithSeparator} from '@management-ui/core';
import {useEngineers} from '../../../../../hooks';

export default function Fields({allocation = {}, slotOffer = false, prefix = '', multiplePanes = true, children}) {
  const {/** @type {function} */watch} = useFormContext();

  const type = watch(`${prefixWithSeparator(prefix)}type`);

  const engineers = useEngineers();

  const handleReset = useCallback((subject) => {
    if (!subject.id) {
      subject.type = 'allocation';
    }
    if (subject.engineer) {
      subject.engineer_id = subject.engineer.id;
      delete subject.engineer;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={allocation}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        ...(allocation.id || slotOffer ? [] : [
          {
            title: 'What',
            fields: [
              <RadioGroup
                name="type"
                prefix={prefix}
                options={[
                  {title: 'Allocation', value: 'allocation'},
                  {title: 'Quick Allocation', value: 'quick'}
                ]}
                label="Engineer"
              />,
              <TextField
                name="description"
                prefix={prefix}
                label="Description"
                rules={type !== 'quick' ? {} : {required: 'Please enter a description'}}
                fieldProps={{
                  multiline: true,
                  rows: 5,
                  disabled: type !== 'quick'
                }}
              />
            ]
          },
        ]),
        {
          title: 'When',
          fields: [
            <DateField
              name="date"
              prefix={prefix}
              label="Select a date"
              rules={{required: 'Please select a date'}}
            />,
            <TimeRangeSelector
              fromName="start_at"
              toName="end_at"
              prefix={prefix}
              label="Select a time range"
            />,
            ...(allocation.id || slotOffer ? [] : [
              <DateTimeField
                name="expires_at"
                prefix={prefix}
                label="Select an expiry date and time"
                fieldProps={{
                  disabled: type !== 'quick'
                }}
                rules={type === 'quick' ? {required: 'Please select an expiry date and time'} : {}}
              />,
            ])
          ]
        },
        {
          title: 'Who',
          fields: [
            <Select
              name="engineer_id"
              prefix={prefix}
              options={engineers}
              label="Engineer"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Allocation'}
    >{children}</Panes>
  );
}
