import {address, Address, DetailColumns, DetailPane, name} from '@management-ui/core';
import {reverse} from 'named-urls';
import numeral from 'numeral';
import React from 'react';
import routes from '../../../../routes';

const JobPanes = ({job}) => (
  <DetailColumns columns={[

    <DetailPane
      title="Job Products"
      details={[
        {title: 'Reference', value: job?.reference ?? '-'},
        {
          title: 'Company',
          value: job?.company ? job.company.name : '-',
          route: job?.company ? reverse(routes.companies.detail, {id: job.company.id}) : null
        },
        {
          title: 'Contact',
          value: job?.contact ? name(job.contact) : '-',
          route: job?.contact ? reverse(routes.contacts.detail, {id: job.contact.id}) : null
        },
        {title: 'Description', value: job?.description ?? '-'}
      ]}
    />,


    <DetailPane
      title="Location"
      details={[
        {title: 'Address', value: job ? <Address address={address(job)}/> : '-'},
        {title: 'Latitude', value: job?.latitude ? numeral(job.latitude).format('0,0.00000') : '-'},
        {title: 'Longitude', value: job?.longitude ? numeral(job.longitude).format('0,0.00000') : '-'},
      ]}
    />,

  ]}/>
);

export default JobPanes;
