import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import Emails from '../OutboundMessages/Table';
import Management from './Management';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({order, setOrder, loadOrder, loading, setLoading, showLinkJob, setShowLinkJob}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Details',
      slug: '',
      path: reverse(routes.orders.detail, {
        id: order?.id ?? 0,
        tab: ''
      })
    },
    {
      title: 'Emails',
      slug: 'emails',
      path: reverse(routes.orders.tab, {
        id: order?.id ?? 0,
        tab: 'emails'
      })
    }
  ], [order]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Management {...{order, setOrder, loadOrder, loading, setLoading, showLinkJob, setShowLinkJob}}/>
      </Switched>
      <Switched tab="emails" currentTab={tab}>
        <Container>
          <Emails order={order}/>
        </Container>
      </Switched>
    </>
  );
};

export default Tabs;
