import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const LinkContactForm = forwardRef(({contact, company = null, onSave = null, onSaved = null, endUser = false}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={contact}
      type="contact"
      fieldsComponent={(props) => <Fields {...props} company={company}/>}
      onSave={onSave ? onSave : ({contact_id}) => services.company.linkContact(company, {id: contact_id}, endUser)}
      onSaved={onSaved}
    />
  );
});

export default LinkContactForm;
