import {Box, Paper} from '@mui/material';
import React, {useRef} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import LocationForm from '../../forms/LocationForm';
import CompanyLocations from './CompanyLocations';

const NewCompanyLocation = ({onBack, onNext, session}) => {
  /** @type {({current: LocationForm})} */
  const formRef = useRef();
  return (
    <>
      <Header>Create a New Location</Header>
      <Paper component={Box} padding={2}>
        <LocationForm ref={formRef} type="company" location={{}} parent={session.selectedCompany} onSaved={() => onNext(
          {type: CompanyLocations.type, id: CompanyLocations.type}
        )}/>
      </Paper>
      <Controls
        onBack={() => onBack({type: CompanyLocations.type, id: CompanyLocations.type})}
        onNext={() => formRef.current.save()}
        nextLabel="Save New Location"
      />
    </>
  );
};

NewCompanyLocation.type = 'NEW_COMPANY_LOCATION';

export default NewCompanyLocation;
