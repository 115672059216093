import {address, ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Add, Delete, Edit} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import Text from '../../../../components/Text';
import YesNo from '../../../../components/YesNo';
import LocationForm from '../../forms/LocationForm';

const Locations = ({contact, onContactUpdated}) => {
  const services = useContext(ServiceContext);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.contact.deleteLocation(contact, selected).then(updated => {
        setLoading(false);
        onContactUpdated(updated);
      }).catch(() => setLoading(false));
    }
  }, [services, contact, selected, onContactUpdated]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Locations</h3>
          <Tooltip title="Add New Location">
            <span>
              <IconButton
                onClick={() => setSelected(null) || setShowEdit(true)}
                disabled={contact.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Name</Table.Header>
          <Table.Header>Billing?</Table.Header>
          <Table.Header>Type</Table.Header>
          <Table.Header>Address</Table.Header>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {contact.locations.length > 0 ? (
          contact.locations.map((location, index) => (
            <Table.Row key={index}>
              <Table.Column>{location.name?.label ?? '-'}</Table.Column>
              <Table.Column><YesNo value={location.is_billing}/></Table.Column>
              <Table.Column>{location.type?.name ?? ''}</Table.Column>
              <Table.Column>
                <Text text={address(location).join('\n')}/>
              </Table.Column>
              <Table.Action>
                <Tooltip title="Edit Location">
                  <span>
                    <IconButton
                      onClick={() => setSelected(location) || setShowEdit(true)}
                      disabled={contact.archived}>
                      <Edit/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Delete Location">
                  <span>
                    <IconButton
                      onClick={() => setSelected(location) || setShowDelete(true)}
                      disabled={contact.archived}>
                      <Delete/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          <Table.NoRecords>There are no locations associated with this contact</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title={selected ? 'Edit Location' : 'Add New Location'}
        open={showEdit}
        maxWidth="md"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <LocationForm
            {...props}
            type="contact"
            parent={contact}
            location={selected ?? {}}
            onSaved={(updated) => setShowEdit(false) || onContactUpdated(updated)}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete Location"
        message="Are you sure you want to delete this location?"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
};

export default Locations;
