import {useEffect, useState} from 'react';

const withJobInProgress = (Slide) => {
  return (props) => {
    const {session} = props;

    const [job, setJob] = useState(null);

    useEffect(() => {
      if (session) {
        if (session.selectedCompany?.id) {
          const details = session.job ?? {};
          setJob({...details, company_id: session.selectedCompany.id});
          return;
        }
        if (session.selectedContact?.id) {
          const details = session.job ?? {};
          setJob({...details, contact_id: session.selectedContact.id});
          return;
        }
      }
      setJob({});
    }, [session]);

    return <Slide job={job} {...props}/>;
  }
};

export default withJobInProgress;
