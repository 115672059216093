import {Box, Paper} from '@mui/material';
import React, {useRef} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import LocationForm from '../../forms/LocationForm';
import ContactLocations from './ContactLocations';

const NewContactLocation = ({onBack, onNext, session}) => {
  /** @type {({current: LocationForm})} */
  const formRef = useRef();
  return (
    <>
      <Header>Create a New Location</Header>
      <Paper component={Box} padding={2}>
        <LocationForm ref={formRef} type="contact" location={{}} parent={session.selectedContact} onSaved={() => onNext(
          {type: ContactLocations.type, id: ContactLocations.type}
        )}/>
      </Paper>
      <Controls
        onBack={() => onBack({type: ContactLocations.type, id: ContactLocations.type})}
        onNext={() => formRef.current.save()}
        nextLabel="Save New Location"
      />
    </>
  );
};

NewContactLocation.type = 'NEW_CONTACT_LOCATION';

export default NewContactLocation;
