import {DataTable} from '@management-ui/core';
import {Box, Paper} from '@mui/material';
import React, {useContext, useMemo, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {truncate} from '../../../../formatters';
import CalendarForm from '../../forms/CalendarForm';

export default function Calendars({user}) {
  const services = useContext(ServiceContext);
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  return (
    <Paper width="100%" component={Box} marginTop={1}>
      <DataTable
        ref={tableRef}
        title="Calendars"
        options={{
          search: false
        }}
        columns={useMemo(() => [
          {title: 'Name', field: 'name'},
          {title: 'Type', field: 'type'},
          {title: 'URL', field: 'url', render: data => truncate(data.url)},
          {title: 'Active?', field: 'is_active', lookup: {true: 'Yes', false: 'No'}}
        ], [])}
        canRefresh={false}
        loadData={query => new Promise((resolve, reject) => {
          services.user.getTableCalendars(query, {user: user.id})
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => {
            reject();
          });
        })}
        newForm={user.archived ? null : {
          title: 'Add New Calendar',
          render: (props) => <CalendarForm calendar={{}} user={user} {...props}/>,
          onSaved: () => tableRef.current.refresh()
        }}
        editForm={user.archived ? null : {
          render: (calendar, props) => (
            <CalendarForm {...props} calendar={calendar} user={user} onSaved={() => tableRef.current.refresh()}/>
          ),
          onSaved: () => {
          }
        }}
      />
    </Paper>
  );
}
