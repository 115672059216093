
export const compareDateFormat = 'YYYY-MM-DD';

export const colours = [
  '#023a4b',
  '#85c7c5',
  '#60ad35',
  '#f34545',
  '#ee9536',
  '#efcf3d',
  '#d76ed2',
  '#8d58d9',
];
