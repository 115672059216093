import {Paper as MUIPaper} from '@mui/material';
import {styled} from '@mui/material';
import React from 'react';
import Details from './Details';

const Container = styled(MUIPaper)(({theme}) => ({
  '&.MuiPaper-root': {
    display: 'flex',
    overflow: 'hidden',
    margin: theme.spacing(2, 0, 0),

    '& > div': {
      display: 'flex',
      flex: '0 0 50%',
      width: '50%',

      '&:first-of-type': {
        backgroundColor: theme.palette.grey['100'],
      }
    }
  }
}));

const Paper = ({details, children}) => (
  <Container>
    <div>
      <Details details={details}/>
    </div>
    <div>
      {children}
    </div>
  </Container>
);

export default Paper;
