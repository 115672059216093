import {Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({title, note = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={note}
      prefix={prefix}
      panes={[
        {
          title,
          fields: [
            <TextField
              name="comments"
              prefix={prefix}
              label="Comments"
              fieldProps={{multiline: true, rows: 6}}
            />
          ]
        }
      ]}
      title={multiplePanes ? null : title}
    >{children}</Panes>
  );
}
