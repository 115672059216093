import {ConfirmationDialog} from '@management-ui/core';
import {Box, Menu, MenuItem} from '@mui/material';
import moment from 'moment';
import React, {useCallback, useState} from 'react';
import BlockedList from '../../components/BlockedList';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import JobPanes from './JobPanes';
import JobProducts from './JobProducts';
import LinkJobVehicle from './LinkJobVehicle';
import NewJobVehicle from './NewJobVehicle';
import withJob from './withJob';

const Slide = ({job, onNext, goBack, updateSession, onFinish}) => {
  const [confirm, setConfirm] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleBack = useCallback((confirmed) => {
    setConfirm(false);
    if (confirmed) {
      goBack();
    }
  }, [goBack]);

  return (
    <>
      <Header>Job Vehicles</Header>

      <JobPanes job={job}/>

      <Box marginTop={2}>
        {job ? <BlockedList
          blocks={job.vehicles}
          onSelect={(selected, event) => {
            if (Object.keys(selected).length < 1) {
              setMenuAnchor(event.currentTarget);
              setShowNew(true);
            }
          }}
          layout="wide"
          newProps={{}}
          onRenderItem={(vehicle) => (
            vehicle ? (
              <>
                <p>{vehicle.registration}</p>
                {vehicle.make || vehicle.model ? <span>{vehicle.make} {vehicle.model}</span> : null}
                {vehicle.colour ? <span>{vehicle.colour}</span> : null}
                {vehicle.manufactured_on ? <span>Year: {moment(vehicle.manufactured_on).format('YYYY')}</span> : null}
                {vehicle.vin ? <p>VIN: {vehicle.vin}</p> : null}
              </>
            ) : <><p/><p>Add Vehicle</p></>
          )}
        /> : null}
      </Box>
      <Controls
        onBack={() => setConfirm(true)}
        onNext={() => onNext({type: JobProducts.type, id: JobProducts.type})}
        onFinish={() => onFinish(updateSession, job)}
      />
      <Menu
        anchorEl={menuAnchor}
        open={showNew}
        onClose={() => setShowNew(false)}
      >
        <MenuItem onClick={() => setShowNew(false) || onNext({type: NewJobVehicle.type, id: NewJobVehicle.type})}>
          Add a New Vehicle
        </MenuItem>
        <MenuItem onClick={() => setShowNew(false) || onNext({type: LinkJobVehicle.type, id: LinkJobVehicle.type})}>
          Link an Existing Vehicle
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={confirm}
        title="Back to Start"
        message="Are you sure you want to go back? This will reset the form to create a new job."
        onClose={handleBack}
      />
    </>
  );
};

const JobVehicles = withJob(Slide);

JobVehicles.type = 'JOB_VEHICLES';

export default JobVehicles;
