import {FormDialog, SquareButton} from '@management-ui/core';
import {Add, Remove, SwapHoriz} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import AllocateStockForm from '../../../forms/AllocateStockForm';
import StockItemForm from '../../../forms/StockItemForm';
import SwapProductForm from '../../../forms/SwapProductForm';
import Item from './Item';

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  return {
    container: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: border,
      borderTop: border,
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
      width: '100%',

      '&:last-child': {
        borderBottom: 'none',
      }
    },

    name: {
      alignItems: 'center',
      color: theme.palette.text.primary,
      display: 'flex',
      flex: 1,
      fontSize: '1.1em',
      fontWeight: '600',
      padding: theme.spacing(1),
      textDecoration: 'none',
      transition: '0.25s background-color ease-in-out',

      '&:hover': {
        backgroundColor: theme.palette.grey['100'],
      }
    },

    action: {
      alignItems: 'center',
      borderLeft: border,
      borderRight: border,
      cursor: 'pointer',
      display: 'flex',
      fontSize: '1.1em',
      fontWeight: '600',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },

    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },

    swap: {
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }
    },

    new: {
      color: theme.palette.success.main,

      '&:hover': {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
      }
    },

    existing: {
      color: theme.palette.warning.main,

      '&:hover': {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
      }
    },

    button: {
      alignItems: 'center',
      borderLeft: border,
      display: 'flex',
      flex: '0 0 40px',
      justifyContent: 'center',
      width: 40
    },

    quantity: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey['100'],
      borderLeft: border,
      display: 'flex',
      flex: '0 0 80px',
      justifyContent: 'center',
      padding: theme.spacing(2),
      width: 80,
    },

    items: {
      backgroundColor: theme.palette.grey['100'],
      display: 'flex',
      flex: '0 0 100%',
      flexDirection: 'column',
      width: '100%',
    },

    allocateButton: {
      '&.MuiButton-root': {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white,

        '&:hover': {
          backgroundColor: theme.palette.info.dark,
        },
      }
    },
  }
});

const Product = ({job, product, onJobUpdated, onLoading, onReloadJob, selectedItems, onSelectItem}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const [quantity, setQuantity] = useState(0);
  const subject$ = useRef(new BehaviorSubject(0));
  const [showSwap, setShowSwap] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showExisting, setShowExisting] = useState(false);
  const [pending, setPending] = useState(0);

  const handleUpdate = useCallback((updatedQuantity) => {
    if (updatedQuantity !== product.quantity) {
      onLoading(true);
      (updatedQuantity ?
          services.job.saveProduct(job, {id: product.id, quantity: updatedQuantity})
          :
          services.job.deleteProduct(job, {id: product.id})
      ).then((updated) => {
        onLoading(false);
        onJobUpdated(updated);
      }).catch(() => onLoading(false));
    }
  }, [services, job, product, onJobUpdated, onLoading]);

  useEffect(() => {
    const subscription = subject$.current
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => handleUpdate(value));
    return () => {
      subscription.unsubscribe();
    };
  }, [services, handleUpdate]);

  useEffect(() => {
    setQuantity(product.quantity);
    setPending(product.quantity - product.items.filter(i => !!i.id).length);
  }, [product]);
  useEffect(() => subject$.current.next(quantity), [quantity]);

  const handleIncrement = useCallback((increment) => {
    const update = quantity + increment
    setQuantity(update >= 0 ? update : 0);
  }, [quantity]);

  const handleSwap = useCallback((alternativeID) => new Promise(async (resolve, reject) => {
    onLoading(true);
    for (let item of product.items) {
      await services.stockItem.saveItem({id: item.id, product_id: alternativeID});
    }
    onJobUpdated(
      await services.job.saveProduct(job, {id: product.id, product_id: alternativeID, quantity: product.quantity})
    );
    onLoading(false);
    setShowSwap(false);
  }), [services, onLoading, onJobUpdated, job, product]);

  const handleAllocated = useCallback(() => {
    setShowNew(false);
    setShowExisting(false);
    onReloadJob();
  }, [onReloadJob]);

  return (
    <>
      <div className={classes.container}>
        <Link
          className={classes.name}
          to={reverse(routes[product.product.archived ? 'archive' : 'stock'].products.detail, {id: product.product.id})}
        >{product.product.name}</Link>
        {product.alternatives.length > 0 ? (
          <div className={[classes.action, classes.swap].join(' ')}
               onClick={() => setShowSwap(true)}>
            <SwapHoriz/>Swap
          </div>
        ) : null}
        <div className={[classes.action, classes.new, pending > 0 && !job.archived ? '' : classes.disabled].join(' ')}
             onClick={() => setShowNew(true)}>
          <Add/>New
        </div>
        <div
          className={[classes.action, classes.existing, pending > 0 && !job.archived ? '' : classes.disabled].join(' ')}
          onClick={() => setShowExisting(true)}>
          <Add/>Existing
        </div>
        <div className={classes.button}>
          <SquareButton
            small={true}
            disabled={job.archived}
            tooltip="Decrease"
            icon={<Remove/>}
            onClick={() => handleIncrement(-1)}
          />
        </div>
        <div className={classes.quantity}>{quantity}</div>
        <div className={classes.button}>
          <SquareButton
            small={true}
            disabled={job.archived}
            tooltip="Increase"
            icon={<Add/>}
            onClick={() => handleIncrement(1)}
          />
        </div>
        <div className={classes.items}>
          {product.items.map((item, index) => (
            <Item
              key={index}
              job={job}
              product={product}
              item={item}
              onLoading={onLoading}
              onReloadJob={onReloadJob}
              selectedItems={selectedItems}
              onSelect={onSelectItem}
            />
          ))}
        </div>
      </div>
      <FormDialog
        open={showSwap}
        title="Swap Product"
        maxWidth="sm"
        render={props => (
          <SwapProductForm
            product={product}
            onSave={handleSwap}
            {...props}
          />
        )}
        onClose={() => setShowSwap(false)}
      />
      <FormDialog
        open={showNew}
        onClose={() => setShowNew(false)}
        title={useMemo(() => `Allocate New ${product.product.name}`, [product])}
        render={(props) => (
          <StockItemForm
            item={{
              quantity: quantity - product.items.filter(i => !!i.id).length,
              product_id: product.product.id,
              purchase_price: product.product.purchase_price,
              sale_price: product.product.sale_price,
              supplier_id: product.product.manufacturer?.is_supplier ? product.product.manufacturer.id : '',
              job_id: job.id
            }}
            onSaved={handleAllocated}
            {...props}
          />
        )}
      />
      <FormDialog
        open={showExisting}
        onClose={() => setShowExisting(false)}
        fullScreen={true}
        title={useMemo(() => `Allocate Available ${product.product.name}`, [product])}
        additionalButtons={job.location ? [{
          label: `Allocate and Transfer to ${job.location.name}`,
          classes: classes.allocateButton,
          onClick: (formRef) => formRef.current.save(job.location.id)
        }] : []}
        buttons={{save: 'Allocate'}}
        render={(props) => (
          <AllocateStockForm
            job={job}
            product={product.product}
            quantity={pending}
            onAllocated={handleAllocated}
            {...props}
          />
        )}
      />
    </>
  );
};

export default Product;
