import {Box, Paper} from '@mui/material';
import React, {useRef} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import LinkVehicleForm from '../../forms/LinkVehicleForm';
import JobVehicles from './JobVehicles';

const LinkJobVehicle = ({onBack, onNext, session}) => {
  /** @type {({current: LinkVehicleForm})} */
  const formRef = useRef();
  return (
    <>
      <Header>Link an Existing Vehicle</Header>
      <Paper component={Box} padding={2}>
        <LinkVehicleForm ref={formRef} vehicle={{}} job={session.selectedJob} toJob={true} onSaved={() => onNext(
          {type: JobVehicles.type, id: JobVehicles.type}
        )}/>
      </Paper>
      <Controls
        onBack={() => onBack({type: JobVehicles.type, id: JobVehicles.type})}
        onNext={() => formRef.current.save()}
        nextLabel="Link Vehicle"
      />
    </>
  );
};

LinkJobVehicle.type = 'LINK_JOB_VEHICLE';

export default LinkJobVehicle;
