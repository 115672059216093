import {reverse} from 'named-urls';
import qs from 'qs';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import routes from '../../../../routes';
import SlidingForm from '../../components/SlidingForm';
import CompanyLocations from './CompanyLocations';
import ContactLocations from './ContactLocations';
import JobAllocations from './JobAllocations';
import JobDetails from './JobDetails';
import JobLocation from './JobLocation';
import JobProducts from './JobProducts';
import JobVehicles from './JobVehicles';
import LinkJobVehicle from './LinkJobVehicle';
import NewCompany from './NewCompany';
import NewCompanyLocation from './NewCompanyLocation';
import NewContact from './NewContact';
import NewContactLocation from './NewContactLocation';
import NewJobAllocation from './NewJobAllocation';
import NewJobProduct from './NewJobProduct';
import NewJobVehicle from './NewJobVehicle';
import SelectCompany from './SelectCompany';
import SelectContact from './SelectContact';
import When from './When';
import Who from './Who';

const DEFAULT_SESSION = {initialised: false, data: null};

const NewJob = () => {
  const [session, setSession] = useState({...DEFAULT_SESSION});
  const {pathname} = useLocation();
  const history = useHistory();

  useEffect(() => {
    const updated = {...DEFAULT_SESSION, initialised: true};
    if (window.location.search) {
      const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
      if (params.company) {
        updated.data = {selectedCompany: params.company};
      }
      if (params.contact) {
        updated.data = {selectedContact: params.contact};
      }
      if (updated.data) {
        updated.data.slide = JobDetails.type;
        history.replace(pathname);
      } else if (params.when) {
        if (params.when === 'select') {
          updated.data = {slide: When.type};
        } else {
          updated.data = {slide: Who.type, selectedAllocation: params.when};
        }
        history.replace(pathname);
      } else if (params.who) {
        updated.data = {slide: Who.type};
        history.replace(pathname);
      }
    }
    setSession(updated);
  }, [pathname, history]);

  return session.initialised ? (
    <SlidingForm
      title="Create a New Job"
      sessionKey="newJob"
      initialSession={session.data}
      onFinish={(updateSession, job) => {
        updateSession(null);
        history.push(job ? reverse(routes.jobs.detail, {id: job.id}) : routes.jobs.index);
      }}
      slides={[
        When,
        Who,
        NewCompany,
        CompanyLocations,
        NewCompanyLocation,
        SelectCompany,
        NewContact,
        ContactLocations,
        NewContactLocation,
        SelectContact,
        JobDetails,
        JobLocation,
        JobVehicles,
        NewJobVehicle,
        LinkJobVehicle,
        JobProducts,
        NewJobProduct,
        JobAllocations,
        NewJobAllocation
      ]}
    />
  ) : null;
}

export default NewJob;
