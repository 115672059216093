import {Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

export default function Fields({settings = {}, prefix = '', multiplePanes = true, children}) {
  const locations = useOptions('stockLocations', useCallback((list) => list.map(location => ({
    value: location.id,
    title: location.name
  })), []));

  return (
    <Panes
      entity={settings}
      prefix={prefix}
      panes={[
        {
          title: 'General Settings',
          fields: [
            <TextField
              name="inbound"
              prefix={prefix}
              label="Inbound Email Address"
            />,
            <TextField
              name="subscriptionRecipients"
              prefix={prefix}
              label="Subscription Email Recipients"
            />,
            <TextField
              name="taxRate"
              prefix={prefix}
              label="Tax Rate (VAT as a decimal e.g 0.2)"
            />,
            <TextField
              name="confirmationURL"
              prefix={prefix}
              label="Full Confirm Details URL (use {{REFERENCE}} to indicate where the reference should go)"
            />,
            <TextField
              name="shortConfirmationURL"
              prefix={prefix}
              label="Short Confirm Details URL (use {{REFERENCE}} to indicate where the reference should go)"
            />,
            <TextField
              name="generalRecipients"
              prefix={prefix}
              label="Recipients of general information check emails (comma-separated list)"
            />,
            <TextField
              name="trackingURL"
              prefix={prefix}
              label="Safe Track Tracking URL"
            />,
            <TextField
              name="orderRecipients"
              prefix={prefix}
              label="Recipients of website order emails (comma-separated list)"
            />,
            <TextField
              name="confirmationRecipients"
              prefix={prefix}
              label="Recipients of confirmation emails (comma-separated list)"
            />,
            <TextField
              name="stripeRecipients"
              prefix={prefix}
              label="Recipients of Stripe notifications (comma-separated list)"
            />
          ]
        },
        {
          title: 'Stock Settings',
          fields: [
            <Select
              name="defaultStockLocation"
              prefix={prefix}
              options={locations}
              label="Default Stock Location"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Settings'}
    >{children}</Panes>
  );
}
