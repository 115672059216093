import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({delivery = {}, prefix = ''}) => {
  return (
    <Panes
      entity={delivery}
      prefix={prefix}
      panes={[
        {
          title: 'Tracking',
          fields: [
            <TextField
              name="number"
              prefix={prefix}
              label="Do you have a tracking number?"
            />,
          ]
        }
      ]}
      title={null}
    />
  );
};

export default Fields;
