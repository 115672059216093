import {DataTable, fullDateTime, name} from '@management-ui/core';
import {reverse} from 'named-urls';
import qs from 'qs';
import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import AllocationSummary from '../../components/AllocationSummary';

export default function Table({company, contact, archive = false}) {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToNew = useCallback(() => {
    let search = null;
    if (company) {
      const {id, name} = company;
      search = {company: {id, name}};
    }
    if (contact) {
      const {id, first_name, last_name} = contact;
      search = {contact: {id, first_name, last_name}};
    }
    history.push({pathname: routes.jobs.new, search: search ? `?${qs.stringify(search)}` : '?who=1'});
  }, [history, company, contact]);

  const goToDetail = useCallback((job) => {
    if (job.id) {
      history.push(reverse(`${archive ? routes.archive.jobs.detail : routes.jobs.detail}`, {id: job.id}));
    }
  }, [history, archive]);

  return (
    <DataTable
      title="Jobs"
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 280px)'
      }}
      columns={useMemo(() => [
        {title: 'Reference', field: 'reference', render: data => data.reference},
        {title: 'Summary', field: 'summary', render: data => data.summary},
        ...(company || contact ? [] : [{
          title: 'Customer',
          field: 'customer',
          sorting: false,
          render: data => data.company ? data.company.name : data.contact ? name(data.contact) : '-'
        }]),
        {
          title: 'First Allocation',
          field: 'first_allocation',
          render: data => <AllocationSummary allocation={data.first_allocation}/>
        },
        {title: 'Date Added', field: 'created_at', render: data => fullDateTime(data.created_at)},
        {title: 'Last Updated', field: 'updated_at', render: data => fullDateTime(data.updated_at)},
        ...(archive ? [{
          title: 'Archived',
          field: 'deleted_at',
          render: data => data.deleted_at ? fullDateTime(data.deleted_at) : 'Job Deleted'
        }] : [])
      ], [archive, company, contact])}
      loadData={query => new Promise((resolve, reject) => {
        const filters = archive ? {archive: 'only'} : {};
        if (company) {
          filters.company = company.id;
        }
        if (contact) {
          filters.contact = contact.id
        }
        services.job.getTableJobs(query, filters)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      actions={{
        after: archive ? [] : [{
          icon: 'add',
          tooltip: 'Add New Job',
          isFreeAction: true,
          onClick: goToNew
        }]
      }}
      onRowClick={goToDetail}
    />
  );
}
