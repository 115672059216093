import {styled} from '@mui/material';
import React from 'react';

const Container = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 0, 0),

  '& > h3': {
    fontSize: '1.6em',
    margin: 0,
    padding: theme.spacing(0, 1),
  }
}));

const Section = ({title, children}) => (
  <Container>
    <h3>{title}</h3>
    {children}
  </Container>
);

export default Section;
