import {Add, Link} from '@mui/icons-material';
import {styled} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from './Button';

const Container = styled('ul')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  margin: 0,
  padding: theme.spacing(1, 0),
  transition: '0.25s opacity ease-in-out',

  '&.loading': {
    opacity: 0.5,
    pointerEvents: 'none',
  }
}));

const Block = styled('li')(({theme}) => ({
  display: 'flex',
  margin: 0,
  padding: theme.spacing(1)
}));

const Blocks = ({blocks, addNew, linkExisting, loading}) => {
  const history = useHistory();
  return (
    <Container className={loading ? 'loading' : ''}>
      {blocks.map(({title, icon, link}, index) => (
        <Block key={index}>
          <Button onClick={e => e.preventDefault() || link ? history.push(link) : null}>
            {icon}
            <span>{title}</span>
          </Button>
        </Block>
      ))}
      {linkExisting ? (
        <Block>
          <Button className="action" onClick={e => e.preventDefault() || linkExisting.onSelect()}>
            <Link/>
            <span>{linkExisting.title}</span>
          </Button>
        </Block>
      ) : null}
      {addNew ? (
        <Block>
          <Button className="action" onClick={e => e.preventDefault() || addNew.onSelect()}>
            <Add/>
            <span>{addNew.title}</span>
          </Button>
        </Block>
      ) : null}
    </Container>
  );
}

export default Blocks;
