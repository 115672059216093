import {name} from '@management-ui/core';
import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Suppliers from '../../components/Suppliers';

const AssociatedComponentSuppliers = ({item, onSent, children}) => {
  const services = useContext(ServiceContext);

  const handleNotify = useCallback(
    contact => (
      services.stockItem.associateItem(item.id, contact)
        .then((sent) => {
          if (onSent) {
            onSent(sent);
          }
          return Promise.resolve('The associated serial number message has been sent');
        })
        .catch(() => Promise.reject('There was a problem sending the message'))
    ),
    [item, onSent, services]
  );

  return (
    <Suppliers
      type="suppliers"
      onNotify={handleNotify}
      title="Send to Association Serial Number Message"
      getMessage={(contact) => `Are you sure you want to inform ${name(contact)} that this unit's serial numbers should be associated?`}
    >
      {children}
    </Suppliers>
  )
};

export default AssociatedComponentSuppliers;
