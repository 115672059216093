import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import routes from '../../../../routes';
import Table from './Table';

const CalendarNotes = () => {
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Calendar', link: routes.calendar}, {title: 'Quick Allocations'}]}/>
      <Paper>
        <Table/>
      </Paper>
    </div>
  );
};

export default CalendarNotes;
