import * as qs from 'qs';
import {API} from './API';

export class DeliveryService {
  async getTableDeliveries(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getDeliveries(query, page, length);
  }

  async getDeliveries(query, page, length) {
    return (await API.getConnection())
      .get('deliveries', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getDelivery(id, archive = false) {
    return (await API.getConnection())
      .get(`deliveries/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveDelivery(data) {
    return (await API.getConnection())
      .post('deliveries', data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async processing(delivery) {
    return (await API.getConnection())
      .put(`deliveries/${delivery.id}/processing`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async dispatched(delivery, trackingNumber = null) {
    return (await API.getConnection())
      .put(`deliveries/${delivery.id}/dispatched`, {tracking_number: trackingNumber})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
