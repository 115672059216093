import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import When from './When';

const withJob = (Slide) => {
  return (props) => {
    const {onBack, session, setLoading, clearLoading} = props;

    const services = useContext(ServiceContext);
    const [job, setJob] = useState(null);
    const idRef = useRef('');

    const handleBack = useCallback(() => onBack({type: When.type, id: When.type}, {selectedJob: null}), [onBack])

    const loadJob = useCallback((id) => {
      setLoading(id ? 'Loading Job Details' : 'Reloading Job Details');
      services.job.getJob(id ?? idRef.current).then((retrieved) => {
        setJob(retrieved);
        clearLoading();
      }).catch(() => {
        clearLoading();
        handleBack();
      });
    }, [services, setLoading, clearLoading, handleBack]);

    useEffect(() => {
      if (session) {
        const id = session.selectedJob?.id ? `${session.selectedJob.id}` : '';
        if (id && id !== idRef.current) {
          idRef.current = id;
          loadJob(id);
        }
      }
    }, [services, session, loadJob]);

    return <Slide job={job} onJobUpdated={setJob} goBack={handleBack} onReloadJob={loadJob} {...props}/>;
  }
};

export default withJob;
