import {DateField, Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {InputAdornment} from '@mui/material';
import {useEngineers} from '../../../../hooks';

const Fields = ({cost = {}, prefix = '', children}) => {
  const engineers = useEngineers();

  const handleReset = useCallback((subject) => {
    if (subject.engineer) {
      subject.engineer_id = subject.engineer.id;
      delete subject.engineer;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={cost}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Details',
          fields: [
            <Select
              name="engineer_id"
              prefix={prefix}
              options={engineers}
              label="Engineer"
            />,
            <TextField
              name="amount"
              prefix={prefix}
              label="Amount"
              rules={{required: 'Please enter an amount'}}
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="reference"
              prefix={prefix}
              label="Reference"
            />,
            <DateField
              name="paid_at"
              prefix={prefix}
              label="Payment Date"
            />,
          ]
        },
        {
          title: 'Administration', fields: [
            <TextField
              name="notes"
              prefix={prefix}
              label="Notes"
              fieldProps={{multiline: true, rows: 3}}
            />
          ]
        }
      ]}
      title={null}
    >{children}</Panes>
  );
}

export default Fields