import {ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Add, Delete, Edit, Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import React, {useCallback, useContext, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import Text from '../../../../components/Text';
import InvoiceItemForm from '../../forms/InvoiceItemForm';

const Items = ({invoice, onInvoiceUpdated}) => {
  const services = useContext(ServiceContext);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleHide = useCallback((item) => {
    setLoading(true);
    services.invoice.saveInvoiceItem(invoice, {id: item.id, is_hidden: !item.is_hidden}).then(updated => {
      setLoading(false);
      onInvoiceUpdated(updated);
    }).catch(() => setLoading(false));
  }, [services, invoice, onInvoiceUpdated]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.invoice.deleteInvoiceItem(invoice, selected).then(updated => {
        setLoading(false);
        onInvoiceUpdated(updated);
      }).catch(() => setLoading(false));
    }
  }, [services, invoice, selected, onInvoiceUpdated]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Items</h3>
          <Tooltip title="Add New Item">
            <span>
              <IconButton
                onClick={() => setSelected(null) || setShowEdit(true)}
                disabled={!!invoice.published_at || !!invoice.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Description</Table.Header>
          <Table.Header>Unit Price</Table.Header>
          <Table.Header>Discount</Table.Header>
          <Table.Header>Quantity</Table.Header>
          <Table.Header>Total</Table.Header>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {invoice.items.length > 0 ? (
          invoice.items.map((item, index) => (
            <Table.Row key={index}>
              <Table.Column {...(item.is_hidden ? {className: 'disabled'} : {})}><Text text={item.description ?? '-'}/></Table.Column>
              <Table.Column {...(item.is_hidden ? {className: 'disabled'} : {})}><Currency amount={item.unit_price}/></Table.Column>
              <Table.Column {...(item.is_hidden ? {className: 'disabled'} : {})}><Currency amount={item.discount}/></Table.Column>
              <Table.Column {...(item.is_hidden ? {className: 'disabled'} : {})}>{item.quantity}</Table.Column>
              <Table.Column {...(item.is_hidden ? {className: 'disabled'} : {})}><Currency amount={item.total}/></Table.Column>
              <Table.Action>
                <Tooltip title={item.is_hidden ? 'Show' : 'Hide'}>
                  <span>
                    <IconButton
                      onClick={() => toggleHide(item)}
                      disabled={!!invoice.published_at || !!invoice.archived}>
                      {item.is_hidden ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Edit Item">
                  <span>
                    <IconButton
                      onClick={() => setSelected(item) || setShowEdit(true)}
                      disabled={!!invoice.published_at || !!invoice.archived}>
                      <Edit/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Delete Item">
                  <span>
                    <IconButton
                      onClick={() => setSelected(item) || setShowDelete(true)}
                      disabled={!!invoice.published_at || !!invoice.archived}>
                      <Delete/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          <Table.NoRecords>There are no items associated with this invoice</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title={selected ? 'Edit Item' : 'Add New Item'}
        open={showEdit}
        maxWidth="md"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <InvoiceItemForm
            {...props}
            invoice={invoice}
            item={selected ?? {}}
            onSaved={(updated) => setShowEdit(false) || onInvoiceUpdated(updated)}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete Item"
        message="Are you sure you want to delete this item?"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
};

export default Items;
