import {DetailColumns, DetailPane, fullDateTime, useDialogs, useErrors} from '@management-ui/core';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import StripeHolder from '../../../../components/StripeHolder';
import {useLoading} from '../../../../hooks';
import routes from '../../../../routes';
import Status from '../../components/Status';
import Details from './Details';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const Invoice = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'approve', 'void', 'deposit', 'delete']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    invoice: 'Sorry the invoice could not be accessed'
  }), []));

  const idRef = useRef('');
  const {loading, setLoading} = useLoading();
  const [invoice, setInvoice] = useState(null);
  const [contacts, setContacts] = useState([]);

  const loadInvoice = useCallback((id, force = false) => {
    if (force || idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.invoice.getInvoice(id, archive).then(retrieved => {
        setInvoice(retrieved);
        setLoading(false);

        const emailAddresses = [];
        const phoneNumbers = [];
        if (retrieved.job?.contact?.email) {
          emailAddresses.push({contact: retrieved.job.contact, type: 'email'});
        }
        if (retrieved.job?.contact?.mobile) {
          phoneNumbers.push({contact: retrieved.job.contact, type: 'sms'});
        }
        if (retrieved.job?.company?.contacts) {
          retrieved.job.company.contacts.filter(({is_end_user}) => !is_end_user).forEach(contact => {
            if (contact.email) {
              const index = emailAddresses.findIndex(({contact: c}) => c.id === contact.id);
              if (index < 0) {
                emailAddresses.push({contact, type: 'email'});
              }
            }
            if (contact.mobile) {
              const index = phoneNumbers.findIndex(({contact: c}) => c.id === contact.id);
              if (index < 0) {
                phoneNumbers.push({contact, type: 'sms'});
              }
            }
          });
        }
        setContacts([...emailAddresses, ...phoneNumbers]);

      }).catch(() => {
        toggleError('invoice', true);
        setLoading(false);
        setTimeout(() => {
          history.push(archive ? reverse(routes.archive.index, {tab: 'invoices'}) : routes.invoices.index);
        }, 3000);
      });
    }
  }, [archive, services, history, toggleError, setLoading]);

  useEffect(() => {
    if (match.params?.id) {
      loadInvoice(match.params?.id);
    }
  }, [history, match, invoice, loadInvoice]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...(archive ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs)]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [];
    if (invoice) {
      header = invoice.reference;
      trail = [
        ...(invoice.archived ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs),
        {title: header, link: ''}
      ];
    }
    setTitle(header);
    setCrumbs(trail);
  }, [invoice]);

  return invoice ? (
    <Wrapper title={title} loading={loading} job={invoice.job} crumbs={crumbs} errors={errors}>
      {invoice?.id ? (
        <StripeHolder key={invoice.account?.public} publicKey={invoice.account?.public}>
          <DetailColumns columns={[

            <Details
              invoice={invoice}
              onUpdate={setInvoice}
              onLoading={setLoading}
              openDialogs={openDialogs}
              toggleDialog={toggleDialog}
              contacts={contacts}
            />,

            <DetailPane
              title="Totals"
              details={[
                {title: 'Total (inc. Tax)', value: <Currency amount={invoice.total}/>},
                {
                  title: 'Tax',
                  value: <span><Currency amount={invoice.tax}/>&nbsp;
                    <em>({Math.round(100 * invoice.tax_rate)}%)</em></span>
                },
                {title: 'Paid', value: <Currency amount={invoice.paid}/>},
                {title: 'Outstanding', value: <Currency amount={invoice.outstanding}/>},
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <Box marginBottom={1}><Status entity={invoice}/></Box>},
                {title: 'Xero ID', value: invoice.xero_uuid ?? '-'},
                {title: 'Created', value: fullDateTime(invoice.created_at)},
                {title: 'Last Updated', value: fullDateTime(invoice.updated_at)}
              ]}
            />,
          ]}/>

          <Tabs
            invoice={invoice}
            onInvoiceUpdated={setInvoice}
            loading={loading}
            onReloadInvoice={() => loadInvoice(invoice.id, true)}
            onLoading={setLoading}
            contacts={contacts}
            toggleError={toggleError}
          />

        </StripeHolder>
      ) : null}
    </Wrapper>
  ) : null;
}

export default Invoice;
