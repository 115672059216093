import {Panes, RelationAutocomplete} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({job = {}, unattached, prefix = ''}) => {
  const services = useContext(ServiceContext);
  return (
    <Panes
      entity={job}
      prefix={prefix}
      panes={[
        {
          title: 'Job',
          fields: [
            <Box minHeight={300}>
              <RelationAutocomplete
                valueName="job_id"
                titleName="job_reference"
                prefix={prefix}
                label="Search Jobs"
                loadOptions={query => (
                  services.job.getJobs({filter: {search: query, ...(unattached ? {unattached: '1'} : {})}}, 1, 50)
                    .then(response => response.data.map(job => ({title: job.reference, value: job.id})))
                    .catch(() => [])
                )}
              />
            </Box>
          ]
        }
      ]}
      title=""
    />
  );
};

export default Fields;
