import {prefixWithSeparator, Select, TextField} from '@management-ui/core';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useOptions} from '../../../../hooks';

const withTemplatedFields = (FieldsComponent) => {
  return (props) => {
    const {prefix, onLoading} = {...props};
    const {setValue, /** @type {function} */watch} = useFormContext();

    const optionsLoading = useSelector(state => state['options'].loading);
    useEffect(() => onLoading(optionsLoading), [optionsLoading, onLoading]);

    const templates = useOptions('jobTemplates', useCallback((list) => list.map(template => ({
      value: template.id,
      title: template.name,
      description: template.description
    })), []));

    const summaryName = useMemo(() => `${prefixWithSeparator(prefix)}summary`, [prefix]);
    const descriptionName = useMemo(() => `${prefixWithSeparator(prefix)}description`, [prefix]);
    const selectedTemplate = watch(useMemo(() => `${prefixWithSeparator(prefix)}template`, [prefix]));

    useEffect(() => {
      if (selectedTemplate) {
        const template = templates.find(t => `${t.value}` === `${selectedTemplate}`);
        if (template) {
          setValue(summaryName, template.title);
          setValue(descriptionName, template.description);
        }
      }
    }, [selectedTemplate, summaryName, descriptionName, templates, setValue]);

    return <FieldsComponent fields={[
      <Select
        name="template"
        prefix={prefix}
        options={templates}
        label="Pre-populate from Template"
      />,
      <TextField
        name="summary"
        prefix={prefix}
        label="Summary"
      />,
      <TextField
        name="description"
        prefix={prefix}
        label="Description"
        fieldProps={{
          multiline: true,
          rows: 8
        }}
        rules={{required: 'Please enter a description for this job'}}
      />,
      <TextField
        name="notes"
        prefix={prefix}
        label="Notes"
        fieldProps={{multiline: true, rows: 5}}
      />,
      <TextField
        name="admin_notes"
        prefix={prefix}
        label="Admin Notes"
        fieldProps={{multiline: true, rows: 5}}
      />,
    ]} {...props} />;
  }
};

export default withTemplatedFields;
