import {FormDialog, MonthlyCalendar, name} from '@management-ui/core';
import {Box, Grid, Paper, styled} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {useOptions} from '../../../../hooks';
import {compareDateFormat} from '../../../../vars';
import DetailForm from './DetailForm';
import EngineerSelector from './EngineerSelector';
import EventDialog from './EventDialog';
import Events from './Events';
import Notes from './Notes';
import NoteDialog from './NoteDialog';

const Key = styled(Box)(({theme}) => ({

  'p': {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 600,
    margin: 0,
    padding: 0,
  },

  'ul': {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },

  'li': {
    alignItems: 'center',
    display: 'flex',
    margin: `0 0 0 ${theme.spacing(2)}`,
    padding: 0,
  }
}))


const JobAllocationForm = forwardRef((
  {
    job,
    allocation,
    onSaved,
    loading,
    onLoading,
    canEdit = true,
    additionalAvailability = [],
    detailFormProps = {}
  }
  , ref) => {
  const services = useContext(ServiceContext);

  const engineerList = useOptions('users');
  const [engineers, setEngineers] = useState([]);

  useEffect(() => {
    setEngineers([...engineerList, {
      id: 0,
      first_name: 'Unallocated',
      colour: '#bbb',
      is_engineer: true,
      is_regular: true
    }].filter(u => u.is_engineer).map(u => ({
      id: u.id,
      name: name(u),
      colour: u.colour,
      is_regular: u.is_regular
    })));
  }, [engineerList]);

  // noinspection JSUnresolvedFunction
  const [month, setMonth] = useState(null);
  const initialised = useRef(false);
  const loaded = useRef('');
  const [showDetail, setShowDetail] = useState(false);
  const [selectedEngineers, setSelectedEngineers] = useState([]);
  const [selectedAllocation, setSelectedAllocation] = useState(null);
  const [showEvent, setShowEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showNote, setShowNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const [availability, setAvailability] = useState([]);
  const [engineerNotes, setEngineerNotes] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      if (allocation?.id) {
        setMonth(moment(allocation.date).startOf('month'));
      } else {
        setMonth(moment().startOf('month'));
      }
    }
  }, [allocation]);

  const loadMonth = useCallback(month => {
    onLoading(true);
    services.job.getAvailability(
      month.clone().startOf('isoWeek'),
      month.clone().endOf('month').endOf('isoWeek')
    ).then(({events, notes}) => {
      onLoading(false);
      setAvailability(events);
      setEngineerNotes(notes);
    }).catch(() => {
      onLoading(false);
      setAvailability([]);
      setEngineerNotes([]);
    });
  }, [services, onLoading]);

  useImperativeHandle(ref, () => ({
    reload() {
      loadMonth(month);
    }
  }));

  useEffect(() => {
    if (month && month.format(compareDateFormat) !== loaded.current) {
      loaded.current = month.format(compareDateFormat);
      loadMonth(month);
    }
  }, [month, loadMonth]);

  useEffect(() => {
    const list = [];
    const generate = (block, start, end) => {
      const {engineer, title, description} = block;
      // noinspection JSUnresolvedFunction
      return {
        title,
        description,
        when: {
          date: start.clone().startOf('day'),
          start: start.clone(),
          end: end.clone(),
        },
        engineer_id: engineer.id,
        colour: engineer.colour,
        block
      };
    };
    [...availability, ...additionalAvailability]
      .filter(a => selectedEngineers.indexOf(a.engineer.id) >= 0)
      .forEach((block) => {
        // noinspection JSUnresolvedFunction
        const start = moment(block.start);
        const end = moment(block.end);
        let startTime = start.clone();
        let endTime = end.clone();
        const from = startTime.format(compareDateFormat);
        const to = endTime.format(compareDateFormat);
        if (from === to) {
          list.push(generate(block, startTime, endTime));
        } else {
          // noinspection JSUnresolvedFunction
          while (startTime.unix() < end.unix()) {
            if (startTime.format(compareDateFormat) !== from) {
              // noinspection JSUnresolvedFunction
              startTime.startOf('day');
            } else {
              startTime = start.clone();
            }
            if (endTime.format(compareDateFormat) !== to) {
              // noinspection JSUnresolvedFunction
              endTime.endOf('day');
            } else {
              endTime = end.clone();
            }
            list.push(generate(block, startTime, endTime));
            startTime.add(1, 'day');
            endTime = startTime.clone();
          }
        }
      });
    setEvents(list);
  }, [additionalAvailability, availability, selectedEngineers])

  const handleDay = useCallback((day) => {
    let selected = {
      date: day.toISOString(),
      start_at: moment().hour(9).minute(0).seconds(0).toISOString(),
      end_at: moment().hour(10).minute(0).seconds(0).toISOString()
    };
    if (allocation?.id) {
      selected = {...selected, engineer_id: allocation.engineer.id, id: allocation.id};
    }
    setSelectedAllocation(selected);
    setShowDetail(true);
  }, [allocation]);

  const handleEvent = useCallback(({block}) => {
    if (allocation?.id && block.allocation?.id && allocation.id === block.allocation.id) {
      setSelectedAllocation(allocation);
      setShowDetail(true);
    } else {
      setSelectedEvent(block);
      setShowEvent(true);
    }
  }, [allocation]);

  const handleNote = useCallback((note) => {
    setSelectedNote(note);
    setShowNote(true);
  }, []);

  const handleNoteUpdated = useCallback(() => {
    setShowNote(false);
    loadMonth(month);
  }, [month, loadMonth]);

  const handleSaved = useCallback((saved) => {
    setShowDetail(false);
    setShowEvent(false);
    if (saved) {
      onSaved(saved);
    } else {
      loadMonth(month);
    }
  }, [onSaved, month, loadMonth]);

  return month ? (
    <>
      <EngineerSelector
        engineers={engineers}
        selected={selectedEngineers}
        onChange={setSelectedEngineers}
      />
      <Key paddingX={1} marginY={2}>
        <Grid container={true} alignItems="center" justifyContent="flex-start">
          <p>Key:</p>
          <ul>
            <li><strong>B</strong>&nbsp;-&nbsp;Booking Status</li>
            <li><strong>C</strong>&nbsp;-&nbsp;Customer Notified</li>
            <li><strong>V</strong>&nbsp;-&nbsp;Vehicles</li>
            <li><strong>I</strong>&nbsp;-&nbsp;Information Checks</li>
            <li><strong>S</strong>&nbsp;-&nbsp;Stock</li>
            <li><strong>P</strong>&nbsp;-&nbsp;Invoices / Orders</li>
            <li><strong>U</strong>&nbsp;-&nbsp;Safe Track User</li>
            <li><strong>N</strong>&nbsp;-&nbsp;Admin Notes</li>
          </ul>
        </Grid>
      </Key>
      <Paper style={loading ? {opacity: 0.5} : {}}>
        <MonthlyCalendar
          month={month}
          events={events}
          onSelectMonth={setMonth}
          onSelectDay={(day) => handleDay(day.date)}
          onRenderNotes={(day) => (
            <Notes
              notes={engineerNotes}
              day={day}
              engineerIDs={selectedEngineers}
              onSelectNote={handleNote}
            />
          )}
          onRenderEvents={(day) => (
            <Events
              currentAllocation={allocation}
              engineers={engineers.filter(e => selectedEngineers.indexOf(e.id) >= 0)}
              events={day.events}
              onSelectEvent={handleEvent}
            />
          )}
        />
      </Paper>
      <FormDialog
        title={selectedAllocation?.id ? 'Update Allocation' : 'Create Allocation'}
        open={showDetail}
        onClose={() => setShowDetail(false)}
        render={(props) => <DetailForm
          {...props}
          job={job}
          allocation={selectedAllocation}
          onSaved={handleSaved}
          {...detailFormProps}
        />}
        minHeight={400}
        maxWidth="lg"
      />
      <EventDialog
        open={showEvent}
        onClose={() => setShowEvent(false)}
        onLoading={onLoading}
        job={job}
        event={selectedEvent}
        onAllocationSaved={canEdit ? handleSaved : null}
        onNoteSaved={() => handleSaved(null)}
      />
      <NoteDialog
        open={showNote}
        onClose={() => setShowNote(false)}
        onLoading={onLoading}
        note={selectedNote}
        onNoteSaved={() => handleNoteUpdated()}
      />
    </>
  ) : null;
});

export default JobAllocationForm;
