import {ConfirmationDialog, name} from '@management-ui/core';
import {Alert, Menu, MenuItem, Portal, Snackbar} from '@mui/material';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../components/Services';

const Suppliers = (
  {
    type,
    onNotify,
    title,
    getMessage,
    children
  }
) => {
  const services = useContext(ServiceContext);

  const initialised = useRef(false);
  const [loaded, setLoaded] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [showNotify, setShowNotify] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [contact, setContact] = useState(null);
  const anchorRef = useRef();
  const [showSent, setShowSent] = useState(false);
  const [message, setMessage] = useState({body: '', severity: 'success'});

  useEffect(() => {
    if (showContacts && !initialised.current) {
      initialised.current = true;
      setLoaded(false);
      const filter = {};
      filter[type] = '1';
      services.contact.getContacts({filter}, 1, 50)
        .then(response => {
          setContacts(response.data);
          setLoaded(true);
        })
        .catch(() => {
          setContacts([]);
          setLoaded(true);
        });
    }
  }, [type, services, showContacts]);

  const handleNotify = useCallback(confirmed => {
    setShowNotify(false);
    if (confirmed) {
      onNotify(contact)
        .then((success) => setMessage({body: success, severity: 'success'}) || setShowSent(true))
        .catch((error) => setMessage({body: error, severity: 'error'}) || setShowSent(true));
    }
  }, [onNotify, contact]);

  return (
    <>
      {children(() => setShowContacts(true), anchorRef)}
      <Menu
        anchorEl={() => anchorRef.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={showContacts}
        onClose={() => setShowContacts(false)}
      >
        {loaded ? (contacts.length ? (
          contacts.map((c, index) => (
            <MenuItem
              key={index}
              onClick={() => setContact(c) || setShowContacts(false) || setShowNotify(true)}>
              {name(c)} ({c.email})
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled={true}>
            There are no {type} in the system
          </MenuItem>
        )) : (
          <MenuItem disabled={true}>
            Loading...
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDialog
        title={title}
        message={contact ? getMessage(contact) : ''}
        open={showNotify}
        onClose={handleNotify}
      />
      <Portal>
        <Snackbar open={showSent} autoHideDuration={4000} onClose={() => setShowSent(false)}>
          <Alert onClose={() => setShowSent(false)} severity={message.severity}>
            {message.body}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  )
};

export default Suppliers;
