import {ConfirmationDialog, DetailPane, FormDialog, fullDate, name} from '@management-ui/core';
import {MailOutline, RemoveCircle, VolumeOff, VolumeUp} from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import PaymentIcon from '@mui/icons-material/Payment';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SendIcon from '@mui/icons-material/Send';
import {Alert, Link, Menu, MenuItem, Snackbar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useStripe} from '@stripe/react-stripe-js';
import {reverse} from 'named-urls';
import * as React from 'react';
import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import LinkJobForm from '../../forms/LinkJobForm';
import SubscriptionForm from '../../forms/SubscriptionForm';
import SubscriptionSuppliers from './SubscriptionSuppliers';

const useStyles = makeStyles(() => ({
  holder: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }
}));

const Details = ({subscription, onUpdate, onLoading, openDialogs, toggleDialog}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const stripe = useStripe();
  const history = useHistory();
  const [actions, setActions] = useState([]);
  const [showContacts, setShowContacts] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [showReminders, setShowReminders] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState(null);
  const [showSent, setShowSent] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    if (subscription) {
      const recipients = [];
      if (subscription.job?.contact?.email) {
        recipients.push(subscription.job.contact);
      }
      if (subscription.job?.company?.contacts) {
        subscription.job.company.contacts.filter(c => !!c.email).forEach(contact => {
          const index = recipients.findIndex(c => c.id === contact.id);
          if (index < 0) {
            recipients.push(contact);
          }
        });
      }
      setContacts(recipients);
    }
  }, [subscription]);

  const handleShouldSend = useCallback((event) => {
    setMenuAnchor(event.currentTarget);
    setShowContacts(true);
  }, []);

  const handleStart = useCallback(() => {
    onLoading(true);
    const url = `${window.location.origin}${window.location.pathname}`;
    services.subscription.startSubscription(subscription, url, `${url}?cancelled=1`).then((response) => {
      onLoading(false);
      stripe.redirectToCheckout({
        sessionId: response.session
      }).then((result) => {
        return Promise.reject(result.error.message);
      }).catch(() => null);
    }).catch(() => onLoading(false));
  }, [services, stripe, subscription, onLoading]);

  const handleNotify = useCallback(confirmed => {
    setShowNotify(false);
    if (confirmed) {
      services.subscription.requestActivation(subscription, contact).then(() => {
        setShowSent(true);
      }).catch(() => null);
    }
  }, [services, subscription, contact]);

  const handleReminders = useCallback(confirmed => {
    setShowReminders(false);
    if (confirmed) {
      onLoading(true);
      services.subscription.toggleReminders(subscription).then((updated) => {
        onUpdate(updated);
        onLoading(false);
      }).catch(() => onLoading(false));
    }
  }, [services, subscription, onUpdate, onLoading]);

  const handleLink = useCallback(({job_id}) => {
    setShowLink(false);
    return new Promise(resolve => {
      if (job_id) {
        onLoading(true);
        services.subscription.saveSubscription({id: subscription.id, job_id: job_id}).then((updated) => {
          onLoading(false);
          onUpdate(updated);
          resolve();
        }).catch(() => {
          onLoading(false);
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, [services, onLoading, subscription, onUpdate]);

  useEffect(() => {
    let list = [];
    if (!subscription.published_at) {
      list = [
        ...list,
        {title: 'Edit Subscription', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
      ];
      if (subscription.items.length > 0) {
        list.push({
          title: 'Approve Subscription',
          icon: <PriceCheckIcon/>,
          onClick: () => toggleDialog('approve', true)
        });
      }
      if (!subscription.archived) {
        list.push({title: 'Delete Subscription', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)});
      }
    } else if (subscription.account) {
      if (!subscription.completed_at && !subscription.stripe_reference) {
        list.push({title: 'Start Subscription (Stripe)', icon: <PaymentIcon/>, onClick: handleStart});
        if (contacts.length > 0) {
          list.push({title: 'Send', icon: <SendIcon/>, onClick: handleShouldSend});
        }
      }
      if (subscription.stripe_reference && !subscription.cancelled_at) {
        list.push({title: 'Cancel Subscription', icon: <CancelIcon/>, onClick: () => toggleDialog('cancel', true)});
      }
      if (!subscription.is_recurring && subscription.completed_at) {
        list.push({
          title: `${subscription.silenced_at ? 'Reactivate' : 'Silence'} Reminders`,
          icon: subscription.silenced_at ? <VolumeUp/> : <VolumeOff/>,
          onClick: () => setShowReminders(true)
        });
      }
      list.push({
        title: 'Request removal',
        tooltipPlacement: 'top',
        icon: (
          <SubscriptionSuppliers
            subscription={subscription}
            onSent={(updated) => onUpdate(updated)}
          >{(onDisplay, anchorRef) => (
            <span className={classes.holder} ref={anchorRef} onClick={onDisplay}><MailOutline/></span>
          )}</SubscriptionSuppliers>
        ),
        onClick: () => null
      });
      if ((!subscription.is_recurring && subscription.completed_at) || (subscription.is_recurring && subscription.cancelled_at)) {
        if (!subscription.removed_at) {
          list.push({
            title: 'Mark as Removed',
            icon: <RemoveCircle/>,
            onClick: () => toggleDialog('remove', true)
          });
        }
      }
    }
    if (!subscription.job) {
      list.push({title: 'Link to a Job', icon: <LinkIcon/>, onClick: () => setShowLink(true)});
    }
    setActions(list);
  }, [subscription, onUpdate, toggleDialog, classes, handleStart, contacts, handleShouldSend]);

  return (
    <>
      <DetailPane
        title="Subscription Details"
        actions={actions}
        details={[
          {title: 'Type', value: subscription.is_recurring ? 'Monthly Payments' : 'Single Payment'},
          ...(subscription.job ? [{
            title: 'Job',
            value: subscription.job.reference,
            route: reverse(routes.jobs.tab, {id: subscription.job.id, tab: 'invoicing'})
          }] : []),
          ...(subscription.company ? [{
            title: 'Company',
            value: subscription.company.name,
            route: reverse(routes.companies.tab, {id: subscription.company.id, tab: 'invoicing'})
          }] : []),
          ...(subscription.contact ? [{
            title: 'Contact',
            value: name(subscription.contact),
            route: reverse(routes.contacts.tab, {id: subscription.contact.id, tab: 'invoicing'})
          }] : []),
          {title: 'Account', value: subscription.account?.name ?? '-'},
          {title: 'Reference', value: subscription.reference},
          {title: 'Date', value: subscription.published_at ? fullDate(subscription.published_at) : '-'},
          {
            title: 'Payment Link',
            value: subscription.link ? 'Click here to view' : '-',
            link: subscription.link ?? null
          },
          ...(subscription.source ? [{
            title: 'Renewed From',
            value: subscription.source.reference,
            route: reverse(routes.subscriptions.detail, {id: subscription.source.id})
          }] : []),
          ...(subscription.renewals.length > 0 ? [{
            title: 'Renewals',
            value: subscription.renewals.map(({id, reference}, index) => (
              <Fragment key={index}>
                {index > 0 ? <>&nbsp;&nbsp;</> : null}
                <Link component={RouterLink} to={reverse(routes.subscriptions.detail, {id})}>
                  {reference}
                </Link>
              </Fragment>
            )),
          }] : []),
        ]}
        dialogs={[
          (props) => (
            <FormDialog
              {...props}
              open={openDialogs.edit ?? false}
              title="Edit Subscription"
              onClose={() => toggleDialog('edit', false)}
              maxWidth="sm"
              render={(props) => (
                <SubscriptionForm
                  {...props}
                  job={subscription.job}
                  subscription={subscription}
                  onSaved={(saved) => toggleDialog('edit', false) || onUpdate(saved)}
                />
              )}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={openDialogs.delete ?? false}
              title="Delete"
              message={subscription ? 'Are you sure you want to delete this subscription?' : ''}
              onClose={confirmed => {
                toggleDialog('delete', false);
                if (confirmed) {
                  onLoading(true);
                  services.subscription.deleteSubscription(subscription).then(() => {
                    onLoading(false);
                    history.push(reverse(`${routes.jobs.detail}`, {id: subscription.job.id}))
                  }).catch(() => onLoading(false));
                }
              }}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={openDialogs['approve'] ?? false}
              title="Approve"
              message={subscription ? 'Are you sure you want to approve this subscription? You will no longer be able to edit it.' : ''}
              onClose={confirmed => {
                toggleDialog('approve', false);
                if (confirmed) {
                  onLoading(true);
                  services.subscription.publishSubscription(subscription).then((updated) => {
                    onLoading(false);
                    onUpdate(updated);
                  }).catch(() => onLoading(false));
                }
              }}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={showNotify}
              title="Send to Customer"
              message={contact ? `Are you sure you want to send this subscription to ${contact.email}?` : ''}
              onClose={handleNotify}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={showReminders}
              title={`${subscription.silenced_at ? 'Reactivate' : 'Silence'} Reminders`}
              message={`Are you sure you want to ${subscription.silenced_at ? 'reactivate' : 'silence'} reminders for this subscription?`}
              onClose={handleReminders}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={openDialogs['cancel'] ?? false}
              title="Cancel"
              message={subscription ? 'Are you sure you want to cancel this subscription? This action can not be reversed.' : ''}
              onClose={confirmed => {
                toggleDialog('cancel', false);
                if (confirmed) {
                  onLoading(true);
                  services.subscription.cancelSubscription(subscription).then((updated) => {
                    onLoading(false);
                    onUpdate(updated);
                  }).catch(() => onLoading(false));
                }
              }}
            />
          ),
          (props) => (
            <ConfirmationDialog
              {...props}
              open={openDialogs['remove'] ?? false}
              title="Mark as Removed"
              message={subscription ? 'Are you sure you want to mark this subscription as removed?' : ''}
              onClose={confirmed => {
                toggleDialog('remove', false);
                if (confirmed) {
                  onLoading(true);
                  services.subscription.removeSubscription(subscription).then((updated) => {
                    onLoading(false);
                    onUpdate(updated);
                  }).catch(() => onLoading(false));
                }
              }}
            />
          ),
          (props) => (
            <FormDialog
              {...props}
              title="Link to a Job"
              open={showLink}
              maxWidth="sm"
              onClose={() => setShowLink(false)}
              render={(props) => (
                <LinkJobForm
                  {...props}
                  job={{}}
                  unattached={false}
                  onSave={handleLink}
                />
              )}
            />
          )
        ]}
      />
      <Menu
        anchorEl={menuAnchor}
        open={showContacts}
        onClose={() => setShowContacts(false)}
      >
        {contacts.map((c, index) => (
          <MenuItem key={index} onClick={() => setContact(c) || setShowContacts(false) || setShowNotify(true)}>
            {name(c)} ({c.email})
          </MenuItem>
        ))}
      </Menu>
      <Snackbar open={showSent} autoHideDuration={4000} onClose={() => setShowSent(false)}>
        <Alert onClose={() => setShowSent(false)} severity="success">
          The subscription has been sent
        </Alert>
      </Snackbar>
    </>
  );
};

export default Details;
