import {combineReducers} from 'redux';

import auth from './auth';
import options from './options';
import settings from './settings';

export default combineReducers({
  auth,
  options,
  settings,
});
