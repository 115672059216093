import {ColouredLabel, ConfirmationDialog, FormDialog, SquareButton} from '@management-ui/core';
import {MailOutline} from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {IconButton, Link, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import {vehicleTitle} from '../../../../../formatters';
import routes from '../../../../../routes';
import LinkVehicleForm from '../../../forms/LinkVehicleForm';
import AssociatedComponentSuppliers from '../../StockItem/AssociatedComponentSuppliers';

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  return {
    container: {
      display: 'flex',
      borderTop: border,
    },

    select: {
      alignItems: 'center',
      display: 'flex',
    },

    column: {
      display: 'flex',
      borderRight: border,
      alignItems: 'center',
      flex: 1,
      padding: theme.spacing(1),
    },

    clickColumn: {
      cursor: 'pointer',
    },

    last: {
      borderRight: 'none',
    },

    action: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 39px',
      width: 39,
    },

    checkedOut: {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      fontWeight: '700'
    },

    pending: {
      color: theme.palette.warning.main,
      fontStyle: 'italic',
      fontWeight: '700'
    },

    associate: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },

    serial: {
      '& i': {
        fontSize: '0.9em',
      },
    },

    noSerial: {
      '& span': {
        color: theme.palette.grey['500'],
      }
    }
  };
})

const Item = ({job, item, onLoading, onReloadJob, selectedItems, onSelect}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const [columns, setColumns] = useState([{value: '', styles: []}]);
  const [selected, setSelected] = useState(null);
  const [showLink, setShowLink] = useState(false);
  const [showRelease, setShowRelease] = useState(false);

  const handleRelease = useCallback((confirmed) => {
    if (confirmed) {
      onLoading(true);
      services.stockItem.saveItem({id: selected.id, job_id: null, vehicle_id: null}).then(() => {
        onLoading(false);
        onReloadJob();
      }).catch(() => onLoading(false));
    }
    setShowRelease(false);
  }, [services, onLoading, onReloadJob, selected]);

  const handleLink = useCallback((vehicle) => {
    onLoading(true);
    return services.stockItem.saveItem({id: selected.id, vehicle_id: vehicle}).then(() => {
      onLoading(false);
      onReloadJob();
      setShowLink(false);
    }).catch(() => {
      onLoading(false);
      setShowLink(false);
    });
  }, [services, onLoading, onReloadJob, selected]);

  useEffect(() => {
    if (item.id) {
      let location = 'Checked out';
      if (item.location) {
        location = (
          <Link
            component={RouterLink}
            underline="hover"
            to={reverse(routes[item.location.archived ? 'archive' : 'stock'].locations.detail, {id: item.location.id})}
            color="secondary"
          >{item.location.name}</Link>
        );
        if (item.location.users.length > 0 && item.location.users[0].colour) {
          location = <ColouredLabel colour={item.location.users[0].colour} label={location}/>;
        }
      }
      setColumns([
        {
          value: (
            <>
              <Link
                component={RouterLink}
                underline="hover"
                to={reverse(routes[item.archived ? 'archive' : 'stock'].items.detail, {id: item.id})}
                color="secondary"
              >
                <span>{item.serial_number ? item.serial_number : 'No Serial Number'}</span>
                {item.associated_serial_number ? (
                  <>
                    <br/>
                    <i>Component Serial: {item.associated_serial_number}</i>
                  </>
                ) : null}
              </Link>
              {item.associated_serial_number ? (
                <div className={classes.associate}>
                  <AssociatedComponentSuppliers item={item}>{(onDisplay, anchorRef) => (
                    <Tooltip title="Send email to supplier to associate these serial numbers">
                      <span ref={anchorRef}>
                        <IconButton onClick={onDisplay}>
                          <MailOutline/>
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}</AssociatedComponentSuppliers>
                </div>
              ) : null}
            </>
          ),
          styles: [classes.serial, ...(item.serial_number ? [] : [classes.noSerial])]
        },
        {
          value: location,
          styles: item.location ? [] : [classes.checkedOut, classes.last]
        },
        {
          value: item.vehicle ? vehicleTitle(item.vehicle) : '-',
          styles: [],
          onClick: () => {
            setSelected(item);
            setShowLink(true);
          }
        }
      ]);
    } else {
      setColumns([{value: 'Pending', styles: [classes.pending, classes.last]}]);
    }
  }, [classes, item]);

  return (
    <div className={classes.container}>
      <div className={classes.select}>
        {item.id && item.location ? (
          <SquareButton
            small={true}
            icon={selectedItems.indexOf(item.id) >= 0 ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
            onClick={() => onSelect(item.id)}
          />
        ) : null}
      </div>
      {columns.map(({value, styles, onClick = null}, index) => (
        <div
          key={index}
          className={[classes.column, ...(onClick ? [classes.clickColumn] : []), ...styles].join(' ')}
          onClick={onClick ? onClick : () => null}
        >{value}</div>
      ))}
      <div className={classes.action}>
        {item.id && item.location ? (
          <SquareButton
            small={true}
            tooltip="Release"
            icon={<IndeterminateCheckBoxIcon/>}
            onClick={() => setSelected(item) || setShowRelease(true)}
          />
        ) : null}
      </div>
      <FormDialog
        open={showLink}
        title="Link Stock Item to Vehicle"
        maxWidth="sm"
        render={props => (
          <LinkVehicleForm
            vehicle={{id: `${selected?.vehicle?.id ?? ''}`}}
            toJob={false}
            job={job}
            onSave={handleLink}
            {...props}
          />
        )}
        onClose={() => setShowLink(false)}
      />
      <ConfirmationDialog
        open={showRelease}
        onClose={handleRelease}
        title="Release Stock Item"
        message="Are you sure you want to release this stock item from this job?"
      />
    </div>
  );
};

export default Item;
