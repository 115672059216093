import {styled} from '@mui/material';

const Pill = styled('span')(({theme}) => ({
  backgroundColor: theme.palette.info.main,
  borderRadius: theme.spacing(2),
  color: theme.palette.common.white,
  display: 'inline-block',
  margin: theme.spacing(1, 1, 0, 0),
  padding: theme.spacing(0.5, 1),
  textTransform: 'capitalize',

  '&.paned': {
    margin: theme.spacing(0, 0, 1)
  },

  '&.internal': {
    backgroundColor: theme.palette.success.main,
  },

  '&.external': {
    backgroundColor: theme.palette.warning.main,
  }
}));

export default Pill;
