import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import * as React from 'react';
import routes from '../../../../routes';
import Products from './Products';
import StockItems from './StockItems';
import StockLocations from './StockLocations';
import VirtualProducts from './VirtualProducts';

const TABS = [
  {
    id: 'products',
    slug: 'products',
    title: 'Products',
    icon: <VideoLabelIcon/>,
    render: (props) => <Products {...props} />
  },
  {
    id: 'locations',
    slug: 'locations',
    title: 'Locations',
    icon: <GpsFixedIcon/>,
    render: (props) => <StockLocations {...props} />
  },
  {
    id: 'items',
    slug: 'items',
    title: 'Items',
    icon: <FormatListBulletedIcon/>,
    render: (props) => <StockItems {...props} />
  },
  {
    id: 'virtualProducts',
    slug: 'virtual-products',
    title: 'Virtual Products',
    icon: <SubscriptionsIcon/>,
    render: (props) => <VirtualProducts {...props} />
  },
];

export default function Stock() {
  const {tab, handleTab} = useTab(TABS, routes.stock.index);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Stock'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
