import {DataTable, fullDateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import ReferrerForm from '../../forms/ReferrerForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((referrer) => {
    history.push(reverse(`${archive ? routes.archive.referrers.detail : routes.referrers.detail}`, {id: referrer.id}));
  }, [history, archive]);

  return (
    <DataTable
      title="Referrers"
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 260px)'
      }}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Date Added', field: 'created_at', render: data => fullDateTime(data.created_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => fullDateTime(data.deleted_at)}] : [])
      ], [archive])}
      loadData={query => new Promise((resolve, reject) => {
        services.referrer.getTableReferrers(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={{
        title: 'Add New Referrer',
        render: (props) => <ReferrerForm referrer={{}} {...props}/>,
        onSaved: goToDetail
      }}
    />
  );
};

export default Table;
