import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';
import moment from 'moment';

const CostForm = forwardRef(({job, cost, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={cost}
      type="cost"
      fieldsComponent={Fields}
      onSave={useCallback(({engineer_id, amount, reference, notes, paid_at}) => (
        services.cost.saveCost({
          ...(cost?.id ? {id: cost.id} : {}),
          engineer_id,
          job_id: job.id,
          amount,
          reference,
          notes,
          paid_at: paid_at ? moment(paid_at).format('YYYY-MM-DD') : null,
        })
      ), [services, cost, job])}
      onSaved={onSaved}
    />
  );
});

export default CostForm;
