import {Breadcrumbs, ErrorPopup, Title} from '@management-ui/core';
import {reverse} from 'named-urls';
import React from 'react';
import routes from '../../../../routes';


const Wrapper = ({title, loading, titleControls, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading} controls={titleControls}/>
      {children}
      <ErrorPopup errors={['account']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Developer', link: routes.developer.index},
  {title: 'Gateway Accounts', link: reverse(routes.developer.index, {tab: 'gateway-accounts'})}
];

export default Wrapper;
