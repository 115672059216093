import {API} from './API';

export class EngineerAvailabilityNoteService {

  async saveNote(data) {
    let method = 'post';
    let url = 'engineer-availability-notes';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteNote(note) {
    return (await API.getConnection())
      .delete(`engineer-availability-notes/${note.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }
}
