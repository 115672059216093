import {DataTable, fullDateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import VirtualProductForm from '../../forms/VirtualProductForm';

export default function VirtualProducts({title = 'Virtual Products', canCreate = true, archive = false}) {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((product) => {
    history.push(reverse(`${archive ? routes.archive.virtualProducts.detail : routes.stock.virtualProducts.detail}`, {id: product.id}));
  }, [history, archive]);

  return (
    <DataTable
      title={title}
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 360px)'
      }}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Reference', field: 'reference'},
        {
          title: 'Xero Codes',
          field: 'xero_account_code',
          render: data => `${data.xero_account_code ?? '-'}${data.xero_item_code ? ` (${data.xero_item_code})` : ''}`
        },
        {title: 'Price', field: 'price', render: data => <Currency amount={data.price}/>},
        {title: 'Cost', field: 'cost', render: data => data.cost ? <Currency amount={data.cost}/> : '-'},
        {title: 'Length', field: 'length'},
        {title: 'Product', field: 'product', render: data => data.product?.name ?? '-', sorting: false},
        {title: 'Date Added', field: 'created_at', render: data => fullDateTime(data.created_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => fullDateTime(data.deleted_at)}] : [])
      ], [archive])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.virtualProduct.getTableProducts(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, archive])}
      onRowClick={goToDetail}
      newForm={archive || !canCreate ? null : {
        title: 'Add New Virtual Product',
        render: (props) => <VirtualProductForm {...props} product={{}}/>,
        onSaved: goToDetail
      }}
    />
  );
}
