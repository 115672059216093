export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_INSTALLERS = 'SET_INSTALLERS';
export const SET_LOCATION_NAMES = 'SET_LOCATION_NAMES';
export const SET_LOCATION_TYPES = 'SET_LOCATION_TYPES';
export const SET_MANUFACTURERS = 'SET_MANUFACTURERS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_VIRTUAL_PRODUCTS = 'SET_VIRTUAL_PRODUCTS';
export const SET_STOCK_LOCATIONS = 'SET_STOCK_LOCATIONS';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_USERS = 'SET_USERS_OPTIONS';
export const SET_JOB_TEMPLATES = 'SET_JOB_TEMPLATES';
export const SET_GATEWAY_ACCOUNTS = 'SET_GATEWAY_ACCOUNTS';
export const SET_BATTERY_TYPES = 'SET_BATTERY_TYPES';
export const SET_FUEL_TYPES = 'SET_FUEL_TYPES';
export const SET_VEHICLE_TYPES = 'SET_VEHICLE_TYPES';
export const SET_LOADING = 'SET_OPTIONS_LOADING';

const options = (
  state = {
    batteryTypes: [],
    companies: [],
    contacts: [],
    countries: [],
    fuelTypes: [],
    gatewayAccounts: [],
    jobTemplates: [],
    installers: [],
    locationNames: [],
    locationTypes: [],
    manufacturers: [],
    products: [],
    vehicleTypes: [],
    virtualProducts: [],
    stockLocations: [],
    suppliers: [],
    users: [],
    loadingCount: 0,
    loading: false
  },
  action,
) => {
  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.companies
      };
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries
      };
    case SET_INSTALLERS:
      return {
        ...state,
        installers: action.installers
      };
    case SET_LOCATION_NAMES:
      return {
        ...state,
        locationNames: action.names
      };
    case SET_LOCATION_TYPES:
      return {
        ...state,
        locationTypes: action.types
      };
    case SET_MANUFACTURERS:
      return {
        ...state,
        manufacturers: action.manufacturers
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products
      };
    case SET_VIRTUAL_PRODUCTS:
      return {
        ...state,
        virtualProducts: action.products
      };
    case SET_STOCK_LOCATIONS:
      return {
        ...state,
        stockLocations: action.locations
      };
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.suppliers
      };
    case SET_USERS:
      return {
        ...state,
        users: action.users
      };
    case SET_JOB_TEMPLATES:
      return {
        ...state,
        jobTemplates: action.templates
      };
    case SET_GATEWAY_ACCOUNTS:
      return {
        ...state,
        gatewayAccounts: action.accounts
      };
    case SET_BATTERY_TYPES:
      return {
        ...state,
        batteryTypes: action.types
      };
    case SET_FUEL_TYPES:
      return {
        ...state,
        fuelTypes: action.types
      };
    case SET_VEHICLE_TYPES:
      return {
        ...state,
        vehicleTypes: action.types
      };
    case SET_LOADING:
      const count = state.loadingCount + (action.loading ? 1 : -1);
      return {
        ...state,
        loadingCount: count,
        loading: count > 0
      };
    default:
  }
  return state;
};

export default options;
