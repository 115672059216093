import {Paper} from '@mui/material';
import React from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import Table from '../Contacts/Table';
import ContactLocations from './ContactLocations';
import NewContact from './NewContact';
import Who from './Who';

const SelectContact = ({onBack, onNext}) => {
  return (
    <>
      <Header>Select an Existing Contact</Header>
      <Paper>
        <Table
          title=""
          onSelect={({id, first_name, last_name}) => onNext(
            {type: ContactLocations.type, id: ContactLocations.type},
            {selectedContact: {id, first_name, last_name}}
          )}
          canCreate={false}
        />
      </Paper>
      <Controls
        onBack={() => onBack({type: Who.type, id: Who.type})}
        onNext={() => onNext({type: NewContact.type, id: NewContact.type})}
        nextLabel="Create a New Contact"
      />
    </>
  );
};

SelectContact.type = 'SELECT_CONTACT';

export default SelectContact;
