import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';
import routes from '../../../../routes';

const Referrers = () => {
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Orders', link: routes.orders.index}, {title: 'Referrers'}]}/>
      <Paper>
        <Table/>
      </Paper>
    </div>
  );
};

export default Referrers;
