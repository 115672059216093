import {FormDialog, fullDate, time} from '@management-ui/core';
import {Delete, Send} from '@mui/icons-material';
import {Box, IconButton, Paper, styled, Tooltip} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import {useOptions} from '../../../../hooks';
import EngineerLabel from '../../components/EngineerLabel';
import JobAllocationForm from '../../forms/JobAllocationForm';
import OfferDateForm from '../../forms/OfferDateForm';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Offer = ({request, onRequestUpdated, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const [dates, setDates] = useState([]);
  const engineers = useOptions('users');
  const [showOffer, setShowOffer] = useState(false);

  const addDate = useCallback((date) => {
    setDates([
      ...dates,
      {
        title: 'Pending Date',
        description: request.description,
        date: date.date,
        start: date.start_at,
        end: date.end_at,
        engineer: engineers.find(e => `${e.id}` === `${date.engineer_id}`),
        type: 'pending'
      }
    ])
  }, [request, dates, engineers]);

  const handleOffer = useCallback((expires, response) => {
    return new Promise((resolve, reject) => {
      setShowOffer(false);
      if (expires) {
        onLoading(true);
        services.dateRequest.offerDates(
          request.id,
          `${moment(expires).format('YYYY-MM-DD HH:mm')}:00`,
          dates.map(({date, start, end, engineer}) => ({
            date: moment(date).format('YYYY-MM-DD'),
            start_at: moment(start).format('HH:mm:ss'),
            end_at: moment(end).format('HH:mm:ss'),
            engineer_id: engineer ? engineer.id : null
          })),
          response
        )
          .then((offered) => {
            onLoading(false);
            onRequestUpdated(offered);
            resolve();
          }).catch(() => {
          onLoading(false);
          reject();
        });
      } else {
        reject();
      }
    });
  }, [request, onRequestUpdated, onLoading, services, dates]);

  const handleRemove = useCallback((index) => {
    const updated = [...dates];
    updated.splice(index, 1);
    setDates(updated);
  }, [dates])

  return (
    <>
      <Container>
        <Table className={loading ? 'loading' : ''}>
          <Table.Actions>
            <h3>Selected Dates</h3>
            {dates.length > 0 ? (
              <Tooltip title="Offer to Customer">
                <span>
                  <IconButton
                    onClick={() => setShowOffer(true)}
                    disabled={request.archived}>
                    <Send/>
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
          </Table.Actions>
          <Table.Row>
            <Table.Header>Engineer</Table.Header>
            <Table.Header>Date</Table.Header>
            <Table.Header>Time</Table.Header>
            <Table.Action/>
          </Table.Row>
          {dates.length > 0 ? (
            dates.map((date, index) => (
              <Table.Row key={index}>
                <Table.Column>
                  {date.engineer ? <EngineerLabel engineer={date.engineer}/> : 'Unallocated'}
                </Table.Column>
                <Table.Column>{fullDate(date.date)}</Table.Column>
                <Table.Column>{time(date.start)} - {time(date.end)}</Table.Column>
                <Table.Action>
                  <Tooltip title="Remove Date">
                  <span>
                    <IconButton onClick={() => handleRemove(index)}>
                      <Delete/>
                    </IconButton>
                  </span>
                  </Tooltip>
                </Table.Action>
              </Table.Row>
            ))
          ) : (
            <Table.NoRecords>Use the calendar below to select dates to offer to the customer</Table.NoRecords>
          )}
        </Table>
      </Container>
      <Box marginTop={2}>
        <JobAllocationForm
          allocation={{}}
          job={{}}
          detailFormProps={{
            slotOffer: true,
          }}
          additionalAvailability={dates}
          onSaved={addDate}
          canEdit={false}
          loading={loading}
          onLoading={onLoading}
        />
      </Box>
      <FormDialog
        title="Offer Dates"
        open={showOffer}
        maxWidth="xl"
        onClose={() => setShowOffer(false)}
        render={(props) => (
          <OfferDateForm
            {...props}
            request={request}
            onOffer={handleOffer}
          />
        )}
      />
    </>
  );
}

export default Offer;
