import {Checkbox, Panes, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

export default function Fields({product = {}, prefix = '', multiplePanes = true, children}) {
  const manufacturers = useOptions('manufacturers', useCallback((list) => list.map(type => ({
    value: type.id,
    title: type.name
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.manufacturer) {
      subject.manufacturer_id = subject.manufacturer.id;
      delete subject.manufacturer;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={product}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="code"
              prefix={prefix}
              label="Product Code"
            />,
            <TextField
              name="sku"
              prefix={prefix}
              label="SKU"
            />,
            <TextField
              name="purchase_price"
              prefix={prefix}
              label="Purchase Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="sale_price"
              prefix={prefix}
              label="Sale Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <Select
              name="manufacturer_id"
              prefix={prefix}
              options={manufacturers}
              label="Manufacturer"
              rules={{required: 'Please select a manufacturer'}}
            />,
            <TextField
              name="review_url"
              prefix={prefix}
              label="Review Link"
            />,
          ]
        }, {
          title: 'Product Flags',
          fields: [
            <Checkbox
              name="is_scorpion"
              prefix={prefix}
              label="Is this a scorpion product?"
            />,
            <Checkbox
              name="is_safe_track"
              prefix={prefix}
              label="Is this a Safe Track product?"
            />,
            <Checkbox
              name="is_fleet"
              prefix={prefix}
              label="Is this a fleet product?"
            />,
            <Checkbox
              name="has_tags"
              prefix={prefix}
              label="Does this product have driver ID tags (Scorpion or Safe Track only)?"
            />,
            <Checkbox
              name="has_immobiliser"
              prefix={prefix}
              label="Can this product immobilise the vehicle (Scorpion or Safe Track only)?"
            />,
            <Checkbox
              name="is_hidden_on_invoices"
              prefix={prefix}
              label="Should this product be hidden by default on invoices?"
            />,
            <Checkbox
              name="requires_certificate"
              prefix={prefix}
              label="Does this product require a certificate?"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Details'}
    >{children}</Panes>
  );
}
