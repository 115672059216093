import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ReviewProviderForm from '../../forms/ReviewProviderForm';

const ReviewProviders = () => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Review Providers"
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Link', field: 'url'}
      ], [])}
      loadData={query => new Promise((resolve, reject) => {
        services.reviewProvider.getTableProviders(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Provider',
        render: (props) => <ReviewProviderForm provider={{}} {...props}/>
      }}
      editForm={{
        render: (country, props) => <ReviewProviderForm provider={country} {...props}/>
      }}
    />
  );
}

export default ReviewProviders;
