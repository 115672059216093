import {fullDateTime} from '@management-ui/core';
import React from 'react';
import Currency from '../../../../components/Currency';
import Status from '../Status';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  reference: {title: 'Reference', render: (data) => data.reference},
  status: {title: 'Status', render: (data) => <Status entity={data}/>},
  published_at: {title: 'Published', render: (data) => data.published_at ? fullDateTime(data.published_at) : '-'},
  total: {title: 'Total', render: (data) => <Currency amount={data.total ?? 0}/>},
  tax: {title: 'Tax', render: (data) => <Currency amount={data.tax ?? 0}/>},
  tax_rate: {title: 'Tax Rate', render: (data) => `${(data.tax_rate ?? 0) * 100}%`}
};

export default function SubscriptionUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
