import {Box, Paper} from '@mui/material';
import React, {useRef} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import JobDetailsForm from '../../forms/JobDetailsForm';
import JobLocation from './JobLocation';
import SelectedCustomer from './SelectedCustomer';
import Who from './Who';
import withJobInProgress from './withJobInProgress';

const Slide = ({job, onNext, onBack, session}) => {
  /** @type {({current: JobDetailsForm})} */
  const formRef = useRef();

  return (
    <>
      <Header>Job Details</Header>
      <SelectedCustomer session={session}/>
      <Paper component={Box} padding={2}>
        {job ? (
          <JobDetailsForm
            ref={formRef}
            job={job}
            onSaved={(details) => onNext({type: JobLocation.type, id: JobLocation.type}, {job: details})}
          />
        ) : null}
      </Paper>
      <Controls
        onBack={() => onBack({type: Who.type, id: Who.type}, {selectedCompany: null, selectedContact: null, job: null})}
        onNext={() => formRef.current.save()}
        nextLabel="Add Job Location"
      />
    </>
  );
};

const JobDetails = withJobInProgress(Slide);

JobDetails.type = 'JOB_DETAILS';

export default JobDetails;
