import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import {Reviews, WorkOutline} from '@mui/icons-material';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from 'react';
import routes from '../../../../routes';
import BatteryTypes from './BatteryTypes';
import Countries from './Countries';
import FuelTypes from './FuelTypes';
import JobTemplates from './JobTemplates';
import LocationNames from './LocationNames';
import LocationTypes from './LocationTypes';
import Settings from './Settings';
import VehicleTypes from './VehicleTypes';
import ReviewProviders from './ReviewProviders';

const TABS = [
  {
    id: 'settings',
    slug: 'settings',
    title: 'Settings',
    icon: <SettingsIcon/>,
    render: (props) => <Settings {...props} />
  },
  {
    id: 'countries',
    slug: 'countries',
    title: 'Countries',
    icon: <PublicIcon/>,
    render: (props) => <Countries {...props} />
  },
  {
    id: 'locationNames',
    slug: 'location-names',
    title: 'Location Names',
    icon: <EditLocationIcon/>,
    render: (props) => <LocationNames {...props} />
  },
  {
    id: 'locationTypes',
    slug: 'location-types',
    title: 'Location Types',
    icon: <NotListedLocationIcon/>,
    render: (props) => <LocationTypes {...props} />
  },
  {
    id: 'jobTemplates',
    slug: 'job-templates',
    title: 'Job Templates',
    icon: <WorkOutline/>,
    render: (props) => <JobTemplates {...props} />
  },
  {
    id: 'vehicleTypes',
    slug: 'vehicle-types',
    title: 'Vehicle Types',
    icon: <DirectionsCarIcon/>,
    render: (props) => <VehicleTypes {...props} />
  },
  {
    id: 'fuelTypes',
    slug: 'fuel-types',
    title: 'Fuel Types',
    icon: <LocalGasStationIcon/>,
    render: (props) => <FuelTypes {...props} />
  },
  {
    id: 'batteryTypes',
    slug: 'battery-types',
    title: 'Battery Types',
    icon: <BatteryCharging90Icon/>,
    render: (props) => <BatteryTypes {...props} />
  },
  {
    id: 'reviewProviders',
    slug: 'review-providers',
    title: 'Review Providers',
    icon: <Reviews/>,
    render: (props) => <ReviewProviders {...props} />
  },
];

export default function Management() {
  const {tab, handleTab} = useTab(TABS, routes.management);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Management'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
