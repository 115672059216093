import * as qs from 'qs';
import {clearCompanies} from '../store/actions/options';
import {API} from './API';

export class CompanyService {
  async getTableCompanies(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getCompanies(query, page, length);
  }

  async getCompanies(query, page, length) {
    return (await API.getConnection())
      .get('companies', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getCompany(id, archive = false) {
    return (await API.getConnection())
      .get(`companies/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveCompany(company) {
    let data = company;
    let method = 'post';
    let url = 'companies';
    if (company.id) {
      method = 'put';
      url = `companies/${company.id}`;
    } else if (!data.company) {
      data = {company};
    } else {
      if (data.contact && Object.keys(data.contact).filter(key => !!data.contact[key]).length < 1) {
        delete data.contact;
      }
      if (data.location && Object.keys(data.location).filter(key => !!data.location[key]).length < 1) {
        delete data.location;
      }
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearCompanies());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async deleteCompany(id) {
    return (await API.getConnection())
      .delete(`companies/${id}`)
      .then(response => {
        API.store.dispatch(clearCompanies());
        return response;
      })
      .catch(error => API.handleError(error));
  }

  async restoreCompany(id) {
    return (await API.getConnection())
      .put(`companies/${id}/restore`)
      .then(response => {
        API.store.dispatch(clearCompanies());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async linkContact(company, contact, endUser = false) {
    return (await API.getConnection())
      .put(`companies/${company.id}/contact`, {contact_id: contact.id, is_end_user: endUser})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getCompanyActivity(id, query, page, length) {
    return (await API.getConnection())
      .get(`companies/${id}/activity`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async saveComment(id, comment, page = 1, length = 5) {
    return (await API.getConnection())
      .post(`companies/${id}/comment`, {comment}, {
        params: {page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getLocations(id) {
    return (await API.getConnection())
      .get(`companies/${id}/locations`)
      .then(response => response.data.data)
      .catch(API.handleError);
  }

  async saveLocation(company, data) {
    let method = 'post';
    let url = `companies/${company.id}/locations`;
    if (data.id) {
      method = 'put';
      url = `companies/${company.id}/locations/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteLocation(company, location) {
    return (await API.getConnection())
      .delete(`companies/${company.id}/locations/${location.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }
}
