import {Box, Paper} from '@mui/material';
import React, {useRef} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import ContactForm from '../../forms/ContactForm';
import ContactLocations from './ContactLocations';
import Who from './Who';

const NewContact = ({onBack, onNext}) => {
  /** @type {({current: ContactForm})} */
  const formRef = useRef();
  return (
    <>
      <Header>Create a New Contact</Header>
      <Paper component={Box} padding={2}>
        <ContactForm ref={formRef} contact={{}} onSaved={(saved) => onNext(
          {type: ContactLocations.type, id: ContactLocations.type},
          {selectedContact: {id: saved.id, name: saved.name}}
        )}/>
      </Paper>
      <Controls
        onBack={() => onBack({type: Who.type, id: Who.type})}
        onNext={() => formRef.current.save()}
        nextLabel="Save New Contact"
      />
    </>
  );
};

NewContact.type = 'NEW_CONTACT';

export default NewContact;
