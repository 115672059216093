import * as qs from 'qs';
import {clearStockLocations} from '../store/actions/options';
import {API} from './API';

export class StockLocationService {
  async getTableLocations(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getLocations(query, page, length);
  }

  async getLocations(query, page, length) {
    return (await API.getConnection())
      .get('stock-locations', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getSummaries(product) {
    return (await API.getConnection())
      .get('stock-locations/summaries', {
        params: {product: product.id},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data['summaries'])
      .catch(error => API.handleError(error));
  }

  async getLocation(id, archive = false) {
    return (await API.getConnection())
      .get(`stock-locations/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveLocation(location) {
    let data = location;
    let method = 'post';
    let url = 'stock-locations';
    if (location.id) {
      method = 'put';
      url = `stock-locations/${location.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearStockLocations());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async deleteLocation(id) {
    return (await API.getConnection())
      .delete(`stock-locations/${id}`)
      .then(response => {
        API.store.dispatch(clearStockLocations());
        return response;
      })
      .catch(error => API.handleError(error));
  }

  async restoreLocation(id) {
    return (await API.getConnection())
      .put(`stock-locations/${id}/restore`)
      .then(response => {
        API.store.dispatch(clearStockLocations());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }
}
