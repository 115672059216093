export const SET_SETTINGS = 'SET_SETTINGS';

const settings = (
  state = {
    settings: null
  },
  action,
) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.settings
      };
    default:
  }
  return state;
};

export default settings;
