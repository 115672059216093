import React from 'react';
import CompanyFields from '../CompanyForm/Fields';
import ContactFields from '../ContactForm/Fields';
import {CompanyFields as LocationFields} from '../LocationForm/Fields';

export default function Fields({entities: {company, contact, location}}) {
  return (
    <>
      <CompanyFields
        company={company}
        prefix="company"
        multiplePanes={false}/>
      <ContactFields
        contact={contact}
        validate={false}
        prefix="contact"
        multiplePanes={false}/>
      <LocationFields
        location={location}
        prefix="location"
        multiplePanes={false}/>
    </>
  );
}
