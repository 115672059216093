import {
  address,
  Address,
  ConfirmationDialog,
  DetailPane,
  FormDialog,
  fullDate,
  fullDateTime,
  name,
  time
} from '@management-ui/core';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment/moment';
import {reverse} from 'named-urls';
import qs from 'qs';
import React, {useCallback, useContext, useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import CalendarNoteForm from '../CalendarNoteForm';
import DetailForm from './DetailForm';

const useStyles = makeStyles((theme) => ({
  title: {
    alignItems: 'center',
    display: 'flex',
  },

  engineer: {
    fontSize: '1.2em',
    margin: 0,
    padding: 0,
  },

  colour: {
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
    flex: `0 0 ${theme.spacing(2)}`,
    marginRight: theme.spacing(2),
    width: theme.spacing(2),
  }
}));

const EventDialog = ({open, onClose, onLoading, job, event, onAllocationSaved, onNoteSaved}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const history = useHistory();
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const [convertNote, setConvertNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [deleteNote, setDeleteNote] = useState(false);

  const handleConvertNote = useCallback((confirmed) => {
    setConvertNote(false);
    if (confirmed) {
      const formattedDate = moment(event.note.date).format('YYYY-MM-DD');
      history.push({
        pathname: routes.jobs.new, search: `?${qs.stringify({
          when: {
            d: formattedDate,
            s: `${formattedDate} ${moment(event.note.start_at).format('HH:mm')}`,
            e: `${formattedDate} ${moment(event.note.end_at).format('HH:mm')}`,
            id: event.note.engineer?.id,
            n: event.note.id
          }
        })}`
      });
    }
  }, [event, history]);

  const handleDeleteNote = useCallback((confirmed) => {
    setDeleteNote(false);
    if (confirmed) {
      onLoading(true);
      services.calendarNote.deleteNote(event.note).then(() => {
        onLoading(false);
        onNoteSaved(null);
      }).catch(() => onLoading(false));
    }
  }, [onLoading, event, onNoteSaved, services]);

  return event ? (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{style: {backgroundColor: theme.palette.background.default}}}
      >
        <DialogTitle classes={{root: classes.title}}>
          <span className={classes.colour} style={{backgroundColor: event.engineer.colour}}/>
          <span className={classes.engineer}>{name(event.engineer)}</span>
        </DialogTitle>
        <DialogContent>
          <DetailPane
            title={event.title}
            details={event.type === 'allocation' ? [
              {title: 'Description', value: event.description},
              {title: 'Date', value: fullDate(event.start)},
              {title: 'Time', value: `${time(event.start)} - ${time(event.end)}`},
              ...(((job) => {
                const details = [];
                if (job.company) {
                  details.push({
                    title: 'Company',
                    value: job.company.name,
                    route: reverse(routes.companies.detail, {id: job.company.id})
                  });
                  if (job.company.phone) {
                    details.push({title: 'Phone', value: job.company.phone});
                  }
                } else if (job.contact) {
                  details.push({
                    title: 'Contact',
                    value: name(job.contact),
                    route: reverse(routes.contacts.detail, {id: job.contact.id})
                  });
                  if (job.contact.phone) {
                    details.push({title: 'Phone', value: job.contact.phone});
                  }
                  if (job.contact.mobile) {
                    details.push({title: 'Mobile', value: job.contact.mobile});
                  }
                  if (job.contact.email) {
                    details.push({title: 'Email', value: job.contact.email});
                  }
                }
                return details;
              })(event.allocation.job)),
              {
                title: 'Address',
                value: <Address address={address(event.allocation.job)}/>
              },
            ] : [
              {
                title: 'Description',
                value: <div dangerouslySetInnerHTML={{__html: event.description.replace('\n', '<br/><br/>')}}/>
              },
              {title: 'Start', value: fullDateTime(event.start)},
              {title: 'End', value: fullDateTime(event.end)},
              ...(event.type === 'calendar' ? [
                {title: 'Type', value: 'Calendar Entry'},
              ] : []),
              ...(event.type === 'note' ? [
                {title: 'Expires', value: event.note.expires_at ? fullDateTime(event.note.expires_at) : '-'},
                {title: 'Type', value: 'Quick Allocation'},
              ] : []),
              ...(event.calendar ? [{
                title: 'Calendar',
                value: (
                  <Link component={RouterLink} to={reverse(routes.engineers.detail, {id: event.engineer.id})}>
                    {event.calendar.name}
                  </Link>
                )
              }] : []),
            ]}
          />
        </DialogContent>
        <DialogActions>
          {event.type === 'allocation' ? (
            onAllocationSaved ? (
              <Button onClick={() => setEdit(true)} color="secondary" variant="contained">Edit
                Allocation</Button>
            ) : (
              <Button
                component={RouterLink}
                to={reverse(routes.jobs.detail, {id: event.allocation.job.id})}
                color="secondary"
                variant="contained"
              >
                View Job
              </Button>
            )
          ) : event.type === 'note' ? (
            <>
              <Button onClick={() => setConvertNote(true)} color="success" variant="contained">Convert</Button>
              <Button onClick={() => setEditNote(true)} color="secondary" variant="contained">Edit</Button>
              <Button onClick={() => setDeleteNote(true)} color="error" variant="contained">Delete</Button>
            </>
          ) : null}
          <Button onClick={() => onClose()} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <FormDialog
        title="Update Allocation"
        open={edit}
        onClose={() => setEdit(false)}
        render={(props) => event.allocation ? (
          <DetailForm
            {...props}
            job={job}
            allocation={{
              id: event.allocation.id,
              engineer_id: event.engineer.id,
              date: event.start,
              start_at: event.start,
              end_at: event.end
            }}
            onSaved={(saved) => {
              setEdit(false);
              onAllocationSaved(saved);
            }}/>
        ) : null}
        minHeight={400}
        maxWidth="md"
      />
      <FormDialog
        title="Update Quick Allocation"
        open={editNote}
        onClose={() => setEditNote(false)}
        render={(props) => (
          <CalendarNoteForm
            {...props}
            note={event.note}
            onSaved={() => {
              setEditNote(false);
              onNoteSaved();
            }}/>
        )}
        minHeight={400}
        maxWidth="md"
      />
      <ConfirmationDialog
        open={convertNote}
        title="Convert Quick Allocation"
        message="Are you sure you want to convert this quick into a job allocation?"
        onClose={handleConvertNote}
      />
      <ConfirmationDialog
        open={deleteNote}
        title="Delete Quick Allocation"
        message="Are you sure you want to delete this quick allocation?"
        onClose={handleDeleteNote}
      />
    </>
  ) : null;
}

export default EventDialog;
