import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  name,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import ReferrerForm from '../../forms/ReferrerForm';
import Wrapper from './Wrapper';
import Generator from './Generator';
import Orders from './Orders';

const Referrer = ({history, archive = false}) => {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    referrer: 'Sorry the referrer could not be accessed'
  }), []));

  const {
    entity: referrer,
    setEntity: setReferrer,
    loadEntity: loadReferrer,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.referrer.getReferrer,
    archive,
    useCallback((referrer) => [{title: referrer.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('referrer', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setReferrer(updated);
    } else if (referrer?.id) {
      loadReferrer(referrer.id)
    }
  }, [toggleDialog, setReferrer, loadReferrer, referrer]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.referrer.restoreReferrer(referrer.id).then((restored) => {
      setLoading(false);
      setReferrer(restored);
      history.push(reverse(routes.referrers.detail, {id: referrer.id}))
    }).catch(() => setLoading(false));
  }, [referrer, services, history, setReferrer, setLoading]);

  return (
    <Wrapper title="Referrer" loading={loading} referrer={referrer} crumbs={crumbs} errors={errors}>
      {referrer?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Referrer Details"
              minHeight={400}
              actions={!referrer.archived ? [
                {title: 'Edit Referrer', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Referrer', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {title: 'Restore Referrer', icon: <RestoreFromTrashIcon/>, onClick: handleRestore},
              ]}
              details={[
                {title: 'Name', value: referrer.name},
                {title: 'Unique Token', value: referrer.token}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Referrer"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    maxWidth="sm"
                    render={(props) => (
                      <ReferrerForm
                        {...props}
                        referrer={referrer}
                        engineer={true}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={referrer ? `Are you sure you want to delete ${name(referrer)}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.referrer.deleteReferrer(referrer.id).then(() => {
                          setLoading(false);
                          history.push(reverse(routes.engineers.index))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Link Generator"
            >
              <Generator token={referrer.token}/>
            </DetailPane>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: fullDateTime(referrer.created_at)},
                {title: 'Last Updated', value: fullDateTime(referrer.updated_at)},
                ...(referrer.archived ? [{title: 'Archived', value: fullDateTime(referrer.deleted_at)}] : [])
              ]}
            />
          ]}/>

          <Orders referrer={referrer}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Referrer;
