import {styled} from '@mui/material';

const FormErrors = styled('ul')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0,
  padding: theme.spacing(1),

  '& > li': {
    color: theme.palette.error.main,
    margin: 0,
    padding: theme.spacing(1, 0),
  }
}));

export default FormErrors;
