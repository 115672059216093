import {name} from '@management-ui/core';
import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Suppliers from '../../components/Suppliers';

const SubscriptionSuppliers = ({subscription, onSent, children}) => {
  const services = useContext(ServiceContext);

  const handleNotify = useCallback(
    contact => (
      services.subscription.requestRemoval(subscription, contact).then((sent) => {
        if (onSent) {
          onSent(sent);
        }
        return Promise.resolve('The removal request has been sent');
      }).catch(() => {
        return Promise.reject('The removal request could not be sent as there were no applicable units');
      })
    ),
    [subscription, onSent, services]
  );

  return (
    <Suppliers
      type="subscription_suppliers"
      onNotify={handleNotify}
      title="Request that this subscription is removed?"
      getMessage={(contact) => `Are you sure you want to send a request to ${name(contact)} in order to remove this subscription from your account?`}
    >
      {children}
    </Suppliers>
  )
};

export default SubscriptionSuppliers;
