import {prefixWithSeparator, Spinner, SquareButton, useFormStyles} from '@management-ui/core';
import {
  ClickAwayListener,
  InputAdornment,
  Link,
  List,
  ListItem,
  Paper,
  Popper,
  TextField as MatTextField,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import React, {useCallback, useContext, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../components/Services';

const PostcodeLookup = ({prefix}) => {
  const classes = useFormStyles();
  const services = useContext(ServiceContext);
  const {setValue} = useFormContext();

  const [loading, setLoading] = useState(false);
  const anchorRef = useRef();
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback(() => {
    if (query.trim().length >= 3) {
      setLoading(true);
      services.postcode.lookupPostcode(query).then((addresses) => {
        setLoading(false);
        setOptions(addresses);
        setOpen(true);
      }).catch(() => setLoading(false));
    }
  }, [services, query]);

  const handleClear = useCallback(() => {
    setQuery('');
    setOptions([]);
    setOpen(false);
  }, []);

  const handleSelect = useCallback((address) => {
    for (let field of ['address_number', 'address1', 'address2', 'town', 'region', 'postcode']) {
      // noinspection JSCheckFunctionSignatures
      setValue(`${prefixWithSeparator(prefix)}${field}`, address[field]);
    }
    handleClear();
  }, [setValue, prefix, handleClear]);

  return (
    <>
      <MatTextField
        value={`${query ?? ''}`}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        variant="outlined"
        margin="normal"
        id="postcodeLookup"
        label="Postcode Lookup"
        ref={anchorRef}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading ? (
                <Spinner/>
              ) : query && options.length > 0 ? (
                <SquareButton
                  tooltip="Clear Search"
                  icon={<ClearIcon/>}
                  onClick={handleClear}
                />
              ) : (
                <SquareButton
                  tooltip="Search"
                  icon={<SearchIcon/>}
                  onClick={handleSearch}
                />
              )}
            </InputAdornment>
          ),
          fullWidth: true,
        }}
      />
      <Popper
        open={open}
        className={classes.popper}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <>
              {options && options.length > 0 ? (
                <List>
                  {options.map((option, index) => (
                    <ListItem
                      key={index}
                      button
                      component={Link}
                      onClick={() => handleSelect(option)}>
                      {option.summary}
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography className={classes.noResults}>
                  Sorry no results were found for your query
                </Typography>
              )}
            </>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
};

export default PostcodeLookup;
