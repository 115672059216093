import {Box} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import SummaryBoxes from '../../components/SummaryBoxes';

const Products = ({location, setLoading, onSelect}) => {
  const services = useContext(ServiceContext);
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (location) {
      setLoading(true);
      services.product.getSummaries(location).then(products => {
        setLoading(false);
        setProducts(products);
      }).catch(() => {
        setLoading(false);
        setProducts([]);
      });
    } else {
      setProducts([]);
    }
  }, [services, location, setLoading]);

  return products.length > 0 ? (
    <Box marginTop={2} width="100%">
      <SummaryBoxes boxes={products.map((product) => ({
        title: product.name,
        rows: [[
          {title: 'Available', value: product.available.quantity},
          {title: 'Cost', value: <Currency amount={product.available.cost}/>},
          {title: 'Value', value: <Currency amount={product.available.value}/>}
        ], [
          {title: 'Allocated', value: product.allocated.quantity},
          {title: 'Cost', value: <Currency amount={product.allocated.cost}/>},
          {title: 'Value', value: <Currency amount={product.allocated.value}/>}
        ]],
        selected: selected && selected.id === product.id,
        onClick: () => {
          const updated = selected && selected.id === product.id ? null : product;
          setSelected(updated);
          onSelect(updated);
        }
      }))}/>
    </Box>
  ) : null;
};

export default Products;
