import {dateTime, name} from '@management-ui/core';
import {Button, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Text from '../../../../components/Text';
import NoteForm from '../../forms/NoteForm';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },

  column: {
    flex: 1,

    '& h3': {
      color: theme.palette.secondary.main,
      flex: 1,
      fontSize: '1.4em',
      margin: `${theme.spacing(1.6)} ${theme.spacing(1)} 0`,
      padding: 0,
    },

    '&:last-of-type': {
      borderLeft: `2px solid ${theme.palette.grey['200']}`
    }
  },

  form: {
    '& .MuiButton-root': {
      margin: `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },

  noUpdates: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },

  update: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexWrap: 'wrap',

    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey['100'],
    },

    '& .MuiTypography-root': {
      margin: 0,
      padding: theme.spacing(1),
    },

    '& .MuiTypography-subtitle1': {
      flex: '0 0 100%',
      fontSize: '1em',
      paddingBottom: 0,
      width: '100%'
    },

    '& .MuiTypography-subtitle2': {
      color: theme.palette.secondary.main,
      flex: '0 0 50%',
      fontSize: '0.9em',
      fontWeight: 600,
      width: '50%',

      '&:last-of-type': {
        textAlign: 'right',
      }
    }
  }
}));

const Updates = ({job, onJobUpdated}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  /** @type {({current: NoteForm})} */
  const formRef = useRef();
  const [update, setUpdate] = useState({});

  const handleSaved = useCallback((saved) => {
    setUpdate({});
    onJobUpdated(saved);
  }, [onJobUpdated]);

  return (
    <div className={classes.container}>
      <div className={classes.column}>

        <div className={classes.form}>
          <h3>Updates</h3>
          {job.archived ? (
            <Typography className={classes.noUpdates}>Updates can not be added to this job as it is archived.</Typography>
          ) : (
            <>
              <NoteForm
                ref={formRef}
                title="Update"
                job={job}
                note={update}
                onSave={(comment) => services.job.saveUpdate(job, {comment})}
                onSaved={handleSaved}
              />
              <Button variant="contained" onClick={() => formRef.current.save()}>Save</Button>
            </>
          )}
        </div>
      </div>
      <div className={classes.column}>
        {job.updates.length < 1 ? (
          <Typography className={classes.noUpdates}>There are no updates associated with this job</Typography>
        ) : (
          job.updates.map((update, index) => (
            <div key={index} className={classes.update}>
              <Typography variant="subtitle1"><Text text={update.comment}/></Typography>
              <Typography variant="subtitle2">{name(update.user)}</Typography>
              <Typography variant="subtitle2">{dateTime(update.created_at)}</Typography>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Updates;
