import * as qs from 'qs';
import {API} from './API';

export class ContactService {
  async getTableContacts(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getContacts(query, page, length);
  }

  async getContacts(query, page, length) {
    return (await API.getConnection())
      .get('contacts', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async getContact(id, archive = false) {
    return (await API.getConnection())
      .get(`contacts/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveContact(contact) {
    let data = contact;
    let method = 'post';
    let url = 'contacts';
    if (contact.id) {
      method = 'put';
      url = `contacts/${contact.id}`;
    } else if (!data.contact) {
      data = {contact};
    } else {
      if (data.company && Object.keys(data.company).filter(key => !!data.company[key]).length < 1) {
        delete data.company;
      }
      if (data.location && Object.keys(data.location).filter(key => !!data.location[key]).length < 1) {
        delete data.location;
      }
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async registerScorpionUser(id) {
    return (await API.getConnection())
      .post(`contacts/${id}/register`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async updateScorpionPassword(id) {
    return (await API.getConnection())
      .post(`contacts/${id}/password`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteContact(id) {
    return (await API.getConnection())
      .delete(`contacts/${id}`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async restoreContact(id) {
    return (await API.getConnection())
      .put(`contacts/${id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getContactActivity(id, query, page, length) {
    return (await API.getConnection())
      .get(`contacts/${id}/activity`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async saveComment(id, comment, page = 1, length = 5) {
    return (await API.getConnection())
      .post(`contacts/${id}/comment`, {comment}, {
        params: {page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getLocations(id) {
    return (await API.getConnection())
      .get(`contacts/${id}/locations`)
      .then(response => response.data.data)
      .catch(API.handleError);
  }

  async saveLocation(contact, data) {
    let method = 'post';
    let url = `contacts/${contact.id}/locations`;
    if (data.id) {
      method = 'put';
      url = `contacts/${contact.id}/locations/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteLocation(contact, location) {
    return (await API.getConnection())
      .delete(`contacts/${contact.id}/locations/${location.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }
}
