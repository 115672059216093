import {FormDialog, prefixWithSeparator} from '@management-ui/core';
import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';
import LocationList from '../../components/LocationList';
import LocationForm from '../LocationForm';

export const useStyles = makeStyles(theme => ({
  label: {
    flex: '0 0 100%',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },

  instruction: {
    padding: `0 ${theme.spacing(2)}`,
  }
}));

const FIELDS = ['address_number', 'address1', 'address2', 'town', 'region', 'postcode', 'country_id'];

export default function Locations(
  {
    prefix,
    copyIn = true,
    companyID: inputCompanyID = null,
    contactID: inputContactID = null
  }
) {
  const classes = useStyles();
  const services = useContext(ServiceContext);

  const {watch, setValue, getValues} = useFormContext();
  // noinspection JSCheckFunctionSignatures
  const watchCompanyID = watch(`${prefixWithSeparator(prefix)}company_id`);
  const companyLoaded = useRef(0);
  const companyLocations = useRef([]);
  // noinspection JSCheckFunctionSignatures
  const watchContactID = watch(`${prefixWithSeparator(prefix)}contact_id`);
  const contactLoaded = useRef(0);
  const contactLocations = useRef([]);

  const [companyID, setCompanyID] = useState(null);
  const [contactID, setContactID] = useState(null);

  useEffect(() => {
    if (inputCompanyID !== null) {
      setCompanyID(inputCompanyID);
    }
  }, [inputCompanyID]);

  useEffect(() => {
    if (watchCompanyID != null) {
      setCompanyID(watchCompanyID);
    }
  }, [watchCompanyID]);

  useEffect(() => {
    if (inputContactID !== null) {
      setContactID(inputContactID);
    }
  }, [inputContactID]);

  useEffect(() => {
    if (watchContactID != null) {
      setContactID(watchContactID);
    }
  }, [watchContactID]);

  const [locations, setLocations] = useState([]);

  const updateLocations = useCallback((companyList = null, contactList = null) => {
    const formatLocations = (locations, type) => locations.map(location => ({...location, type}));
    if (companyList !== null) {
      const formatted = formatLocations(companyList, 'Company');
      companyLocations.current = formatted;
      setLocations([...formatted, ...contactLocations.current]);
    } else if (contactList !== null) {
      const formatted = formatLocations(contactList, 'Contact');
      contactLocations.current = formatted;
      setLocations([...companyLocations.current, ...formatted]);
    }
  }, []);

  const loadCompanyLocations = useCallback(() => {
    services.company.getLocations(companyID)
      .then((list) => updateLocations(list, null))
      .catch(() => {
      });
  }, [services, companyID, updateLocations]);

  const loadContactLocations = useCallback(() => {
    services.contact.getLocations(contactID)
      .then((list) => updateLocations(null, list))
      .catch(() => {
      });
  }, [services, contactID, updateLocations]);

  useEffect(() => {
    if (companyID) {
      if (companyID !== companyLoaded.current) {
        companyLoaded.current = companyID;
        loadCompanyLocations()
      }
    } else if (companyLocations.current.length > 0) {
      updateLocations([], null);
    }
  }, [services, companyID, loadCompanyLocations, updateLocations]);

  useEffect(() => {
    if (contactID) {
      if (contactID !== contactLoaded.current) {
        contactLoaded.current = contactID;
        loadContactLocations();
      }
    } else if (contactLocations.current.length > 0) {
      updateLocations(null, []);
    }
  }, [services, contactID, loadContactLocations, updateLocations]);


  const [selectedLocation, setSelectedLocation] = useState({open: false, parent: {}, location: {}, type: 'contact'});

  const handleSelect = useCallback((location) => {
    if (copyIn) {
      FIELDS.forEach((field) => {
        setValue(`${prefixWithSeparator(prefix)}${field}`, location[field]);
      });
    } else {
      const values = getValues();
      const updated = {...location};
      FIELDS.forEach((field) => {
        updated[field] = values[`${prefixWithSeparator(prefix)}${field}`];
      });
      setSelectedLocation({
        open: true,
        parent: updated.company ?? updated.contact,
        location: updated,
        type: location.company ? 'company' : 'contact'
      });
    }
  }, [prefix, copyIn, setValue, getValues]);

  return (
    <Box display="flex" flexDirection="column">
      <h4 className={classes.label}>
        {copyIn ? 'Select a location to pre-populate the address' : 'Select a location to copy this address to'}
      </h4>
      <LocationList
        locations={locations}
        onSelect={handleSelect}
        addNew={!copyIn ? (companyID ? {
          props: {company: {id: companyID}},
          type: 'Company'
        } : (contactID ? {
          props: {contact: {id: contactID}},
          type: 'Contact'
        } : null)) : null}
      />
      {copyIn && locations.length < 1 ? (
        <p className={classes.instruction}>Select a company or contact and their existing locations will appear here to
          add to the job.</p>
      ) : null}
      <FormDialog
        title={selectedLocation.type === 'company' ? 'Company Location' : 'Contact Location'}
        open={selectedLocation.open}
        onClose={() => setSelectedLocation({...selectedLocation, open: false})}
        render={(props) => (
          <LocationForm
            {...props}
            type={selectedLocation.type}
            parent={selectedLocation.parent}
            location={selectedLocation.location}
            onSaved={(saved) => {
              if (saved.contacts) {
                loadCompanyLocations();
              }
              if (saved.companies) {
                loadContactLocations();
              }
              setSelectedLocation({...selectedLocation, open: false});
            }}/>
        )}
      />
    </Box>
  );
}
