import {fullDate, fullDateTime, name} from '@management-ui/core';
import moment from 'moment';
import React from 'react';
import EngineerLabel from '../EngineerLabel';
import Status from '../Status';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  status: {title: 'Status', render: (data) => <Status entity={data}/>},
  engineer: {
    title: 'Engineer',
    render: (data) => data.engineer ? (
      data.engineer.colour ? <EngineerLabel engineer={data.engineer}/> : name(data.engineer)
    ) : 'Unallocated'
  },
  date: {title: 'Date', render: (data) => fullDate(data.date)},
  start_at: {title: 'Start', render: (data) => moment(data.start_at).format('HH:mm')},
  end_at: {title: 'End', render: (data) => moment(data.end_at).format('HH:mm')},
  sent_at: {title: 'Sent', render: (data) => data.sent_at ? fullDateTime(data.sent_at) : '-'},
  accepted_at: {title: 'Accepted', render: (data) => data.accepted_at ? fullDateTime(data.accepted_at) : null},
  rejected_at: {title: 'Rejected', render: (data) => data.rejected_at ? fullDateTime(data.rejected_at) : null},
};

export default function JobAllocationUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
