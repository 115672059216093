import {Title} from '@management-ui/core';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box} from '@mui/material';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Slider from './Slider';

const SlidingForm = (
  {
    title,
    sessionKey,
    slides,
    initialSession = null,
    onFinish = () => {}
  }) => {
  const initialised = useRef(false);
  /** @type {({current: Slider})} */
  const sliderRef = useRef();

  const [sessionData, setSessionData] = useState(null);

  const setUp = useCallback((sessionData = null) => {
    let next = slides[0].type;
    if (sessionData) {
      localStorage.setItem(sessionKey, JSON.stringify(sessionData));
      setSessionData(sessionData);
      next = sessionData.slide;
    } else {
      const cached = localStorage.getItem(sessionKey);
      if (cached) {
        const parsed = JSON.parse(cached);
        setSessionData(parsed);
        next = parsed.slide;
      } else {
        const data = {slide: next};
        localStorage.setItem(sessionKey, JSON.stringify(data));
        setSessionData(data);
      }
    }
    sliderRef.current.next({type: next, id: next});
  }, [slides, sessionKey])

  useEffect(() => {
    if (sliderRef.current && !initialised.current) {
      initialised.current = true;
      setUp(initialSession);
    }
  }, [setUp, initialSession]);

  const handleUpdateSession = useCallback((updates = null) => {
    let updated = null;
    if (updates) {
      updated = {...sessionData, ...updates};
      localStorage.setItem(sessionKey, JSON.stringify(updated));
    } else {
      localStorage.removeItem(sessionKey);
    }
    setSessionData(updated);
  }, [sessionKey, sessionData]);

  const handleNext = useCallback((slide, sessionUpdates = {}) => {
    handleUpdateSession({slide: slide.type, ...sessionUpdates});
    sliderRef.current.next(slide);
  }, [handleUpdateSession]);

  const handleBack = useCallback((slide, sessionUpdates = {}) => {
    handleUpdateSession({slide: slide.type, ...sessionUpdates});
    sliderRef.current.back(slide);
  }, [handleUpdateSession]);

  const handleReset = useCallback(() => {
    handleUpdateSession(null);
    setUp();
  }, [handleUpdateSession, setUp]);

  const handleSetLoading = useCallback((loading) => {
    sliderRef.current.setLoading(loading);
  }, []);

  const handleClearLoading = useCallback(() => {
    sliderRef.current.clearLoading();
  }, []);

  const handleRender = useCallback((slide) => {
    const props = {
      slide,
      onNext: handleNext,
      onBack: handleBack,
      session: sessionData,
      updateSession: handleUpdateSession,
      reset: handleReset,
      setLoading: handleSetLoading,
      clearLoading: handleClearLoading,
      onFinish
    };
    const Component = slides.find(s => s.type === slide.type);
    return Component ? <Component {...props} /> : null;
  }, [slides, handleNext, handleBack, sessionData, handleUpdateSession, handleReset, handleSetLoading, handleClearLoading, onFinish]);

  return (
    <Box width="100%" display="flex" flexDirection="column" flex={1}>
      <Title
        title={title}
        controls={[{icon: <RefreshIcon/>, onClick: handleReset}]}
      />
      <Box marginTop={2} width="100%" display="flex" flexDirection="column" flex={1}>
        <Slider ref={sliderRef} onRenderSlide={handleRender}/>
      </Box>
    </Box>
  );
};

export default SlidingForm;
