import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  name,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Password} from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import {useLoading} from '../../../../hooks';
import routes from '../../../../routes';
import ContactForm from '../../forms/ContactForm';
import ContactScorpionForm from '../../forms/ContactScorpionForm';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const Contact = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'scorpion', 'password', 'register', 'delete']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    contact: 'Sorry the contact could not be accessed',
    link: 'Sorry the company could not be linked',
    unlink: 'Sorry the company could not be unlinked'
  }), []));

  const idRef = useRef('');
  const {loading, setLoading} = useLoading();
  const [contact, setContact] = useState(null);

  const loadContact = useCallback((id, force = false) => {
    if (force || idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.contact.getContact(id, archive).then(retrieved => {
        setContact(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('contact', true);
        setLoading(false);
        setTimeout(() => {
          history.push(archive ? reverse(routes.archive.index, {tab: 'contacts'}) : routes.contacts.index);
        }, 3000);
      });
    }
  }, [archive, services, history, toggleError, setLoading]);

  useEffect(() => {
    if (match.params?.id) {
      loadContact(match.params?.id);
    }
  }, [history, match, contact, loadContact]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...(archive ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs)]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [];
    if (contact) {
      header = name(contact);
      trail = [
        ...(contact.archived ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs),
        {title: header, link: ''}
      ];
    }
    setTitle(header);
    setCrumbs(trail);
  }, [contact]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    toggleDialog('scorpion', false);
    if (updated) {
      setContact(updated);
    } else if (contact?.id) {
      loadContact(contact.id)
    }
  }, [toggleDialog, setContact, loadContact, contact]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.contact.restoreContact(contact.id).then((restored) => {
      setLoading(false);
      setContact(restored);
      if (match.params?.tab) {
        history.push(reverse(routes.contacts.tab, {id: contact.id, tab: match.params.tab}))
      } else {
        history.push(reverse(routes.contacts.detail, {id: contact.id}))
      }
    }).catch(() => setLoading(false));
  }, [services, history, match, contact, setLoading]);

  return (
    <Wrapper title={title} loading={loading} contact={contact} crumbs={crumbs} errors={errors}>
      {contact?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Contact Details"
              minHeight={400}
              actions={!contact.archived ? [
                {title: 'Edit Contact', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Contact', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {title: 'Restore Contact', icon: <RestoreFromTrashIcon/>, onClick: handleRestore},
              ]}
              details={[
                {title: 'Name', value: name(contact)},
                {title: 'Job Title', value: contact.position ?? '-'},
                {
                  title: 'Email Address',
                  value: contact.email ?? '-',
                  link: contact?.email ? `mailto:${contact.email}` : null
                },
                {title: 'Telephone Number', value: contact.phone ?? '-'},
                {title: 'Mobile Number', value: contact.mobile ?? '-'},
                {
                  title: 'Is Associated Component Supplier?',
                  value: <YesNo value={contact.is_associated_component_supplier}/>
                },
                {title: 'Is Subscription Supplier?', value: <YesNo value={contact.is_subscription_supplier}/>},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Contact"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <ContactForm
                        {...props}
                        contact={contact}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={contact ? `Are you sure you want to delete ${name(contact)}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.contact.deleteContact(contact.id).then(() => {
                          setLoading(false);
                          history.push(reverse(`${routes.contacts.index}`))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Identifiers"
              actions={!contact.archived ? [
                {title: 'Edit Identifiers', icon: <EditIcon/>, onClick: () => toggleDialog('scorpion', true)},
                ...(contact.scorpion_customer && !contact.scorpion_user ? [
                  {
                    title: 'Register Scorpion User',
                    icon: <AppRegistrationIcon/>,
                    onClick: () => toggleDialog('register', true)
                  }
                ] : []),
                ...(contact.scorpion_customer && contact.scorpion_user ? [
                  {
                    title: 'Update Scorpion Password',
                    icon: <Password/>,
                    onClick: () => toggleDialog('password', true)
                  }
                ] : [])
              ] : []}
              details={[
                {title: 'Scorpion Customer ID', value: contact.scorpion_customer ?? '-'},
                {title: 'Scorpion User ID', value: contact.scorpion_user ?? '-'}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Identifiers"
                    open={openDialogs['scorpion'] ?? false}
                    onClose={() => toggleDialog('scorpion', false)}
                    maxWidth="sm"
                    render={(props) => (
                      <ContactScorpionForm
                        {...props}
                        contact={contact}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.register ?? false}
                    title="Register Scorpion User"
                    message={contact ? `Are you sure you want to register ${name(contact)} as a scorpion user? If they are a Safe Track customer this will send them their username and password` : ''}
                    onClose={confirmed => {
                      toggleDialog('register', false);
                      if (confirmed) {
                        setLoading(true);
                        services.contact.registerScorpionUser(contact.id).then((updated) => {
                          setLoading(false);
                          setContact(updated);
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.password ?? false}
                    title="Updated Scorpion Password"
                    message={contact ? `Are you sure you want to update ${name(contact)}'s as a scorpion password? This will send the contact their new username and password` : ''}
                    onClose={confirmed => {
                      toggleDialog('password', false);
                      if (confirmed) {
                        setLoading(true);
                        services.contact.updateScorpionPassword(contact.id).then((updated) => {
                          setLoading(false);
                          setContact(updated);
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: fullDateTime(contact.created_at)},
                {title: 'Last Updated', value: fullDateTime(contact.updated_at)},
                ...(contact.import_batch ? [{title: 'Import Batch', value: contact.import_batch}] : []),
                ...(contact.archived ? [{title: 'Archived', value: fullDateTime(contact.deleted_at)}] : [])
              ]}
            />
          ]}/>

          <Tabs
            contact={contact}
            onContactUpdated={handleSaved}
            onReloadContact={() => loadContact(contact.id, true)}
            loading={loading}
            onLoading={setLoading}
            toggleError={toggleError}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export default Contact;
