import {ColourSelector} from '@management-ui/colour';
import {Checkbox, Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import validator from 'email-validator';
import React, {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

export default function Fields(
  {
    user = {},
    admin = false,
    engineer = false,
    prefix = '',
    multiplePanes = true,
    children
  }
) {
  const {/** @var {function(string): string} */getValues} = useFormContext();

  const locations = useOptions('stockLocations', useCallback((list) => list.map(type => ({
    value: type.id,
    title: type.name
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.location) {
      subject.location_id = subject.location.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={user}
      onReset={handleReset}
      prefix={prefix}
      panes={[
        {
          title: 'Personal Details',
          fields: [
            <TextField
              name="first_name"
              prefix={prefix}
              label="First Name"
              rules={{required: 'Please enter a first name'}}
            />,
            <TextField
              name="last_name"
              prefix={prefix}
              label="Surname"
              rules={{required: 'Please enter a surname'}}
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                required: 'Please enter a email address',
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              autoComplete="email"
            />,
            ...(engineer ? [
              <Checkbox
                name="is_regular"
                prefix={prefix}
                label="Regular Engineer?"
              />,
              <Select
                name="location_id"
                prefix={prefix}
                options={locations}
                label="Associated Stock Location"
              />,
              <ColourSelector
                name="colour"
                prefix={prefix}
                label="Colour"
              />,
              <TextField
                name="postcodes"
                prefix={prefix}
                label="Postcode Areas (these can be space, comma or new-line separated)"
                fieldProps={{
                  multiline: true,
                  rows: 5
                }}
              />
            ] : [])
          ]
        }, {
          title: 'Access Details',
          fields: [
            <TextField
              name="password"
              prefix={prefix}
              label="Password"
              rules={{
                ...(!user.id ? {required: 'Please enter a password'} : {}),
                validate: value => {
                  if (value) {
                    return value === getValues(`${prefixWithSeparator(prefix)}confirmPassword`) ? true : 'Please make sure the passwords match';
                  }
                  return true;
                }
              }}
              fieldProps={{
                type: 'password',
                autoComplete: 'password'
              }}
            />,
            <TextField
              name="confirmPassword"
              prefix={prefix}
              label="Confirm Password"
              rules={{
                validate: value => {
                  const password = getValues(`${prefixWithSeparator(prefix)}confirmPassword`);
                  if (password) {
                    return value ? true : 'Please confirm the password';
                  }
                  return true;
                }
              }}
              fieldProps={{
                type: 'password',
                autoComplete: 'password'
              }}
            />,
            ...(admin ? [
              <Checkbox
                name="is_engineer"
                prefix={prefix}
                label="Has Engineer Access?"
              />,
            ] : []),
            <Checkbox
              name="is_active"
              prefix={prefix}
              label="Active User?"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'User Details'}
    >{children}</Panes>
  );
}
