import {Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {fullDate} from '@management-ui/core';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import Status from '../../components/Status';

const Subscriptions = ({contact, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [subscriptions, setSubscriptions] = useState([]);
  const loaded = useRef(false);

  const loadSubscriptions = useCallback(() => {
    onLoading(true);
    services.subscription.getSubscriptions({filter: {contact: contact.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setSubscriptions(response.data);
      }).catch(() => onLoading(false));
  }, [contact, onLoading, services]);

  useEffect(() => {
    if (contact) {
      if (!loaded.current) {
        loaded.current = true;
        loadSubscriptions();
      }
    }
  }, [contact, loadSubscriptions]);

  const goToDetail = useCallback((subscription) => {
    history.push(reverse(routes.subscriptions.detail, {id: subscription.id}));
  }, [history]);

  return (
    <Table className={loading ? 'loading' : ''}>
      <Table.Actions>
        <h3>Subscriptions</h3>
        <Tooltip title="Refresh">
          <IconButton onClick={() => loadSubscriptions()}>
            <Refresh/>
          </IconButton>
        </Tooltip>
      </Table.Actions>
      <Table.Row>
        <Table.Header>Reference</Table.Header>
        <Table.Header>Account</Table.Header>
        <Table.Header>Type</Table.Header>
        <Table.Header>Status</Table.Header>
        <Table.Header>Date</Table.Header>
        <Table.Header>Total</Table.Header>
        <Table.Action/>
      </Table.Row>
      {subscriptions.length > 0 ? (
        subscriptions.map((subscription, index) => (
          <Table.Row key={index}>
            <Table.Column>{subscription.reference}</Table.Column>
            <Table.Column>{subscription.account?.name ?? '-'}</Table.Column>
            <Table.Column>{subscription.is_recurring ? 'Monthly Payments' : 'Single Payment'}</Table.Column>
            <Table.Column><Status entity={subscription}/></Table.Column>
            <Table.Column>{fullDate(subscription.created_at)}</Table.Column>
            <Table.Column><Currency amount={subscription.total}/></Table.Column>
            <Table.Action>
              <Tooltip title="View Subscription">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(subscription)}
                      disabled={contact.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
              </Tooltip>
            </Table.Action>
          </Table.Row>
        ))
      ) : (
        loading ? null : <Table.NoRecords>There are no subscriptions associated with this contact</Table.NoRecords>
      )}
    </Table>
  );
};

export default Subscriptions;
