import {Panes, prefixWithSeparator, Select} from '@management-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';
import {truncate} from '../../../../formatters';
import orderOptions from '../OrderJobForm/orderOptions';

const Fields = ({vehicle = {}, job, toJob, order, prefix = ''}) => {
  const {setValue} = useFormContext();
  const services = useContext(ServiceContext);
  const [vehicles, setVehicles] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let companyIDs;
    let contactIDs;
    if (order) {
      const {companies, contacts} = orderOptions(order);
      companyIDs = companies.map(({value}) => value).join(',');
      contactIDs = contacts.map(({value}) => value).join(',');
    }
    services.vehicle.getVehicles({
      filter: {
        ...(job ? (toJob ? {link: job.id} : {job: job.id}) : {}),
        ...(companyIDs ? {company: companyIDs} : {}),
        ...(companyIDs ? {contact: contactIDs} : {})
      }
    }, 1, 50)
      .then(({data}) => {
        setVehicles(data.map(vehicle => ({
          title: truncate([vehicle.registration, vehicle.make, vehicle.model, vehicle.vin].filter(d => !!d).join(' - ')),
          value: `${vehicle.id}`
        })))
        setLoaded(true);
      })
      .catch(() => setLoaded(true));
  }, [services, job, toJob, order]);

  useEffect(() => {
    if (vehicle?.id && vehicles.length && vehicles.findIndex(v => v.value === `${vehicle?.id}`) >= 0) {
      setValue(`${prefixWithSeparator(prefix)}vehicle`, `${vehicle?.id}`);
    }
  }, [vehicle, prefix, setValue, vehicles]);

  return (
    <Panes
      entity={vehicle}
      prefix={prefix}
      panes={[
        {
          title: 'Vehicle',
          fields: [
            <Select
              name="vehicle"
              prefix={prefix}
              options={vehicles.length < 1 ? [{title: '', value: '0'}] : vehicles}
              label={loaded ? (vehicles.length > 0 ? 'Select a Vehicle' : 'No Vehicles to Link') : 'Loading...'}
              fieldProps={{disabled: vehicles.length < 1}}
            />
          ]
        }
      ]}
      title={''}
    />
  );
};

export default Fields;
