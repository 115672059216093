import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, styled} from '@mui/material';
import Table from '../../../../components/Table';
import {FormDialog, fullDate, name} from '@management-ui/core';
import Currency from '../../../../components/Currency';
import {reverse} from 'named-urls';
import routes from '../../../../routes';
import {Link as RouterLink} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import ParseDataForm from '../../forms/ParseDataForm';

const statuses = {
  '&.missing': {
    backgroundColor: '#d4d4ff',
  },

  '&.matched': {
    backgroundColor: '#e5ffd7',
  },

  '&.inconsistent': {
    backgroundColor: '#fffed4',
  },

  '&.additional': {
    backgroundColor: '#ffd4d4',
  },

  '&.removed': {
    backgroundColor: '#e0e0e0',
  },

  '&.renewed': {
    backgroundColor: '#bbe9ff',
  },
};

const StyledTable = styled(Table)(() => ({
  '&.missing': {
    '.matched, .inconsistent, .additional, .removed, .renewed': {
      display: 'none'
    }
  },

  '&.matched': {
    '.missing, .inconsistent, .additional, .removed, .renewed': {
      display: 'none'
    }
  },

  '&.inconsistent': {
    '.missing, .matched, .additional, .removed, .renewed': {
      display: 'none'
    }
  },

  '&.additional': {
    '.missing, .matched, .inconsistent, .removed, .renewed': {
      display: 'none'
    }
  },

  '&.removed': {
    '.missing, .matched, .inconsistent, .additional, .renewed': {
      display: 'none'
    }
  },

  '&.renewed': {
    '.missing, .matched, .inconsistent, .additional, .removed': {
      display: 'none'
    }
  },
}))

const Row = styled(Table.Row)(() => ({
  ...statuses,

  '&.duplicate': {
    fontWeight: 700,
  }
}));

const Link = styled(RouterLink)(({theme}) => ({
  color: theme.palette.secondary.main,

  '&:hover': {
    textDecoration: 'none',
  }
}));

const Totals = styled('div')(({theme}) => ({
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 2),
}));

const Total = styled('div')(({theme}) => ({
  borderRadius: theme.spacing(0.5),
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  opacity: 0.5,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  ...statuses,

  '&.active': {
    opacity: 1,
  }
}));

const SummaryModal = ({open, onClose, title = '', showCheck = false, payments = []}) => {
  const services = useContext(ServiceContext)
  const [showUpload, setShowUpload] = useState(false);
  const [parsed, setParsed] = useState([]);
  const [rows, setRows] = useState([]);
  const [totals, setTotals] = useState([]);
  const [status, setStatus] = useState(null);

  const selectStatus = useCallback((selected) => {
    setStatus(status === selected ? null : selected);
  }, [status]);

  useEffect(() => {
    setParsed([]);
  }, [payments]);

  const handleParse = useCallback(async ({data}) => new Promise(resolve => {
    services.reporting.parseData(data).then(parsed => {
      setParsed(parsed);
      resolve();
    }).catch(() => null);
  }), [services]);

  const handleParsed = useCallback(() => setShowUpload(false), []);

  useEffect(() => {
    const rows = [];
    for (let {
      id,
      is_recurring: isRecurring,
      date,
      amount,
      cost,
      subscription,
      product,
      vehicle,
      is_removed: isRemoved,
      is_renewed: isRenewed
    } of payments) {
      rows.push({
        registrations: vehicle?.registrations ?? [],
        vehicle,
        isRecurring,
        date,
        amount,
        cost,
        actualCost: null,
        subscription,
        company: subscription?.company,
        contact: subscription?.contact,
        product,
        status: parsed.length > 0 ? (isRemoved ? 'removed' : (isRenewed ? 'renewed' : 'missing')) : 'neutral',
        duplicated: payments.filter(p => p.id !== id && p.vehicle.registrations.filter(r => vehicle.registrations.includes(r)).length > 0).length > 0
      });
    }
    for (let {registration, cost, customer, customer_id: customerID} of parsed) {
      const index = rows.findIndex(row => row.registrations.indexOf(registration) >= 0);
      if (index >= 0) {
        if (['removed', 'renewed'].indexOf(rows[index].status) < 0) {
          if (rows[index].cost === cost) {
            rows[index].status = 'matched';
          } else {
            rows[index].status = 'inconsistent';
            rows[index].actualCost = cost;
          }
        }
      } else {
        rows.push({
          registrations: [registration],
          vehicle: null,
          isRecurring: null,
          date: null,
          amount: null,
          cost,
          actualCost: null,
          subscription: null,
          company: {
            name: `${customer} (${customerID})`,
          },
          contact: null,
          product: null,
          status: 'additional',
          duplicated: false
        });
      }
    }
    setRows(rows);
    let totals = [];
    if (parsed.length > 0) {
      totals = [
        {title: 'Matched', status: 'matched'},
        {title: 'Inconsistent', status: 'inconsistent'},
        {title: 'Scorpion Only', status: 'additional'},
        {title: 'System Only', status: 'missing'},
        {title: 'Removed', status: 'removed'},
        {title: 'Renewed', status: 'renewed'},
      ].map(({title, status}) => {
        const filtered = rows.filter((row) => row.status === status);
        return {
          title, status, count: filtered.length, total: filtered.reduce((total, row) => total + row.cost, 0)
        };
      });
    }
    setTotals(totals);
  }, [payments, parsed]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullScreen={true}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <StyledTable className={status ?? ''}>
            <Row>
              <Table.Header>Type</Table.Header>
              <Table.Header>Date</Table.Header>
              <Table.Header>Amount</Table.Header>
              <Table.Header>Cost</Table.Header>
              <Table.Header>Subscription</Table.Header>
              <Table.Header>Customer</Table.Header>
              <Table.Header>Product</Table.Header>
              <Table.Header>Vehicle</Table.Header>
            </Row>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <Row key={index} className={[row.status, row.duplicated ? 'duplicate' : ''].join(' ')}>
                  <Table.Column>{row.isRecurring === null ? '-' : (row.isRecurring ? 'Recurring' : 'Fixed')}</Table.Column>
                  <Table.Column>
                    {row.date ? fullDate(row.date) : '-'}
                  </Table.Column>
                  <Table.Column>{row.amount ? <Currency amount={row.amount}/> : '-'}</Table.Column>
                  <Table.Column>
                    <Currency amount={row.cost}/>
                    {row.actualCost ? <>&nbsp;(<Currency amount={row.actualCost}/>)</> : null}
                  </Table.Column>
                  <Table.Column>
                    {row.subscription ? (
                      <Link
                        to={reverse(routes.subscriptions.detail, {id: row.subscription.id})}>
                        {row.subscription.reference}
                      </Link>
                    ) : null}
                  </Table.Column>
                  <Table.Column>
                    {row.company ? (
                      row.company.id ? (
                        <Link
                          to={reverse(routes.companies.detail, {id: row.company.id})}>
                          {row.company.name}
                        </Link>
                      ) : row.company.name
                    ) : row.contact ? (
                      <Link
                        to={reverse(routes.contacts.detail, {id: row.contact.id})}>
                        {name(row.contact)}
                      </Link>
                    ) : null}
                  </Table.Column>
                  <Table.Column>{row.product === null ? '-' : row.product}</Table.Column>
                  <Table.Column>{
                    row.registrations.length < 1 ? '-' : (
                      row.vehicle ? (
                        <Link
                          to={reverse(routes.vehicles.detail, {id: row.vehicle.id})}>
                          {row.registrations[0]}
                        </Link>
                      ) : row.registrations[0]
                    )
                  }</Table.Column>
                </Row>
              ))
            ) : (
              <Table.NoRecords>There are no payments to display</Table.NoRecords>
            )}
          </StyledTable>
        </DialogContent>
        <DialogActions>
          <Totals>
            {totals.map(({title, status: totalStatus, count, total}, index) => (
              <Total key={index} className={`${totalStatus} ${!status || status === totalStatus ? 'active' : ''}`}
                     onClick={() => selectStatus(totalStatus)}>
                <strong>{title} ({count})</strong>
                <Currency amount={total}/>
              </Total>
            ))}
          </Totals>
          {showCheck ? (
            <Button onClick={() => setShowUpload(true)} color="info" variant="contained">Check Scorpion Data</Button>
          ) : null}
          <Button onClick={() => onClose()} color="primary" variant="contained">Close</Button>
        </DialogActions>
      </Dialog>
      <FormDialog
        title="Import Data"
        open={showUpload}
        onClose={() => setShowUpload(false)}
        maxWidth="sm"
        render={(props) => (
          <ParseDataForm
            {...props}
            holder={{}}
            onSave={handleParse}
            onSaved={handleParsed}
          />
        )}
      />
    </>
  )
}

export default SummaryModal