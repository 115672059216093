import {
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EditIcon from '@mui/icons-material/Edit';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {Alert, Box, Snackbar} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import GatewayAccountForm from '../../forms/GatewayAccountForm';
import Wrapper from './Wrapper';

const GatewayAccount = () => {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    product: 'Sorry the account could not be accessed'
  }), []));

  const {
    entity: account,
    setEntity: setAccount,
    loadEntity: loadAccount,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.gatewayAccount.getAccount,
    false,
    useCallback((account) => [{title: account.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('account', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit']);
  const [message, setMessage] = useState({open: false, message: '', severity: 'success'});

  const handleAuthorise = useCallback(() => {
    setLoading(true);
    services.gatewayAccount.beginAuthorisation(
      account,
      `${window.location.origin}${routes.developer.gatewayAccounts.verify}`
    )
      .then((url) => {
        setLoading(false);
        window.location.href = url;
      }).catch(() => setLoading(false));
  }, [services, account, setLoading]);

  const handleVerify = useCallback(() => {
    setLoading(true);
    services.gatewayAccount.verify(account).then((verified) => {
      setLoading(false);
      setAccount(verified);
      if (account.xero) {
        setMessage({open: true, message: 'Connection Verified', severity: 'success'});
      } else {
        setMessage({open: true, message: 'Connection Failed', severity: 'error'});
      }
    }).catch(() => setLoading(false));
  }, [services, account, setAccount, setLoading]);

  const handleReset = useCallback(() => {
    setLoading(true);
    services.gatewayAccount.reset(account).then((reset) => {
      setLoading(false);
      setAccount(reset);
      setMessage({open: true, message: 'Connection Reset', severity: 'success'});
    }).catch(() => setLoading(false));
  }, [services, account, setAccount, setLoading]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setAccount(updated);
    } else if (account?.id) {
      loadAccount(account.id)
    }
  }, [toggleDialog, setAccount, loadAccount, account]);

  return (
    <Wrapper title="Gateway Account" loading={loading} crumbs={crumbs} errors={errors}>
      {account?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Core Details"
              minHeight={400}
              actions={[
                {title: 'Edit Account', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
              ]}
              details={[
                {title: 'Name', value: account.name ?? '-'},
                {title: 'Identifier', value: account.identifier ?? '-'},
                {title: 'Invoice URL', value: account.invoice_url ?? '-'},
                {title: 'Short Invoice URL', value: account.short_invoice_url ?? '-'},
                {title: 'Subscription URL', value: account.subscription_url ?? '-'},
                {title: 'Twilio Sender ID', value: account.twilio_number ?? '-'},
                {title: 'Brand', value: account.brand.name ?? '-'},
                {title: 'Xero Sales Account Code', value: account.xero_sales_account ?? '-'},
                {title: 'Xero Online Order Account Code', value: account.xero_order_account ?? '-'},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Account"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <GatewayAccountForm
                        {...props}
                        account={account}
                        onSaved={handleSaved}/>
                    )}
                  />
                )
              ]}
            />,

            <Box display="flex" flexDirection="column" width="100%">
              <Box width="100%" marginBottom={1}>
                <DetailPane
                  title="Stripe Details"
                  details={[
                    {title: 'Public Key', value: account.public ?? '-'},
                    {title: 'Secret Key', value: account.secret ?? '-'},
                    {title: 'Hook Key', value: account.hook ?? '-'},
                  ]}
                />
              </Box>
              <Box width="100%" marginVertical={1}>
                <DetailPane
                  title="PayPal Details"
                  details={[
                    {title: 'Client ID', value: account.paypal_client ?? '-'},
                    {title: 'Secret', value: account.paypal_secret ?? '-'},
                    {title: 'App ID', value: account.paypal_app ?? '-'},
                  ]}
                />
              </Box>
              <Box width="100%" marginTop={1}>
                <DetailPane
                  title="Xero Details"
                  actions={account.xero ? [
                    {title: 'Verify', icon: <FactCheckIcon/>, onClick: handleVerify},
                    {title: 'Reset', icon: <RestartAltIcon/>, onClick: handleReset},
                  ] : [
                    {title: 'Authorise', icon: <AccountBalanceIcon/>, onClick: handleAuthorise},
                  ]}
                  details={account.xero ? [
                    {title: 'Status', value: 'Authorised'},
                    {title: 'Organisation Name', value: account.xero.name},
                    {title: 'Organisation ID', value: account.xero.id},
                  ] : [
                    {title: 'Status', value: 'Unauthorised'}
                  ]}
                />
              </Box>
            </Box>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: fullDateTime(account.created_at)},
                {title: 'Last Updated', value: fullDateTime(account.updated_at)}
              ]}
            />
          ]}/>
        </>
      ) : null}
      <Snackbar
        open={message.open}
        autoHideDuration={4000}
        onClose={useCallback(() => setMessage({...message, open: false}), [message])}>
        <Alert
          onClose={useCallback(() => setMessage({...message, open: false}), [message])}
          severity={message.severity}>{message.message}</Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default GatewayAccount;
