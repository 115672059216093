import {ArrowBack, ArrowForward, Check} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import React from 'react';

const Controls = (
  {
    onBack,
    backLabel = 'Back',
    onNext,
    nextLabel = 'Next',
    onFinish,
    finishLabel = 'Finish'
  }
) => (
  <Box display="flex" marginTop={2}>
    {onBack ? (
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBack/>}
        onClick={() => onBack()}
      >{backLabel}</Button>
    ) : null}
    <Box flex={1}/>
    {onNext ? (
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowForward/>}
        onClick={() => onNext()}
      >{nextLabel}</Button>
    ) : null}
    {onFinish ? (
      <Box marginLeft={2}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<Check/>}
          onClick={() => onFinish()}
        >{finishLabel}</Button>
      </Box>
    ) : null}
  </Box>
);

export default Controls;
