import {Box, Paper} from '@mui/material';
import moment from 'moment';
import React, {useCallback, useContext, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import JobLocationForm from '../../forms/JobLocationForm';
import JobDetails from './JobDetails';
import JobVehicles from './JobVehicles';
import SelectedCustomer from './SelectedCustomer';
import withJobInProgress from './withJobInProgress';

const Slide = ({job, onNext, onBack, session, setLoading, clearLoading}) => {
  /** @type {({current: JobLocationForm})} */
  const formRef = useRef();
  const services = useContext(ServiceContext);

  const handleNext = useCallback((saved) => {
    onNext({type: JobVehicles.type, id: JobVehicles.type}, {
      selectedAllocation: null,
      selectedCompany: null,
      selectedContact: null,
      selectedJob: {id: saved.id, reference: saved.reference}
    });
  }, [onNext])

  const handleSaved = useCallback((saved) => {
    if (session.selectedAllocation) {
      setLoading('Saving New Allocation');
      const {d, s, e, id, n} = session.selectedAllocation;
      services.job.saveAllocation({
        job_id: saved.id,
        date: moment(d).format('YYYY-MM-DD'),
        start_at: moment(s).format('HH:mm:ss'),
        end_at: moment(e).format('HH:mm:ss'),
        engineer_id: id
      }).then(() => {
        clearLoading();
        if (n) {
          setLoading('Removing Quick Allocation');
          services.calendarNote.expireNote({id: n}).then(() => {
            clearLoading();
            handleNext(saved);
          }).catch(() => clearLoading());
        } else {
          handleNext(saved);
        }
      }).catch(() => clearLoading());
    } else {
      handleNext(saved);
    }
  }, [services, handleNext, session, setLoading, clearLoading]);

  return (
    <>
      <Header>Job Location</Header>
      <SelectedCustomer session={session}/>
      <Paper component={Box} padding={2}>
        {job ? <JobLocationForm ref={formRef} job={job} showLinked={true} onSaved={handleSaved}/> : null}
      </Paper>
      <Controls
        onBack={() => onBack({type: JobDetails.type, id: JobDetails.type}, {
          selectedJob: null
        })}
        onNext={() => formRef.current.save()}
        nextLabel="Save New Job"
      />
    </>
  );
};

const JobLocation = withJobInProgress(Slide);
JobLocation.type = 'JOB_LOCATION';
export default JobLocation;
