import {FormControl, FormHelperText, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import Bubbles from '../components/Bubbles';
import {prefixWithSeparator} from './utilities';

const useStyles = makeStyles(theme => ({
  bubbles: {
    fontSize: '120%',
  },
  bubblesLabel: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}));

/**
 * A field made up of `Bubbles` for use within the `BaseForm` component.
 *
 * @module BubblesField
 *
 * @param {string} name The name for field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {IBubble[]} bubbles The available options to show as bubbles
 * @param {boolean} multiSelect Whether it is possible to select more than 1 option
 * @param {object} fieldProps Any additional props for the `Bubbles` component
 *
 * @example
 * <BubblesField
 *   name="colours"
 *   prefix={prefix}
 *   label="Colours"
 *   id="colours"
 *   rules={{required: 'Please select at least 1 colour'}}
 *   bubbles={[{id: 1, label: 'Red', colour: '#ff0000'}, {id: 2, label: 'Blue', colour: '#0000ff'}]}
 *   multiSelect={true}
 * />
 *
 */
const BubblesField = (
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    bubbles = [],
    multiSelect = false,
    fieldProps
  }
) => {
  const classes = useStyles();
  const {control, formState: {errors}, watch, setValue} = useFormContext();
  const prefixedName = `${prefixWithSeparator(prefix)}${name}`;
  // noinspection JSCheckFunctionSignatures
  const value = watch(prefixedName, null);
  const valueRef = useRef(null);

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const serialised = JSON.stringify(value);
    if (serialised !== valueRef.current) {
      valueRef.current = serialised;
      if (multiSelect) {
        setSelected(value ?? []);
      } else {
        setSelected([value]);
      }
    }
  }, [value, multiSelect]);

  const handleSelect = useCallback((bubble) => {
    if (multiSelect) {
      const updated = [...selected];
      const index = updated.indexOf(bubble.id);
      if (index >= 0) {
        updated.splice(index, 1);
      } else {
        updated.push(bubble.id);
      }
      setSelected(updated);
      setValue(prefixedName, updated);
    } else {
      setSelected([bubble.id]);
      setValue(prefixedName, bubble.id);
    }
  }, [multiSelect, selected, setValue, prefixedName]);

  return bubbles.length ? (
    <FormControl
      variant="outlined"
      required={rules && !!rules.required}
      className={classes.bubbles}
      fullWidth>
      <Typography className={classes.bubblesLabel} variant="body2">{label}</Typography>
      <Controller
        name={prefixedName}
        control={control}
        rules={rules}
        render={() => (
          <Bubbles
            {...fieldProps}
            id={id ?? prefixedName}
            bubbles={bubbles}
            selected={selected}
            onSelect={handleSelect}
          />
        )}
      />
      {!!errors[prefixedName] ? <FormHelperText>{errors[prefixedName].message}</FormHelperText> : null}
    </FormControl>
  ) : null;
};

BubblesField.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  bubbles: PropTypes.array,
  multiSelect: PropTypes.bool,
  fieldProps: PropTypes.object
};

export default BubblesField;
