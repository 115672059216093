
export class ServiceProvider {
  static getServices() {
    return ServiceProvider.services;
  }

  static setServices(services) {
    ServiceProvider.services = services;
  }
}
