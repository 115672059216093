import React from 'react';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  name: {title: 'Company Name', render: null},
  phone: {title: 'Phone', render: null},
  website: {title: 'Website', render: null},
  is_manufacturer: {title: 'Is Manufacturer?', render: company => company.is_manufacturer ? 'Yes' : 'No'},
  is_supplier: {title: 'Is Supplier?', render: company => company.is_supplier ? 'Yes' : 'No'},
  is_installer: {title: 'Is Installer?', render: company => company.is_installer ? 'Yes' : 'No'}
};

export default function CompanyUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
