import React, {useCallback, useRef, useState} from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import JobAllocationForm from '../../forms/JobAllocationForm';
import Who from './Who';

const When = ({onNext}) => {
  const [isLoading, setIsLoading] = useState(false);
  const loadingCount = useRef(0);

  const handleLoading = useCallback((loading) => {
    if (loading) {
      loadingCount.current += 1;
    } else {
      loadingCount.current -= 1;
    }
    setIsLoading(loadingCount.current > 0);
  }, []);

  return (
    <>
      <Header>When is the Job?</Header>
      <JobAllocationForm
        allocation={{}}
        job={{}}
        additionalAvailability={[]}
        onSaved={(saved) => onNext(
          {type: Who.type, id: Who.type},
          {selectedAllocation: saved}
        )}
        canEdit={false}
        loading={isLoading}
        onLoading={handleLoading}
      />
      <Controls
        onNext={() => onNext({type: Who.type, id: Who.type}, {selectedAllocation: null})}
        nextLabel="Skip to Job"
      />
    </>
  );
};

When.type = 'WHEN';

export default When;
