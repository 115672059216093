import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ActionHolder from '../../components/Actions';

export default function Actions(
  {
    user,
    setLoading,
    toggleError,
    page = 1,
    length = 5,
    updatePageCount
  }) {
  const services = useContext(ServiceContext);

  const loadActivity = useCallback((currentPage, selectedLength) => {
    return services.user.getUserActivity(
      user.id,
      user.archived ? {filter: {archive: 'only'}} : {},
      currentPage,
      selectedLength
    );
  }, [services, user]);

  return (
    <ActionHolder
      loadActivity={loadActivity}
      setLoading={setLoading}
      toggleError={toggleError}
      page={page}
      length={length}
      updatePageCount={updatePageCount}
    />
  );
}
