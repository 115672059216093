import * as qs from 'qs';
import {clearUsers} from '../store/actions/options';
import {API} from './API';

export class UserService {
  async getTableUsers(params, additional = {}, resource = 'user') {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getUsers(query, page, length, resource);
  }

  async getUsers(query, page, length, resource = 'user') {
    return (await API.getConnection())
      .get('users', {
        params: {...query, ...{page, length, resource}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async lookupPostcode(postcode) {
    return (await API.getConnection())
      .get('users/lookup', {
        params: {postcode},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getUser(id, archive = false) {
    return (await API.getConnection())
      .get(`users/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveUser(data, resource = 'user') {
    let method = 'post';
    let url = 'users';
    if (data.id) {
      method = 'put';
      url = `users/${data.id}`;
    }
    return (await API.getConnection())
      [method](`${url}?resource=${resource}`, data)
      .then(response => {
        API.store.dispatch(clearUsers());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async deleteUser(id) {
    return (await API.getConnection())
      .delete(`users/${id}`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async restoreUser(id) {
    return (await API.getConnection())
      .put(`users/${id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getUserActivity(id, query, page, length) {
    return (await API.getConnection())
      .get(`users/${id}/activity`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async getTableCalendars(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getCalendars(query, page, length);
  }

  async getCalendars(query, page, length) {
    return (await API.getConnection())
      .get(`calendars`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async getCalendar(id, archive = false) {
    return (await API.getConnection())
      .get(`calendars/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveCalendar(data) {
    let method = 'post';
    let url = `calendars`;
    if (data.id) {
      method = 'put';
      url = `calendars/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteCalendar(calendar) {
    return (await API.getConnection())
      .delete(`calendars/${calendar.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }
}
