import {Panes, Select} from '@management-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

export default function Fields({association = {}, product, existing, prefix = ''}) {
  const services = useContext(ServiceContext);
  const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    services.product.getProducts({}, 1, 50)
      .then(({data}) => {
        setProducts(
          data
            .filter(p => p.id !== product.id && existing.findIndex(e => e.id === p.id) < 0)
            .map(({name: title, id: value}) => ({title, value}))
        );
        setLoaded(true);
      })
      .catch(() => setLoaded(true));
  }, [services, product, existing]);

  return (
    <Panes
      entity={association}
      prefix={prefix}
      panes={[
        {
          title: 'Product to Associate',
          fields: [
            <Select
              name="associate"
              prefix={prefix}
              options={products.length < 1 ? [{title: '', value: '0'}] : products}
              label={loaded ? (products.length > 0 ? 'Select a Product' : 'No Products to Associate') : 'Loading...'}
              fieldProps={{disabled: products.length < 1}}
            />
          ]
        }
      ]}
      title={''}
    />
  );
}
