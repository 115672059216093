import {ServiceProvider} from '../../services/ServiceProvider';
import {
  SET_BATTERY_TYPES,
  SET_COUNTRIES,
  SET_FUEL_TYPES,
  SET_GATEWAY_ACCOUNTS,
  SET_INSTALLERS,
  SET_JOB_TEMPLATES,
  SET_LOADING,
  SET_LOCATION_NAMES,
  SET_LOCATION_TYPES,
  SET_MANUFACTURERS,
  SET_PRODUCTS,
  SET_STOCK_LOCATIONS,
  SET_SUPPLIERS,
  SET_USERS,
  SET_VEHICLE_TYPES,
  SET_VIRTUAL_PRODUCTS
} from '../reducers/options';

export function setOptionsLoading(loading) {
  return {
    type: SET_LOADING,
    loading
  };
}

function setCountries(countries) {
  return {
    type: SET_COUNTRIES,
    countries
  };
}

export function loadCountries() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().country
      .getCountries({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setCountries(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearCountries() {
  return (dispatch) => {
    dispatch(setCountries([]));
  }
}

function setLocationNames(names) {
  return {
    type: SET_LOCATION_NAMES,
    names
  };
}

export function loadLocationNames() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().locationName
      .getNames()
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setLocationNames(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearLocationNames() {
  return (dispatch) => {
    dispatch(setLocationNames([]));
  }
}

function setLocationTypes(types) {
  return {
    type: SET_LOCATION_TYPES,
    types
  };
}

export function loadLocationTypes() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().locationType
      .getTypes()
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setLocationTypes(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearLocationTypes() {
  return (dispatch) => {
    dispatch(setLocationTypes([]));
  }
}

function setUsers(users) {
  return {
    type: SET_USERS,
    users
  };
}

export function loadUsers() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().user
      .getUsers({filter: {is_active: 1}}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setUsers(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearUsers() {
  return (dispatch) => {
    dispatch(setUsers([]));
  }
}

function setManufacturers(manufacturers) {
  return {
    type: SET_MANUFACTURERS,
    manufacturers
  };
}

export function loadManufacturers() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().company
      .getCompanies({filter: {manufacturers: 1}}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setManufacturers(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

function setSuppliers(suppliers) {
  return {
    type: SET_SUPPLIERS,
    suppliers
  };
}

export function loadSuppliers() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().company
      .getCompanies({filter: {suppliers: 1}}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setSuppliers(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

function setInstallers(installers) {
  return {
    type: SET_INSTALLERS,
    installers
  };
}

export function loadInstallers() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().company
      .getCompanies({filter: {installers: 1}}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setInstallers(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearCompanies() {
  return (dispatch) => {
    dispatch(setInstallers([]));
    dispatch(setManufacturers([]));
    dispatch(setSuppliers([]));
  }
}

function setProducts(products) {
  return {
    type: SET_PRODUCTS,
    products
  };
}

export function loadProducts() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().product
      .getProducts({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setProducts(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearProducts() {
  return (dispatch) => {
    dispatch(setProducts([]));
  }
}

function setStockLocations(locations) {
  return {
    type: SET_STOCK_LOCATIONS,
    locations
  };
}

export function loadStockLocations() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().stockLocation
      .getLocations({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setStockLocations(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearStockLocations() {
  return (dispatch) => {
    dispatch(setStockLocations([]));
  }
}

function setJobTemplates(templates) {
  return {
    type: SET_JOB_TEMPLATES,
    templates
  };
}

export function loadJobTemplates() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().jobTemplate
      .getTemplates({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setJobTemplates(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearJobTemplates() {
  return (dispatch) => {
    dispatch(setJobTemplates([]));
  }
}

function setGatewayAccounts(accounts) {
  return {
    type: SET_GATEWAY_ACCOUNTS,
    accounts
  };
}

export function loadGatewayAccounts() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().gatewayAccount
      .getAccounts({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setGatewayAccounts(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearGatewayAccounts() {
  return (dispatch) => {
    dispatch(setGatewayAccounts([]));
  }
}

function setVirtualProducts(products) {
  return {
    type: SET_VIRTUAL_PRODUCTS,
    products
  };
}

export function loadVirtualProducts() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().virtualProduct
      .getProducts({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setVirtualProducts(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearVirtualProducts() {
  return (dispatch) => {
    dispatch(setVirtualProducts([]));
  }
}

function setBatteryTypes(types) {
  return {
    type: SET_BATTERY_TYPES,
    types
  };
}

export function loadBatteryTypes() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().batteryType
      .getTypes({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setBatteryTypes(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearBatteryTypes() {
  return (dispatch) => {
    dispatch(setBatteryTypes([]));
  }
}

function setFuelTypes(types) {
  return {
    type: SET_FUEL_TYPES,
    types
  };
}

export function loadFuelTypes() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().fuelType
      .getTypes({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setFuelTypes(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearFuelTypes() {
  return (dispatch) => {
    dispatch(setFuelTypes([]));
  }
}

function setVehicleTypes(types) {
  return {
    type: SET_VEHICLE_TYPES,
    types
  };
}

export function loadVehicleTypes() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().vehicleType
      .getTypes({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setVehicleTypes(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearVehicleTypes() {
  return (dispatch) => {
    dispatch(setVehicleTypes([]));
  }
}
