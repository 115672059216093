import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {CompanyFields, ContactFields} from './Fields';

const LocationForm = forwardRef(({type, parent, location, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={location}
      type="location"
      fieldsComponent={type === 'company' ? CompanyFields : ContactFields}
      onSave={(update) => {
        const {latitude, longitude, ...data} = update;
        return services[type].saveLocation(parent, data);
      }}
      onSaved={onSaved}
    />
  );
});

export default LocationForm;
