import {fullDateTime} from '@management-ui/core';
import {styled} from '@mui/material';
import React from 'react';
import Metric from './Metric';

const COLOURS = {
  green: '#30b046',
  amber: '#efcf3d',
  red: '#bd0f0f',
};

const Container = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderBottomRightRadius: 3,
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(0.5, 0, 0),
  padding: theme.spacing(0.25),
}));

// noinspection JSCheckFunctionSignatures
const Metrics = ({allocation}) => (
  <Container>
    <Metric
      abbreviation="B"
      blocks={[
        {width: 1, colour: allocation.status.colour, title: `Booking Status: ${allocation.status.title}`}
      ]}/>
    <Metric
      abbreviation="C"
      blocks={[
        {
          width: 1,
          colour: allocation.customer_notified_at ? COLOURS.green : COLOURS.red,
          title: `Customer Notified: ${allocation.customer_notified_at ? fullDateTime(allocation.customer_notified_at) : 'No'}`
        }
      ]}/>
    <Metric
      abbreviation="V"
      blocks={allocation.vehicles.length < 1 ? [
        {width: 1, colour: null, title: 'No Vehicles'}
      ] : allocation.vehicles.map(({vin}) => ({
        width: 1 / allocation.vehicles.length,
        colour: vin ? COLOURS.green : COLOURS.red,
        title: `Vehicle: ${vin ? `VIN - ${vin}` : 'No VIN'}`
      }))}
      counter={allocation.vehicles.length}/>
    <Metric
      abbreviation="I"
      blocks={allocation.confirmations.length < 1 ? [
        {width: 1, colour: null, title: 'No Information Checks'}
      ] : allocation.confirmations.map(({status: {colour, title}}) => ({
        width: 1 / allocation.confirmations.length,
        colour: colour,
        title: `Information Check: ${title}`
      }))}
      counter={allocation.confirmations.length}/>
    <Metric
      abbreviation="S"
      blocks={allocation.stock.length < 1 ? [
        {width: 1, colour: null, title: 'No Stock Allocated'}
      ] : allocation.stock.map(({product: {name}, allocated}) => ({
        width: 1 / allocation.stock.length,
        colour: allocated ? COLOURS.green : COLOURS.red,
        title: `Stock: ${name} (${allocated ? 'Allocated' : 'Not Allocated'})`
      }))}
      counter={allocation.stock.length}/>
    <Metric
      abbreviation="P"
      blocks={allocation.invoices.length < 1 ? [
        {width: 1, colour: null, title: 'No Invoices or Orders'}
      ] : allocation.invoices[0].reference === allocation.job.reference ? [
        {width: 1, colour: COLOURS.green, title: 'Manually Flagged as Paid'}
      ] : allocation.invoices.map(({reference, status: {colour, title}}) => ({
        width: 1 / allocation.invoices.length,
        colour: colour,
        title: `${reference !== '-' ? reference : 'Invoice'} - ${title}`
      }))}
      counter={allocation.invoices.length > 0 && allocation.invoices[0].reference === allocation.job.reference ? null : allocation.invoices.length}/>
    <Metric
      abbreviation="U"
      blocks={[
        {
          width: 1,
          colour: allocation.safe_track_user === null ? null : (allocation.safe_track_user ? COLOURS.green : COLOURS.red),
          title: `Safe Track User: ${allocation.safe_track_user === null ? 'Not Required' : (allocation.safe_track_user ? 'Registered' : 'Not Registered')}`
        }
      ]}/>
    <Metric
      abbreviation="N"
      blocks={allocation.admin_notes ? [
        {width: 1, colour: COLOURS.amber, title: allocation.admin_notes}
      ] : [
        {width: 1, colour: null, title: 'No Notes Available'}
      ]}/>
  </Container>
);

Metrics.COLOURS = COLOURS;

export default Metrics;
