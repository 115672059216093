import * as qs from 'qs';
import {API} from './API';

export class CalendarNoteService {
  async getTableNotes(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getNotes(query, page, length);
  }

  async getNotes(query, page, length) {
    return (await API.getConnection())
      .get('calendar-notes', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async saveNote(data) {
    let method = 'post';
    let url = 'calendar-notes';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async expireNote(note) {
    return (await API.getConnection())
      .put(`calendar-notes/${note.id}/expire`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteNote(note) {
    return (await API.getConnection())
      .delete(`calendar-notes/${note.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }
}
