import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import orderOptions from '../OrderJobForm/orderOptions';
import Fields from './Fields';

// noinspection DuplicatedCode
const DeliveryForm = forwardRef(({order, delivery, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const [errors, setErrors] = useState([]);

  const handleSave = useCallback((
    {
      company_id,
      contact_id,
      location_id
    }
  ) => {
    const errors = [];
    const {locations} = orderOptions(order);
    const location = locations.find(({value}) => `${value}` === location_id);
    if (!location) {
      errors.push('Please select a location');
    }
    if (!contact_id && !company_id) {
      errors.push('Please select at least a company or a contact');
    }
    setErrors(errors);
    if (errors.length < 1) {
      return services.delivery.saveDelivery({
        order_id: order.id,
        company_id,
        contact_id,
        address1: location.location.address1,
        address2: location.location.address2,
        town: location.location.town,
        region: location.location.region,
        postcode: location.location.postcode,
        country_id: location.location.country_id
      });
    } else {
      return new Promise((resolve, reject) => reject());
    }
  }, [order, services]);

  return (
    <BaseForm
      ref={formRef}
      entity={delivery}
      type="delivery"
      fieldsComponent={(props, onLoading) => <Fields order={order} errors={errors} onLoading={onLoading} {...props}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default DeliveryForm;
