import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import HttpsIcon from '@mui/icons-material/Https';
import * as React from 'react';
import routes from '../../../../routes';
import AccessKeys from './AccessKeys';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GatewayAccounts from './GatewayAccounts';

const TABS = [
  {
    id: 'accessKeys',
    slug: 'access-keys',
    title: 'Access Keys',
    icon: <HttpsIcon/>,
    render: (props) => <AccessKeys {...props} />
  },
  {
    id: 'gatewayAccounts',
    slug: 'gateway-accounts',
    title: 'Gateway Accounts',
    icon: <AccountBalanceIcon/>,
    render: (props) => <GatewayAccounts {...props} />
  }
];

export default function Developer() {
  const {tab, handleTab} = useTab(TABS, routes.developer.index);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Developer'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
