import * as qs from 'qs';
import {clearJobTemplates} from '../store/actions/options';
import {API} from './API';

export class JobTemplateService {
  async getTableTemplates(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTemplates(query, page, length);
  }

  async getTemplates(query, page, length) {
    return (await API.getConnection())
      .get('job-templates', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(API.handleError);
  }

  async saveTemplate(data) {
    let method = 'post';
    let url = 'job-templates';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearJobTemplates());
        return response.data.data
      })
      .catch(API.handleError);
  }
}
