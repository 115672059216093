import {makeStyles} from '@mui/styles';
import React, {useEffect, useState} from 'react';

const useStyles = makeStyles(theme => ({
  attributes: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  attribute: {
    display: 'flex',
    fontSize: '0.9em',

    '& > span': {

      '&:first-child': {
        flex: 1,
        fontWeight: 700,
        paddingRight: theme.spacing(1),
      },

      '&:last-child': {
        flex: 3,
      }
    }
  }
}));

export default function AttributeUpdate({action, attrs}) {
  const classes = useStyles();

  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (action && action.data) {
      setAttributes(Object.keys(attrs).map(key => {
        const attr = attrs[key];
        let display = false;
        let value;
        if (action.data.hasOwnProperty(key)) {
          display = true;
          value = attr.render ? attr.render(action.data) : action.data[key];
        }
        return {title: attr.title, value, display};
      }).filter(a => a.display));
    }
  }, [action, attrs]);

  return attributes.length > 0 ? (
    <ul className={classes.attributes}>
      {attributes.map((a, index) => (
        <li className={classes.attribute} key={index}><span>{a.title}:</span><span>{a.value}</span></li>
      ))}
    </ul>
  ) : null;
}
