import {BaseForm} from '@management-ui/core';
import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const ParseDataForm = forwardRef(({holder = {}, onSave, onSaved}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={holder}
      type="holder"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={onSave}
      onSaved={onSaved}
    />
  );
});

export default ParseDataForm;
