import {DataTable, fullDate, name} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Filter from '../../components/Filter';
import Status from '../../components/Status';

const STATUSES = [
  {
    title: 'All',
    slug: '',
    colour: '#000'
  },
  {
    title: 'Created',
    slug: 'created',
    colour: '#efcf3d'
  },
  {
    title: 'Pending',
    slug: 'pending',
    colour: '#43ded9'
  },
  {
    title: 'Active',
    slug: 'active',
    colour: '#95ec5b'
  },
  {
    title: 'Complete',
    slug: 'complete',
    colour: '#30b046'
  },
  {
    title: 'Cancelled',
    slug: 'cancelled',
    colour: '#cc0000'
  },
  {
    title: 'Renewed',
    slug: 'renewed',
    colour: '#2fb5f3'
  },
  {
    title: 'Expired',
    slug: 'expired',
    colour: '#a20000'
  },
  {
    title: 'Removed',
    slug: 'removed',
    colour: '#b0b0b0'
  },
];

const Table = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const {search, pathname} = useLocation();

  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const firstLoad = useRef(true);
  const [initialised, setInitialised] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const status = params.get('status');
    const current = STATUSES.find(({slug}) => slug === (status ?? ''));
    if (current) {
      setStatus(current);
      setInitialised(true);
      if (!firstLoad.current) {
        tableRef.current.refresh();
      }
      firstLoad.current = false;
    } else {
      history.push(reverse(routes.subscriptions.index));
    }
  }, [history, search]);

  const changeStatus = useCallback((selected) => {
    history.push({
      pathname,
      search: selected ? `?status=${selected}` : ''
    });
  }, [history, pathname]);

  const goToDetail = useCallback((subscription) => {
    history.push(reverse(routes.subscriptions.detail, {id: subscription.id}));
  }, [history]);

  const loadData = useCallback(query => new Promise((resolve, reject) => {
    const filters = {};
    if (status?.slug) {
      filters.status = status.slug;
    }
    services.subscription.getTableSubscriptions(query, filters)
      .then(response => {
        resolve({
          data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
        });
      }).catch(() => {
      reject();
    });
  }), [services, status]);

  const tableProps = useMemo(() => ({
    components: {
      Toolbar: props => (
        <div>
          <MTableToolbar {...props} />
          <Filter status={status.slug} onChange={changeStatus} statuses={STATUSES}/>
        </div>
      )
    },
    columns: [
      {title: 'Reference', field: 'reference'},
      {
        title: 'Customer',
        field: 'customer',
        sorting: false,
        render: data => data.company ? data.company.name : data.contact ? name(data.contact) : '-'
      },
      {title: 'Job', field: 'job', render: data => data.job ? data.job.reference : '-'},
      {title: 'Account', field: 'account', render: data => data.account?.name ?? '-'},
      {
        title: 'Type',
        field: 'is_recurring',
        render: data => data.is_recurring ? 'Monthly Payments' : 'Single Payment'
      },
      {title: 'Status', field: 'status', render: data => <Status entity={data}/>},
      {title: 'Date', field: 'date', render: data => fullDate(data.created_at)},
      {title: 'Total', field: 'total', render: data => <Currency amount={data.total}/>}
    ],
    loadData,
    onRowClick: goToDetail
  }), [status, changeStatus, loadData, goToDetail]);

  return initialised ? (
    <DataTable
      ref={tableRef}
      title="Subscriptions"
      options={{
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 300px)',
        sorting: false,
        draggable: false
      }}
      {...tableProps}
      newForm={null}
    />
  ) : null;
};

export default Table;
