import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Who from './Who';

const withContact = (Slide) => {
  return (props) => {
    const {onBack, session, setLoading, clearLoading} = props;

    const services = useContext(ServiceContext);
    const [contact, setContact] = useState(null);
    const idRef = useRef('');

    const handleBack = useCallback(() => onBack({type: Who.type, id: Who.type}, {
      selectedCompany: null,
      selectedContact: null
    }), [onBack])

    useEffect(() => {
      if (session) {
        const id = session.selectedContact?.id ? `${session.selectedContact.id}` : '';
        if (id && id !== idRef.current) {
          idRef.current = id;
          setLoading('Loading Contact Details');
          services.contact.getContact(id).then((retrieved) => {
            setContact(retrieved);
            clearLoading();
          }).catch(() => {
            clearLoading();
            handleBack();
          });
        }
      }
    }, [services, session, setLoading, clearLoading, handleBack]);

    return <Slide contact={contact} goBack={handleBack} {...props}/>;
  }
};

export default withContact;
