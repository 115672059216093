import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useOptions} from '../../../../hooks';

const Fields = (
  {
    item = {},
    prefix = '',
    multiplePanes = true,
    settings = {},
    onLoading,
    children
  }
) => {
  const {setValue, /** @type {function} */watch} = useFormContext();

  const optionsLoading = useSelector(state => state['options'].loading);
  useEffect(() => onLoading(optionsLoading), [optionsLoading, onLoading]);

  const locations = useOptions('stockLocations', useCallback((list) => list.map(location => ({
    value: location.id,
    title: location.name
  })), []));

  const products = useOptions('products', useCallback((list) => list.map(product => ({
    value: product.id,
    title: product.name,
    purchase_price: product.purchase_price,
    sale_price: product.sale_price,
    manufacturer: product.manufacturer
  })), []));

  const suppliers = useOptions('suppliers', useCallback((list) => list.map(supplier => ({
    value: supplier.id,
    title: supplier.name
  })), []));

  const selectedProduct = watch(useMemo(() => `${prefixWithSeparator(prefix)}product_id`, [prefix]));

  useEffect(() => {
    if (selectedProduct) {
      const product = products.find(p => `${p.value}` === selectedProduct);
      if (product) {
        setValue(`${prefixWithSeparator(prefix)}purchase_price`, product.purchase_price);
        setValue(`${prefixWithSeparator(prefix)}sale_price`, product.sale_price);
        if (product.manufacturer && product.manufacturer.is_supplier) {
          setValue(`${prefixWithSeparator(prefix)}supplier_id`, product.manufacturer.id);
        }
      }
    }
  }, [selectedProduct, products, setValue, prefix])

  const handleReset = useCallback((subject) => {
    if (subject.supplier) {
      subject.supplier_id = subject.supplier.id;
      delete subject.supplier;
    }
    if (subject.product) {
      subject.product_id = subject.product.id;
    }
    if (subject.location) {
      subject.location_id = subject.location.id;
    } else {
      subject.location_id = settings.defaultStockLocation ?? '';
    }
    return subject;
  }, [settings]);

  return (
    <Panes
      entity={item}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Associations',
          fields: [
            <Select
              name="product_id"
              prefix={prefix}
              options={products}
              label="Product"
              rules={{required: 'Please select a product'}}
            />,
            <Select
              name="supplier_id"
              prefix={prefix}
              options={suppliers}
              label="Supplier"
              rules={{required: 'Please select a supplier'}}
            />,
            <Select
              name="location_id"
              prefix={prefix}
              options={locations}
              label="Location"
              rules={{required: 'Please select a location'}}
            />,
          ]
        },
        {
          title: 'Item Details',
          fields: [
            <TextField
              name="serial_number"
              prefix={prefix}
              label="Serial Number"
            />,
            <TextField
              name="associated_serial_number"
              prefix={prefix}
              label="Associated Component Serial Number"
            />,
            <TextField
              name="purchase_price"
              prefix={prefix}
              label="Purchase Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="sale_price"
              prefix={prefix}
              label="Sale Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="po_number"
              prefix={prefix}
              label="Order Number"
            />,
            ...(item.id ? [] : [
              <TextField
                name="quantity"
                prefix={prefix}
                label="Quantity"
                rules={{required: 'Please enter a quantity'}}
              />
            ])
          ]
        },
      ]}
      title={multiplePanes ? null : 'Details'}
    >{children}</Panes>
  );
};

export default Fields;
