import {API} from './API';

export class AuthService {
  async login(email, password) {
    return (await API.getConnection())
      .post('auth/login', {email, password})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async me() {
    return (await API.getConnection())
      .get('auth/me')
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async logout() {
    return (await API.getConnection())
      .post('auth/logout')
      .then(() => {
      })
      .catch(error => API.handleError(error));
  }

  async forgotPassword(email, resetUrl) {
    return (await API.getConnection())
      .post('auth/forgot-password', {email, reset_url: resetUrl})
      .then(() => {
      })
      .catch(error => API.handleError(error));
  }

  async resetPassword(email, password, token) {
    return (await API.getConnection())
      .post('auth/reset-password', {email, password, token})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async setDeviceToken(device) {
    return (await API.getConnection())
      .post('auth/device', {device})
      .then(() => {
      })
      .catch(error => API.handleError(error));
  }
}
