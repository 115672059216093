import {address, Address, name} from '@management-ui/core';
import {styled} from '@mui/material';
import React from 'react';

const TYPES = ['Billing', 'Installation', 'Delivery'];

const Location = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
}));

const orderOptions = (order) => {
  const companies = [];
  const contacts = [];
  const locations = [];
  for (let title of TYPES) {
    const type = title.toLowerCase();
    const company = order[`${type}_company`];
    if (company) {
      companies.push({title: `${title} - ${company.name}`, value: company.id, company});
    }
    const companyLocation = order[`${type}_company_location`];
    if (companyLocation) {
      locations.push({
        value: `company_${type}_${companyLocation.id}`,
        location: companyLocation,
        title: (
          <Location>
            <strong>{company.name} ({title} Address)</strong>
            <Address address={address(companyLocation)}/>
          </Location>
        )
      });
    }
    const contact = order[`${type}_contact`];
    if (contact && contacts.findIndex(({value}) => value === `${contact.id}`) < 0) {
      contacts.push({title: `${title} - ${name(contact)}`, value: `${contact.id}`, contact});
    }
    const contactLocation = order[`${type}_contact_location`];
    if (contactLocation) {
      locations.push({
        value: `contact_${type}_${contactLocation.id}`,
        location: contactLocation,
        title: (
          <Location>
            <strong>{name(contact)} ({title} Address)</strong>
            <Address address={address(contactLocation)}/>
          </Location>
        )
      });
    }
  }
  return {companies, contacts, locations};
};

export default orderOptions;
