import {fullDate, time} from '@management-ui/core';
import {Add, Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import qs from 'qs';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import EngineerLabel from '../../components/EngineerLabel';
import Status from '../../components/Status';

const Allocations = ({contact, type = 'contact', loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [allocations, setAllocations] = useState([]);
  const loaded = useRef(false);

  const loadAllocations = useCallback(() => {
    onLoading(true);
    const filter = {};
    filter[type] = contact.id;
    services.job.getAllocations({filter}, 1, 500)
      .then(response => {
        onLoading(false);
        setAllocations(response.data);
      }).catch(() => onLoading(false));
  }, [contact, type, onLoading, services]);

  useEffect(() => {
    if (contact) {
      if (!loaded.current) {
        loaded.current = true;
        loadAllocations();
      }
    }
  }, [contact, type, loadAllocations]);

  const goToNew = useCallback(() => {
    const {id, first_name, last_name} = contact;
    history.push({pathname: routes.jobs.new, search: `?${qs.stringify({contact: {id, first_name, last_name}})}`});
  }, [history, contact]);

  const goToDetail = useCallback((job) => {
    history.push(reverse(routes.jobs.detail, {id: job.id}));
  }, [history]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Allocations as {type === 'contact' ? 'Main Contact' : 'End User'}</h3>
          <Tooltip title="Refresh">
            <IconButton onClick={() => loadAllocations()}>
              <Refresh/>
            </IconButton>
          </Tooltip>
          {type === 'contact' ? (
            <Tooltip title="Add New Allocation">
            <span>
              <IconButton disabled={contact.archived} onClick={() => goToNew()}>
                <Add/>
              </IconButton>
            </span>
            </Tooltip>
          ) : null}
        </Table.Actions>
        <Table.Row>
          <Table.Header>Job</Table.Header>
          <Table.Header>Engineer</Table.Header>
          <Table.Header>Date</Table.Header>
          <Table.Header>Time</Table.Header>
          <Table.Header>Status</Table.Header>
          <Table.Action/>
        </Table.Row>
        {allocations.length > 0 ? (
          allocations.map((allocation, index) => (
            <Table.Row key={index}>
              <Table.Column>{allocation.job.reference}</Table.Column>
              <Table.Column>
                {allocation.engineer ? (
                  <EngineerLabel engineer={allocation.engineer}/>
                ) : 'Unallocated'}
              </Table.Column>
              <Table.Column>{fullDate(allocation.date)}</Table.Column>
              <Table.Column>{time(allocation.start_at)} - {time(allocation.end_at)}</Table.Column>
              <Table.Column><Status entity={allocation}/></Table.Column>
              <Table.Action>
                <Tooltip title="View Job">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(allocation.job)}
                      disabled={contact.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>
            There are no allocations associated with this {type === 'contact' ? 'contact' : 'end user'}
          </Table.NoRecords>
        )}
      </Table>
    </>
  );
};

export default Allocations;
