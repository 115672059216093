import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({contact = {}, prefix = ''}) => (
  <Panes
    entity={contact}
    prefix={prefix}
    panes={[
      {
        title: 'Scorpion IDs',
        fields: [
          <TextField
            name="scorpion_customer"
            prefix={prefix}
            label="Customer ID"
          />,
          <TextField
            name="scorpion_user"
            prefix={prefix}
            label="User ID"
          />
        ]
      }
    ]}
    title=""/>
);

export default Fields;

