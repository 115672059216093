import {DateField, DateTimeField, Panes, Select, TextField, TimeRangeSelector} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useEngineers} from '../../../../hooks';

const Fields = ({note = {}, prefix = '', multiplePanes = true, children}) => {
  const engineers = useEngineers();

  const handleReset = useCallback((subject) => {
    if (subject.engineer) {
      subject.engineer_id = subject.engineer.id;
      delete subject.engineer;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={note}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'What',
          fields: [
            <TextField
              name="description"
              prefix={prefix}
              label="Description"
              rules={{required: 'Please enter a description'}}
              fieldProps={{
                multiline: true,
                rows: 5
              }}
            />
          ]
        },
        {
          title: 'When',
          fields: [
            <DateField
              name="date"
              prefix={prefix}
              label="Select a date"
              rules={{required: 'Please select a date'}}
            />,
            <TimeRangeSelector
              fromName="start_at"
              toName="end_at"
              prefix={prefix}
              label="Select a time range"
            />,
            <DateTimeField
              name="expires_at"
              prefix={prefix}
              label="Select an expiry date and time"
              rules={{required: 'Please select an expiry date and time'}}
            />,
          ]
        },
        {
          title: 'Who',
          fields: [
            <Select
              name="engineer_id"
              prefix={prefix}
              options={engineers}
              label="Engineer"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Quick Allocation Details'}
    >{children}</Panes>
  );
};

export default Fields;
