import {BaseForm} from '@management-ui/core';
import React, {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import withSettings from '../../hoc/withSettings';
import Fields from './Fields';

const StockItemForm = forwardRef(({item, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={{quantity: 1, ...item}}
      type="item"
      fieldsComponent={(props, onLoading) => withSettings(Fields)({...props, onLoading}, onLoading)}
      onSave={(update) => services.stockItem.saveItem(update)}
      onSaved={onSaved}
    />
  );
});

export default StockItemForm;
