import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Toolbar} from '@mui/material';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';
import React, {useCallback, useEffect, useRef, useState} from 'react';

const border = (theme) => `1px solid ${theme.palette.grey['300']}`;
const buttonSize = '60px';

const Title = styled(Typography)(() => ({
  flex: 1,
}));

const Nav = styled('div')(({theme}) => ({
  borderBottom: border(theme),
  borderTop: border(theme),
  display: 'flex',

  '& h2': {
    alignItems: 'center',
    borderLeft: border(theme),
    borderRight: border(theme),
    display: 'flex',
    flex: 1,
    fontSize: '1.4em',
    fontWeight: 700,
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    textAlign: 'center',
  },

  '& button': {
    alignItems: 'center',
    background: 'none',
    border: 0,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    display: 'flex',
    height: buttonSize,
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    width: buttonSize,

    '&:focus': {
      outline: 0,
    }
  }
}));

const Months = styled('div')(({theme}) => ({
  borderBottom: border(theme),
  display: 'flex',
}))

const Month = styled('div')(({theme}) => ({
  borderRight: border(theme),
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',

  '& > span': {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.2em',
    fontWeight: '700',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },

  '&:last-child': {
    borderRight: 0
  },

  '&.now': {
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.primary.main
  },

  '&.current': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main
  },
}));

const MonthSelector = ({month, onSelectMonth, total}) => {
  const [year, setYear] = useState(0);
  const [months, setMonths] = useState([]);
  const initialised = useRef(false);

  useEffect(() => {
    if (month && !initialised.current) {
      initialised.current = true;
      setYear(month.year());
    }
  }, [month]);

  useEffect(() => {
    if (year) {
      const thisMonth = moment().startOf('month');
      const compareMonth = 'YYYY-MM';
      const list = [];
      const date = moment().year(year).startOf('year');
      for (let m = 0; m < 12; m++) {
        const workingMonth = date.clone().month(m).startOf('month');
        list.push({
          title: workingMonth.format('MMM'),
          start: workingMonth.clone(),
          now: workingMonth.format(compareMonth) === thisMonth.format(compareMonth),
          current: workingMonth.format(compareMonth) === month.format(compareMonth),
        });
      }

      setMonths(list);
    }
  }, [year, month]);

  const handleNext = useCallback(() => {
    setYear(year + 1);
  }, [year]);

  const handleBack = useCallback(() => {
    setYear(year - 1);
  }, [year]);

  return year ? (
    <>
      <Toolbar>
        <Title component="h2" variant="h5">Orders</Title>
        <Typography component="h2" variant="h6">&pound;{numeral(total).format('0,0.00')}</Typography>
      </Toolbar>
      <Nav>
        <button onClick={handleBack}><ChevronLeftIcon/></button>
        <h2><span>{year}</span></h2>
        <button onClick={handleNext}><ChevronRightIcon/></button>
      </Nav>
      <Months>
        {months.map((month, monthIndex) => (
          <Month
            key={monthIndex}
            className={[
              month.current ? 'current' : '',
              month.now ? 'now' : ''
            ].join(' ')}
            onClick={() => onSelectMonth(month.start)}
          >
            <span>{month.title}</span>
          </Month>
        ))}
      </Months>
    </>
  ) : null;
};

export default MonthSelector;