import {DetailColumns, DetailPane, fullDateTime, useDialogs, useErrors} from '@management-ui/core';
import {Sync} from '@mui/icons-material';
import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import StripeHolder from '../../../../components/StripeHolder';
import {useLoading} from '../../../../hooks';
import routes from '../../../../routes';
import Status from '../../components/Status';
import Details from './Details';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const useStyles = makeStyles(theme => ({
  total: {
    color: theme.palette.common.white,
    padding: theme.spacing(0, 0.5),
  },
  synced: {
    backgroundColor: theme.palette.success.main
  },
  outdated: {
    backgroundColor: theme.palette.error.main
  }
}));

const Subscription = ({archive = false}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'approve', 'cancel', 'remove', 'delete']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    subscription: 'Sorry the subscription could not be accessed'
  }), []));

  const idRef = useRef('');
  const {loading, setLoading} = useLoading();
  const [subscription, setSubscription] = useState(null);
  const [contacts, setContacts] = useState([]);

  const loadSubscription = useCallback((id, force = false) => {
    if (force || idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.subscription.getSubscription(id, archive).then(retrieved => {
        setSubscription(retrieved);
        setLoading(false);

        const emailAddresses = [];
        const phoneNumbers = [];
        if (retrieved.job?.contact?.email) {
          emailAddresses.push({contact: retrieved.job.contact, type: 'email'});
        }
        if (retrieved.job?.contact?.mobile) {
          phoneNumbers.push({contact: retrieved.job.contact, type: 'sms'});
        }
        if (retrieved.job?.company?.contacts) {
          retrieved.job.company.contacts.filter(({is_end_user}) => !is_end_user).forEach(contact => {
            if (contact.email) {
              const index = emailAddresses.findIndex(({contact: c}) => c.id === contact.id);
              if (index < 0) {
                emailAddresses.push({contact, type: 'email'});
              }
            }
            if (contact.mobile) {
              const index = phoneNumbers.findIndex(({contact: c}) => c.id === contact.id);
              if (index < 0) {
                phoneNumbers.push({contact, type: 'sms'});
              }
            }
          });
        }
        setContacts([...emailAddresses, ...phoneNumbers]);
      }).catch(() => {
        toggleError('subscription', true);
        setLoading(false);
        setTimeout(() => {
          history.push(archive ? reverse(routes.archive.index, {tab: 'subscriptions'}) : routes.subscriptions.index);
        }, 3000);
      });
    }
  }, [archive, services, history, toggleError, setLoading]);

  useEffect(() => {
    if (match.params?.id) {
      loadSubscription(match.params?.id);
    }
  }, [history, match, subscription, loadSubscription]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...(archive ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs)]);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    let header = 'Loading';
    let trail = [];
    let canEdit = false;
    if (subscription) {
      header = subscription.reference;
      trail = [
        ...(subscription.archived ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs),
        {title: header, link: ''}
      ];
      if (!subscription.archived) {
        if (subscription.published_at) {
          canEdit = !subscription.completed_at && !subscription.cancelled_at && subscription.is_recurring;
        } else {
          canEdit = true;
        }
      }
    }
    setTitle(header);
    setCrumbs(trail);
    setCanEdit(canEdit);
  }, [subscription]);

  const handleSync = useCallback(() => {
    setLoading(true);
    services.subscription.amendTotal(subscription).then(updated => {
      setLoading(false);
      setSubscription(updated);
    }).catch(() => setLoading(false));
  }, [subscription, services, setLoading]);

  return subscription ? (
    <Wrapper title={title} loading={loading} job={subscription.job} crumbs={crumbs} errors={errors}>
      {subscription?.id ? (
        <StripeHolder key={subscription.account?.public} publicKey={subscription.account?.public}>
          <DetailColumns columns={[

            <Details
              subscription={subscription}
              onUpdate={setSubscription}
              onLoading={setLoading}
              openDialogs={openDialogs}
              toggleDialog={toggleDialog}
            />,

            <DetailPane
              title="Totals"
              details={[
                {title: 'Total (inc. Tax)', value: <Currency amount={subscription.total}/>},
                {
                  title: 'Tax',
                  value: <span><Currency amount={subscription.tax}/>&nbsp;
                    <em>({Math.round(100 * subscription.tax_rate)}%)</em></span>
                },
                ...(subscription.stripe_total ? [
                  {
                    title: 'Total in Stripe',
                    value: <Currency
                      classes={[classes.total, subscription.stripe_total === subscription.total ? classes.synced : classes.outdated]}
                      amount={subscription.stripe_total}
                    />
                  },
                ] : [])
              ]}
              actions={canEdit && subscription.published_at && subscription.stripe_total !== subscription.total ? [
                {title: 'Sync with Stripe', icon: <Sync/>, onClick: handleSync}
              ] : []}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <Box marginBottom={1}><Status entity={subscription}/></Box>},
                {title: 'Stripe Reference', value: subscription.stripe_reference ?? '-'},
                {title: 'Created', value: fullDateTime(subscription.created_at)},
                {title: 'Last Updated', value: fullDateTime(subscription.updated_at)},
                {title: 'Completed', value: subscription.completed_at ? fullDateTime(subscription.completed_at) : '-'},
                {title: 'Cancelled', value: subscription.cancelled_at ? fullDateTime(subscription.cancelled_at) : '-'},
                ...(!subscription.is_recurring && subscription.completed_at ? [
                  {
                    title: 'Reminders',
                    value: (() => {
                      if (subscription.silenced_at) {
                        return 'Silenced';
                      } else {
                        const sent = [28, 21, 14, 7, 2].filter(n => !!subscription[`reminded_${n}_at`]);
                        if (sent.length) {
                          return sent.map(n => `${n} days`).join(', ');
                        } else {
                          return '-';
                        }
                      }
                    })()
                  }
                ] : [])
              ]}
            />,
          ]}/>

          <Tabs
            subscription={subscription}
            onSubscriptionUpdated={setSubscription}
            canEdit={canEdit}
            onReloadSubscription={() => loadSubscription(subscription.id, true)}
            loading={loading}
            onLoading={setLoading}
            contacts={contacts}
          />

        </StripeHolder>
      ) : null}
    </Wrapper>
  ) : null;
}

export default Subscription;
