import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  no: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    }
  },
  yes: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,

      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    }
  },
}));

/**
 * A popup to confirm a user's action was correct
 *
 * @module ConfirmationDialog
 *
 * @param {boolean} open A toggle to determine if the dialog is showing
 * @param {string} title The title of the dialog
 * @param {string} message A message displayed on the dialog
 * @param {string} noLabel The text for the 'no' button
 * @param {string} yesLabel The text for the 'yes' button
 * @param {function} onClose A function to be executed when the dialog is closed
 * @param {any} data A payload passed back out through the `onClose` event
 *
 * @example
 * <ConfirmationDialog
 *   open={true}
 *   title="Are you sure?"
 *   message="Are you certain you want to do that?"
 *   noLabel="Not really"
 *   yesLabel="Absolutely"
 *   onClose={(confirmed, data) => console.log({confirmed, data})}
 *   data={{id: 1}}
 * />
 *
 */
const ConfirmationDialog = ({open, title, message, noLabel, yesLabel, onClose, data = null}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose(false, data)}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false, data)} variant="contained" color="primary" className={classes.no}>
            {noLabel ? noLabel : 'No'}
          </Button>
          <Button onClick={() => onClose(true, data)} variant="contained" color="primary" className={classes.yes}
                  autoFocus>
            {yesLabel ? yesLabel : 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  noLabel: PropTypes.string,
  yesLabel: PropTypes.string,
  onClose: PropTypes.func,
  data: PropTypes.any
};

export default ConfirmationDialog;
