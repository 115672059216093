import * as qs from 'qs';
import {clearCompanies} from '../store/actions/options';
import {API} from './API';

export class StockItemService {
  async getTableItems(params, additional = {}, exportName = null) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getItems(query, page, length, exportName);
  }

  async getItems(query, page, length, exportName = null) {
    return (await API.getConnection())
      .get(`stock-items${exportName ? '/export' : ''}`, {
        params: {...query, ...{page, length}, ...(exportName ? {name: exportName} : {})},
        paramsSerializer: params => qs.stringify(params),
        ...(exportName ? {responseType: 'blob'} : {})
      })
      .then(response => {
        if (exportName) {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: 'octet/stream'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportName} - Stock Report.xlsx`);
          document.body.appendChild(link);
          link.click();
        } else {
          return response.data;
        }
      })
      .catch(error => API.handleError(error));
  }

  async getItem(id, archive = false) {
    return (await API.getConnection())
      .get(`stock-items/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveItem(item) {
    let data = item;
    let method = 'post';
    let url = 'stock-items';
    if (item.id) {
      method = 'put';
      url = `stock-items/${item.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearCompanies());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async deleteItem(id) {
    return (await API.getConnection())
      .delete(`stock-items/${id}`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async restoreItem(id) {
    return (await API.getConnection())
      .put(`stock-items/${id}/restore`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async associateItem(id, contact) {
    return (await API.getConnection())
      .post(`stock-items/${id}/associate`, {contact: contact.id})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async subscribeItem(id) {
    return (await API.getConnection())
      .post(`stock-items/${id}/subscribed`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getItemActivity(id, query, page, length) {
    return (await API.getConnection())
      .get(`stock-items/${id}/activity`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => API.handleError(error));
  }

  async saveComment(id, comment, page = 1, length = 5) {
    return (await API.getConnection())
      .post(`stock-items/${id}/comment`, {comment}, {
        params: {page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async transferItems(ids, location) {
    return (await API.getConnection())
      .post('stock-items/transfer', {items: ids.map((id) => ({id, location_id: location}))})
      .then(() => true)
      .catch(error => API.handleError(error));
  }

  async allocateItems(ids, job, location = 0) {
    return (await API.getConnection())
      .post('stock-items/transfer', {
        items: ids.map((id) => ({
          id,
          job_id: job,
          ...(location ? {location_id: location} : {})
        }))
      })
      .then(() => true)
      .catch(error => API.handleError(error));
  }
}
