import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import Wrapper from './Wrapper';
import {ServiceContext} from '../../../../components/Services';
import qs from 'qs';
import {reverse} from 'named-urls';
import routes from '../../../../routes';
import {useHistory, useLocation} from 'react-router-dom';


const Verify = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const verified = useRef(false);

  useEffect(() => {
    if (!verified.current) {
      const query = qs.parse(location.search.replace('?', ''));
      if (query.code && query.state) {
        verified.current = true;
        setLoading(true);
        services.gatewayAccount.completeAuthorisation(query.code, query.state).then((verified) => {
          setLoading(false);
          history.push(reverse(`${routes.developer.gatewayAccounts.detail}`, {id: verified.id}));
        }).catch(() => setLoading(false))
      }
    }
  }, [services, history, location.search]);

  return (
    <Wrapper
      title="Verifying Connection, Please Wait"
      loading={loading}
      crumbs={useMemo(() => [...Wrapper.standardCrumbs, {title: 'Verifying Connection'}], [])}
      errors={{}}
    />
  );
};

export default Verify;
