import {styled, TablePagination} from '@mui/material';
import * as React from 'react';

const Table = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  transition: '0.25s opacity ease-in-out',
  width: '100%',

  '&.loading': {
    opacity: 0.4,
    pointerEvents: 'none'
  },
}));

const Row = styled('div')(({theme}) => ({
  display: 'flex',
  borderBottom: `2px solid ${theme.palette.grey['100']}`,

  '&:last-of-type': {
    borderBottom: 0,
  }
}));

const Column = styled('div')(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  padding: theme.spacing(1),

  '&.disabled': {
    opacity: 0.4,
  }
}));

const Actions = styled(Row)(({theme}) => ({
  justifyContent: 'flex-end',
  padding: theme.spacing(1),

  '& h3': {
    alignSelf: 'center',
    color: theme.palette.secondary.main,
    flex: 1,
    fontSize: '1.4em',
    margin: 0,
    padding: 0
  },
}));

const NoRecords = styled(Row)(({theme}) => ({
  padding: theme.spacing(1),
  justifyContent: 'center',
}));

const Header = styled(Column)(() => ({
  fontWeight: '700',
}));

const Action = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flex: '0 0 50px',
  justifyContent: 'center',
  padding: 5,
  width: 50
}));

const PaginationRow = styled(Row)(() => ({
  justifyContent: 'flex-end'
}));

const Pagination = ({total, page, onPageChange, rows, onRowsChange}) => (
  <PaginationRow>
    {total ? (
      <TablePagination
        component="div"
        count={total}
        page={page - 1}
        onPageChange={(e, pageNumber) => onPageChange(pageNumber + 1)}
        rowsPerPage={rows}
        onRowsPerPageChange={(e) => onRowsChange(e.target.value)}
        rowsPerPageOptions={[50, 100, 250]}
      />
    ) : null}
  </PaginationRow>
);

const Flagged = styled('span')(({theme}) => ({
  backgroundColor: theme.palette.common.black,
  borderRadius: 4,
  color: theme.palette.common.white,
  fontSize: '0.8em',
  fontWeight: '400',
  margin: theme.spacing(0, 0, 0, 2),
  padding: theme.spacing(0.75, 1.5),
}));

const Notice = ({display, colour, children}) => (
  display ? <Flagged {...colour ? {style: {backgroundColor: colour}} : {}}>{children}</Flagged> : null
);

Table.Row = Row;
Table.Column = Column;
Table.Header = Header;
Table.Actions = Actions;
Table.Action = Action;
Table.NoRecords = NoRecords;
Table.Pagination = Pagination;
Table.Notice = Notice;

export default Table;
