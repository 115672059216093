import React from 'react';
import CompanyFields from '../CompanyForm/Fields';
import ContactFields from '../ContactForm/Fields';
import {ContactFields as LocationFields} from '../LocationForm/Fields';

export default function Fields({entities: {contact, company, location}}) {
  return (
    <>
      <ContactFields
        contact={contact}
        prefix="contact"
        multiplePanes={false}/>
      <CompanyFields
        company={company}
        validate={false}
        prefix="company"
        multiplePanes={false}/>
      <LocationFields
        location={location}
        prefix="location"
        multiplePanes={false}/>
    </>
  );
}
