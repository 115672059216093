import * as qs from 'qs';
import {API} from './API';

export class ScorpionLogService {
  async getTableLogs(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getLogs(query, page, length);
  }

  async getLogs(query, page, length) {
    return (await API.getConnection())
      .get('scorpion-logs', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}
