import {Paper, styled} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import Emails from '../OutboundMessages/Table';
import Items from './Items';
import Payments from './Payments';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
  }
}));

const Tabs = ({subscription, onSubscriptionUpdated, canEdit, loading, contacts}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Items',
      slug: '',
      path: reverse(subscription.archived ? routes.archive.subscription.detail : routes.subscriptions.detail, {
        id: subscription?.id ?? 0,
        tab: ''
      })
    },
    ...(subscription.published_at ? [{
      title: 'Payments',
      slug: 'payments',
      path: reverse(subscription.archived ? routes.archive.subscriptions.tab : routes.subscriptions.tab, {
        id: subscription?.id ?? 0,
        tab: 'payments'
      })
    }] : []),
    {
      title: 'Emails',
      slug: 'emails',
      path: reverse(subscription.archived ? routes.archive.subscription.tab : routes.subscriptions.tab, {
        id: subscription?.id ?? 0,
        tab: 'emails'
      })
    },
  ], [subscription]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Container>
          <Items
            subscription={subscription}
            onSubscriptionUpdated={onSubscriptionUpdated}
            canEdit={canEdit}
            loading={loading}
          />
        </Container>
      </Switched>
      <Switched tab="payments" currentTab={tab}>
        <Container>
          <Payments
            subscription={subscription}
            onSubscriptionUpdated={onSubscriptionUpdated}
            loading={loading}
            contacts={contacts}
          />
        </Container>
      </Switched>
      <Switched tab="emails" currentTab={tab}>
        <Container>
          <Emails subscription={subscription}/>
        </Container>
      </Switched>
    </>
  );
};

export default Tabs;
