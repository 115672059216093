import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import VirtualProductForm from '../../forms/VirtualProductForm';
import Wrapper from './Wrapper';

export default function VirtualProduct({history, archive = false}) {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    product: 'Sorry the virtual product could not be accessed'
  }), []));

  const {
    entity: product,
    setEntity: setProduct,
    loadEntity: loadProduct,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.virtualProduct.getProduct,
    archive,
    useCallback((product) => [{title: product.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('product', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setProduct(updated);
    } else if (product?.id) {
      loadProduct(product.id)
    }
  }, [toggleDialog, setProduct, loadProduct, product]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.virtualProduct.restoreProduct(product.id).then((restored) => {
      setLoading(false);
      setProduct(restored);
      history.push(reverse(`${routes.stock.virtualProducts.detail}`, {id: product.id}))
    }).catch(() => setLoading(false));
  }, [services, history, product, setProduct, setLoading]);

  return (
    <Wrapper title="Virtual Product" loading={loading} product={product} crumbs={crumbs} errors={errors}>
      {product?.id ? (
        <DetailColumns columns={[

          <DetailPane
            title="Virtual Product Details"
            minHeight={400}
            actions={!product.archived ? [
              {title: 'Edit Virtual Product', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
              {title: 'Delete Virtual Product', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
            ] : [
              {title: 'Restore Virtual Product', icon: <RestoreFromTrashIcon/>, onClick: handleRestore},
            ]}
            details={[
              {title: 'Name', value: product.name ?? '-'},
              {title: 'Reference', value: product.reference ?? '-'},
              {title: 'Xero Sales Code', value: product.xero_account_code ?? '-'},
              {title: 'Xero Item Code', value: product.xero_item_code ?? '-'},
              {title: 'Price', value: <Currency amount={product.price}/>},
              {title: 'Cost', value: product.cost ? <Currency amount={product.cost}/> : 'None Set'},
              {title: 'Length (Months)', value: product.length ?? '-'},
              {
                title: 'Associated Product',
                value: product.product?.name ?? '-',
                ...(product.product ? {route: reverse(routes[product.product.archived ? 'archive' : 'stock'].products.detail, {id: product.product.id})} : {})
              }
            ]}
            dialogs={[
              (props) => (
                <FormDialog
                  {...props}
                  title="Edit Virtual Product"
                  open={openDialogs.edit ?? false}
                  onClose={() => toggleDialog('edit', false)}
                  render={(props) => (
                    <VirtualProductForm
                      {...props}
                      product={product}
                      onSaved={handleSaved}/>
                  )}
                />
              ),
              (props) => (
                <ConfirmationDialog
                  {...props}
                  open={openDialogs.delete ?? false}
                  title="Delete"
                  message={product ? `Are you sure you want to delete ${product.name}?` : ''}
                  onClose={confirmed => {
                    toggleDialog('delete', false);
                    if (confirmed) {
                      setLoading(true);
                      services.virtualProduct.deleteProduct(product.id).then(() => {
                        setLoading(false);
                        history.push(reverse(routes.stock.index, {tab: 'virtual-products'}))
                      }).catch(() => setLoading(false));
                    }
                  }}
                />
              )
            ]}
          />,

          <DetailPane
            title="Admin Details"
            details={[
              {title: 'Created', value: fullDateTime(product.created_at)},
              {title: 'Last Updated', value: fullDateTime(product.updated_at)},
              ...(product.archived ? [{title: 'Archived', value: fullDateTime(product.deleted_at)}] : [])
            ]}
          />
        ]}/>
      ) : null}
    </Wrapper>
  );
}
