import {Breadcrumbs, ErrorPopup, Notice, Title} from '@management-ui/core';
import {reverse} from 'named-urls';
import React from 'react';
import routes from '../../../../routes';

const Wrapper = ({title, loading, titleControls, order, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading} controls={titleControls}/>
      <Notice
        display={order && order.archived}
        message={[
          'This order has been archived and is therefore no longer available.',
          'You won\'t be able to modify it unless it is restored.'
        ].join(' ')}
      />
      {children}
      <ErrorPopup errors={['order']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Orders', link: reverse(routes.orders.index, {tab: 'orders'})}
];
Wrapper.archiveCrumbs = [
  {title: 'Archive', link: reverse(routes.archive.index)},
  {title: 'Orders', link: reverse(routes.archive.index, {tab: 'orders'})}
];

export default Wrapper;
