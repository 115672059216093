import {FormDialog} from '@management-ui/core';
import {Paper, styled} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import JobProductForm from '../../forms/JobProductForm';
import DeliveryLink from './DeliveryLink';
import JobLink from './JobLink';

const Container = styled(Paper)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2, 0, 0),
  overflow: 'hidden',
}));

const Items = ({order, onOrderUpdated, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [job, setJob] = useState(null);

  useEffect(() => {
    setItems(order?.items ?? []);
  }, [order]);

  const handleUpdate = useCallback((item, product = null, delivery = null) => {
    setJob(null);
    onLoading(true);
    services.order.updateItem(order, item, product ? {job_product_id: product.id} : {delivery_id: delivery.id}).then((updated) => {
      onLoading(false);
      onOrderUpdated(updated);
    }).catch(() => onLoading(false));
  }, [order, onOrderUpdated, onLoading, services]);

  return (
    <Container>
      <Table className={loading ? 'loading' : ''}>
        <Table.Row>
          <Table.Header>Description</Table.Header>
          <Table.Header>Add-ons</Table.Header>
          <Table.Header>Unit Price</Table.Header>
          <Table.Header>Discount</Table.Header>
          <Table.Header>Quantity</Table.Header>
          <Table.Header>Total</Table.Header>
          {order.status.slug === 'paid' ? <Table.Header>Job / Delivery</Table.Header> : null}
        </Table.Row>
        {items.length > 0 ? (
          items.map((item, index) => (
            <Table.Row key={index}>
              <Table.Column>{item['description']}</Table.Column>
              <Table.Column>{item['add_ons']}</Table.Column>
              <Table.Column><Currency amount={item['unit_price']}/></Table.Column>
              <Table.Column><Currency amount={item['discount']}/></Table.Column>
              <Table.Column>{item['quantity']}</Table.Column>
              <Table.Column><Currency amount={item['total']}/></Table.Column>
              {order.status.slug === 'paid' ? (
                <Table.Column>
                  {item ? (
                    <>
                      <JobLink
                        order={order}
                        job={item['job_product']?.job}
                        delivery={item['delivery']}
                        onLink={(job) => setSelected(item) || setJob(job)}
                      />
                      <DeliveryLink
                        order={order}
                        job={item['job_product']?.job}
                        delivery={item['delivery']}
                        onLink={(delivery) => handleUpdate(item, null, delivery)}
                      />
                    </>
                  ) : null}
                </Table.Column>
              ) : null}
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no items associated with this order</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title="Add Stock to Job"
        open={job != null}
        maxWidth="sm"
        onClose={() => setJob(null)}
        render={(props) => (
          <JobProductForm
            {...props}
            job={job}
            product={selected ? {quantity: selected.quantity} : {}}
            onSaved={(updated) => handleUpdate(selected ?? {}, updated.products[updated.products.length - 1])}
          />
        )}
      />
    </Container>
  );
};

export default Items;
