import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import LocationNameForm from '../../forms/LocationNameForm';

export default function LocationNames() {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Location Names"
      columns={useMemo(() => [
        {title: 'Label', field: 'label'}
      ], [])}
      loadData={query => new Promise((resolve, reject) => {
        services.locationName.getTableNames(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Name',
        render: (props) => <LocationNameForm name={{}} {...props}/>
      }}
      editForm={{
        render: (name, props) => <LocationNameForm name={name} {...props}/>
      }}
    />
  );
}
