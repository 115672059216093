import React, {useEffect, useRef, useState} from 'react';

const LoadableImage = ({id, width, height, onLoad}) => {
  const loaded = useRef('');
  const [data, setData] = useState('');

  useEffect(() => {
    if (loaded.current !== id) {
      loaded.current = id;
      onLoad().then(image => setData(image)).catch(() => setData(''));
    }
  }, [id, onLoad]);

  return data ? <img width={200} height={(height / width) * 200} src={data} alt={id}/> : null
};

export default LoadableImage;
