import {Checkbox, Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({type = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={type}
      prefix={prefix}
      panes={[
        {
          title: 'Type Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="scorpion_reference"
              prefix={prefix}
              label="Scorpion Reference"
              rules={{required: 'Please enter a scorpion reference'}}
            />,
            <TextField
              name="ukvd_reference"
              prefix={prefix}
              label="UKVD Reference"
              rules={{required: 'Please enter a UKVD reference'}}
            />
          ]
        },
        {
          title: 'Type Settings',
          fields: [
            <Checkbox
              name="is_default"
              prefix={prefix}
              label="Default Fuel Type?"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Type Details'}
    >{children}</Panes>
  );
}
