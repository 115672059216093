import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const LinkLocationForm = forwardRef(({location = {}, company = null, contact = null, onSelected = () => null}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={location}
      type="location"
      fieldsComponent={(props) => <Fields {...props} company={company} contact={contact} onSelect={onSelected}/>}
      onSave={() => onSelected(null)}
    />
  );
});

export default LinkLocationForm;
