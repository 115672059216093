import {Checkbox, Panes, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React from 'react';

const Fields = ({item = {}, prefix = ''}) => (
  <Panes
    entity={item}
    prefix={prefix}
    panes={[
      {
        title: 'Information',
        fields: [
          <TextField
            name="description"
            prefix={prefix}
            label="Description"
            rules={{required: 'Please enter a description'}}
            fieldProps={{multiline: true, rows: 4}}
          />
        ]
      },
      {
        title: 'Amounts', fields: [
          <TextField
            name="unit_price"
            prefix={prefix}
            label="Unit Price"
            rules={{required: 'Please enter a unit price'}}
            fieldProps={{
              InputProps: {
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }
            }}
          />,
          <TextField
            name="discount"
            prefix={prefix}
            label="Discount (per unit)"
            fieldProps={{
              InputProps: {
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }
            }}
          />,
          <Checkbox
            name="excluding_tax"
            prefix={prefix}
            label="Is this price excluding VAT? (VAT will be added on save)"
          />,
          <TextField
            name="quantity"
            prefix={prefix}
            label="Quantity"
            rules={{required: 'Please enter a quantity'}}
          />
        ]
      }
    ]}
    title={''}
  />
);

export default Fields;
