import {ConfirmationDialog, FormDialog, name} from '@management-ui/core';
import {Add, Link, LinkOff, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import ContactForm from '../../forms/ContactForm';
import LinkContactForm from '../../forms/LinkContactForm';

const Contacts = ({company, onReloadCompany, loading, onLoading, endUsers = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [showNew, setShowNew] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleUnlink = useCallback((confirmed) => {
    setShowUnlink(false);
    if (confirmed) {
      onLoading(true);
      services.company.linkContact(company, selected).then(() => {
        onLoading(false);
        onReloadCompany();
      }).catch(() => onLoading(false));
    }
  }, [company, onLoading, services, selected, onReloadCompany]);

  const goToDetail = useCallback((contact) => {
    history.push(reverse(contact.archived ? routes.archive.contacts.tab : routes.contacts.tab, {id: contact.id, tab: 'companies'}));
  }, [history]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>{endUsers ? 'End Users' : 'Contacts'}</h3>
          <Tooltip title="Link Existing Contact">
            <span>
              <IconButton
                onClick={() => setShowLink(true)}
                disabled={company.archived}>
                <Link/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Add New Contact">
            <span>
              <IconButton
                onClick={() => setShowNew(true)}
                disabled={company.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Name</Table.Header>
          <Table.Header>Position</Table.Header>
          <Table.Header>Telephone</Table.Header>
          <Table.Header>Mobile</Table.Header>
          <Table.Header>Email</Table.Header>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {company[endUsers ? 'end_users' : 'contacts'].length > 0 ? (
          company[endUsers ? 'end_users' : 'contacts'].map((contact, index) => (
            <Table.Row key={index}>
              <Table.Column>
                <span>
                  {name(contact)}{contact.archived ? <><br/><strong>Archived</strong></> : null}
                </span>
              </Table.Column>
              <Table.Column>{contact.position ?? '-'}</Table.Column>
              <Table.Column>{contact.phone ?? '-'}</Table.Column>
              <Table.Column>{contact.mobile ?? '-'}</Table.Column>
              <Table.Column>{contact.email ?? '-'}</Table.Column>
              <Table.Action>
                <Tooltip title="View Contact">
                  <IconButton onClick={() => goToDetail(contact)}>
                    <Visibility/>
                  </IconButton>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Unlink Contact">
                  <span>
                    <IconButton
                      onClick={() => setSelected(contact) || setShowUnlink(true)}
                      disabled={company.archived}>
                      <LinkOff/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no {endUsers ? 'end users' : 'contacts'} associated with this
            company</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title={`Add a New ${[endUsers ? 'End User' : 'Contact']}`}
        open={showNew}
        maxWidth="md"
        onClose={() => setShowNew(false)}
        render={(props) => (
          <ContactForm
            {...props}
            contact={{}}
            company={company}
            endUser={endUsers}
            onSaved={() => setShowNew(false) || onReloadCompany()}
          />
        )}
      />
      <FormDialog
        title="Link Existing Contact"
        open={showLink}
        maxWidth="sm"
        onClose={() => setShowLink(false)}
        render={props => (
          <LinkContactForm
            contact={{}}
            company={company}
            endUser={endUsers}
            onSaved={() => setShowLink(false) || onReloadCompany()}
            {...props}
          />
        )}
      />
      <ConfirmationDialog
        title="Unlink Contact"
        message="Are you sure you want to unlink this contact?"
        open={showUnlink}
        onClose={handleUnlink}
      />
    </>
  );
};

export default Contacts;
