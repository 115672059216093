import {Breadcrumbs, DataTable} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../components/Services';
import UserForm from '../forms/UserForm';

export default function Administrators() {
  const services = useContext(ServiceContext);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Administrators'}]}/>
      <Paper>
        <DataTable
          title="Administrators"
          columns={useMemo(() => [
            {title: 'Name', field: 'name', render: data => `${data.first_name} ${data.last_name}`},
            {title: 'Email', field: 'email'},
            {title: 'Has Engineer Access?', field: 'is_engineer', lookup: {true: 'Yes', false: 'No'}},
            {title: 'Active?', field: 'is_active', lookup: {true: 'Yes', false: 'No'}},
          ], [])}
          loadData={query => new Promise((resolve, reject) => {
            services.user.getTableUsers(query, {admins: '1'})
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch(() => {
              reject();
            });
          })}
          newForm={{
            title: 'Add New Administrator',
            render: (props) => <UserForm user={{}} admin={true} {...props}/>
          }}
          editForm={{
            render: (user, props) => <UserForm user={user} admin={true} {...props}/>
          }}
        />
      </Paper>
    </div>
  );
}
