import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {AssignmentTurnedIn, MailOutline} from '@mui/icons-material';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import {itemTitle} from '../../../../formatters';
import routes from '../../../../routes';
import Status from '../../components/Status';
import StockItemForm from '../../forms/StockItemForm';
import Actions from './Actions';
import AssociatedComponentSuppliers from './AssociatedComponentSuppliers';
import Wrapper from './Wrapper';

const useStyles = makeStyles(() => ({
  holder: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }
}));

export default function StockItem({history, archive = false}) {
  const classes = useStyles();
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    item: 'Sorry the item could not be accessed',
    activity: 'Sorry the item activity could not be loaded'
  }), []));

  const {
    entity: item,
    setEntity: setItem,
    loadEntity: loadItem,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.stockItem.getItem,
    archive,
    useCallback((item) => [{title: itemTitle(item)}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('item', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'subscribe', 'delete']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setItem(updated);
    } else if (item?.id) {
      loadItem(item.id)
    }
  }, [toggleDialog, setItem, loadItem, item]);

  return (
    <Wrapper title="Stock Item" loading={loading} item={item} crumbs={crumbs} errors={errors}>
      {item?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Stock Item Details"
              minHeight={400}
              actions={!item.archived ? [
                {title: 'Edit Item', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                ...(item.associated_serial_number ? [
                  {
                    title: 'Send to supplier',
                    tooltipPlacement: 'top',
                    icon: (
                      <AssociatedComponentSuppliers
                        item={item}
                        onSent={(updated) => setItem(updated)}
                      >{(onDisplay, anchorRef) => (
                        <span className={classes.holder} ref={anchorRef} onClick={onDisplay}><MailOutline/></span>
                      )}</AssociatedComponentSuppliers>
                    ),
                    onClick: () => null
                  }
                ] : []),
                ...(item.installed_at && !item.subscribed_at ? [
                  {
                    title: 'Mark as Subscribed',
                    icon: <AssignmentTurnedIn/>,
                    onClick: () => toggleDialog('subscribe', true)
                  }
                ] : []),
                {title: 'Delete Item', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : item.deleted_at ? [
                {
                  title: 'Restore Item', icon: <RestoreFromTrashIcon/>, onClick: () => {
                    setLoading(true);
                    services.stockItem.restoreItem(item.id).then(() => {
                      setLoading(false);
                      history.push(reverse(`${routes.stock.items.detail}`, {id: item.id}))
                    }).catch(() => setLoading(false));
                  }
                },
              ] : []}
              details={[
                {title: 'Serial Number', value: item.serial_number ?? '-'},
                {title: 'Associated Component Serial Number', value: item.associated_serial_number ?? '-'},
                {title: 'Purchase Price', value: <Currency amount={item.purchase_price}/>},
                {title: 'Sale Price', value: <Currency amount={item.sale_price}/>},
                {title: 'Order Number', value: item.po_number ?? '-'},
                {
                  title: 'Job',
                  value: item.job?.reference ?? '-',
                  route: item.job ? reverse(item.job.archived ? routes.archive.jobs.detail : routes.jobs.detail, {id: item.job.id}) : null
                },
                {
                  title: 'Product',
                  value: item.product?.name ?? '-',
                  route: item.product ? reverse(item.product.archived ? routes.archive.products.detail : routes.stock.products.detail, {id: item.product.id}) : null
                },
                {
                  title: 'Location',
                  value: item.location?.name ?? '-',
                  route: item.location ? reverse(item.location.archived ? routes.archive.locations.detail : routes.stock.locations.detail, {id: item.location.id}) : null
                },
                {
                  title: 'Supplier',
                  value: item.supplier?.name ?? '-',
                  route: item.supplier ? reverse(item.supplier.archived ? routes.archive.companies.detail : routes.companies.detail, {id: item.supplier.id}) : null
                },
                {
                  title: 'Vehicle',
                  value: item.vehicle?.registration ?? '-',
                  route: item.vehicle ? reverse(item.vehicle.archived ? routes.archive.vehicles.detail : routes.vehicles.detail, {id: item.vehicle.id}) : null
                },
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Item"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <StockItemForm
                        {...props}
                        item={item}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.subscribe ?? false}
                    title="Mark as Subscribed"
                    message={item ? 'Are you sure you want to mark this unit and subscribed and stop any further activation reminders?' : ''}
                    onClose={confirmed => {
                      toggleDialog('subscribe', false);
                      if (confirmed) {
                        setLoading(true);
                        services.stockItem.subscribeItem(item.id).then((updated) => {
                          setLoading(false);
                          setItem(updated)
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={item ? `Are you sure you want to delete ${itemTitle(item)}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.stockItem.deleteItem(item.id).then(() => {
                          setLoading(false);
                          history.push(reverse(routes.stock.index, {tab: 'items'}))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <Box marginBottom={1}><Status entity={item}/></Box>},
                {title: 'Created', value: fullDateTime(item.created_at)},
                {title: 'Last Updated', value: fullDateTime(item.updated_at)},
                {title: 'Last Moved', value: item.moved_at ? fullDateTime(item.moved_at) : '-'},
                ...(item.installed_at ? [
                  {title: 'Installed', value: fullDateTime(item.installed_at)},
                  {
                    title: 'Reminders',
                    value: (() => {
                      const sent = [7, 14, 21, 28].filter(n => !!item[`reminded_${n}_at`]);
                      if (sent.length) {
                        return sent.map(n => `${n} days`).join(', ');
                      } else {
                        return '-';
                      }
                    })()
                  },
                  {title: 'Subscribed', value: item.subscribed_at ? fullDateTime(item.subscribed_at) : '-'},
                ] : []),
                ...(item.archived ? [{
                  title: 'Archived',
                  value: item.deleted_at ? fullDateTime(item.deleted_at) : 'Relation Deleted'
                }] : [])
              ]}
            />,

            <DetailPane
              title="Recent Activity"
              actions={[
                ...(item.archived ? [] : [{
                  title: 'Add Comment',
                  icon: <AddCommentIcon/>,
                  onClick: () => toggleDialog('note', true)
                }]),
                {
                  title: 'View all Activity', icon: <QuestionAnswerIcon/>, onClick: () => {
                    history.push(reverse(item.archived ? routes.archive.items.activity : routes.stock.items.activity, {id: item.id}))
                  }
                }
              ]}
            ><Actions
              item={item}
              setLoading={setLoading}
              openDialogs={openDialogs}
              toggleDialog={toggleDialog}
              toggleError={toggleError}
            /></DetailPane>
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
}
