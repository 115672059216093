import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  FormDialog,
  fullDateTime,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import {useLoading} from '../../../../hooks';
import routes from '../../../../routes';
import CompanyForm from '../../forms/CompanyForm';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const Company = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    company: 'Sorry the company could not be accessed',
    link: 'Sorry the contact could not be linked',
    unlink: 'Sorry the contact could not be unlinked'
  }), []));

  const idRef = useRef('');
  const {loading, setLoading} = useLoading();
  const [company, setCompany] = useState(null);

  const loadCompany = useCallback((id, force = false) => {
    if (force || idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.company.getCompany(id, archive).then(retrieved => {
        setCompany(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('company', true);
        setLoading(false);
        setTimeout(() => {
          history.push(archive ? reverse(routes.archive.index, {tab: 'companies'}) : routes.companies.index);
        }, 3000);
      });
    }
  }, [archive, services, history, toggleError, setLoading]);

  useEffect(() => {
    if (match.params?.id) {
      loadCompany(match.params?.id);
    }
  }, [history, match, company, loadCompany]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...(archive ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs)]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [];
    if (company) {
      header = company.name;
      trail = [
        ...(company.archived ? Wrapper.archiveCrumbs : Wrapper.standardCrumbs),
        {title: header, link: ''}
      ];
    }
    setTitle(header);
    setCrumbs(trail);
  }, [company]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setCompany(updated);
    } else if (company?.id) {
      loadCompany(company.id)
    }
  }, [toggleDialog, setCompany, loadCompany, company]);

  const handleRestore = useCallback(() => {
    setLoading(true);
    services.company.restoreCompany(company.id).then((restored) => {
      setLoading(false);
      setCompany(restored);
      if (match.params?.tab) {
        history.push(reverse(routes.companies.tab, {id: company.id, tab: match.params.tab}))
      } else {
        history.push(reverse(routes.companies.detail, {id: company.id}))
      }
    }).catch(() => setLoading(false));
  }, [services, history, match, company, setLoading]);

  return (
    <Wrapper
      title={title}
      loading={loading}
      company={company}
      crumbs={crumbs}
      errors={errors}
    >
      {company?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Company Details"
              actions={!company.archived ? [
                {title: 'Edit Company', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Company', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {title: 'Restore Company', icon: <RestoreFromTrashIcon/>, onClick: handleRestore}
              ]}
              details={[
                {title: 'Name', value: company.name},
                {title: 'Telephone Number', value: company.phone ?? '-'},
                {title: 'Website Address', value: company.website ?? '-', link: company?.website},
                {title: 'Is Installer?', value: <YesNo value={company.is_installer}/>},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Company"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <CompanyForm
                        {...props}
                        company={company}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={company ? `Are you sure you want to delete ${company.name}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.company.deleteCompany(company.id).then(() => {
                          setLoading(false);
                          history.push(reverse(`${routes.companies.index}`))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Stock Details"
              details={[
                {title: 'Is Manufacturer?', value: <YesNo value={company.is_manufacturer}/>},
                {title: 'Is Supplier?', value: <YesNo value={company.is_supplier}/>},
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: fullDateTime(company.created_at)},
                {title: 'Last Updated', value: fullDateTime(company.updated_at)},
                ...(company.import_batch ? [{title: 'Import Batch', value: company.import_batch}] : []),
                ...(company.archived ? [{title: 'Archived', value: fullDateTime(company.deleted_at)}] : [])
              ]}
            />,
          ]}/>

          <Tabs
            company={company}
            onCompanyUpdated={handleSaved}
            onReloadCompany={() => loadCompany(company.id, true)}
            loading={loading}
            onLoading={setLoading}
            toggleError={toggleError}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export default Company;
