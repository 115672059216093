import {ConfirmationDialog, FormDialog, fullDate, time} from '@management-ui/core';
import {Add, Delete, Edit, Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import AllocationDetailsDialog from '../../components/AllocationDetailsDialog';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import EngineerLabel from '../../components/EngineerLabel';
import Status from '../../components/Status';
import JobAllocationForm from '../../forms/JobAllocationForm';

const Allocations = ({job, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [allocations, setAllocations] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const loaded = useRef(false);

  const loadAllocations = useCallback(() => {
    onLoading(true);
    services.job.getAllocations({filter: {job: job.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setAllocations(response.data);
      }).catch(() => onLoading(false));
  }, [job, onLoading, services]);

  useEffect(() => {
    if (job) {
      if (!loaded.current) {
        loaded.current = true;
        loadAllocations();
      }
    }
  }, [job, loadAllocations]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      onLoading(true);
      services.job.deleteAllocation(selected).then(() => {
        onLoading(false);
        loadAllocations();
      }).catch(() => onLoading(false));
    }
  }, [onLoading, services, selected, loadAllocations]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Allocations</h3>
          <Tooltip title="Refresh">
            <IconButton onClick={() => loadAllocations()}>
              <Refresh/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Add New Allocation">
            <span>
              <IconButton
                onClick={() => history.push(reverse(routes.jobs.allocations.new, {id: job.id}))}
                disabled={job.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Engineer</Table.Header>
          <Table.Header>Date</Table.Header>
          <Table.Header>Time</Table.Header>
          <Table.Header>Status</Table.Header>
          <Table.Action/>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {allocations.length > 0 ? (
          allocations.map((allocation, index) => (
            <Table.Row key={index}>
              <Table.Column>
                {allocation.engineer ? (
                  <EngineerLabel engineer={allocation.engineer}/>
                ) : 'Unallocated'}
              </Table.Column>
              <Table.Column>{fullDate(allocation.date)}</Table.Column>
              <Table.Column>{time(allocation.start_at)} - {time(allocation.end_at)}</Table.Column>
              <Table.Column><Status entity={allocation}/></Table.Column>
              <Table.Action>
                <Tooltip title="View Allocation">
                  <span>
                    <IconButton
                      onClick={() => setSelected(allocation) || setShowDetails(true)}
                      disabled={job.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Edit Allocation">
                  <span>
                    <IconButton
                      onClick={() => history.push(reverse(routes.jobs.allocations.edit, {
                        jobID: allocation.job.id,
                        id: allocation.id
                      }))}
                      disabled={job.archived}>
                      <Edit/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Delete Allocation">
                  <span>
                    <IconButton
                      onClick={() => setSelected(allocation) || setShowDelete(true)}
                      disabled={job.archived}>
                      <Delete/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no allocations associated with this job</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title={selected ? 'Edit Allocation' : 'Add New Allocation'}
        open={showEdit}
        maxWidth="sm"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <JobAllocationForm
            {...props}
            job={job}
            allocation={selected ?? {}}
            additionalAvailability={[]}
            onSaved={() => setShowEdit(false) || loadAllocations()}
          />
        )}
      />
      <AllocationDetailsDialog
        allocation={selected}
        onAllocationUpdated={() => setShowDetails(false) || loadAllocations()}
        open={showDetails}
        onClose={() => setShowDetails(false)}
        onDeleted={() => setShowDetails(false) || loadAllocations()}
      />
      <ConfirmationDialog
        title="Delete Allocation"
        message="Are you sure you want to delete this allocation?"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
};

export default Allocations;
