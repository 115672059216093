import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  fullDate,
  fullDateTime,
  time,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import routes from '../../../../../routes';
import EngineerLabel from '../../../components/EngineerLabel';
import Status from '../../../components/Status';
import Wrapper from '../Wrapper';

const Allocation = ({history, archive = false}) => {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    allocation: 'Sorry the allocation could not be accessed'
  }), []));

  const {
    entity: allocation,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.job.getAllocation,
    archive,
    useCallback((allocation) => [
      {
        title: allocation.job.reference,
        link: reverse(routes.jobs.detail, {id: allocation.job.id})
      },
      {title: `${fullDate(allocation.date)} | ${time(allocation.start_at)} - ${time(allocation.end_at)}`}
    ], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('allocation', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['delete']);

  return allocation ? (
    <Wrapper title="Allocation" loading={loading} job={allocation.job} crumbs={crumbs} errors={errors}>
      {allocation?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Allocation Details"
              minHeight={400}
              actions={!allocation.job.archived ? [
                {
                  title: 'Edit Allocation',
                  icon: <EditIcon/>,
                  onClick: () => history.push(reverse(routes.jobs.allocations.edit, {
                    jobID: allocation.job.id,
                    id: allocation.id
                  }))
                },
                {title: 'Delete Allocation', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : []}
              details={[
                {
                  title: 'Engineer',
                  value: (
                    <Box marginBottom={1}>
                      <EngineerLabel engineer={allocation.engineer}/>
                    </Box>
                  )
                },
                {title: 'Date', value: fullDate(allocation.date)},
                {title: 'Start Time', value: time(allocation.start_at)},
                {title: 'End Time', value: time(allocation.end_at)}
              ]}
              dialogs={[
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={allocation ? 'Are you sure you want to delete this allocation?' : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.job.deleteAllocation(allocation.job, allocation.id).then(() => {
                          setLoading(false);
                          history.push(reverse(`${routes.jobs.detail}`, {id: allocation.id}))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <Box marginBottom={1}><Status entity={allocation}/></Box>},
                {title: 'Created', value: fullDateTime(allocation.created_at)},
                {title: 'Last Updated', value: fullDateTime(allocation.updated_at)}
              ]}
            />,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  ) : null;
}

export default Allocation;
