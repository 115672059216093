import {MapServices} from '@management-ui/maps';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material';
import React, {useEffect} from 'react';
import {Provider, useDispatch, useSelector, useStore} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Services from '../components/Services';
import routes from '../routes';
import {API} from '../services/API';
import {store} from '../store';
import {getUser} from '../store/actions/auth';
import Access from './access/Access';
import Admin from './admin/Admin';
import './App.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#DCDC00',
      contrastText: '#373735'
    },
    secondary: {
      main: '#373735',
      contrastText: '#fff'
    },
    background: {
      default: '#fafafa',
    }
  },
  typography: {
    fontFamily: [
      'Nunito Sans'
    ],
    body1: {
      fontSize: '0.875rem'
    }
  }
});

function Axios({children}) {
  const store = useStore();

  useEffect(() => {
    API.initialise(store);
  }, [store]);
  return <>{children}</>;
}

export default function App() {
  const user = useSelector(state => state['auth'].user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === false) {
      dispatch(getUser());
    }
  }, [user, dispatch]);

  let router = null;

  if (user !== false) {
    if (user) {
      router = (
        <Router>
          <Switch>
            <Route path="/auth"><Redirect to={`${routes.dashboard}`}/></Route>
            <Route path="/"><Admin/></Route>
          </Switch>
        </Router>
      );
    } else {
      router = (
        <Router>
          <Switch>
            <Route path="/auth"><Access/></Route>
            <Route path="/"><Redirect to={`${routes.login}`}/></Route>
          </Switch>
        </Router>
      )
    }
  }
  return (
    <Services>
      <MapServices>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Axios>
                {router}
              </Axios>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </MapServices>
    </Services>
  );
}
