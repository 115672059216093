import {Box, Paper} from '@mui/material';
import React from 'react';
import Controls from '../../components/Slider/Controls';
import Header from '../../components/Slider/Header';
import Stock from '../Job/Stock';
import JobAllocations from './JobAllocations';
import JobPanes from './JobPanes';
import JobVehicles from './JobVehicles';
import withJob from './withJob';

const Slide = ({job, onNext, onBack, updateSession, onFinish, onJobUpdated, onReloadJob}) => (
  <>
    <Header>Job Products</Header>

    <JobPanes job={job}/>

    <Paper component={Box} overflow="hidden" marginTop={2}>
      {job ? <Stock
        job={job}
        onJobUpdated={onJobUpdated}
        onReloadJob={onReloadJob}
      /> : null}
    </Paper>

    <Controls
      onBack={() => onBack({type: JobVehicles.type, id: JobVehicles.type})}
      onNext={() => onNext({type: JobAllocations.type, id: JobAllocations.type})}
      onFinish={() => onFinish(updateSession, job)}
    />
  </>
);

const JobProducts = withJob(Slide);

JobProducts.type = 'JOB_PRODUCTS';

export default JobProducts;
