import {BaseForm} from '@management-ui/core';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';
import orderOptions from './orderOptions';

// noinspection DuplicatedCode
const OrderJobForm = forwardRef(({order, job, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const [errors, setErrors] = useState([]);

  const handleSave = useCallback((
    {
      summary,
      description,
      company_id,
      contact_id,
      end_user_id,
      location_id
    }
  ) => {
    const errors = [];
    const {locations} = orderOptions(order);
    const location = locations.find(({value}) => `${value}` === location_id);
    if (!location) {
      errors.push('Please select a location');
    }
    if (!contact_id && !company_id) {
      errors.push('Please select at least a company or a contact');
    }
    setErrors(errors);
    return new Promise((resolve, reject) => {
      if (errors.length < 1) {
        services.job.saveJob({
          order_id: order.id,
          company_id,
          contact_id,
          end_user_id,
          summary,
          description,
          address_number: location.location.address_number,
          address1: location.location.address1,
          address2: location.location.address2,
          town: location.location.town,
          region: location.location.region,
          postcode: location.location.postcode,
          country_id: location.location.country_id
        }).then(saved => {
          if (order.note && !order.note.expires_at) {
            services.job.saveAllocation({
              job_id: saved.id,
              engineer_id: order.note.engineer.id,
              date: moment(order.note.date).format('YYYY-MM-DD'),
              start_at: moment(order.note.start_at).format('HH:mm:ss'),
              end_at: moment(order.note.end_at).format('HH:mm:ss')
            }).then(() => {
              services.calendarNote.expireNote(order.note).then(() => {
                resolve(saved);
              }).catch(() => reject());
            }).catch(() => reject())
          } else {
            resolve(saved);
          }
        }).catch(() => reject());
      } else {
        reject();
      }
    });
  }, [order, services]);

  return (
    <BaseForm
      ref={formRef}
      entity={job}
      type="job"
      fieldsComponent={(props, onLoading) => <Fields order={order} errors={errors} onLoading={onLoading} {...props}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default OrderJobForm;
