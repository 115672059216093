import {API} from './API';

export class ReportingService {
  async getPaymentYear(year) {
    return (await API.getConnection())
      .get(`reporting/payments/${year}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadExport() {
    return (await API.getConnection())
      .get('reporting/payments/export', {responseType: 'blob'})
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'octet/stream'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'AllPayments.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async parseData(file) {
    const body = new FormData();
    if (file) {
      body.append('data', file);
    }
    return (await API.getConnection())
      .post('reporting/payments/parse', body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data.rows)
      .catch(error => API.handleError(error));
  }
}
