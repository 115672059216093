import {Checkbox, Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({company = {}, validate = true, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={company}
      prefix={prefix}
      panes={[
        {
          title: 'Company Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Company Name"
              rules={validate ? {required: 'Please enter a company name'} : {}}
            />,
            <Checkbox
              name="is_installer"
              prefix={prefix}
              label="Is this company an installer?"
            />,
            <Checkbox
              name="is_manufacturer"
              prefix={prefix}
              label="Is this company a manufacturer?"
            />,
            <Checkbox
              name="is_supplier"
              prefix={prefix}
              label="Is this company a supplier?"
            />
          ]
        }, {
          title: 'Contact Details',
          fields: [
            <TextField
              name="phone"
              prefix={prefix}
              id="companyPhone"
              label="Telephone Number"
            />,
            <TextField
              name="website"
              prefix={prefix}
              label="Website Address"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Company Details'}
    >{children}</Panes>
  );
}
