import {BaseForm, ErrorPopup} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const ERRORS = {none: '', tooMany: ''};

const AllocateStockForm = forwardRef(({job, product, quantity, onAllocated}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  const [errors, setErrors] = useState(ERRORS);
  const locationRef = useRef(0);

  useImperativeHandle(ref, () => ({
    save(locationID = 0) {
      locationRef.current = locationID;
      formRef.current.save();
    }
  }));

  const handleAllocate = useCallback(({ids}) => {
    if (ids.length < 1) {
      setErrors({...ERRORS, none: `You require ${quantity} but have not selected any`});
      return new Promise((resolve, reject) => reject());
    }
    if (ids.length > quantity) {
      setErrors({...ERRORS, tooMany: `You have selected ${ids.length} but only require ${quantity}`});
      return new Promise((resolve, reject) => reject());
    }
    setErrors({...ERRORS});
    return services.stockItem.allocateItems(ids, job.id, locationRef.current);
  }, [services, job, quantity])

  return (
    <>
      <BaseForm
        ref={formRef}
        entity={useMemo(() => ({ids: []}), [])}
        type="allocation"
        fieldsComponent={(props) => <Fields {...props} product={product} quantity={quantity}/>}
        onSave={handleAllocate}
        onSaved={onAllocated}
      />
      <ErrorPopup
        errors={['none', 'tooMany']}
        messages={errors}
      />
    </>
  );
});

export default AllocateStockForm;
