import {name} from '@management-ui/core';
import React from 'react';
import AttributeUpdate from './AttributeUpdate';

const ATTRS = {
  company: {title: 'Company', render: (data) => `${data.company.name}`},
  contact: {title: 'Contact', render: (data) => name(data.contact)},
};

export default function CompanyContactLinked({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
