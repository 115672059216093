import {prefixWithSeparator, Spinner} from '@management-ui/core';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import {IconButton, Tooltip} from '@mui/material';
import React, {useCallback, useContext, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../components/Services';

const RegistrationLookup = ({name, prefix}) => {
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const {setValue, watch} = useFormContext();
  // noinspection JSCheckFunctionSignatures
  const registration = watch(`${prefixWithSeparator(prefix)}${name}`, null);

  const handleLookup = useCallback(() => {
    if (registration) {
      setLoading(true);
      services.vehicle.lookupRegistration(registration).then((data) => {
        Object.keys(data).forEach(key => {
          setValue(`${prefixWithSeparator(prefix)}${key}`, data[key]);
        });
        setLoading(false);
      }).catch(() => setLoading(false));
    }
  }, [services, registration, prefix, setValue]);

  return (

    <Tooltip title="Lookup Registration">
      <IconButton
        onClick={handleLookup}
        edge="end"
        disabled={loading}
      >
        {loading ? <Spinner/> : <DirectionsCarIcon/>}
      </IconButton>
    </Tooltip>
  )
}
export default RegistrationLookup;
