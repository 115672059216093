import {name} from '@management-ui/core';
import React from 'react';
import AttributeUpdate from './AttributeUpdate';
import {ADDRESS_ATTRS} from './LocationUpdate';

const ATTRS = {
  reference: {title: 'Reference', render: null},
  company: {title: 'Company', render: (data) => data.company ? `${data.company.name}` : ''},
  contact: {title: 'Contact', render: (data) => data.contact ? name(data.contact) : ''},
  installer: {title: 'Installer', render: (data) => data.installer ? `${data.installer.name}` : ''},
  summary: {title: 'Summary', render: null},
  description: {title: 'Description', render: null},
  ...ADDRESS_ATTRS
};

export default function JobUpdate({action}) {
  return <AttributeUpdate action={action} attrs={ATTRS}/>;
}
