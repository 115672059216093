import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

// noinspection DuplicatedCode
const JobDetailsForm = forwardRef(({job, onSaved}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={job}
      type="job"
      fieldsComponent={(props, onLoading) => <Fields onLoading={onLoading} {...props}/>}
      onSave={(update) => (new Promise(resolve => {
        const {latitude, longitude, ...data} = update;
        resolve(data);
      }))}
      onSaved={onSaved}
    />
  );
});

export default JobDetailsForm;
