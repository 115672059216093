import {Checkbox, name, Panes, prefixWithSeparator, Select} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';

export default function Fields({job, confirmation = {}, prefix = ''}) {
  const services = useContext(ServiceContext);
  const [contacts, setContacts] = useState([]);
  const [fitters, setFitters] = useState([]);
  const [loading, setLoading] = useState(false);
  const fitterRef = useRef('');
  const {register, setValue, /** @type {function} */watch} = useFormContext();

  useEffect(() => {
    if (job) {
      const recipients = [];
      if (job.contact?.email || job.contact?.mobile) {
        recipients.push(job.contact);
      }
      if (job.company?.contacts) {
        job.company.contacts.filter(c => !!c.email || !!c.mobile).forEach(contact => {
          const index = recipients.findIndex(c => c.id === contact.id);
          if (index < 0) {
            recipients.push(contact);
          }
        });
      }
      setContacts(recipients.map(r => ({title: name(r), value: r.id})));
    }
  }, [job]);

  const scorpionName = useMemo(() => `${prefixWithSeparator(prefix)}scorpion_required`, [prefix]);
  const safeTrackName = useMemo(() => `${prefixWithSeparator(prefix)}safe_track_required`, [prefix]);
  const scorpionRequired = watch(scorpionName);
  const safeTrackRequired = watch(safeTrackName);
  const fitterID = watch(useMemo(() => `${prefixWithSeparator(prefix)}scorpion_fitter_id`, [prefix]));
  const fitterName = useMemo(() => `${prefixWithSeparator(prefix)}scorpion_fitter_name`, [prefix]);
  register(fitterName);

  useEffect(() => {
    if (scorpionRequired) {
      setValue(safeTrackName, false);
    }
  }, [scorpionRequired, safeTrackName, setValue]);

  useEffect(() => {
    if (safeTrackRequired) {
      setValue(scorpionName, false);
    }
  }, [safeTrackRequired, scorpionName, setValue]);

  useEffect(() => {
    const load = (brand) => {
      if (fitterRef.current !== brand) {
        fitterRef.current = brand;
        setLoading(true);
        setFitters([]);
        services.job.getFitters(brand).then(list => {
          setFitters(list.map(({id, name, type}) => ({value: id, name, title: `${name} (${type})`})));
          setLoading(false);
        }).catch(() => setLoading(false));
      }
    };
    if (scorpionRequired) {
      load('safeandsound');
    } else if (safeTrackRequired) {
      load('safetrack');
    } else {
      fitterRef.current = '';
      setLoading(false);
      setFitters([]);
    }
  }, [services, scorpionRequired, safeTrackRequired]);

  useEffect(() => {
    const fitter = fitters.find(f => `${f.value}` === `${fitterID}`);
    setValue(fitterName, fitter?.name ?? '');
  }, [fitterID, fitters, fitterName, setValue]);

  const handleReset = useCallback((subject) => {
    if (subject.contact) {
      subject.contact_id = subject.contact.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={confirmation}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Customer',
          fields: [
            <Select
              name="contact_id"
              prefix={prefix}
              options={contacts}
              label="Contact"
              rules={{required: 'Please select a contact'}}
            />
          ]
        },
        {
          title: 'Information',
          fields: [
            <Checkbox
              name="general_required"
              prefix={prefix}
              label="General?"
            />,
            <Checkbox
              name="end_user_required"
              prefix={prefix}
              label="End User?"
            />,
            <Checkbox
              name="scorpion_required"
              prefix={prefix}
              label="Scorpion?"
            />,
            <Checkbox
              name="safe_track_required"
              prefix={prefix}
              label="Safe Track?"
            />,
            <>{loading ? <Box padding={2}>Loading Fitters...</Box> : null}</>,
            <Select
              name="scorpion_fitter_id"
              prefix={prefix}
              options={fitters}
              label="Fitter"
            />
          ]
        }
      ]}
      title={''}
    />
  );
}
