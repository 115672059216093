import {CircularProgress, Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';

const useStyles = makeStyles((theme) => ({
  window: {
    flex: 1,
    minHeight: 400,
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    width: '100%',
  },

  loadingMessage: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
    opacity: 0,
    padding: theme.spacing(2),
    position: 'absolute',
    right: 0,
    top: 0,
    transition: '0.25s opacity ease-in-out',

    '& span': {
      color: theme.palette.background.paper,
      fontSize: '1.3em',
      fontWeight: 700,
      marginLeft: theme.spacing(2),
    },
  },

  spinner: {
    color: theme.palette.background.paper,
  },

  loadingMessageVisible: {
    opacity: 1,
  },

  container: {
    float: 'left',
    transition: '0.25s opacity ease-in-out',
  },

  loading: {
    opacity: 0.4,
  },

  slide: {
    transition: '0.6s left ease-in-out',
    position: 'absolute',
    padding: theme.spacing(1),
    top: 0,
    width: '100%',
  },

  slideNext0: {
    left: 0,
  },

  slideNext1: {
    left: '100%',
  },

  slideBack0: {
    left: '-100%',
  },

  slideBack1: {
    left: 0,
  },

  next: {
    '& $slideNext0': {
      left: '-100%',
    },

    '& $slideNext1': {
      left: 0,
    }
  },

  back: {
    '& $slideBack0': {
      left: 0,
    },

    '& $slideBack1': {
      left: '100%',
    }
  }
}));

// noinspection JSCheckFunctionSignatures
const Slider = forwardRef(({onRenderSlide}, ref) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [visible, setVisible] = useState({animation: 'next', slides: []});
  const visibleID = useRef('');
  const [animating, setAnimating] = useState('');

  const handleNext = useCallback((slide) => {
    setVisible({animation: 'next', slides: [...visible.slides, slide]});
  }, [visible]);

  const handleBack = useCallback((slide) => {
    setVisible({animation: 'back', slides: [slide, ...visible.slides]})
  }, [visible]);

  useEffect(() => {
    if (visible.slides.length > 1) {
      setTimeout(() => {
        setAnimating(visible.animation);
        setTimeout(() => {
          setVisible({animation: 'next', slides: [visible.slides[(visible.animation === 'next') ? 1 : 0]]});
        }, 700);
      }, 100);

    } else {
      setAnimating('');
      visibleID.current = (visible.length > 0) ? visible[0].id : '';
    }
  }, [visible]);

  useImperativeHandle(ref, useCallback(() => ({
    next(slide) {
      handleNext(slide);
    },

    back(slide) {
      handleBack(slide);
    },

    setLoading(message = '') {
      setLoadingMessage(message);
      setLoading(true);
    },

    clearLoading() {
      setLoading(false);
    }
  }), [handleNext, handleBack]));

  return visible.slides.length > 0 ? (
    <div
      className={[classes.window, loading ? classes.loading : '', animating ? classes[animating] : ''].join(' ')}>
      <>
        {visible.slides.map((slide, index) => (
          <div
            key={slide.id}
            className={[classes.slide, classes[`slide${visible.animation.charAt(0).toUpperCase()}${visible.animation.slice(1)}${index}`]].join(' ')}
          >
            {onRenderSlide(slide)}
          </div>
        ))}
      </>
      <Paper className={[classes.loadingMessage, loading ? classes.loadingMessageVisible : ''].join(' ')}>
        <CircularProgress className={classes.spinner}/><span>{loadingMessage}</span>
      </Paper>
    </div>
  ) : null;
});

export default Slider;
