import {DateTimeField, Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({request = {}, prefix = ''}) => (
  <Panes
    entity={request}
    prefix={prefix}
    panes={[
      {
        title: 'When should these date slots expire?',
        fields: [
          <DateTimeField
            name="expires"
            prefix={prefix}
            label="Select an expiry date and time"
            rules={{required: 'Please select an expiry date and time'}}
          />
        ]
      },
      {
        title: 'Comments',
        fields: [
          <TextField
            name="response"
            prefix={prefix}
            label="Provide a message for the customer"
            fieldProps={{multiline: true, rows: 5}}
          />,
        ]
      }
    ]}
    title={''}
  />
);

export default Fields;
