import {ConfirmationDialog, DetailColumns, DetailPane, fullDate, fullDateTime, name, time} from '@management-ui/core';
import {Alert, Box, Menu, MenuItem, Snackbar} from '@mui/material';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import DetailDialog from '../../../components/DetailDialog';
import {ServiceContext} from '../../../components/Services';
import EngineerLabel from './EngineerLabel';
import Status from './Status';

const AllocationDetailsDialog = ({allocation, onAllocationUpdated, open, onClose, onDeleted}) => {
  const services = useContext(ServiceContext);
  const [showEngineer, setShowEngineer] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);

  useEffect(() => {
    if (allocation) {
      const emailAddresses = [];
      const phoneNumbers = [];
      if (allocation.job?.contact?.email) {
        emailAddresses.push({contact: allocation.job.contact, type: 'email'});
      }
      if (allocation.job?.contact?.mobile) {
        phoneNumbers.push({contact: allocation.job.contact, type: 'sms'});
      }
      if (allocation.job?.company?.contacts) {
        allocation.job.company.contacts.forEach(contact => {
          if (contact.email) {
            const index = emailAddresses.findIndex(({contact: c}) => c.id === contact.id);
            if (index < 0) {
              emailAddresses.push({contact, type: 'email'});
            }
          }
          if (contact.mobile) {
            const index = phoneNumbers.findIndex(({contact: c}) => c.id === contact.id);
            if (index < 0) {
              phoneNumbers.push({contact, type: 'sms'});
            }
          }
        });
      }
      setContacts([...emailAddresses, ...phoneNumbers]);
    }
  }, [allocation]);

  const handleShouldSend = useCallback((event) => {
    setMenuAnchor(event.currentTarget);
    setShowContacts(true);
  }, []);

  const handleNotify = useCallback(confirmed => {
    setShowNotify(false);
    if (confirmed) {
      services.job.notifyCustomer(allocation, contact.contact, contact.type).then(() => {
        setShowSent(true);
      }).catch(() => null);
    }
  }, [services, allocation, contact]);

  const handleEngineer = useCallback((confirmed) => {
    if (confirmed) {
      services.job.notifyEngineer(allocation).then((updated) => {
        onAllocationUpdated(updated);
        setShowSent(true);
      }).catch(() => null);
    }
    setShowEngineer(false);
  }, [services, allocation, onAllocationUpdated]);

  const handleAccept = useCallback(confirmed => {
    setShowAccept(false);
    if (confirmed) {
      services.job.acceptAllocation(allocation).then((accepted) => {
        onAllocationUpdated(accepted);
      }).catch(() => null);
    }
  }, [services, allocation, onAllocationUpdated]);

  const handleCancel = useCallback(confirmed => {
    setShowCancel(false);
    if (confirmed) {
      services.job.cancelAllocation(allocation).then((cancelled) => {
        onAllocationUpdated(cancelled);
      }).catch(() => null);
    }
  }, [services, allocation, onAllocationUpdated]);

  const handleDelete = useCallback(confirmed => {
    setShowDelete(false);
    if (confirmed) {
      services.job.deleteAllocation(allocation).then(() => {
        onClose();
        onDeleted();
      }).catch(() => null);
    }
  }, [services, allocation, onClose, onDeleted]);

  return allocation ? (
    <>
      <DetailDialog
        title={`${fullDate(allocation.date)} | ${time(allocation.start_at)} - ${time(allocation.end_at)}`}
        maxWidth="md"
        open={open}
        onClose={onClose}
        actions={[
          {label: 'Send to Engineer', colour: 'secondary', variant: 'outlined', onClick: () => setShowEngineer(true)},
          ...(contacts.length > 0 ? [
            {label: 'Send to Customer', colour: 'secondary', variant: 'outlined', onClick: handleShouldSend}
          ] : []),
          ...(!allocation.accepted_at && !allocation.cancelled_at ? [
            {label: 'Accept', colour: 'success', onClick: () => setShowAccept(true)}
          ] : []),
          ...(allocation.sent_at && !allocation.cancelled_at ? [
            {label: 'Cancel', colour: 'error', onClick: () => setShowCancel(true)}
          ] : []),
          {label: 'Delete', onClick: () => setShowDelete(true)},
          {label: 'Close', colour: 'primary', onClick: () => onClose()}
        ]}
      >
        <DetailColumns columns={[
          <DetailPane
            title="Allocation Details"
            minHeight={400}
            details={[
              {title: 'Job Reference', value: allocation.job.reference},
              {
                title: 'Engineer',
                value: (
                  <Box marginBottom={1}>
                    <EngineerLabel engineer={allocation.engineer}/>
                  </Box>
                )
              },
              {title: 'Date', value: fullDate(allocation.date)},
              {title: 'Start Time', value: time(allocation.start_at)},
              {title: 'End Time', value: time(allocation.end_at)}
            ]}
          />,
          <DetailPane
            title="Admin Details"
            details={[
              {title: 'Status', value: <Box marginBottom={1}><Status entity={allocation}/></Box>},
              {title: 'Created', value: fullDateTime(allocation.created_at)},
              {title: 'Last Updated', value: fullDateTime(allocation.updated_at)},
              ...(allocation.sent_at ? [
                {title: 'Date/Time Sent', value: fullDateTime(allocation.sent_at)},
              ] : []),
              ...(allocation.accepted_at ? [
                {title: 'Date/Time Accepted', value: fullDateTime(allocation.accepted_at)},
              ] : []),
              ...(allocation.rejected_at ? [
                {title: 'Date/Time Rejected', value: fullDateTime(allocation.rejected_at)},
              ] : []),
              ...(allocation.cancelled_at ? [
                {title: 'Date/Time Cancelled', value: fullDateTime(allocation.cancelled_at)},
              ] : [])
            ]}
          />,
        ]}/>
      </DetailDialog>
      <ConfirmationDialog
        open={showNotify}
        title="Send to Customer"
        message={contact ? `Are you sure you want to send details of this booking to ${contact.type === 'email' ? contact.contact.email : contact.contact.mobile}?` : ''}
        onClose={handleNotify}
      />
      <ConfirmationDialog
        open={showEngineer}
        title="Send Booking?"
        message="Are you sure you want to send this booking to the engineer?"
        onClose={handleEngineer}
      />
      <ConfirmationDialog
        open={showAccept}
        title="Accept"
        message="Are you sure you want to accept this allocation? The allocation's status will change to 'Booked'"
        onClose={handleAccept}
      />
      <ConfirmationDialog
        open={showCancel}
        title="Cancel"
        message={'Are you sure you want to cancel this allocation? The engineer will be notified'}
        onClose={handleCancel}
      />
      <ConfirmationDialog
        open={showDelete}
        title="Delete"
        message={'Are you sure you want to delete this allocation?'}
        onClose={handleDelete}
      />
      <Menu
        anchorEl={menuAnchor}
        open={showContacts}
        onClose={() => setShowContacts(false)}
      >
        {contacts.map((c, index) => (
          <MenuItem key={index} onClick={() => setContact(c) || setShowContacts(false) || setShowNotify(true)}>
            {c.type === 'email' ? `Email: ${name(c.contact)} - ${c.contact.email}` : `SMS: ${name(c.contact)} - ${c.contact.mobile}`}
          </MenuItem>
        ))}
      </Menu>
      <Snackbar open={showSent} autoHideDuration={4000} onClose={() => setShowSent(false)}>
        <Alert onClose={() => setShowSent(false)} severity="success">
          The notification has been sent
        </Alert>
      </Snackbar>
    </>
  ) : null;
}

export default AllocationDetailsDialog;
