import {Breadcrumbs, ErrorPopup, Notice, Title} from '@management-ui/core';
import {reverse} from 'named-urls';
import React from 'react';
import routes from '../../../../routes';

const Wrapper = ({title, loading, titleControls, job, crumbs, errors, children}) => (
  <>
    <Breadcrumbs crumbs={crumbs}/>
    <Title title={title} loading={loading} controls={titleControls}/>
    <Notice
      display={job && job.archived}
      message={[
        'This job has been archived and is therefore no longer available.',
        'You won\'t be able to modify it unless it is restored.'
      ].join(' ')}
    />
    {children}
    <ErrorPopup errors={['job']} messages={errors}/>
  </>
);

Wrapper.standardCrumbs = [
  {title: 'Jobs', link: routes.jobs.index}
];
Wrapper.archiveCrumbs = [
  {title: 'Archive', link: reverse(routes.archive.index)},
  {title: 'Jobs', link: reverse(routes.archive.index, {tab: 'jobs'})}
];

export default Wrapper;
