import {ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Add, Link, LinkOff, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import CompanyForm from '../../forms/CompanyForm';
import LinkCompanyForm from '../../forms/LinkCompanyForm';

const Companies = ({contact, onReloadContact, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [showNew, setShowNew] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleUnlink = useCallback((confirmed) => {
    setShowUnlink(false);
    if (confirmed) {
      onLoading(true);
      services.company.linkContact(selected, contact).then(() => {
        onLoading(false);
        onReloadContact();
      }).catch(() => onLoading(false));
    }
  }, [contact, onLoading, services, selected, onReloadContact]);

  const goToDetail = useCallback((company) => {
    history.push(reverse(company.archived ? routes.archive.companies.tab : routes.companies.tab, {
      id: company.id,
      tab: company.is_end_user ? 'end-users' : 'contacts'
    }));
  }, [history]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Companies</h3>
          <Tooltip title="Link Existing Company">
            <span>
              <IconButton
                onClick={() => setShowLink(true)}
                disabled={contact.archived}>
                <Link/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Add New Company">
            <span>
              <IconButton
                onClick={() => setShowNew(true)}
                disabled={contact.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Name</Table.Header>
          <Table.Header>Telephone</Table.Header>
          <Table.Header>Website</Table.Header>
          <Table.Header>End User?</Table.Header>
          <Table.Action/>
          <Table.Action/>
        </Table.Row>
        {contact.companies.length > 0 ? (
          contact.companies.map((company, index) => (
            <Table.Row key={index}>
              <Table.Column>
                <span>
                  {company.name}{company.archived ? <><br/><strong>Archived</strong></> : null}
                </span>
              </Table.Column>
              <Table.Column>{company.phone ?? '-'}</Table.Column>
              <Table.Column>{company.website ?? '-'}</Table.Column>
              <Table.Column><YesNo value={company.is_end_user}/></Table.Column>
              <Table.Action>
                <Tooltip title="View Company">
                  <IconButton onClick={() => goToDetail(company)}>
                    <Visibility/>
                  </IconButton>
                </Tooltip>
              </Table.Action>
              <Table.Action>
                <Tooltip title="Unlink Company">
                  <span>
                    <IconButton
                      onClick={() => setSelected(company) || setShowUnlink(true)}
                      disabled={contact.archived}>
                      <LinkOff/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no companies associated with this contact</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        title="Add New Company"
        open={showNew}
        maxWidth="md"
        onClose={() => setShowNew(false)}
        render={(props) => (
          <CompanyForm
            {...props}
            company={{}}
            contact={contact}
            onSaved={() => setShowNew(false) || onReloadContact()}
          />
        )}
      />
      <FormDialog
        title="Link Existing Company"
        open={showLink}
        maxWidth="sm"
        onClose={() => setShowLink(false)}
        render={props => (
          <LinkCompanyForm
            company={{}}
            contact={contact}
            onSaved={() => setShowLink(false) || onReloadContact()}
            {...props}
          />
        )}
      />
      <ConfirmationDialog
        title="Unlink Company"
        message="Are you sure you want to unlink this company?"
        open={showUnlink}
        onClose={handleUnlink}
      />
    </>
  );
};

export default Companies;
